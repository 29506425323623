import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "flex flex-row w-full md:flex-col flex-shrink-0 h-full items-center md:border-r-2 md:border-gray-200 bg-sideBar" };
const _hoisted_2 = { class: "flex-shrink-0" };
const _hoisted_3 = { class: "md:px-2 md:py-4 hidden md:block flex items-center justify-center text-xl font-bold tracking-wider uppercase" };
const _hoisted_4 = { class: "flex flex-row md:flex-col items-center md:justify-center flex-1 w-full" };
const _hoisted_5 = {
    key: 0,
    class: "hidden md:flex flex-col items-center md:justify-center flex-shrink-0"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("nav", null, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("a", _hoisted_3, [
                    _renderSlot(_ctx.$slots, "title")
                ])
            ]),
            _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "default")
            ]),
            (_ctx.$slots.append)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _renderSlot(_ctx.$slots, "append")
                ]))
                : _createCommentVNode("", true)
        ])
    ]));
}
