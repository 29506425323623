import { defineComponent, onMounted, ref } from 'vue';
import Avatar from '@/components/general/Avatar.vue';
import { mapGetters } from 'vuex';
import { Language } from '@/types';
export default defineComponent({
    name: 'DefaultLanguageSelector',
    components: {
        Avatar,
    },
    props: { defaultLanguage: String },
    emits: ['selectedLanguage'],
    setup({ defaultLanguage }, ctx) {
        const dropDownOpen = ref(false);
        const selectedLang = ref();
        onMounted(() => {
            setSelected();
        });
        const langs = [
            {
                key: Language.NL,
                shorthand: 'nl',
                longhand: 'Nederlands',
            },
            {
                key: Language.EN,
                shorthand: 'en',
                longhand: 'English',
            },
            {
                key: Language.FR,
                shorthand: 'fr',
                longhand: 'Français',
            },
        ];
        const setSelected = () => {
            selectedLang.value = defaultLanguage;
        };
        const setDefaultLang = lang => {
            selectedLang.value = lang;
            ctx.emit('selectedLanguage', lang);
            dropDownOpen.value = !dropDownOpen.value;
        };
        const closeDropdown = () => {
            dropDownOpen.value = false;
        };
        const getLang = () => {
            var _a;
            return (_a = langs.find(lang => lang.shorthand == selectedLang.value)) === null || _a === void 0 ? void 0 : _a.longhand;
        };
        return {
            dropDownOpen,
            langs,
            selectedLang,
            Language,
            setDefaultLang,
            closeDropdown,
            getLang,
        };
    },
    computed: Object.assign({}, mapGetters(['currentUser'])),
});
