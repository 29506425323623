import { Language } from '@/types';
import moment from 'moment';
export var UserMutationTypes;
(function (UserMutationTypes) {
    UserMutationTypes["SET_PERSONAL_SPACES"] = "SET_PERSONAL_SPACES";
    UserMutationTypes["SET_CURRENT_USER"] = "SET_CURRENT_USER";
    UserMutationTypes["SET_CURRENT_LANGUAGE"] = "SET_CURRENT_LANGUAGE";
})(UserMutationTypes || (UserMutationTypes = {}));
export const userMutations = {
    [UserMutationTypes.SET_PERSONAL_SPACES](state, personalSpaces) {
        state.personalSpaces = personalSpaces;
    },
    [UserMutationTypes.SET_CURRENT_USER](state, currentUser) {
        state.currentUser = currentUser;
    },
    [UserMutationTypes.SET_CURRENT_LANGUAGE](state, selectedLanguage) {
        moment.updateLocale(selectedLanguage, [Language.NL, Language.EN]);
        localStorage.setItem('selectedLanguage', selectedLanguage);
        state.selectedLanguage = selectedLanguage;
    },
};
