import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "font-noto" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_top_bar = _resolveComponent("top-bar");
    const _component_idle_component = _resolveComponent("idle-component");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_top_bar, null, {
            leftButton: _withCtx(() => [
                _renderSlot(_ctx.$slots, "leftButton")
            ]),
            title: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, [
                    _renderSlot(_ctx.$slots, "title")
                ])
            ]),
            _: 3
        }),
        (_openBlock(), _createElementBlock("section", {
            class: "h-full w-full overflow-hidden bg-white z-0",
            key: _ctx.selectedLanguage
        }, [
            _renderSlot(_ctx.$slots, "content")
        ])),
        _createVNode(_component_idle_component)
    ], 64));
}
