import { __awaiter } from "tslib";
import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue';
import Avatar from '../general/Avatar.vue';
import SearchBar from '@/components/vault/SearchBar.vue';
import { useStore } from '@/store';
import { Role } from '@/types';
import { formatDate, translate } from '@/filters';
import { mapGetters } from 'vuex';
import UserSidebar from '@/components/vault/sidebars/UserSidebar.vue';
import Button from '@/components/general/Button.vue';
import { ScreenWidth, SortType, Table } from '@jimber/shared-components';
import { getVaultUsers } from '@/services/admin.service';
import EmptyTableState from '@/components/general/emptyStates/EmptyTableState.vue';
import { config } from '@/config';
export default defineComponent({
    name: 'AdminUserOverview',
    components: {
        Avatar,
        SearchBar,
        UserSidebar,
        Button,
        Table,
        EmptyTableState,
    },
    emits: ['clickedUser', 'showEditUser', 'showInvitation'],
    setup(props, ctx) {
        const store = useStore();
        const isLoading = ref(true);
        const selectedUser = ref(undefined);
        const vaultUsers = ref([]);
        const userSearch = ref('');
        const searchedVaultUsers = ref([]);
        const sortedField = ref({
            prop: 'lastAccessed',
            order: SortType.DESCENDING,
        });
        const headers = computed(() => [
            {
                key: 'username',
                displayName: translate('tables.headers.username'),
                enableSorting: true,
            },
            {
                key: 'vaults',
                displayName: translate('tables.headers.vaults'),
                displayWidth: ScreenWidth.Desktop,
            },
            {
                key: 'lastAccessed',
                displayName: translate('tables.headers.lastSignedIn'),
                enableSorting: true,
                formatter: entry => {
                    if (!entry.lastAccessed) {
                        return translate('tables.headers.neverLoggedIn');
                    }
                    const date = new Date(entry.lastAccessed);
                    return formatDate(date);
                },
            },
            {
                key: 'role',
                displayName: translate('tables.headers.userRoles'),
                enableSorting: true,
            },
        ]);
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            vaultUsers.value = yield getVaultUsers({
                skip: 0,
                take: 25,
                field: (_a = sortedField.value) === null || _a === void 0 ? void 0 : _a.prop,
                order: (_b = sortedField.value) === null || _b === void 0 ? void 0 : _b.order,
            });
            isLoading.value = false;
        }));
        watch(userSearch, (value) => __awaiter(this, void 0, void 0, function* () {
            var _c, _d;
            if (value === '') {
                return;
            }
            const newSearchedUsers = yield getVaultUsers({
                value,
                skip: 0,
                take: 25,
                field: (_c = sortedField.value) === null || _c === void 0 ? void 0 : _c.prop,
                order: (_d = sortedField.value) === null || _d === void 0 ? void 0 : _d.order,
            });
            if (newSearchedUsers.length <= 0) {
                searchedVaultUsers.value = [];
                return;
            }
            searchedVaultUsers.value = newSearchedUsers;
        }));
        const sortUsers = () => __awaiter(this, void 0, void 0, function* () {
            var _e, _f;
            const newUsers = yield getVaultUsers({
                value: userSearch.value !== '' ? userSearch.value : undefined,
                skip: 0,
                take: 25,
                field: (_e = sortedField.value) === null || _e === void 0 ? void 0 : _e.prop,
                order: (_f = sortedField.value) === null || _f === void 0 ? void 0 : _f.order,
            });
            if (newUsers.length <= 0) {
                return;
            }
            if (userSearch.value !== '') {
                searchedVaultUsers.value = newUsers;
                return newUsers;
            }
            vaultUsers.value = newUsers;
        });
        const getMoreUsers = ({ index }) => __awaiter(this, void 0, void 0, function* () {
            var _g, _h;
            const newUsers = yield getVaultUsers({
                value: userSearch.value !== '' ? userSearch.value : undefined,
                skip: index * 25,
                take: 25,
                field: (_g = sortedField.value) === null || _g === void 0 ? void 0 : _g.prop,
                order: (_h = sortedField.value) === null || _h === void 0 ? void 0 : _h.order,
            });
            if (newUsers.length <= 0) {
                return [];
            }
            if (userSearch.value !== '') {
                searchedVaultUsers.value = searchedVaultUsers.value.concat(newUsers);
                return newUsers;
            }
            vaultUsers.value = vaultUsers.value.concat(newUsers);
            return newUsers;
        });
        const users = computed(() => (userSearch.value !== '' ? searchedVaultUsers.value : vaultUsers.value));
        const selectedChanged = item => {
            if (item.selectedItems.length <= 0) {
                selectedUser.value = null;
                return;
            }
            selectedUser.value = item.selectedItems[0];
        };
        const showEditUserModal = (user) => {
            ctx.emit('showEditUser', user);
        };
        return {
            store,
            users,
            headers,
            userSearch,
            selectedUser,
            sortedField,
            isLoading,
            Role,
            formatDate,
            showEditUserModal,
            selectedChanged,
            getMoreUsers,
            sortUsers,
            isAppPreregistrationEnabled: config.value.env.isAppPreregistrationEnabled,
        };
    },
    computed: Object.assign({}, mapGetters(['showEdit'])),
});
