<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-sideBarIcons stroke-current fill-current">
    <path
      d="M12.3939 3.04915C13.0572 0.31695 16.9428 0.316951 17.6061 3.04915C18.0346 4.81411 20.0567 5.6517 21.6077 4.70666C24.0087 3.24372 26.7563 5.99132 25.2933 8.39229C24.3483 9.94329 25.1859 11.9654 26.9509 12.3939C29.6831 13.0572 29.6831 16.9428 26.9509 17.6061C25.1859 18.0346 24.3483 20.0567 25.2933 21.6077C26.7563 24.0087 24.0087 26.7563 21.6077 25.2933C20.0567 24.3483 18.0346 25.1859 17.6061 26.9509C16.9428 29.6831 13.0572 29.6831 12.3939 26.9509C11.9654 25.1859 9.94329 24.3483 8.39229 25.2933C5.99132 26.7563 3.24372 24.0087 4.70666 21.6077C5.6517 20.0567 4.81411 18.0346 3.04915 17.6061C0.316951 16.9428 0.31695 13.0572 3.04915 12.3939C4.81411 11.9654 5.65169 9.94329 4.70665 8.39229C3.24372 5.99132 5.99132 3.24372 8.39229 4.70665C9.94329 5.65169 11.9654 4.81411 12.3939 3.04915Z"
      stroke="#111827"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.6667 15C19.6667 17.5773 17.5773 19.6667 15 19.6667C12.4227 19.6667 10.3333 17.5773 10.3333 15C10.3333 12.4227 12.4227 10.3333 15 10.3333C17.5773 10.3333 19.6667 12.4227 19.6667 15Z"
      stroke="#111827"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'SettingsNavigationIcon',
  });
</script>

<style scoped></style>
