import { defineComponent } from 'vue';
import Modal from '@/components/general/Modal.vue';
export default defineComponent({
    name: 'SpinnerModal',
    components: { Modal },
    props: {
        transparent: {
            type: Boolean,
            default: false,
        },
    },
});
