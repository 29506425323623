import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { class: "flex flex-col h-full overflow-auto border overflow-x-auto border-gray-200 sm:rounded-lg" };
const _hoisted_2 = { class: "w-full divide-y divide-gray-200" };
const _hoisted_3 = { class: "bg-gray-50" };
const _hoisted_4 = ["id"];
const _hoisted_5 = { class: "p-2" };
const _hoisted_6 = {
    colspan: "3",
    class: "w-full text-center py-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("table", _hoisted_2, [
            _createElementVNode("thead", _hoisted_3, [
                _createElementVNode("tr", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
                        return (_openBlock(), _createElementBlock("th", {
                            scope: "col",
                            key: header,
                            id: header,
                            class: "px-2 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                        }, _toDisplayString(_ctx.$t(`tables.headers.${header}`)), 9, _hoisted_4));
                    }), 128))
                ])
            ]),
            _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (value) => {
                    return (_openBlock(), _createElementBlock("tr", { key: value }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedFields, (selectedField) => {
                            return (_openBlock(), _createElementBlock("td", {
                                class: "p-1.5 whitespace-nowrap text-center text-xs font-medium text-gray-900",
                                key: selectedField
                            }, [
                                (_openBlock(), _createElementBlock("span", {
                                    key: value[selectedField],
                                    class: "text-left"
                                }, _toDisplayString(Date.parse(value[selectedField]) ? _ctx.formatDate(value[selectedField]) : value[selectedField]), 1))
                            ]));
                        }), 128))
                    ]));
                }), 128)),
                _withDirectives(_createElementVNode("tr", _hoisted_5, [
                    _createElementVNode("td", _hoisted_6, [
                        _createElementVNode("button", {
                            class: "bg-primary text-white px-4 capitalize",
                            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('showMore')))
                        }, _toDisplayString(_ctx.$t('tables.more')), 1)
                    ])
                ], 512), [
                    [_vShow, _ctx.hasMore]
                ])
            ])
        ])
    ]));
}
