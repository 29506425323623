import { __awaiter } from "tslib";
import Button from '@/components/general/Button.vue';
import Modal from '@/components/general/Modal.vue';
import Tab from '@/components/general/Tab.vue';
import Form from '@/components/general/form/Form.vue';
import { isSeedPhrase } from '@/filters';
import { checkIfEmailAlreadyExists, recoverVaultUser, updateUser } from '@/services/admin.service';
import { useStore } from '@/store';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { Language, Role, UserRoles } from '@/types';
import { InputType } from '@/types/input/inputDetails';
import { ToastType } from '@/types/toast/toast.enum';
import validator from 'validator';
import { defineComponent, ref } from 'vue';
import { mapGetters } from 'vuex';
import { config } from '@/config';
export default defineComponent({
    name: 'EditUserModal',
    components: { Tab, Button, Modal, Form },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        user: {
            type: Object,
        },
    },
    emits: ['update:show', 'editSuccess'],
    setup(props, ctx) {
        const store = useStore();
        const editableUser = ref(props.user);
        const acceptedWarning = ref(false);
        const tabs = ['edit', 'recover'];
        const currentTab = ref(tabs[0]);
        const recoverUserForm = ref({
            name: 'RecoverUserForm',
            inputs: [
                {
                    name: 'seedPhrase',
                    label: 'seedPhrase',
                    type: InputType.TEXT_AREA,
                    validation: { lazy: true, required: true, minLength: 50, validator: isSeedPhrase },
                    placeholder: '',
                },
                {
                    name: 'language',
                    label: 'language',
                    type: InputType.SELECT,
                    canChange: true,
                    selectedValue: Language.EN,
                    items: Object.keys(Language).map(lang => {
                        return { value: lang.toLowerCase() };
                    }),
                },
            ],
            submit: 'recover',
            cancel: 'cancel',
        });
        const editUserForm = ref({
            name: 'EditUserForm',
            inputs: [
                {
                    name: 'firstname',
                    label: 'firstname',
                    type: InputType.TEXT,
                    value: editableUser.value.firstname,
                    validation: { maxLength: 25, required: true },
                    placeholder: '',
                },
                { name: 'lastname', label: 'lastname', type: InputType.TEXT, value: editableUser.value.lastname, validation: { maxLength: 25, required: true }, placeholder: '' },
                {
                    name: 'email',
                    label: 'email',
                    type: InputType.TEXT,
                    value: editableUser.value.email,
                    validation: {
                        maxLength: 100,
                        required: true,
                        validator: validator.isEmail,
                        lazy: true,
                    },
                    placeholder: '',
                },
                {
                    name: 'role',
                    type: InputType.SELECT,
                    label: 'role',
                    capitalize: true,
                    canChange: props.user.role !== Role.ADMIN,
                    selectedValue: props.user.role,
                    items: Object.values(UserRoles).map(role => {
                        return { value: role };
                    }),
                    checkboxWarning: {
                        condition: UserRoles.ADMIN,
                        message: 'forms.messages.accessMessage',
                    },
                },
            ],
            submit: 'edit',
            cancel: 'cancel',
        });
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        const editUserDetails = (formData) => __awaiter(this, void 0, void 0, function* () {
            if (!formData.isValid) {
                return;
            }
            if (editableUser.value.email != formData.values.email.value) {
                const emailExists = yield checkIfEmailAlreadyExists(formData.values.email.value);
                if (emailExists) {
                    store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, { type: ToastType.ERROR, message: `emailAlreadyTaken` });
                    return;
                }
            }
            const roleHasChanged = props.user.role !== formData.values.role.value;
            editableUser.value.firstname = formData.values.firstname.value;
            editableUser.value.lastname = formData.values.lastname.value;
            editableUser.value.email = formData.values.email.value;
            editableUser.value.role = formData.values.role.value;
            const updatedUser = yield updateUser(editableUser.value, roleHasChanged);
            if (!updatedUser) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, { type: ToastType.ERROR, message: `failedToEdit`, data: { name: props.user.username } });
                return;
            }
            closeModal();
            store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, { type: ToastType.SUCCESS, message: `userSuccessFullyUpdated` });
        });
        const sendUserRecovery = (formData) => __awaiter(this, void 0, void 0, function* () {
            if (!formData.isValid) {
                return;
            }
            const recoverUser = yield recoverVaultUser(props.user.id, formData.values.seedPhrase.value, formData.values.language.value);
            if (!recoverUser) {
                return;
            }
            closeModal();
            store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, { type: ToastType.SUCCESS, message: `userRecoverySent` });
        });
        return {
            editUserForm,
            recoverUserForm,
            tabs,
            acceptedWarning,
            editableUser,
            currentTab,
            config: config.value,
            closeModal,
            editUserDetails,
            sendUserRecovery,
        };
    },
    computed: Object.assign({}, mapGetters(['showUserEdit'])),
});
