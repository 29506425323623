import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache) {
    const _component_Form = _resolveComponent("Form");
    const _component_modal = _resolveComponent("modal");
    return (_ctx.show)
        ? (_openBlock(), _createBlock(_component_modal, {
            key: 0,
            onClose: _cache[1] || (_cache[1] = ($event) => (_ctx.closeModal()))
        }, {
            header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('passwordManager.modals.editPassword')), 1)
            ]),
            default: _withCtx(() => [
                _createVNode(_component_Form, {
                    "form-data": _ctx.form,
                    "onUpdate:form-data": _cache[0] || (_cache[0] = ($event) => ((_ctx.form) = $event)),
                    onSubmit: _ctx.editPassword,
                    onCancel: _ctx.closeModal
                }, null, 8, ["form-data", "onSubmit", "onCancel"])
            ]),
            _: 1
        }))
        : _createCommentVNode("", true);
}
