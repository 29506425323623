import { defineComponent, onMounted, computed } from 'vue';
import { ToastType } from '@/types/toast/toast.enum';
import { translate } from '@/filters';
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationIcon, InformationCircleIcon, XIcon } from '@heroicons/vue/outline';
export default defineComponent({
    name: 'Toast',
    components: {
        CheckCircleIcon,
        ExclamationCircleIcon,
        InformationCircleIcon,
        ExclamationIcon,
        XIcon,
    },
    emits: ['remove-toast'],
    props: {
        showDelay: {
            type: Number,
            default: 6000,
        },
        toastData: Object,
    },
    setup({ showDelay, toastData }, ctx) {
        let timeout;
        const close = () => {
            ctx.emit('remove-toast');
            clearTimeout(timeout);
        };
        const toastProgress = computed(() => { var _a; return (_a = toastData.data) === null || _a === void 0 ? void 0 : _a.progress; });
        const iconColor = computed(() => {
            switch (toastData.type) {
                case ToastType.ERROR:
                    return 'red';
                case ToastType.WARNING:
                    return 'yellow';
                case ToastType.INFO:
                    return 'blue';
                case ToastType.SUCCESS:
                    return 'green';
            }
        });
        const toastTitle = computed(() => translate(`error.title.${toastData.message}`, null, toastData.data));
        onMounted(() => {
            timeout = setTimeout(() => {
                if (toastProgress.value && toastProgress.value != 100) {
                    return;
                }
                ctx.emit('remove-toast');
                clearTimeout(timeout);
            }, showDelay);
        });
        return {
            iconColor,
            toastTitle,
            ToastType,
            close,
            translate,
            toastProgress,
            toastData,
        };
    },
});
