import { __awaiter } from "tslib";
import { store } from '@/store';
import { UserMutationTypes } from '@/store/modules/users/user.mutations';
import { DockerStatus } from '@/types/socket/dockerStatus.enum';
import { convertToTEntry } from '@/utils';
import { FileManagerMutationTypes } from '@/store/modules/FileManager/fileManager.mutations';
import { Role } from '@/types';
import { VaultMutationTypes } from '@/store/modules/vault/vault.mutations';
import { router } from '@/main';
export const joinVault = (personalSpace) => {
    const currentPersonalSpaces = [...store.getters.personalWorkspaces];
    currentPersonalSpaces.push(personalSpace);
    store.commit(UserMutationTypes.SET_PERSONAL_SPACES, currentPersonalSpaces);
};
export const leaveVault = (personalSpace) => __awaiter(void 0, void 0, void 0, function* () {
    const currentPersonalSpaces = [...store.getters.personalWorkspaces];
    const spaceIndex = currentPersonalSpaces.findIndex(space => space.id === personalSpace.id);
    if ((router.currentRoute.value.params.spaceId !== personalSpace.id && router.currentRoute.value.name !== 'Selection') || spaceIndex === -1) {
        return;
    }
    currentPersonalSpaces.splice(spaceIndex, 1);
    store.commit(UserMutationTypes.SET_PERSONAL_SPACES, currentPersonalSpaces);
    if (router.currentRoute.value.name === 'Selection') {
        return;
    }
    yield router.push({ name: 'Selection' });
});
export const updateDockerStatus = (status) => {
    if (status === DockerStatus.INACTIVE) {
        return router.push({ name: 'Logout' });
    }
    const updatedUser = Object.assign(Object.assign({}, store.getters.currentUser), { dockerStatus: status });
    store.commit(UserMutationTypes.SET_CURRENT_USER, updatedUser);
};
export const updateFileAccessHistory = ({ item, fileUuid }) => {
    var _a;
    if (((_a = convertToTEntry(store.getters.selectedItemDetails)) === null || _a === void 0 ? void 0 : _a.id) !== fileUuid) {
        return;
    }
    store.commit(FileManagerMutationTypes.SET_SELECTED_ITEM_DETAILS, Object.assign(Object.assign({}, store.getters.selectedItemDetails), { accessHistory: store.getters.selectedItemDetails.accessHistory.concat([item]) }));
};
export const updateVault = ({ item }) => {
    if (store.getters.currentUser === Role.ADMIN) {
        store.commit(FileManagerMutationTypes.UPDATE_WORKSPACE, item);
    }
    const currentPersonalSpaces = store.getters.personalWorkspaces;
    currentPersonalSpaces.push(item);
    store.commit(UserMutationTypes.SET_PERSONAL_SPACES, currentPersonalSpaces);
};
export const updateVaultUser = ({ user }) => {
    if (store.getters.currentUser === Role.ADMIN && store.getters.vaultUsers.some(vaultUser => vaultUser.id === user.id)) {
        store.commit(VaultMutationTypes.UPDATE_VAULT_USER, user);
    }
    if (store.getters.selectedVaultUsers.some(vaultUser => vaultUser.id === user.id)) {
        store.commit(VaultMutationTypes.UPDATE_VAULT_USER, user);
    }
};
