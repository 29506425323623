import TopBar from '@/components/Topbar.vue';
import IdleComponent from '@/components/general/IdleComponent.vue';
import { defineComponent } from 'vue';
import { useStore } from '@/store';
export default defineComponent({
    name: 'TabLayout',
    props: {
        showDetails: Boolean,
    },
    components: {
        IdleComponent,
        TopBar,
    },
    setup() {
        const store = useStore();
        const { selectedLanguage } = store.getters;
        return {
            selectedLanguage,
        };
    },
});
