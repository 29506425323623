import { __awaiter } from "tslib";
import { connectToNamespace } from '@/plugins/socketIo';
import { SocketMutationTypes } from '@/store/modules/socket/socket.mutations';
export var SocketActionTypes;
(function (SocketActionTypes) {
    SocketActionTypes["CREATE_NAMESPACE_CONNECTION"] = "CREATE_NAMESPACE_CONNECTION";
})(SocketActionTypes || (SocketActionTypes = {}));
export const socketActions = {
    [SocketActionTypes.CREATE_NAMESPACE_CONNECTION]({ commit, getters }, namespace) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getters.getNamespaceSocket(namespace)) {
                return;
            }
            const namespacedSocket = connectToNamespace(namespace);
            commit(SocketMutationTypes.ADD_SOCKET_NAMESPACE, { name: namespace, socket: namespacedSocket });
        });
    },
};
