import { __awaiter } from "tslib";
import { defineComponent, ref } from 'vue';
import Card from '@/components/general/Card.vue';
import LanguageSelector from '@/components/general/LanguageSelector/LanguageSelector.vue';
import { generateRandomWordIndexes, generateSeedPhrase, isValidWord } from '@/services/crypto.service';
import { setupVault } from '@/services/setup.service';
import QrCode from 'qrcode.vue';
import { config } from '@/config';
import Button from '@/components/general/Button.vue';
import { isMobile } from '@/filters';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { useStore } from '@/store';
import { ToastType } from '@/types/toast/toast.enum';
import { InputType } from '@/types/input/inputDetails';
import Form from '@/components/general/form/Form.vue';
import validator from 'validator';
var isEmail = validator.isEmail;
import { getPublicUrl } from '@/services/invitaiton.service';
import { setDefaultSettings } from '@/services/admin.service';
import { Language } from '@/types';
export default defineComponent({
    name: 'CommonVaultSetup',
    components: {
        Button,
        Card,
        LanguageSelector,
        QrCode,
        Form,
    },
    setup() {
        const store = useStore();
        const step = ref(0);
        const setupDetails = ref(undefined);
        const settings = ref({});
        const userDetails = ref({
            firstname: '',
            lastname: '',
            email: '',
            seedPhrase: generateSeedPhrase(),
            language: config.value.defaultLanguage,
            username: '',
        });
        const form = ref({
            name: 'EditUser',
            inputs: [
                {
                    name: 'email',
                    label: 'email',
                    type: InputType.EMAIL,
                    value: '',
                    validation: {
                        maxLength: 100,
                        required: true,
                        validator: isEmail,
                        lazy: true,
                    },
                    placeholder: '',
                },
                {
                    name: 'firstname',
                    label: 'firstname',
                    type: InputType.TEXT,
                    validation: {
                        required: true,
                        maxLength: 25,
                    },
                    placeholder: '',
                },
                {
                    name: 'lastname',
                    label: 'lastname',
                    type: InputType.TEXT,
                    validation: {
                        required: true,
                        maxLength: 25,
                    },
                    placeholder: '',
                },
                {
                    name: 'companyName',
                    label: 'companyName',
                    type: InputType.TEXT,
                    validation: {
                        required: true,
                        maxLength: 25,
                    },
                    placeholder: '',
                },
                {
                    name: 'language',
                    label: 'language',
                    type: InputType.SELECT,
                    canChange: true,
                    selectedValue: Language.EN,
                    value: userDetails.value.language,
                    items: Object.keys(Language).map(lang => {
                        return { value: lang.toLowerCase() };
                    }),
                },
            ],
            submit: 'next',
        });
        const usernameForm = ref({
            name: 'UsernameForm',
            inputs: [
                {
                    name: 'username',
                    label: 'username',
                    type: InputType.TEXT,
                    value: '',
                    validation: {
                        maxLength: 50,
                        required: true,
                        regex: '^([a-zA-Z0-9]*)$',
                    },
                    placeholder: '',
                },
            ],
            submit: 'next',
        });
        const appUrl = ref('');
        const validateUsername = formData => {
            if (formData.isValid) {
                userDetails.value.username = formData.values.username.value;
                step.value += 1;
            }
        };
        const validateFields = formData => {
            // Only go to the next step when the given values are valid
            if (formData.isValid) {
                userDetails.value.firstname = formData.values.firstname.value;
                userDetails.value.lastname = formData.values.lastname.value;
                userDetails.value.email = formData.values.email.value;
                userDetails.value.language = formData.values.language.value;
                settings.value.companyName = formData.values.companyName.value;
                settings.value.defaultLanguage = formData.values.language.value;
                usernameForm.value.inputs[0].value = (userDetails.value.firstname + userDetails.value.lastname).replace(/[^a-zA-Z0-9]/g, '');
                step.value += 1;
            }
        };
        const nextStep = () => __awaiter(this, void 0, void 0, function* () {
            step.value++;
            const exposedUrl = yield getPublicUrl();
            const urlForApp = `${exposedUrl}/invitation/getPhrase/${setupDetails.value.id}`;
            appUrl.value = `${config.value.app.urlHead}://register?url=${Buffer.from(urlForApp).toString('base64')}`;
        });
        const filledWords = ref([]);
        const randomWordIndexes = generateRandomWordIndexes();
        const isSeedPhraseWordsCorrect = () => {
            let isValid = true;
            randomWordIndexes.forEach((wordIndex, i) => {
                if (!isValidWord(userDetails.value.seedPhrase.split(' '), wordIndex, filledWords.value[i])) {
                    isValid = false;
                }
            });
            return isValid;
        };
        const processSetup = () => __awaiter(this, void 0, void 0, function* () {
            const canProceed = isSeedPhraseWordsCorrect();
            if (!canProceed) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: `wordInSeedIncorrect`,
                });
                return;
            }
            setupDetails.value = yield setupVault(userDetails.value);
            const updatedSettings = yield setDefaultSettings(settings.value);
            if (!setupDetails.value || !updatedSettings) {
                return;
            }
            yield nextStep();
        });
        const openAppUrl = () => __awaiter(this, void 0, void 0, function* () {
            window.open(appUrl.value, '_self');
        });
        return {
            step,
            userDetails,
            randomWordIndexes,
            filledWords,
            setupDetails,
            form,
            usernameForm,
            appUrl,
            nextStep,
            processSetup,
            openAppUrl,
            isMobile,
            validateFields,
            validateUsername,
        };
    },
});
