import { generalActions } from './general.actions';
import { generalGetters } from './general.getters';
import { generalMutations } from './general.mutations';
import { generalState } from './general.state';
export const generalStore = {
    state: generalState,
    getters: generalGetters,
    mutations: generalMutations,
    actions: generalActions,
    //namespaced: true,
};
