import { computed, defineComponent, ref } from 'vue';
import { translate } from '@/filters';
import { mapGetters, useStore } from 'vuex';
import { FileManagerActionTypes } from '@/store/modules/FileManager/fileManager.actions';
import CategoryMenu from '@/components/general/CategoryMenu.vue';
import ConfirmModal from '@/components/vault/modals/ConfirmModal.vue';
import { AccessOperations, AccessType } from '@/types/authorization';
export default defineComponent({
    name: 'FolderCard',
    components: { CategoryMenu, ConfirmModal },
    props: {
        folder: {
            type: Object,
            default: 'full',
        },
        showFileCount: {
            type: Boolean,
            default: false,
        },
        showMenu: {
            type: Boolean,
            default: false,
        },
        showMenuOptions: {
            type: Boolean,
            default: false,
        },
        showClickIcon: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        open: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:open', 'showFolders', 'editCategory', 'removeCategory'],
    setup({ folder }, ctx) {
        const store = useStore();
        const showHover = ref(false);
        const isMenuOpen = ref(false);
        const handleDrop = e => {
            showHover.value = false;
            const data = JSON.parse(e.dataTransfer.getData('text'));
            const folderChangeDetails = data.map(item => {
                return {
                    item,
                    newFolderId: folder.id || folder.folderUuid,
                    newFolderName: folder.name,
                    oldFolderId: store.getters.selectedFolderId,
                    isFolder: item.isFolder,
                };
            });
            const changedFolders = folderChangeDetails.filter(f => f.oldFolderId !== folder.folderUuid);
            if (changedFolders.length <= 0) {
                return;
            }
            return store.dispatch(FileManagerActionTypes.CHANGE_FOLDER_OF_FILE, changedFolders);
        };
        const openMenu = () => {
            isMenuOpen.value = !isMenuOpen.value;
            ctx.emit('update:open', isMenuOpen.value);
        };
        const editCategory = () => {
            ctx.emit('editCategory');
        };
        const removeCategory = () => {
            ctx.emit('removeCategory');
        };
        const onClick = folder => {
            var _a;
            ctx.emit('showFolders', (_a = folder.folderUuid) !== null && _a !== void 0 ? _a : folder.id);
        };
        const isFolderActive = computed(() => store.getters.selectedFolderId === (folder === null || folder === void 0 ? void 0 : folder.id));
        return {
            showHover,
            isFolderActive,
            isMenuOpen,
            openMenu,
            translate,
            handleDrop,
            onClick,
            editCategory,
            removeCategory,
            AccessType,
            AccessOperations,
        };
    },
    computed: Object.assign({}, mapGetters(['hasAccessToOperation'])),
});
