import { __awaiter } from "tslib";
import LanguageSelector from '@/components/general/LanguageSelector/LanguageSelector.vue';
import SpinnerModal from '@/components/vault/modals/SpinnerModal.vue';
import { onBeforeMount, ref } from 'vue';
import { createTemporarySpace } from '@/services/share.service';
import { useRoute, useRouter } from 'vue-router';
export default {
    name: 'ReadOnlyShareSetup',
    components: {
        LanguageSelector,
        SpinnerModal,
    },
    setup() {
        const { params } = useRoute();
        const router = useRouter();
        const noValidKeysPopup = ref(false);
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            const readOnlyShare = JSON.parse(Buffer.from(params.share, 'base64').toString('utf-8'));
            const space = yield createTemporarySpace(readOnlyShare.email, readOnlyShare.key);
            if (!space) {
                noValidKeysPopup.value = true;
                return;
            }
            return router.push({
                name: 'ReadOnlyShareDetail',
            });
        }));
        return {
            noValidKeysPopup,
        };
    },
};
