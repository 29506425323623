import { createStore } from 'vuex';
import { adminStore } from '@/store/modules/admin';
import { fileManagerStore } from '@/store/modules/FileManager';
import { toastStore } from '@/store/modules/toasts';
import { userStore } from '@/store/modules/users';
import { vaultStore } from '@/store/modules/vault';
import { generalStore } from '@/store/modules/general';
import { webdavStore } from '@/store/modules/webdav';
import { socketStore } from '@/store/modules/socket';
export const store = createStore({
    modules: {
        adminStore,
        fileManagerStore,
        vaultStore,
        toastStore,
        userStore,
        generalStore,
        webdavStore,
        socketStore,
    },
});
export function useStore() {
    return store;
}
