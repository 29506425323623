<template>
  <div class="mb-4">
    <input
      class="input border border-gray-400 appearance-none rounded w-full px-3 py-3 pt-5 pb-2 focus focus:border-indigo-600 focus:outline-none active:outline-none active:border-indigo-600"
      id="email"
      type="text"
      autofocus
    />
    <label for="email" class="label absolute mb-0 pt-4 pl-3 leading-tighter text-gray-400 text-base mt-2 cursor-text">Email Address</label>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  export default defineComponent({
    name: 'input',
    props: {
      text: String,
      icon: String,
      rounded: Boolean,
    },
  });
</script>

<style scoped>
  .input {
    transition: border 0.2s ease-in-out;
    min-width: 280px;
  }

  .input:focus + .label,
  .input:active + .label,
  .input.filled + .label {
    font-size: 0.75rem;
    transition: all 0.2s ease-out;
    top: -0.1rem;
    color: #667eea;
  }

  .label {
    transition: all 0.2s ease-out;
    top: 0.4rem;
    left: 0;
  }
</style>
