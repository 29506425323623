import { __awaiter } from "tslib";
import { UserMutationTypes } from './user.mutations';
import { getPersonalSpaces } from '@/services/user.service';
export var UserActionTypes;
(function (UserActionTypes) {
    UserActionTypes["SET_PERSONAL_SPACES"] = "SET_PERSONAL_SPACES";
})(UserActionTypes || (UserActionTypes = {}));
export const userActions = {
    [UserActionTypes.SET_PERSONAL_SPACES]({ commit }) {
        return __awaiter(this, void 0, void 0, function* () {
            const personalWorkspaces = yield getPersonalSpaces();
            commit(UserMutationTypes.SET_PERSONAL_SPACES, personalWorkspaces);
        });
    },
};
