<template>
  <svg width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-sideBarIcons stroke-current fill-current">
    <path
      d="M27.095 3.77781H22.7408C22.5264 3.77781 22.3518 3.60318 22.3518 3.38877C22.3518 2.00258 21.2242 0.875 19.838 0.875H2.90497C1.78554 0.875 0.875 1.78554 0.875 2.90497V22.257C0.875 23.3764 1.78554 24.287 2.90497 24.287H27.095C28.2145 24.287 29.125 23.3764 29.125 22.257V5.80778C29.125 4.68835 28.2145 3.77781 27.095 3.77781ZM21.1947 3.38877C21.1947 3.52313 21.2121 3.65349 21.2445 3.77781H18.8704C18.656 3.77781 18.4814 3.60318 18.4814 3.38877C18.4814 2.8893 18.3345 2.42362 18.0823 2.03213H19.838C20.5859 2.03213 21.1947 2.64085 21.1947 3.38877ZM15 3.77781C14.7856 3.77781 14.611 3.60318 14.611 3.38877C14.611 2.8893 14.4641 2.42362 14.2119 2.03213H15.9676C16.7155 2.03213 17.3242 2.64085 17.3242 3.38877C17.3242 3.52313 17.3417 3.65349 17.3741 3.77781H15ZM13.4538 3.38877C13.4538 3.52313 13.4713 3.65349 13.5037 3.77781H11.1296C10.9152 3.77781 10.7406 3.60318 10.7406 3.38877V2.90497C10.7406 2.59274 10.6695 2.29668 10.5429 2.03213H12.0972C12.8451 2.03213 13.4538 2.64085 13.4538 3.38877ZM27.9679 22.257C27.9679 22.7382 27.5762 23.1299 27.095 23.1299H2.90497C2.4238 23.1299 2.03213 22.7382 2.03213 22.257V2.90497C2.03213 2.4238 2.4238 2.03213 2.90497 2.03213H8.22678H8.71058C9.19175 2.03213 9.58342 2.4238 9.58342 2.90497V3.38877C9.58342 4.24144 10.2769 4.93494 11.1296 4.93494H27.095C27.5762 4.93494 27.9679 5.32661 27.9679 5.80778V22.257Z"
      stroke-width="0.25"
    />
    <path
      d="M4.84016 8.80532H11.1296C11.4491 8.80532 11.7081 8.54628 11.7081 8.22676C11.7081 7.90723 11.4491 7.64819 11.1296 7.64819H4.84016C4.52064 7.64819 4.2616 7.90723 4.2616 8.22676C4.2616 8.54628 4.52064 8.80532 4.84016 8.80532Z"
      stroke-width="0.25"
    />
    <path
      d="M14.5162 12.4863H4.84016C4.52064 12.4863 4.2616 12.7454 4.2616 13.0649C4.2616 13.3844 4.52064 13.6435 4.84016 13.6435H14.5162C14.8357 13.6435 15.0948 13.3844 15.0948 13.0649C15.0948 12.7454 14.8357 12.4863 14.5162 12.4863Z"
      stroke-width="0.25"
    />
    <path
      d="M14.5162 10.0671H4.84016C4.52064 10.0671 4.2616 10.3262 4.2616 10.6457C4.2616 10.9652 4.52064 11.2243 4.84016 11.2243H14.5162C14.8357 11.2243 15.0948 10.9652 15.0948 10.6457C15.0948 10.3262 14.8357 10.0671 14.5162 10.0671Z"
      stroke-width="0.25"
    />
    <path
      d="M19.1123 11.0349C18.6598 11.0349 18.2919 11.4029 18.2919 11.8554C18.2919 12.3079 18.6598 12.6758 19.1123 12.6758C19.5648 12.6758 19.9328 12.3079 19.9328 11.8554C19.9328 11.4029 19.5648 11.0349 19.1123 11.0349Z"
      stroke-width="0.25"
    />
    <path
      d="M19.1123 9.09961C17.5927 9.09961 16.3566 10.3357 16.3566 11.8553C16.3566 13.3749 17.5927 14.6109 19.1123 14.6109C20.6319 14.6109 21.868 13.3749 21.868 11.8553C21.868 10.3357 20.6319 9.09961 19.1123 9.09961ZM19.1123 13.4538C18.231 13.4538 17.5138 12.7366 17.5138 11.8553C17.5138 10.9739 18.231 10.2567 19.1123 10.2567C19.9936 10.2567 20.7108 10.9739 20.7108 11.8553C20.7108 12.7366 19.9936 13.4538 19.1123 13.4538Z"
      stroke-width="0.25"
    />
  </svg>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'DocumentNavigationIcon',
  });
</script>

<style scoped></style>
