export var Days;
(function (Days) {
    Days["MONDAY"] = "Monday";
    Days["TUESDAY"] = "Tuesday";
    Days["WEDNESDAY"] = "Wednesday";
    Days["THURSDAY"] = "Thursday";
    Days["FRIDAY"] = "Friday";
    Days["SATURDAY"] = "Saturday";
    Days["SUNDAY"] = "Sunday";
})(Days || (Days = {}));
export var Months;
(function (Months) {
    Months["JANUARY"] = "January";
    Months["FEBRUARY"] = "February";
    Months["MARCH"] = "March";
    Months["APRIL"] = "April";
    Months["MAY"] = "May";
    Months["JUNE"] = "June";
    Months["JULY"] = "July";
    Months["AUGUST"] = "August";
    Months["SEPTEMBER"] = "September";
    Months["OCTOBER"] = "October";
    Months["NOVEMBER"] = "November";
    Months["DECEMBER"] = "December";
})(Months || (Months = {}));
