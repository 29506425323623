import { __awaiter } from "tslib";
import { computed, defineComponent, ref } from 'vue';
import Button from '@/components/general/Button.vue';
import Modal from '@/components/general/Modal.vue';
import { translate } from '@/filters';
import { Table } from '@jimber/shared-components';
import DatePicker from '@/components/general/datePicker/DatePicker.vue';
import { updateSpaceUserRights } from '@/services/space.service';
import { useStore } from '@/store';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
export default defineComponent({
    name: 'RenewVaultAccessModal',
    props: {
        show: Boolean,
        user: Object,
        vault: Object,
    },
    components: {
        Table,
        DatePicker,
        Button,
        Modal,
    },
    emits: ['update:show', 'renewed-user'],
    setup(props, ctx) {
        const user = ref(Object.assign(Object.assign({}, props.user), { expiryDate: undefined }));
        const store = useStore();
        const headers = computed(() => [
            {
                key: 'username',
                displayName: translate('tables.headers.username'),
            },
            {
                key: 'email',
                displayName: translate('tables.headers.email'),
            },
            {
                key: 'timeLimit',
                displayName: translate('tables.headers.timeLimit'),
            },
        ]);
        const accessExpiryDate = computed({
            get: () => user.value.expiryDate,
            set: newDate => {
                user.value.expiryDate = newDate;
            },
        });
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        const extendUserExpiryDate = () => __awaiter(this, void 0, void 0, function* () {
            const renewedUser = yield updateSpaceUserRights(props.vault.id, props.user.id, {
                expiryDate: accessExpiryDate.value,
            });
            if (!renewedUser) {
                return store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: 'FailedToRenewExpiryDate',
                    data: {
                        username: renewedUser.username,
                    },
                });
            }
            store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                type: ToastType.SUCCESS,
                message: 'successfullyRenewedExpiryDate',
                data: {
                    username: renewedUser.username,
                },
            });
            ctx.emit('renewed-user', renewedUser);
            closeModal();
        });
        return {
            headers,
            accessExpiryDate,
            extendUserExpiryDate,
            closeModal,
        };
    },
});
