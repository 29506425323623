<template>
  <modal v-if="show" @close="closeModal()" sticky>
    <template #header>
      <slot name="header">{{ $t('webdav.createdTitle') }}</slot>
    </template>
    <slot name="message">
      <div>
        <p class="mb-2">
          {{ $t('webdav.createdBody') }}
        </p>
        <div class="flex justify-items-center items-center w-full">
          <span class="whitespace-nowrap">Linux/MacOS URL: </span>
          <div v-copy-to-clipboard class="flex cursor-pointer justify-items-center items-center w-4/5">
            <span class="truncate mx-2"
              ><b>{{ linuxUrl }}</b></span
            >
            <em class="far fa-copy mr-2"></em>
          </div>
        </div>

        <div class="flex justify-items-center items-center w-full">
          <span class="whitespace-nowrap">Windows URL: </span>
          <div v-copy-to-clipboard class="flex cursor-pointer justify-items-center items-center w-4/5">
            <span class="w-4/5 truncate mx-2"
              ><b>{{ windowsUrl }}</b></span
            >
            <em class="far fa-copy mr-2"></em>
          </div>
        </div>

        <div class="flex justify-items-center items-center w-full">
          <span>{{ $t('webdav.username') }}: </span>
          <div v-copy-to-clipboard class="flex cursor-pointer justify-items-center items-center w-4/5">
            <span class="max-w-1/2 truncate mx-2"
              ><b>{{ username }}</b></span
            >
            <em class="far fa-copy mr-2"></em>
          </div>
        </div>

        <div class="flex justify-items-center items-center w-full">
          <span>{{ $t('webdav.password') }}: </span>
          <div v-copy-to-clipboard class="flex cursor-pointer justify-items-center items-center w-4/5">
            <span class="max-w-1/2 truncate mx-2"
              ><b>{{ password }}</b></span
            >
            <em class="far fa-copy mr-2"></em>
          </div>
        </div>

        <div class="mt-4">
          <span class="warning font-medium"
            ><b>{{ $t('misc.warning') }}</b></span
          >
          <p>{{ $t('webdav.sessionWarning') }}</p>
        </div>
      </div>
    </slot>
    <template #actions>
      <button class="bg-primary text-white capitalize" @click="closeModal">
        {{ $t('misc.ok') }}
      </button>
    </template>
  </modal>
</template>

<script>
  import { computed, defineComponent } from 'vue';
  import Modal from '@/components/general/Modal.vue';
  import { useStore } from '@/store';

  export default defineComponent({
    name: 'MountModal',
    components: { Modal },
    emits: ['update:show'],
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, ctx) {
      const store = useStore();
      const closeModal = () => {
        ctx.emit('update:show', false);
      };
      const username = computed(() => store.getters.webdavLoginUsername);
      const password = computed(() => store.getters.webdavLoginPassword);

      const linuxUrl = computed(() => `davs://${username.value}:${password.value}@${window.location.hostname}/user/dav/v1/${store.getters.currentUser.id}`);
      const windowsUrl = computed(() => `https://${window.location.hostname}/user/dav/v1/${store.getters.currentUser.id}`);

      return {
        closeModal,
        linuxUrl,
        windowsUrl,
        username,
        password,
      };
    },
  });
</script>

<style scoped>
  .warning {
    color: #ff6700;
  }
</style>
