export var defaultFolders;
(function (defaultFolders) {
    defaultFolders["ToBeClassified"] = "to_be_classified";
    defaultFolders["Archived"] = "archived";
    defaultFolders["PersonalData"] = "personal_data";
    defaultFolders["LegalEntities"] = "legal_entities";
    defaultFolders["Taxation"] = "taxation";
    defaultFolders["EstatePlanning"] = "estate_planning";
    defaultFolders["FinancialInstitutions"] = "financial_institutions";
    defaultFolders["RealEstate"] = "real_estate";
    defaultFolders["PrivateEquity"] = "private_equity";
    defaultFolders["OtherInvestments"] = "other_investments";
    defaultFolders["Miscellaneous"] = "miscellaneous";
})(defaultFolders || (defaultFolders = {}));
