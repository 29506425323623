import { __awaiter } from "tslib";
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import { useStore } from '@/store';
import SearchBar from '@/components/vault/SearchBar.vue';
import Avatar from '@/components/general/Avatar.vue';
import DetailsSidebar from '@/components/vault/sidebars/DetailsSidebar.vue';
import { formatDate } from '@/filters';
import AddUserToWorkspaceModal from '@/components/vault/modals/AddUserToWorkspaceModal.vue';
import dropDown from '@/components/general/Dropdown.vue';
import { mapGetters } from 'vuex';
import RemoveUserModal from '@/components/vault/modals/RemoveUserModal.vue';
import EditWorkspaceModal from '@/components/vault/modals/EditWorkspaceModal.vue';
import AdminUserVaultOverview from '@/components/admin/AdminUserVaultOverview.vue';
import { Role } from '@/types';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
import { exportVaultSpace } from '@/services/vaultSpace.service';
export default defineComponent({
    name: 'WorkspaceDetails',
    props: {
        shown: {
            type: Boolean,
            default: false,
        },
        selectedWorkspace: {
            type: Object,
        },
    },
    components: {
        AdminUserVaultOverview,
        EditWorkspaceModal,
        RemoveUserModal,
        Avatar,
        DetailsSidebar,
        SearchBar,
        AddUserToWorkspaceModal,
        dropDown,
    },
    emits: ['update:selectedWorkspace', 'openVault', 'collapse-sidebar', 'update-workspace'],
    setup({ selectedWorkspace }, ctx) {
        const store = useStore();
        const workspaceDetails = ref(undefined);
        const showAddUserModal = ref(false);
        const showEditWorkspaceModal = ref(false);
        const showRemoveUser = ref(false);
        const selectedUser = ref(undefined);
        const vaultUsers = ref([]);
        const usersToRemove = ref([]);
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            workspaceDetails.value = selectedWorkspace;
        }));
        const showRemoveUserModal = () => {
            showRemoveUser.value = true;
        };
        const openVault = toSelect => {
            if (!toSelect) {
                return;
            }
            ctx.emit('openVault', toSelect);
        };
        const exportVault = () => {
            exportVaultSpace(workspaceDetails.value.id);
        };
        const handleClickedUser = user => {
            const userIndex = usersToRemove.value.findIndex(u => user.id === u.id);
            if (userIndex !== -1) {
                usersToRemove.value.splice(userIndex, 1);
                return;
            }
            usersToRemove.value.push(user);
        };
        const editWorkspace = () => {
            showEditWorkspaceModal.value = true;
        };
        const addUsers = (newUsers) => {
            vaultUsers.value.push(...newUsers);
        };
        const deleteUser = (user) => {
            const index = vaultUsers.value.findIndex(u => u.id === user.id);
            vaultUsers.value.splice(index, 1);
        };
        const removeUser = user => {
            showRemoveUser.value = true;
            selectedUser.value = user;
        };
        const updateWorkspace = (workspace) => __awaiter(this, void 0, void 0, function* () {
            store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                type: ToastType.SUCCESS,
                message: 'successFullyUpdated',
            });
            Object.assign(workspaceDetails.value, workspace);
            ctx.emit('update:selectedWorkspace', workspaceDetails.value);
            ctx.emit('update-workspace', workspaceDetails.value);
        });
        const hasSpaceAccess = computed(() => Boolean(workspaceDetails.value.hasSpaceAccess));
        return {
            workspaceDetails,
            showAddUserModal,
            showEditWorkspaceModal,
            selectedUser,
            showRemoveUser,
            usersToRemove,
            vaultUsers,
            hasSpaceAccess,
            Role,
            formatDate,
            showRemoveUserModal,
            addUsers,
            deleteUser,
            editWorkspace,
            removeUser,
            handleClickedUser,
            openVault,
            updateWorkspace,
            exportVault,
        };
    },
    computed: Object.assign({}, mapGetters(['currentUser'])),
});
