import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, Transition as _Transition, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "overflow-auto relative max-h-60" };
const _hoisted_2 = { class: "flex flex-wrap w-full" };
const _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_popover_button = _resolveComponent("popover-button");
    const _component_search_bar = _resolveComponent("search-bar");
    const _component_popover_panel = _resolveComponent("popover-panel");
    const _component_popover = _resolveComponent("popover");
    return (_openBlock(), _createBlock(_component_popover, null, {
        default: _withCtx(({ open }) => [
            _createVNode(_component_popover_button, {
                class: _normalizeClass([{ 'text-opacity-90 ring-blue-500 border-blue-500': open }, "m-0 z-0 w-12 h-12 flex items-center justify-center px-3 py-2 text-base font-medium text-primary border-gray-300 rounded-md group hover:text-opacity-100 focus:outline-none hover:ring-blue-500 hover:border-blue-500"])
            }, {
                default: _withCtx(() => [
                    (_openBlock(), _createElementBlock("span", {
                        key: `${_ctx.color}_${_ctx.icon}`,
                        class: "w-fit"
                    }, [
                        _createElementVNode("i", {
                            style: _normalizeStyle({ color: _ctx.color }),
                            class: _normalizeClass(`fas fa-${_ctx.icon} fa-lg`)
                        }, null, 6)
                    ]))
                ]),
                _: 2
            }, 1032, ["class"]),
            _createVNode(_Transition, {
                "enter-active-class": "transition duration-200 ease-out",
                "enter-from-class": "translate-y-1 opacity-0",
                "enter-to-class": "translate-y-0 opacity-100",
                "leave-active-class": "transition duration-150 ease-in",
                "leave-from-class": "translate-y-0 opacity-100",
                "leave-to-class": "translate-y-1 opacity-0"
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_popover_panel, {
                        id: "iconPicker",
                        class: "absolute bg-white max-w-md z-overlay left-5 p-3 mt-1 border-2 border-gray-200 rounded-md"
                    }, {
                        default: _withCtx(() => [
                            _createElementVNode("div", null, [
                                _createVNode(_component_search_bar, {
                                    class: "mt-2",
                                    ref: "searchBar",
                                    searchValue: _ctx.iconSearch,
                                    "onUpdate:searchValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.iconSearch) = $event))
                                }, null, 8, ["searchValue"])
                            ]),
                            _createElementVNode("div", _hoisted_1, [
                                _createElementVNode("div", _hoisted_2, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredIcons, (filteredIcon) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                            onClick: _withModifiers(($event) => (_ctx.$emit('update:icon', filteredIcon.name)), ["stop", "prevent"]),
                                            class: _normalizeClass(["p-2 w-8 h-8 m-2 cursor-pointer flex justify-center items-center shadow-md border-2 border-gray-200 rounded box-content float-left text-center", [filteredIcon.name === _ctx.icon ? 'bg-blue-100' : '']]),
                                            key: filteredIcon.name
                                        }, [
                                            _createElementVNode("i", {
                                                class: _normalizeClass(`fas fa-${filteredIcon.name} fa-lg`)
                                            }, null, 2)
                                        ], 10, _hoisted_3));
                                    }), 128))
                                ])
                            ])
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            })
        ]),
        _: 1
    }));
}
