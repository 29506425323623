import { __awaiter } from "tslib";
import { defineComponent, onBeforeMount, ref } from 'vue';
import Button from '@/components/general/Button.vue';
import { config } from '@/config';
import { editSettings, getSettings, uploadLogo } from '@/services/admin.service';
import { store } from '@/store';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
import ImagePreviewModal from '@/components/vault/modals/ImagePreviewModal.vue';
import { dataURLtoFile } from '@/utils';
import { BrandingImage } from '@/types/admin/branding.enum';
import { useFavicon } from '@vueuse/core';
export default defineComponent({
    name: 'BrandingSettings',
    components: { Button, ImagePreviewModal },
    setup() {
        const settings = ref({});
        const imageInput = ref(null);
        const formInput = ref(null);
        const showPreviewModal = ref(false);
        const imagePreview = ref(null);
        const imageType = ref(null);
        const companyNameInput = ref();
        const icon = useFavicon();
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            settings.value = yield getSettings();
        }));
        const logoSelected = () => __awaiter(this, void 0, void 0, function* () {
            showPreviewModal.value = true;
            imagePreview.value = imageInput.value.files[0];
            clearLogoInput();
        });
        const clickLogoInput = (type) => __awaiter(this, void 0, void 0, function* () {
            imageType.value = type;
            imageInput.value.click();
        });
        const downscaledToFile = (downscaledImage, type) => __awaiter(this, void 0, void 0, function* () {
            const fileToUpload = yield dataURLtoFile(downscaledImage, 'logo');
            const newLogo = yield uploadLogo(fileToUpload, type);
            switch (type) {
                case BrandingImage.LOGO:
                    config.value.logo = newLogo;
                    break;
                case BrandingImage.FAVICON:
                    config.value.favicon = newLogo;
                    icon.value = config.value.favicon;
                    break;
            }
        });
        const pushSettings = () => __awaiter(this, void 0, void 0, function* () {
            if (!settings.value.companyName) {
                companyNameInput.value.classList.add('border-red-500');
                companyNameInput.value.classList.remove('border-gray-300');
                return;
            }
            else {
                companyNameInput.value.classList.add('border-gray-300');
                companyNameInput.value.classList.remove('border-red-500');
            }
            const updatedSettings = yield editSettings(settings.value);
            if (!updatedSettings) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: `FailEditSettings`,
                });
                return;
            }
            store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                type: ToastType.SUCCESS,
                message: `successFullyEditedSettings`,
            });
            Object.assign(config.value, updatedSettings);
        });
        const clearLogoInput = () => {
            formInput.value.reset();
        };
        return {
            settings,
            imageInput,
            formInput,
            showPreviewModal,
            imagePreview,
            imageType,
            config,
            pushSettings,
            logoSelected,
            clickLogoInput,
            clearLogoInput,
            downscaledToFile,
            companyNameInput,
            brandingImage: BrandingImage,
        };
    },
});
