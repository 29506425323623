import { __awaiter } from "tslib";
import { defineComponent, onBeforeMount, ref } from 'vue';
import { getSingleVaultInvitationDetails } from '@/services/invitationSingleVault.service';
import { useRoute } from 'vue-router';
import { InvitationStatus } from '@/types/invitation/invitation.enum';
import { config } from '@/config';
import { formatDate } from '@/filters';
import LanguageSelector from '@/components/general/LanguageSelector/LanguageSelector.vue';
import Card from '@/components/general/Card.vue';
import Avatar from '@/components/general/Avatar.vue';
import Button from '@/components/general/Button.vue';
export default defineComponent({
    name: 'InvitationSingleVault',
    components: {
        LanguageSelector,
        Card,
        Avatar,
        Button,
    },
    setup() {
        const { params } = useRoute();
        const invitation = ref(undefined);
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            invitation.value = yield getSingleVaultInvitationDetails(params.invitationId);
        }));
        const goToInviteLogin = () => {
            window.location.href = `${config.value.apiEndPoint}/auth/invitation-single-vault/${params.invitationId}`;
        };
        return {
            goToInviteLogin,
            invitation,
            contactEmail: config.value.app.contactEmail,
            companyName: config.value.companyName,
            formatDate,
            InvitationStatus,
        };
    },
});
