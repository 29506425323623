import { __awaiter } from "tslib";
import { AcceptedFiles } from '@/types/file/acceptedFiles.enum';
import { store } from '@/store';
import { getFilesInSpaceByFolderId } from '@/services/folder.service';
import { fromBlob } from 'file-type/browser';
export const buildItemList = (item, path = '', itemList = []) => __awaiter(void 0, void 0, void 0, function* () {
    if (!item.isFile) {
        const entries = yield readDirectoryEntities(item);
        for (const entry of entries) {
            yield buildItemList(entry, item.fullPath, itemList);
        }
    }
    itemList.push(item);
    return itemList;
});
const readDirectoryEntities = (item) => __awaiter(void 0, void 0, void 0, function* () {
    const dirReader = item.createReader();
    const entityList = [];
    let entities;
    do {
        entities = yield new Promise((resolve, reject) => {
            dirReader.readEntries(resolve, reject);
        });
        entityList.push(...entities);
    } while (entities.length >= 100);
    return entityList;
});
export const fileListToItemTree = (fileList) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const fullTree = {};
    const folderTree = [];
    const parent = { folderTree };
    for (const file of Array.from(fileList)) {
        const filePath = file.webkitRelativePath;
        if (filePath.trim() === '') {
            const fileType = yield isValidFile(file);
            fullTree[file.name] = {
                name: file.name,
                isRoot: true,
                isFolder: false,
                isFile: true,
                isValid: fileType.valid,
                data: file,
            };
            continue;
        }
        const relativePath = filePath.split('/');
        yield relativePath.reduce((parentTree, itemName, index) => __awaiter(void 0, void 0, void 0, function* () {
            const parent = yield parentTree;
            const fileType = yield isValidFile(file);
            const replacedItemName = replaceIllegalCharacters(itemName);
            const isFile = file.name === itemName;
            if (!parent[itemName]) {
                parent[itemName] = { folderTree: [] };
                parent.folderTree.push({
                    name: replacedItemName,
                    isRoot: index === 0,
                    isFolder: !isFile,
                    isFile: isFile,
                    isValid: fileType.valid,
                    data: isFile ? file : undefined,
                    children: parent[itemName].folderTree,
                });
            }
            return parent[itemName];
        }), Promise.resolve(parent));
        fullTree[(_a = folderTree[0]) === null || _a === void 0 ? void 0 : _a.name] = folderTree[0];
    }
    return Object.values(fullTree);
});
export const itemListToItemTree = (items) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const fullTree = {};
    for (const itemEntries of items) {
        const folderTree = [];
        const parent = { folderTree };
        const sortedEntries = itemEntries.sort((a, b) => {
            return Number(b.isDirectory) - Number(a.isDirectory);
        });
        for (const itemEntry of sortedEntries) {
            const path = itemEntry.fullPath.substring(1).split('/');
            const file = itemEntry.isFile
                ? yield new Promise(resolve => {
                    // @ts-ignore
                    itemEntry.file(e => {
                        resolve(e);
                    });
                })
                : undefined;
            yield path.reduce((parentTree, itemName, index) => __awaiter(void 0, void 0, void 0, function* () {
                var _c;
                const parent = yield parentTree;
                const fileType = yield isValidFile(file);
                const replacedItemName = replaceIllegalCharacters((_c = fileType === null || fileType === void 0 ? void 0 : fileType.filename) !== null && _c !== void 0 ? _c : itemName);
                if (!parent[itemName]) {
                    parent[itemName] = { folderTree: [] };
                    parent.folderTree.push({
                        name: replacedItemName,
                        isRoot: index === 0,
                        isFolder: itemEntry.isDirectory,
                        isFile: itemEntry.isFile,
                        isValid: fileType.valid,
                        data: file ? file : undefined,
                        children: parent[itemName].folderTree,
                    });
                }
                return parent[itemName];
            }), Promise.resolve(parent));
        }
        fullTree[(_b = folderTree[0]) === null || _b === void 0 ? void 0 : _b.name] = folderTree[0];
    }
    return Object.values(fullTree);
});
export const getDuplicatesItemsInTree = (treeItems, currentFolderId) => __awaiter(void 0, void 0, void 0, function* () {
    let duplicateFiles = [];
    const itemsInParent = yield getFilesInSpaceByFolderId(store.getters.selectedWorkspaceId, currentFolderId);
    for (const item of treeItems) {
        if (item.isFolder) {
            const existingItem = itemsInParent.find(i => i['name'] === item.name);
            if (!existingItem || item.children.length <= 0) {
                continue;
            }
            duplicateFiles = duplicateFiles.concat(yield getDuplicatesItemsInTree(item.children, existingItem === null || existingItem === void 0 ? void 0 : existingItem['folderUuid']));
            continue;
        }
        item.isDuplicate = !!(itemsInParent === null || itemsInParent === void 0 ? void 0 : itemsInParent.find(i => { var _a, _b; return ((_a = i['filename']) === null || _a === void 0 ? void 0 : _a.toLowerCase()) + '.' + i['extension'] === ((_b = item.name) === null || _b === void 0 ? void 0 : _b.toLowerCase()); }));
        if (!item.isDuplicate) {
            continue;
        }
        duplicateFiles.push(item);
    }
    return duplicateFiles;
});
export const isValidFile = (file) => __awaiter(void 0, void 0, void 0, function* () {
    if (!file) {
        return {
            filename: null,
            ext: null,
            valid: false,
        };
    }
    const fileType = yield findFileTypeExtension(file);
    return Object.assign(Object.assign({}, fileType), { valid: !!Object.values(AcceptedFiles).includes(fileType === null || fileType === void 0 ? void 0 : fileType.ext) });
});
export const isUnderMaxSize = (size) => {
    if (size) {
        return size <= 100000000;
    }
    return true;
};
export const replaceIllegalCharacters = (filename, replacement = '_') => {
    return filename.replace(/[/\\?%*:|"<>]/g, replacement);
};
export const findFileTypeExtension = (file) => __awaiter(void 0, void 0, void 0, function* () {
    let filename = file.name;
    const fileType = yield fromBlob(file);
    const fileExtension = filename.substring(filename.lastIndexOf('.') + 1).toLowerCase();
    const extensionIndex = filename.lastIndexOf('.');
    if (fileExtension === AcceptedFiles.EID) {
        return {
            filename,
            ext: fileExtension,
        };
    }
    if (!fileType) {
        if (extensionIndex >= 0) {
            const originalFilename = filename.substring(0, extensionIndex);
            filename = `${originalFilename}.${AcceptedFiles.TXT}`;
        }
        else {
            filename = `${filename}.${AcceptedFiles.TXT}`;
        }
        return {
            filename,
            ext: AcceptedFiles.TXT,
        };
    }
    if (!!fileType && fileExtension !== (fileType === null || fileType === void 0 ? void 0 : fileType.ext)) {
        if (extensionIndex >= 0) {
            const originalFilename = filename.substring(0, extensionIndex);
            filename = `${originalFilename}.${fileType.ext}`;
        }
        else {
            filename = `${filename}.${fileType.ext}`;
        }
    }
    return {
        filename,
        ext: fileType === null || fileType === void 0 ? void 0 : fileType.ext,
    };
});
