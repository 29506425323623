import { __awaiter } from "tslib";
import Button from '@/components/general/Button.vue';
import Modal from '@/components/general/Modal.vue';
import { InputType } from '@/types/input/inputDetails';
import Form from '@/components/general/form/Form.vue';
import { defineComponent, computed, ref } from 'vue';
import IconPicker from '@/components/general/iconPicker/IconPicker.vue';
import CustomSelect from '@/components/general/CustomSelect.vue';
import { translate } from '@/filters';
import { useRoute } from 'vue-router';
import { createPersonalPasswordFolder, createSpacePasswordFolder } from '@/services/password.service';
export default defineComponent({
    name: 'AddCategoryModal',
    components: { Button, Modal, Form, IconPicker, CustomSelect },
    emits: ['update:show', 'create-category'],
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        personal: {
            type: Boolean,
            default: false,
        },
        selectedCategory: Object,
        categoryToEdit: Object,
    },
    setup(props, ctx) {
        const creatingCategory = ref(false);
        const route = useRoute();
        const selectedWorkspaceId = route.params.spaceId;
        const createCategory = (formData) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            let category;
            const data = {
                name: formData.values.name.value,
                parentId: (_a = props === null || props === void 0 ? void 0 : props.selectedCategory) === null || _a === void 0 ? void 0 : _a.id,
                rootId: ((_b = route.params) === null || _b === void 0 ? void 0 : _b.selectedRootFolderId) || null,
            };
            if (!props.personal) {
                category = yield createSpacePasswordFolder(selectedWorkspaceId, data);
            }
            else {
                category = yield createPersonalPasswordFolder(data);
            }
            if (!category) {
                return;
            }
            ctx.emit('create-category', category);
            closeModal();
        });
        const form = computed(() => {
            var _a, _b, _c;
            return {
                name: 'createCategoryForm',
                inputs: [
                    {
                        name: 'name',
                        label: 'category',
                        type: InputType.TEXT,
                        value: translate(`folder.${(_a = props.categoryToEdit) === null || _a === void 0 ? void 0 : _a.name}`, (_b = props.categoryToEdit) === null || _b === void 0 ? void 0 : _b.name) || ((_c = props.categoryToEdit) === null || _c === void 0 ? void 0 : _c.name) || '',
                        validation: {
                            required: true,
                            maxLength: 50,
                        },
                        autofocus: true,
                    },
                ],
                submit: props.categoryToEdit ? 'confirm' : 'create',
                cancel: 'cancel',
            };
        });
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        return {
            closeModal,
            createCategory,
            form,
            creatingCategory,
        };
    },
});
