<template>
  <button
    class="inline-flex items-center border-gray-300 px-2 py-1.5 shadow-sm text-sm font-medium cursor-pointer rounded-md"
    :class="{
      'bg-button border-button text-white': colored && !disabled,
      'bg-white border-gray-300 text-gray-700 border': !colored && !disabled && !shadowless,
      'border-gray-200 border': !colored && disabled && !shadowless,
      'shadow-none border-none m-0': shadowless,
      'cursor-not-allowed text-gray-200': disabled,
    }"
    :disabled="disabled"
  >
    <slot />
  </button>
</template>

<script>
  import { defineComponent } from 'vue';
  export default defineComponent({
    name: 'Button',
    props: {
      colored: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      shadowless: {
        type: Boolean,
        default: false,
      },
    },
  });
</script>

<style scoped></style>
