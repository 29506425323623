import { __awaiter } from "tslib";
import Button from '@/components/general/Button.vue';
import Modal from '@/components/general/Modal.vue';
import { InputType } from '@/types/input/inputDetails';
import Form from '@/components/general/form/Form.vue';
import { defineComponent, computed, onMounted } from 'vue';
import validator from 'validator';
import { editPersonalPassword, editSpacePassword } from '@/services/password.service';
import { useRoute } from 'vue-router';
export default defineComponent({
    name: 'EditPasswordModal',
    components: { Button, Modal, Form },
    emits: ['update:show', 'update-item'],
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        personal: {
            type: Boolean,
            default: false,
        },
        selectedPassword: Object,
    },
    setup(props, ctx) {
        var _a;
        const route = useRoute();
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        const selectedSpaceId = (_a = route.params) === null || _a === void 0 ? void 0 : _a.spaceId;
        const form = computed(() => {
            const { isURL } = validator;
            return {
                name: 'editPasswordForm',
                inputs: [
                    {
                        name: 'name',
                        label: 'passwordName',
                        type: InputType.TEXT,
                        validation: {
                            required: true,
                            maxLength: 100,
                        },
                        autofocus: true,
                        value: props.selectedPassword.name,
                    },
                    {
                        name: 'site',
                        label: 'websiteUrl',
                        type: InputType.TEXT,
                        validation: {
                            required: true,
                            validator: isURL,
                        },
                        value: props.selectedPassword.site,
                    },
                    {
                        name: 'nameIdentifier',
                        label: 'nameIdentifier',
                        type: InputType.TEXT,
                        validation: {
                            required: true,
                            maxLength: 100,
                        },
                        value: props.selectedPassword.nameIdentifier,
                    },
                    {
                        name: 'password',
                        label: 'password',
                        type: InputType.PASSWORD,
                        validation: {
                            required: false,
                        },
                    },
                ],
                submit: 'edit',
                cancel: 'cancel',
            };
        });
        const editPassword = (formData) => __awaiter(this, void 0, void 0, function* () {
            if (!formData.isValid) {
                return;
            }
            const data = {
                name: formData.values.name.value,
                site: formData.values.site.value,
                nameIdentifier: formData.values.nameIdentifier.value,
                password: formData.values.password.value,
            };
            let updatedPassword;
            if (!props.personal) {
                updatedPassword = yield editSpacePassword(selectedSpaceId, props.selectedPassword.id, data);
            }
            else {
                updatedPassword = yield editPersonalPassword(props.selectedPassword.id, data);
            }
            if (!updatedPassword) {
                return;
            }
            ctx.emit('update-item', updatedPassword);
            closeModal();
        });
        onMounted(() => {
            focus();
        });
        const focus = () => {
            setTimeout(function () {
                if (document.querySelectorAll('[autofocus]')[0]) {
                    //@ts-ignore
                    document.querySelectorAll('[autofocus]')[0].focus();
                }
            }, 20);
        };
        return {
            form,
            closeModal,
            editPassword,
        };
    },
});
