import { Namespace } from '@/namespaces/namespace';
import { VaultAction } from '@/types/socket/vaultAction.enum';
import { store } from '@/store';
import { updateItemDetails, addVaultUser, removeVaultUser, updateVaultItem, updateVaultItems, deleteVaultItems, updateSearch } from '@/namespaces/vault/vaultSocket.actions';
export default class Vault extends Namespace {
    constructor(namespace, options) {
        super(namespace, options);
    }
    init() {
        // Al around emits
        this.socket.on(VaultAction.ADD_VAULT_USER, data => {
            return addVaultUser(data.user);
        });
        this.socket.on(VaultAction.REMOVE_VAULT_USER, data => {
            return removeVaultUser(data.user);
        });
        // User only emits
        this.socket.on(store.getters.currentUser.id, data => {
            console.log(data);
            switch (data.type) {
                case VaultAction.UPDATE_VAULT_ITEMS:
                    return updateVaultItems(data);
                case VaultAction.DELETE_VAULT_ITEMS:
                    return deleteVaultItems(data);
                case VaultAction.UPDATE_VAULT_ITEM_DETAILS:
                    return updateItemDetails(data);
                case VaultAction.RENAME_VAULT_ITEM:
                    return updateVaultItem(data);
                case VaultAction.SEARCH_FILE_FOUND:
                    return updateSearch(data);
            }
        });
    }
}
