import { __awaiter } from "tslib";
import Modal from '@/components/general/Modal.vue';
import { computed, defineComponent, ref, watch } from 'vue';
import Button from '@/components/general/Button.vue';
import { InputType } from '@/types/input/inputDetails';
import Form from '@/components/general/form/Form.vue';
import { onBeforeMount } from '@vue/runtime-core';
import { useStore } from '@/store';
import { AcceptedFiles } from '@/types/file/acceptedFiles.enum';
import { ViewType } from '@/types/views/views.enum';
import { FileManagerActionTypes } from '@/store/modules/FileManager/fileManager.actions';
import { getFoldersAndSubFolders } from '@/services/admin.service';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
import CustomSelect from '@/components/general/CustomSelect.vue';
import { mapSubFolders } from '@/utils';
import { useRoute, useRouter } from 'vue-router';
import { createWorkspaceFolder } from '@/services/space.service';
import FileTreeView from '@/components/general/FileTreeView.vue';
import { getFilesInSpaceByFolderId } from '@/services/folder.service';
import { getDuplicatesItemsInTree } from '@/utils/file.utils';
export default defineComponent({
    name: 'FolderUploadModal',
    components: {
        FileTreeView,
        Button,
        Modal,
        Form,
        CustomSelect,
    },
    props: {
        inputDetails: Object,
        validationError: Object,
        view: {
            type: String,
        },
        show: {
            type: Boolean,
            default: false,
        },
        uploadedFiles: Object,
    },
    emits: ['update:show', 'update:uploadedFiles', 'setFolder', 'close'],
    setup({ view, uploadedFiles }, ctx) {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const selectedFolderId = ref(undefined);
        const processingForm = ref(false);
        const folderName = ref('');
        const folders = ref([]);
        const selectedFolder = ref(null);
        const items = ref([]);
        const duplicateItems = ref([]);
        const showDuplicateItems = ref(false);
        const uploadForm = computed(() => {
            var _a;
            return {
                name: 'FileUploadForm',
                inputs: [
                    {
                        name: 'fileFolder',
                        type: InputType.SELECT,
                        label: 'folder',
                        canChange: true,
                        objectValue: 'name',
                        selectedValue: selectedFolder.value,
                        items: view === ViewType.DRAGGING || view === ViewType.USER
                            ? []
                            : folders.value.map(folder => {
                                return {
                                    value: folder,
                                };
                            }),
                        hidden: view === ViewType.DRAGGING || view === ViewType.USER,
                        translation: 'folder',
                    },
                    {
                        name: 'file',
                        label: 'folder',
                        type: InputType.FILE,
                        validation: {
                            required: true,
                        },
                        multiple: true,
                        folderUpload: true,
                        accept: Object.values(AcceptedFiles).map(file => '.' + file),
                        selectedFolderId: (_a = selectedFolder.value) === null || _a === void 0 ? void 0 : _a.folderUuid,
                        value: uploadedFiles,
                    },
                ],
                submit: 'uploadFile',
                cancel: 'cancel',
            };
        });
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            const workspaceId = store.getters.selectedWorkspaceId;
            if (workspaceId && view == ViewType.ADMIN) {
                const foldersToMap = yield getFoldersAndSubFolders(workspaceId);
                folders.value = mapSubFolders(foldersToMap);
                selectedFolder.value = folders.value.find(folder => folder.folderUuid === store.getters.selectedFolderId);
            }
            processingForm.value = false;
        }));
        watch(() => selectedFolder, (folder) => __awaiter(this, void 0, void 0, function* () {
            duplicateItems.value = yield getDuplicatesItemsInTree(items.value, folder.value.folderUuid);
        }), {
            deep: true,
        });
        const closeModal = () => {
            ctx.emit('update:show', false);
            ctx.emit('close');
            processingForm.value = false;
        };
        const closeDuplicateItemModal = () => {
            showDuplicateItems.value = false;
        };
        const submitFolder = (formData, force) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (duplicateItems.value.length > 0 && !force) {
                showDuplicateItems.value = true;
                return;
            }
            showDuplicateItems.value = false;
            processingForm.value = true;
            const items = force ? formData : formData.values.file.value;
            const folderId = (_a = selectedFolder.value) === null || _a === void 0 ? void 0 : _a.folderUuid;
            if (view === ViewType.ADMIN && !folderId) {
                processingForm.value = false;
                return store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: `pleasePickCategory`,
                });
            }
            yield router.push({
                name: 'Files',
                params: {
                    spaceId: route.params.spaceId,
                    spaceName: route.params.spaceName,
                    selectedFolderId: store.getters.selectedFolderId,
                },
            });
            yield uploadFolder(items);
            processingForm.value = false;
            ctx.emit('update:uploadedFiles');
            closeModal();
        });
        const updateInput = (formValues) => __awaiter(this, void 0, void 0, function* () {
            var _b, _c;
            items.value = formValues.file.value;
            if (!((_b = selectedFolder.value) === null || _b === void 0 ? void 0 : _b.folderUuid)) {
                return;
            }
            duplicateItems.value = yield getDuplicatesItemsInTree(items.value, (_c = selectedFolder.value) === null || _c === void 0 ? void 0 : _c.folderUuid);
        });
        const uploadFolder = (treeItems, currentFolderId) => __awaiter(this, void 0, void 0, function* () {
            currentFolderId = currentFolderId || selectedFolder.value.folderUuid;
            const currentFolderItems = yield getFilesInSpaceByFolderId(store.getters.selectedWorkspace.id, currentFolderId);
            for (const item of treeItems) {
                const existingItem = currentFolderItems.find(i => i['name'] === item.name);
                if (item.isFolder) {
                    if (!!existingItem) {
                        yield uploadFolder(item.children, existingItem === null || existingItem === void 0 ? void 0 : existingItem['folderUuid']);
                        continue;
                    }
                    const newFolder = yield createWorkspaceFolder(store.getters.selectedWorkspace.id, item.name, currentFolderId, route.params.selectedFolderId);
                    if (!newFolder) {
                        return;
                    }
                    ctx.emit('setFolder', newFolder);
                    yield uploadFolder(item.children, newFolder.folderUuid);
                    continue;
                }
                const fileUuid = existingItem === null || existingItem === void 0 ? void 0 : existingItem['fileUuid'];
                yield store.dispatch(FileManagerActionTypes.UPLOAD_FILE, {
                    uploadedFile: item.data,
                    folderId: currentFolderId,
                    fileUuid,
                });
            }
        });
        return {
            processingForm,
            folderName,
            uploadForm,
            folders,
            selectedFolder,
            selectedFolderId,
            duplicateItems,
            showDuplicateItems,
            items,
            closeModal,
            updateInput,
            submitFolder,
            closeDuplicateItemModal,
        };
    },
});
