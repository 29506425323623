import { __awaiter } from "tslib";
import Modal from '@/components/general/Modal.vue';
import { defineComponent, ref, onMounted, computed } from 'vue';
import Button from '@/components/general/Button.vue';
import { ToastType } from '@/types/toast/toast.enum';
import { createWorkspaceFolder } from '@/services/space.service';
import { useStore } from '@/store';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { InputType } from '@/types/input/inputDetails';
import Form from '@/components/general/form/Form.vue';
import { onBeforeMount } from '@vue/runtime-core';
import { config } from '@/config';
import { useRoute } from 'vue-router';
import { getFoldersAndSubFolders } from '@/services/admin.service';
import { isBrowserIsolated, mapSubFolders } from '@/utils';
import CustomSelect from '@/components/general/CustomSelect.vue';
import { translate } from '@/filters';
import IconPicker from '@/components/general/iconPicker/IconPicker.vue';
import { editFolder } from '@/services/folder.service';
import { replaceIllegalCharacters } from '@/utils/file.utils';
export default defineComponent({
    name: 'createFolderModal',
    components: {
        Button,
        Modal,
        Form,
        CustomSelect,
        IconPicker,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        category: {
            type: Boolean,
            default: false,
        },
        categoryToEdit: Object,
    },
    emits: ['update:show', 'setFolder', 'update-folder'],
    setup(props, ctx) {
        const creatingCat = ref(false);
        const folders = ref([]);
        const selectedFolder = ref(null);
        const dropdownOpen = ref(false);
        const selectedColor = ref('#000000');
        const selectedIcon = ref('folder');
        const route = useRoute();
        const store = useStore();
        const folderName = ref('');
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const foldersToMap = yield getFoldersAndSubFolders(route.params.spaceId);
            folders.value = mapSubFolders(foldersToMap);
            selectedFolder.value = folders.value.find(folder => folder.folderUuid === store.getters.selectedFolderId);
            if (!selectedFolder.value && ((_a = folders.value) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                selectedFolder.value = folders.value[0];
            }
        }));
        onMounted(() => {
            focus();
        });
        const form = computed(() => {
            return {
                name: 'CreateFolderForm',
                inputs: [
                    {
                        name: 'foldername',
                        label: 'newFolderName',
                        type: InputType.TEXT,
                        validation: {
                            required: true,
                            maxLength: 50,
                            regex: '^[^/\\\\?%*:|"<>]+$',
                        },
                        autofocus: true,
                    },
                    {
                        name: 'fileFolder',
                        type: InputType.SELECT,
                        label: 'newFolderCategory',
                        canChange: true,
                        objectValue: 'name',
                        selectedValue: selectedFolder.value,
                        items: folders.value.map(folder => {
                            return { value: folder };
                        }),
                        translation: 'folder',
                    },
                ],
                submit: 'create',
                cancel: 'cancel',
            };
        });
        const categoryForm = computed(() => {
            var _a, _b, _c;
            return {
                name: 'createCategoryForm',
                inputs: [
                    {
                        name: 'categoryName',
                        label: props.categoryToEdit ? 'editCategoryName' : 'newCategoryName',
                        type: InputType.TEXT,
                        value: translate(`folder.${(_a = props.categoryToEdit) === null || _a === void 0 ? void 0 : _a.name}`, (_b = props.categoryToEdit) === null || _b === void 0 ? void 0 : _b.name) || ((_c = props.categoryToEdit) === null || _c === void 0 ? void 0 : _c.name) || '',
                        validation: {
                            required: true,
                            maxLength: 50,
                            regex: '^[^/\\\\?%*:|"<>]+$',
                        },
                        autofocus: true,
                    },
                ],
                submit: props.categoryToEdit ? 'confirm' : 'create',
                cancel: 'cancel',
            };
        });
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        const focus = () => {
            setTimeout(function () {
                if (document.querySelectorAll('[autofocus]')[0]) {
                    //@ts-ignore
                    document.querySelectorAll('[autofocus]')[0].focus();
                }
            }, 20);
        };
        const createFolder = (formData) => __awaiter(this, void 0, void 0, function* () {
            creatingCat.value = true;
            if (!formData.isValid) {
                creatingCat.value = false;
                return;
            }
            if (!config.value.app.rootFolderCreation && !selectedFolder.value) {
                creatingCat.value = false;
                return;
            }
            const folder = yield createWorkspaceFolder(store.getters.selectedWorkspace.id, formData.values.foldername.value, selectedFolder.value ? selectedFolder.value.folderUuid : null, route.params.selectedFolderId);
            if (folder) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.SUCCESS,
                    message: `successFullyCreated`,
                    data: {
                        name: formData.values.foldername.value,
                    },
                });
                closeModal();
                ctx.emit('setFolder', folder);
            }
            creatingCat.value = false;
        });
        const createCategory = (formData) => __awaiter(this, void 0, void 0, function* () {
            var _b, _c;
            creatingCat.value = true;
            const metaData = { icon: selectedIcon.value, color: selectedColor.value };
            const createdFolder = yield createWorkspaceFolder(store.getters.selectedWorkspace.id, (_b = formData.values) === null || _b === void 0 ? void 0 : _b.categoryName.value, null, null, metaData);
            if (createdFolder) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.SUCCESS,
                    message: 'successFullyCreatedCategory',
                    data: {
                        name: (_c = formData.values) === null || _c === void 0 ? void 0 : _c.categoryName.value,
                    },
                });
                closeModal();
            }
            creatingCat.value = false;
        });
        const editCategory = (formData) => __awaiter(this, void 0, void 0, function* () {
            var _d, _e, _f;
            creatingCat.value = true;
            const changedDetails = {
                name: replaceIllegalCharacters((_d = formData.values) === null || _d === void 0 ? void 0 : _d.categoryName.value, '_'),
                metaData: {
                    icon: (_e = props.categoryToEdit.metaData) === null || _e === void 0 ? void 0 : _e.icon,
                    color: (_f = props.categoryToEdit.metaData) === null || _f === void 0 ? void 0 : _f.color,
                },
            };
            const updatedFolder = yield editFolder(store.getters.selectedWorkspace.id, props.categoryToEdit.id, changedDetails);
            if (updatedFolder) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.SUCCESS,
                    message: 'successFullyEditedCategory',
                });
                ctx.emit('update-folder', updatedFolder);
                closeModal();
            }
            creatingCat.value = false;
        });
        return {
            folderName,
            form,
            creatingCat,
            dropdownOpen,
            selectedFolder,
            categoryForm,
            selectedColor,
            selectedIcon,
            closeModal,
            createFolder,
            editCategory,
            createCategory,
            translate,
            isBrowserIsolated,
        };
    },
});
