import { translate } from '@/filters';
import { InputEvent, InputType } from '@/types/input/inputDetails';
import { defineComponent, ref, watch } from 'vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid';
import Tooltip from '@/components/Tooltip.vue';
export default defineComponent({
    name: 'FormSelect',
    components: { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, CheckIcon, SelectorIcon, Tooltip },
    props: { inputDetails: Object, validationError: Object },
    emits: ['updateField', 'disableButton'],
    setup({ inputDetails }, ctx) {
        var _a;
        const selectedField = inputDetails.items.find(field => field.value === inputDetails.selectedValue);
        const fieldValue = ref(selectedField === null || selectedField === void 0 ? void 0 : selectedField.value);
        const checkboxValue = ref(false);
        ctx.emit('updateField', {
            name: inputDetails.name,
            event: ((_a = inputDetails === null || inputDetails === void 0 ? void 0 : inputDetails.validation) === null || _a === void 0 ? void 0 : _a.lazy) ? InputEvent.BLUR : InputEvent.NONE,
            data: { value: selectedField === null || selectedField === void 0 ? void 0 : selectedField.value },
        });
        watch(checkboxValue, newValue => {
            ctx.emit('disableButton', !newValue);
        });
        watch(fieldValue, newValue => {
            var _a;
            if (inputDetails.checkboxWarning) {
                ctx.emit('disableButton', true);
                if (checkboxValue.value || fieldValue.value !== inputDetails.checkboxWarning.condition) {
                    ctx.emit('disableButton', false);
                }
            }
            ctx.emit('updateField', {
                name: inputDetails.name,
                event: ((_a = inputDetails === null || inputDetails === void 0 ? void 0 : inputDetails.validation) === null || _a === void 0 ? void 0 : _a.lazy) ? InputEvent.BLUR : InputEvent.NONE,
                data: {
                    value: newValue,
                },
            });
        });
        const optionValue = option => {
            const { objectValue, translation } = inputDetails;
            const selectedOption = objectValue ? option[objectValue] : option;
            if (translation) {
                return translate(`${translation}.${selectedOption}`, selectedOption);
            }
            return selectedOption;
        };
        return { selectedField, checkboxValue, fieldValue, InputType, optionValue };
    },
});
