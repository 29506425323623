import { __awaiter } from "tslib";
import Modal from '@/components/general/Modal.vue';
import { computed, defineComponent, ref } from 'vue';
import Button from '@/components/general/Button.vue';
import { ToastType } from '@/types/toast/toast.enum';
import { config } from '@/config';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { useStore } from '@/store';
import { InputType } from '@/types/input/inputDetails';
import validator from 'validator';
import Form from '@/components/general/form/Form.vue';
import { doesUserExistOnPlatform } from '@/services/space.service';
import { inviteSingleVaultUser } from '@/services/invitationSingleVault.service';
import { useRoute } from 'vue-router';
import { Language } from '@/types';
import { InvitationRole } from '@/types/invitation/invitation.enum';
import { generateVaultRoleExplanation } from '@/utils/role.util';
export default defineComponent({
    name: 'InvitationSingleVaultModal',
    components: {
        Button,
        Modal,
        Form,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['add-user', 'update:show'],
    setup({ show }, ctx) {
        const route = useRoute();
        const store = useStore();
        const isNewUser = ref(false);
        const loading = ref(false);
        const userDetails = ref({
            email: '',
            firstname: null,
            lastname: null,
            role: InvitationRole.LIMITED_VAULT_USER,
            language: config.value.defaultLanguage,
        });
        const onInput = (data) => __awaiter(this, void 0, void 0, function* () {
            if (!data.email.valid) {
                isNewUser.value = false;
                userDetails.value.email = null;
                return;
            }
            if (data.email.value === userDetails.value.email) {
                return;
            }
            const userExist = yield doesUserExistOnPlatform(route.params.spaceId, data.email.value);
            isNewUser.value = !userExist;
            userDetails.value.email = data.email.value;
        });
        const form = computed(() => {
            return {
                name: 'UsernameForm',
                inputs: [
                    {
                        name: 'email',
                        label: 'email',
                        type: InputType.TEXT,
                        value: userDetails.value.email,
                        validation: {
                            maxLength: 100,
                            required: true,
                            validator: validator.isEmail,
                            lazy: true,
                        },
                        autofocus: true,
                    },
                    {
                        name: 'firstname',
                        label: 'firstname',
                        type: InputType.TEXT,
                        hidden: !isNewUser.value,
                        validation: {
                            maxLength: 25,
                            required: isNewUser.value,
                            regex: '^([a-zA-Z0-9]*)$',
                        },
                    },
                    {
                        name: 'lastname',
                        label: 'lastname',
                        type: InputType.TEXT,
                        hidden: !isNewUser.value,
                        validation: {
                            maxLength: 25,
                            required: isNewUser.value,
                            regex: '^([a-zA-Z0-9]*)$',
                        },
                    },
                    {
                        name: 'role',
                        type: InputType.SELECT,
                        label: 'role',
                        canChange: true,
                        capitalize: true,
                        selectedValue: InvitationRole.LIMITED_VAULT_USER,
                        value: userDetails.value.role,
                        items: Object.values(InvitationRole)
                            .reverse()
                            .map(role => {
                            return { value: role };
                        }),
                        translation: 'vault.roles',
                        info: generateVaultRoleExplanation(),
                    },
                    {
                        name: 'language',
                        label: 'language',
                        type: InputType.SELECT,
                        canChange: true,
                        selectedValue: config.value.defaultLanguage,
                        value: userDetails.value.language,
                        items: Object.keys(Language).map(lang => {
                            return { value: lang.toLowerCase() };
                        }),
                    },
                ],
                submit: 'invite',
                cancel: 'cancel',
            };
        });
        const closeModal = () => {
            ctx.emit('update:show', false);
            userDetails.value = {
                email: '',
                role: InvitationRole.LIMITED_VAULT_USER,
                language: config.value.defaultLanguage,
            };
        };
        const validateFields = (formData) => __awaiter(this, void 0, void 0, function* () {
            if (!formData.isValid) {
                return;
            }
            if (isNewUser.value) {
                userDetails.value.firstname = formData.values.firstname.value;
                userDetails.value.lastname = formData.values.lastname.value;
            }
            userDetails.value.email = formData.values.email.value;
            userDetails.value.language = formData.values.language.value;
            userDetails.value.role = formData.values.role.value;
            if (isNewUser.value && (!formData.values.firstname.value || !formData.values.lastname.value)) {
                return;
            }
            yield sendInvitation();
        });
        const sendInvitation = () => __awaiter(this, void 0, void 0, function* () {
            loading.value = true;
            const invitation = yield inviteSingleVaultUser(route.params.spaceId, Object.assign({}, userDetails.value));
            if (!invitation) {
                return;
            }
            invitation.user.vaultRole = invitation.role;
            ctx.emit('add-user', invitation.user);
            store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                type: ToastType.SUCCESS,
                message: `successfullySendInvite`,
                data: {
                    user: userDetails.value.email,
                },
            });
            closeModal();
        });
        return {
            form,
            userDetails,
            isNewUser,
            invitationDuration: config.value.invitation.expirationDays,
            closeModal,
            validateFields,
            onInput,
            loading,
        };
    },
});
