import { __awaiter } from "tslib";
import Modal from '@/components/general/Modal.vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import Button from '@/components/general/Button.vue';
import { ToastType } from '@/types/toast/toast.enum';
import { createOfficeFile } from '@/services/space.service';
import { useStore } from '@/store';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { InputType } from '@/types/input/inputDetails';
import Form from '@/components/general/form/Form.vue';
import { onBeforeMount } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import { getFoldersAndSubFolders } from '@/services/admin.service';
import { mapSubFolders } from '@/utils';
import CustomSelect from '@/components/general/CustomSelect.vue';
import { translate } from '@/filters';
import { OfficeType } from '@/types/file/office.enum';
import fileIcons from '@/constants/fileIcons.json';
import { FileManagerMutationTypes } from '@/store/modules/FileManager/fileManager.mutations';
import DocumentIcon from '@/components/icons/files/DocumentIcon.vue';
import PowerPointIcon from '@/components/icons/files/PowerPointIcon.vue';
import SpreadSheetIcon from '@/components/icons/files/SpreadSheetIcon.vue';
import { ViewType } from '@/types/views/views.enum';
import { getFilesInSpaceByFolderId } from '@/services/folder.service';
import { replaceIllegalCharacters } from '@/utils/file.utils';
export default defineComponent({
    name: 'createFileModal',
    components: {
        SpreadSheetIcon,
        PowerPointIcon,
        DocumentIcon,
        Button,
        Modal,
        Form,
        CustomSelect,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        view: {
            type: String,
        },
    },
    emits: ['update:show'],
    setup({ view }, ctx) {
        const creatingFile = ref(false);
        const folders = ref([]);
        const selectedFolder = ref(null);
        const selectedFileType = ref(OfficeType.DOCX);
        const dropdownOpen = ref(false);
        const newFileForm = ref(null);
        const route = useRoute();
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (view == ViewType.ADMIN) {
                const foldersToMap = yield getFoldersAndSubFolders(route.params.spaceId);
                folders.value = mapSubFolders(foldersToMap);
                selectedFolder.value = folders.value.find(folder => folder.folderUuid === store.getters.selectedFolderId);
                if (!selectedFolder.value && ((_a = folders.value) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    selectedFolder.value = folders.value[0];
                }
            }
        }));
        onMounted(() => {
            focus();
        });
        const form = computed(() => {
            return {
                name: 'CreateFileForm',
                inputs: [
                    {
                        name: 'filename',
                        label: 'filename',
                        type: InputType.TEXT,
                        validation: {
                            required: true,
                            maxLength: 260,
                            regex: '^[^/\\\\?%*:|"<>]+$',
                        },
                        autofocus: true,
                    },
                    {
                        name: 'fileType',
                        type: InputType.SELECT,
                        label: 'fileType',
                        canChange: true,
                        selectedValue: OfficeType.XLSX,
                        items: Object.values(OfficeType),
                        translation: 'folder',
                    },
                    {
                        name: 'fileFolder',
                        type: InputType.SELECT,
                        label: 'newFileCategory',
                        canChange: true,
                        objectValue: 'name',
                        selectedValue: selectedFolder.value,
                        items: folders.value.map(folder => {
                            return { value: folder };
                        }),
                        hidden: view === ViewType.DRAGGING || view === ViewType.USER,
                        translation: 'folder',
                    },
                ],
                submit: 'create',
                cancel: 'cancel',
            };
        });
        const store = useStore();
        const folderName = ref('');
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        const focus = () => {
            setTimeout(function () {
                if (document.querySelectorAll('[autofocus]')[0]) {
                    //@ts-ignore
                    document.querySelectorAll('[autofocus]')[0].focus();
                }
            }, 20);
        };
        const CreateFile = (formData) => __awaiter(this, void 0, void 0, function* () {
            creatingFile.value = true;
            if (!formData.isValid) {
                creatingFile.value = false;
                return;
            }
            const itemsInFolder = yield getFilesInSpaceByFolderId(store.getters.selectedWorkspaceId, selectedFolder.value ? selectedFolder.value.folderUuid : null);
            const duplicate = itemsInFolder.find(file => { var _a, _b; return ((_a = file['filename']) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === replaceIllegalCharacters((_b = formData.values.filename.value) === null || _b === void 0 ? void 0 : _b.toUpperCase(), '_'); });
            if (duplicate) {
                newFileForm.value.addErrorMessage('filename', 'duplicateFileName');
                creatingFile.value = false;
                return;
            }
            const updatedItems = yield createOfficeFile(store.getters.currentUser.id, store.getters.selectedWorkspace.id, {
                filename: formData.values.filename.value,
                folderId: selectedFolder.value ? selectedFolder.value.folderUuid : null,
                type: selectedFileType.value,
            });
            if (updatedItems) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.SUCCESS,
                    message: `successFullyCreated`,
                    data: {
                        name: formData.values.filename.value,
                    },
                });
                store.commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, updatedItems);
                closeModal();
            }
            creatingFile.value = false;
        });
        return {
            folderName,
            form,
            creatingFile,
            dropdownOpen,
            selectedFolder,
            selectedFileType,
            newFileForm,
            fileIcons,
            closeModal,
            CreateFile,
            translate,
            OfficeType,
        };
    },
});
