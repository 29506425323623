import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "flex flex-col" };
const _hoisted_2 = ["for"];
const _hoisted_3 = {
    key: 0,
    class: "max-h-72 overflow-y-auto divide-y my-3 py-2"
};
const _hoisted_4 = ["type", "name", "id", "disabled", "multiple", "accept", "mozdirectory", "webkitdirectory"];
const _hoisted_5 = {
    key: 1,
    class: "text-red-600 text-sm"
};
export function render(_ctx, _cache) {
    const _component_Button = _resolveComponent("Button");
    const _component_file_tree_view = _resolveComponent("file-tree-view");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _withDirectives(_createElementVNode("label", {
                class: "text-primary",
                for: _ctx.inputDetails.name
            }, _toDisplayString(_ctx.$t(`forms.labels.${_ctx.inputDetails.label}`)) + " " + _toDisplayString(_ctx.inputDetails.validation.required ? '*' : ''), 9, _hoisted_2), [
                [_vShow, _ctx.inputDetails.label]
            ]),
            _createVNode(_component_Button, {
                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.triggerUpload())),
                colored: "",
                class: "my-1 mx-0 self-start"
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('misc.actions.uploadFile')), 1)
                ]),
                _: 1
            })
        ]),
        (!_ctx.isLoading && _ctx.fileTree.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_file_tree_view, {
                    class: "item",
                    tree: _ctx.fileTree,
                    "onUpdate:tree": _cache[1] || (_cache[1] = ($event) => ((_ctx.fileTree) = $event))
                }, null, 8, ["tree"])
            ]))
            : _createCommentVNode("", true),
        _createElementVNode("input", {
            class: "hidden",
            type: _ctx.inputDetails.type,
            name: _ctx.inputDetails.name,
            id: _ctx.inputDetails.name,
            disabled: _ctx.inputDetails.readonly,
            multiple: _ctx.inputDetails.multiple,
            accept: _ctx.inputDetails.accept,
            mozdirectory: _ctx.inputDetails.folderUpload,
            webkitdirectory: _ctx.inputDetails.folderUpload,
            onClick: _cache[2] || (_cache[2] = _withModifiers(() => { }, ["stop"])),
            onChange: _cache[3] || (_cache[3] =
                //@ts-ignore
                (...args) => (_ctx.handleFile && _ctx.handleFile(...args))),
            ref: "fileInput"
        }, null, 40, _hoisted_4),
        (_ctx.validationError && _ctx.validationError.errors && _ctx.validationError.errors.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t(`error.${_ctx.validationError.errors[0].message}`)), 1))
            : _createCommentVNode("", true)
    ], 64));
}
