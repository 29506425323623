import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { class: "col-span-1" };
const _hoisted_2 = { class: "col-span-10" };
const _hoisted_3 = { class: "col-span-1" };
const _hoisted_4 = {
    key: 0,
    class: "mb-1 col-span-12 grid grid-cols-12"
};
const _hoisted_5 = { class: "h-4 col-span-11" };
const _hoisted_6 = { class: "pl-1 h-4 col-span-1" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_check_circle_icon = _resolveComponent("check-circle-icon");
    const _component_exclamation_icon = _resolveComponent("exclamation-icon");
    const _component_exclamation_circle_icon = _resolveComponent("exclamation-circle-icon");
    const _component_information_circle_icon = _resolveComponent("information-circle-icon");
    const _component_x_icon = _resolveComponent("x-icon");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["grid grid-cols-12 gap-4 py-2 px-3 mb-2 w-96 border rounded-lg select-none", `bg-${_ctx.iconColor}-100 border-${_ctx.iconColor}-400`])
    }, [
        _createElementVNode("div", _hoisted_1, [
            (_ctx.toastData.type === _ctx.ToastType.SUCCESS)
                ? (_openBlock(), _createBlock(_component_check_circle_icon, {
                    key: 0,
                    class: _normalizeClass(["h-7 w-7", `text-${_ctx.iconColor}-800`])
                }, null, 8, ["class"]))
                : _createCommentVNode("", true),
            (_ctx.toastData.type === _ctx.ToastType.WARNING)
                ? (_openBlock(), _createBlock(_component_exclamation_icon, {
                    key: 1,
                    class: _normalizeClass(["h-7 w-7", `text-${_ctx.iconColor}-800`])
                }, null, 8, ["class"]))
                : _createCommentVNode("", true),
            (_ctx.toastData.type === _ctx.ToastType.ERROR)
                ? (_openBlock(), _createBlock(_component_exclamation_circle_icon, {
                    key: 2,
                    class: _normalizeClass(["h-7 w-7", `text-${_ctx.iconColor}-800`])
                }, null, 8, ["class"]))
                : _createCommentVNode("", true),
            (_ctx.toastData.type === _ctx.ToastType.INFO)
                ? (_openBlock(), _createBlock(_component_information_circle_icon, {
                    key: 3,
                    class: _normalizeClass(["h-7 w-7", `text-${_ctx.iconColor}-800`])
                }, null, 8, ["class"]))
                : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_2, [
            (_ctx.toastTitle)
                ? (_openBlock(), _createElementBlock("h3", {
                    key: 0,
                    class: _normalizeClass(["font-medium", [{ truncate: !_ctx.toastData.canOverflow }, `text-${_ctx.iconColor}-800`]])
                }, _toDisplayString(_ctx.toastTitle), 3))
                : _createCommentVNode("", true),
            (_ctx.toastData.message)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: _normalizeClass(["pt-1", `text-${_ctx.iconColor}-800`])
                }, _toDisplayString(_ctx.$t(`error.${_ctx.toastData.message}`, _ctx.toastData.data)), 3))
                : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_x_icon, {
                class: "text-gray-500 cursor-pointer h-6 w-6",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (_ctx.close()), ["stop"]))
            })
        ]),
        (_ctx.toastProgress)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", {
                        class: _normalizeClass([{
                                'bg-green-500': _ctx.toastData.type === _ctx.ToastType.SUCCESS,
                                'bg-red-500': _ctx.toastData.type === _ctx.ToastType.ERROR,
                                'bg-blue-500': _ctx.toastData.type === _ctx.ToastType.INFO,
                            }, "h-4 rounded"]),
                        style: _normalizeStyle({ width: _ctx.toastProgress + '%' })
                    }, null, 6)
                ]),
                _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("span", {
                        class: _normalizeClass([{
                                'text-green-500': _ctx.toastData.type === _ctx.ToastType.SUCCESS,
                                'text-red-500': _ctx.toastData.type === _ctx.ToastType.ERROR,
                                'text-blue-500': _ctx.toastData.type === _ctx.ToastType.INFO,
                            }, "text-xs font-semibold"])
                    }, _toDisplayString(_ctx.toastProgress) + "% ", 3)
                ])
            ]))
            : _createCommentVNode("", true)
    ], 2));
}
