import { __awaiter } from "tslib";
import ButtonNew from '@/components/general/ButtonNew.vue';
import Modal from '@/components/general/Modal.vue';
import Dropdown from '@/components/general/Dropdown.vue';
import { defineComponent, ref, onBeforeMount, computed } from 'vue';
import { getShareableUsersForPassword, shareSpacePassword } from '@/services/password.service';
import { useRoute } from 'vue-router';
import { PasswordRights } from '@/types/password';
import { translate } from '@/filters';
import { Table } from '@jimber/shared-components';
import UserNavigationIcon from '@/components/icons/navigation/UsersNavigationIcon.vue';
import EmptyTableState from '@/components/general/emptyStates/EmptyTableState.vue';
import CustomSelect from '@/components/general/CustomSelect.vue';
import Button from '@/components/general/Button.vue';
import { useStore } from '@/store';
export default defineComponent({
    name: 'SharePasswordModal',
    components: {
        Table,
        ButtonNew,
        Modal,
        Dropdown,
        UserNavigationIcon,
        EmptyTableState,
        CustomSelect,
        Button,
    },
    emits: ['update:show', 'add-shared-users'],
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        selectedPassword: Object,
    },
    setup(props, ctx) {
        var _a;
        const route = useRoute();
        const store = useStore();
        const selectedSpaceId = (_a = route.params) === null || _a === void 0 ? void 0 : _a.spaceId;
        const shareableUsers = ref([]);
        const isLoading = ref(true);
        const headers = computed(() => [
            {
                key: 'checkbox',
                displayName: translate('tables.headers.add'),
            },
            {
                key: 'username',
                displayName: translate('tables.headers.username'),
                enableSorting: true,
            },
            {
                key: 'rights',
                displayName: translate('tables.headers.rights', 'rights'),
            },
        ]);
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            const users = yield getShareableUsersForPassword(selectedSpaceId, props.selectedPassword.id);
            shareableUsers.value = users.map(shareableUser => {
                return Object.assign(Object.assign({}, shareableUser), { rights: PasswordRights.READ_ONLY, selected: false });
            });
            isLoading.value = false;
        }));
        const toggleSharedUser = shareData => {
            const index = shareableUsers.value.findIndex(share => share.id === shareData.id);
            if (index === -1) {
                return;
            }
            shareableUsers.value[index].selected = !shareableUsers.value[index].selected;
        };
        const isChecked = computed(() => (id) => { var _a; return (_a = shareableUsers.value.find(share => share.id === id)) === null || _a === void 0 ? void 0 : _a.selected; });
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        const sharePassword = () => __awaiter(this, void 0, void 0, function* () {
            var _b;
            const sharedPasswordUsers = yield shareSpacePassword({
                userId: (_b = store.getters.currentUser) === null || _b === void 0 ? void 0 : _b.id,
                passwordId: props.selectedPassword.id,
                vaultId: selectedSpaceId,
                passwordShares: shareableUsers.value
                    .filter(share => share.selected)
                    .map(share => {
                    return {
                        id: share.id,
                        rights: share.rights,
                    };
                }),
            });
            if (!sharedPasswordUsers) {
                return;
            }
            ctx.emit('add-shared-users', sharedPasswordUsers);
            closeModal();
        });
        return {
            isLoading,
            shareableUsers,
            headers,
            isChecked,
            PasswordRights,
            toggleSharedUser,
            closeModal,
            sharePassword,
        };
    },
});
