import { __awaiter } from "tslib";
import Modal from '@/components/general/Modal.vue';
import Form from '@/components/general/form/Form.vue';
import { editExistingSpaceById } from '@/services/admin.service';
import { useStore } from '@/store';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { InputType } from '@/types/input/inputDetails';
import { ToastType } from '@/types/toast/toast.enum';
import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'EditWorkspaceModal',
    components: { Modal, Form },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        workspace: {
            type: Object,
        },
    },
    emits: ['update:show', 'editSuccess', 'change', 'validate'],
    setup({ workspace, show }, ctx) {
        const form = ref({
            name: 'EditWorkSpaceForm',
            inputs: [
                {
                    name: 'vaultname',
                    label: 'vaultName',
                    value: workspace.name,
                    type: InputType.TEXT,
                    validation: {
                        maxLength: 50,
                        required: true,
                    },
                    placeholder: '',
                },
                {
                    name: 'notes',
                    label: 'notes',
                    value: workspace.notes,
                    type: InputType.TEXT_AREA,
                    placeholder: '',
                    validation: {
                        maxLength: 250,
                        lazy: false,
                    },
                },
            ],
            submit: 'edit',
            cancel: 'cancel',
        });
        const store = useStore();
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        const confirmEdit = (formData) => __awaiter(this, void 0, void 0, function* () {
            if (!formData.isValid) {
                return;
            }
            if ((workspace === null || workspace === void 0 ? void 0 : workspace.name) != formData.values.vaultname.value) {
                const doesExists = store.getters.workspaces.find(space => {
                    return space.name === formData.values.vaultname.value;
                });
                if (doesExists) {
                    return store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                        type: ToastType.ERROR,
                        message: 'spaceNameAlreadyExists',
                        data: {
                            name: formData.values.vaultname.value,
                        },
                    });
                }
            }
            const newSpace = {
                id: workspace.id,
                name: formData.values.vaultname ? formData.values.vaultname.value : workspace.name,
                notes: formData.values.notes ? formData.values.notes.value : workspace.notes,
            };
            const editedSpace = yield editExistingSpaceById(newSpace);
            if (editedSpace) {
                ctx.emit('editSuccess', newSpace);
                closeModal();
                return;
            }
        });
        return {
            form,
            closeModal,
            confirmEdit,
        };
    },
});
