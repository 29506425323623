import { __awaiter } from "tslib";
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import Avatar from '@/components/general/Avatar.vue';
import SearchBar from '@/components/vault/SearchBar.vue';
import DetailsSidebar from '@/components/vault/sidebars/DetailsSidebar.vue';
import EditUserModal from '@/components/vault/modals/EditUserModal.vue';
import { formatDate } from '@/filters';
import { useStore } from '@/store';
import { getVaultUserHistory } from '@/services/admin.service';
import DataTable from '@/components/general/DataTable.vue';
import Tab from '@/components/general/Tab.vue';
import AdminVaultUserOverview from '@/components/admin/AdminVaultUserOverview.vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    name: 'UserSidebar',
    components: {
        AdminVaultUserOverview,
        DataTable,
        DetailsSidebar,
        SearchBar,
        Avatar,
        EditUserModal,
        Tab,
    },
    props: {
        selectedVaultUser: Object,
    },
    emits: ['edit-user', 'collapse-sidebar'],
    setup({ selectedVaultUser }) {
        const router = useRouter();
        const i18n = useI18n();
        const store = useStore();
        const currentIndex = ref(5);
        const userLoginHistory = ref([]);
        const workspaceSearch = ref('');
        const editUser = ref(false);
        const tabs = ['vault', 'loginHistory'];
        const currentTab = ref(tabs[0]);
        let unWatchLanguage;
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            if (!selectedVaultUser) {
                return;
            }
            userLoginHistory.value = yield getVaultUserHistory(selectedVaultUser.id, 0, 5);
            userLoginHistory.value = translateLoginHistory(userLoginHistory.value);
            unWatchLanguage = store.watch((state, getters) => getters.selectedLanguage, () => __awaiter(this, void 0, void 0, function* () {
                userLoginHistory.value = translateLoginHistory(userLoginHistory.value);
            }));
        }));
        onBeforeUnmount(() => {
            if (unWatchLanguage) {
                unWatchLanguage();
            }
        });
        const goToPersonalSpace = (workspace) => __awaiter(this, void 0, void 0, function* () {
            return yield router.push({
                name: 'Files',
                params: {
                    spaceId: workspace.id,
                    spaceName: workspace.name,
                },
            });
        });
        const translateLoginHistory = loginHistory => {
            return loginHistory.map(history => {
                history.status = i18n.t(`tables.login.${history.success ? 'success' : 'failed'}`);
                return history;
            });
        };
        const addMore = () => __awaiter(this, void 0, void 0, function* () {
            const nextHistory = yield getVaultUserHistory(selectedVaultUser.id, currentIndex.value, 5);
            userLoginHistory.value = userLoginHistory.value.concat(translateLoginHistory(nextHistory));
            currentIndex.value += 5;
        });
        const userWorkspaces = computed(() => workspaceSearch.value.trim() !== ''
            ? selectedVaultUser.spaces.filter(workspace => workspace.name.toLowerCase().indexOf(workspaceSearch.value.trim().toLowerCase()) > -1)
            : selectedVaultUser.spaces);
        return {
            editUser,
            userLoginHistory,
            workspaceSearch,
            userWorkspaces,
            tabs,
            currentTab,
            formatDate,
            goToPersonalSpace,
            addMore,
        };
    },
    computed: {
        translation(translationKey) {
            return this.$t(translationKey);
        },
    },
});
