import { __awaiter } from "tslib";
import axios from 'axios';
import { config } from '@/config';
export const setupVault = (data) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    return (_a = (yield axios.post(`${config.value.apiEndPoint}/setup/vault`, data))) === null || _a === void 0 ? void 0 : _a.data;
});
export const isSetupCompleted = () => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    return (_b = (yield axios.get(`${config.value.apiEndPoint}/setup/vault`))) === null || _b === void 0 ? void 0 : _b.data;
});
export const setupSingleVault = () => __awaiter(void 0, void 0, void 0, function* () {
    location.href = `${config.value.apiEndPoint}/auth/setup`;
});
