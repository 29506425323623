import LanguageSelector from '@/components/general/LanguageSelector/LanguageSelector.vue';
import { config } from '@/config';
import { onBeforeMount } from '@vue/runtime-core';
export default {
    name: 'LogoutScreen',
    components: { LanguageSelector },
    setup() {
        onBeforeMount(() => {
            localStorage.removeItem('redirect');
        });
        const goToSignIn = () => {
            window.location.href = '/signin';
        };
        return {
            goToSignIn,
            logoUrl: config.value.logo,
        };
    },
};
