import { __awaiter } from "tslib";
import Modal from '@/components/general/Modal.vue';
import { defineComponent, onMounted, ref, watch } from 'vue';
import Button from '@/components/general/Button.vue';
import { inviteUser } from '@/services/invitaiton.service';
import { ToastType } from '@/types/toast/toast.enum';
import { config } from '@/config';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { useStore } from '@/store';
import { generateRandomWordIndexes, generateSeedPhrase, isValidWord } from '@/services/crypto.service';
import { InputType } from '@/types/input/inputDetails';
import validator from 'validator';
import Form from '@/components/general/form/Form.vue';
import { checkIfEmailAlreadyExists, checkIfUsernameExistsInAuthenticator } from '@/services/admin.service';
import { Language } from '@/types';
import { translate } from '@/filters';
export default defineComponent({
    name: 'InvitationModal',
    components: {
        Button,
        Modal,
        Form,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['user-added', 'update:show'],
    setup({ show }, ctx) {
        const userDetails = ref({
            email: '',
            firstname: '',
            lastname: '',
            username: '',
            seedPhrase: generateSeedPhrase(),
            language: config.value.defaultLanguage,
        });
        const usernameForm = ref({
            name: 'UsernameForm',
            inputs: [
                {
                    name: 'username',
                    label: 'username',
                    type: InputType.TEXT,
                    value: userDetails.value.username,
                    validation: {
                        maxLength: 50,
                        required: true,
                        regex: '^([a-zA-Z0-9]*)$',
                    },
                    autofocus: true,
                },
            ],
            submit: 'next',
            cancel: 'previous',
        });
        const form = ref({
            name: 'InvitationForm',
            inputs: [
                {
                    name: 'email',
                    label: 'email',
                    type: InputType.TEXT,
                    value: userDetails.value.email,
                    validation: {
                        maxLength: 100,
                        required: true,
                        validator: validator.isEmail,
                        lazy: true,
                    },
                    autofocus: true,
                },
                {
                    name: 'firstname',
                    label: 'firstname',
                    type: InputType.TEXT,
                    value: userDetails.value.firstname,
                    validation: {
                        required: true,
                        maxLength: 25,
                    },
                },
                {
                    name: 'lastname',
                    label: 'lastname',
                    value: userDetails.value.lastname,
                    type: InputType.TEXT,
                    validation: {
                        required: true,
                        maxLength: 25,
                    },
                },
                {
                    name: 'language',
                    label: 'language',
                    type: InputType.SELECT,
                    canChange: true,
                    selectedValue: config.value.defaultLanguage,
                    value: userDetails.value.language,
                    items: Object.keys(Language).map(lang => {
                        return { value: lang.toLowerCase() };
                    }),
                },
            ],
            submit: 'next',
            cancel: 'cancel',
        });
        const store = useStore();
        const step = ref(0);
        const steps = [
            { id: 'Step 1', name: translate('invite.userInfo'), status: 'current' },
            { id: 'Step 2', name: translate('invite.selectUsername'), status: 'upcoming' },
            { id: 'Step 3', name: translate('forms.labels.seedPhrase'), status: 'upcoming' },
            { id: 'Step 4', name: translate('invite.confirmSeedphrase'), status: 'upcoming' },
        ];
        watch(step, () => {
            focus();
        });
        onMounted(() => {
            focus();
        });
        const seedsShownAlready = ref(false);
        const focus = () => {
            setTimeout(function () {
                if (document.querySelectorAll('[autofocus]')[0]) {
                    //@ts-ignore
                    document.querySelectorAll('[autofocus]')[0].focus();
                }
            }, 20);
        };
        const closeModal = () => {
            ctx.emit('update:show', false);
            step.value = 0;
            seedsShownAlready.value = false;
            userDetails.value = {
                email: '',
                firstname: '',
                lastname: '',
                username: '',
                seedPhrase: generateSeedPhrase(),
                language: config.value.defaultLanguage,
            };
        };
        const validateFields = (formData) => __awaiter(this, void 0, void 0, function* () {
            // Only go to the next step when the given values are valid
            if (formData.isValid) {
                userDetails.value.firstname = formData.values.firstname.value;
                userDetails.value.lastname = formData.values.lastname.value;
                userDetails.value.email = formData.values.email.value;
                userDetails.value.language = formData.values.language.value;
                if (yield checkIfEmailAlreadyExists(formData.values.email.value)) {
                    store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                        type: ToastType.ERROR,
                        message: `emailAlreadyTaken`,
                    });
                    return;
                }
                usernameForm.value.inputs[0].value = (userDetails.value.firstname + userDetails.value.lastname).replace(/[^a-zA-Z0-9]/g, '');
                nextStep();
            }
        });
        const validateUsername = (formData) => __awaiter(this, void 0, void 0, function* () {
            if (yield checkIfUsernameExistsInAuthenticator(formData.values.username.value)) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: `usernameAlreadyExists`,
                });
                return;
            }
            if (formData.isValid) {
                userDetails.value.username = formData.values.username.value;
                nextStep();
            }
        });
        const nextStep = () => {
            step.value += 1;
            steps[step.value - 1].status = 'complete';
            steps[step.value].status = 'current';
        };
        const previousStep = () => {
            if (step.value == 2 && !seedsShownAlready.value) {
                seedsShownAlready.value = true;
            }
            step.value -= 1;
            steps[step.value].status = 'current';
            steps[step.value + 1].status = 'upcoming';
        };
        const filledWords = ref([]);
        const randomWordIndexes = generateRandomWordIndexes();
        const isSeedPhraseWordsCorrect = () => {
            let isValid = true;
            randomWordIndexes.forEach((wordIndex, i) => {
                if (!isValidWord(userDetails.value.seedPhrase.split(' '), wordIndex, filledWords.value[i])) {
                    isValid = false;
                }
            });
            return isValid;
        };
        const sendInvitation = () => __awaiter(this, void 0, void 0, function* () {
            const canProceed = isSeedPhraseWordsCorrect();
            if (!canProceed) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: `wordInSeedIncorrect`,
                });
                return;
            }
            const invitation = yield inviteUser(userDetails.value);
            if (!invitation) {
                return;
            }
            ctx.emit('user-added');
            store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                type: ToastType.SUCCESS,
                message: `successfullySendInvite`,
                data: {
                    user: userDetails.value.email,
                },
            });
            closeModal();
        });
        return {
            form,
            userDetails,
            invitationDuration: config.value.invitation.expirationDays,
            closeModal,
            sendInvitation,
            validateFields,
            nextStep,
            previousStep,
            validateUsername,
            usernameForm,
            randomWordIndexes,
            filledWords,
            step,
            steps,
            seedsShownAlready,
            focus,
        };
    },
});
