import { ref } from 'vue';
import { useScroll } from '@vueuse/core';
export default {
    mounted: function (el, binding) {
        const { directions } = useScroll(el);
        const isInitialised = ref(false);
        const isLastCall = ref(false);
        const hasTrigger = ref(false);
        const isLoading = ref(false);
        const index = ref(0);
        const initialScrollHeight = ref(el.scrollHeight);
        el.onscroll = () => {
            var _a, _b, _c, _d;
            if (!isInitialised.value) {
                initialScrollHeight.value = el.scrollHeight;
                isInitialised.value = true;
                return;
            }
            if (directions.left || directions.right) {
                return;
            }
            if (el.scrollHeight === initialScrollHeight.value && hasTrigger.value) {
                isLastCall.value = false;
                index.value = 0;
            }
            if (isLastCall.value) {
                return;
            }
            const triggerPercentage = ((_b = (_a = binding.value) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.triggerPercentage) || 100;
            const limit = ((_d = (_c = binding.value) === null || _c === void 0 ? void 0 : _c.options) === null || _d === void 0 ? void 0 : _d.limit) || 25;
            const scrollPercentage = ((el.scrollTop + el.offsetHeight) / el.scrollHeight) * 100;
            if (scrollPercentage < triggerPercentage || isLoading.value) {
                return;
            }
            isLoading.value = true;
            index.value++;
            if (binding.value instanceof Function) {
                binding.value({ index: index.value }).then(data => {
                    hasTrigger.value = data.length > 0;
                    isLastCall.value = data.length < limit;
                    isLoading.value = false;
                });
                return;
            }
            binding.value.trigger({ index: index.value }).then(data => {
                hasTrigger.value = data.length > 0;
                isLastCall.value = data.length < limit;
                isLoading.value = false;
            });
        };
    },
};
