import { socketActions } from './socket.actions';
import { socketGetters } from './socket.getters';
import { socketMutations } from './socket.mutations';
import { socketState } from './socket.state';
export const socketStore = {
    state: socketState,
    getters: socketGetters,
    mutations: socketMutations,
    actions: socketActions,
    //namespaced: true,
};
