export var VaultAction;
(function (VaultAction) {
    VaultAction["OPEN_VAULT"] = "open";
    VaultAction["UPDATE_VAULT_ITEM_DETAILS"] = "item:update-details";
    VaultAction["UPDATE_VAULT_ITEMS"] = "item:update";
    VaultAction["DELETE_VAULT_ITEMS"] = "item:delete";
    VaultAction["RENAME_VAULT_ITEM"] = "item:rename";
    VaultAction["ADD_VAULT_USER"] = "user:add";
    VaultAction["REMOVE_VAULT_USER"] = "user:remove";
    VaultAction["SEARCH_FILE_FOUND"] = "search:found";
})(VaultAction || (VaultAction = {}));
