import { __awaiter } from "tslib";
import { defineComponent, ref, onMounted, onUnmounted, computed } from 'vue';
import Avatar from '@/components/general/Avatar.vue';
import { logoutUser } from '@/services/authentication.service';
import { useStore } from '@/store';
import { mapGetters } from 'vuex';
import { GeneralActionTypes } from '@/store/modules/general/general.actions';
import { useRoute, useRouter } from 'vue-router';
import SpinnerModal from '@/components/vault/modals/SpinnerModal.vue';
export default defineComponent({
    name: 'UserDropdown',
    components: {
        Avatar,
        SpinnerModal,
    },
    props: {
        noSelection: { type: Boolean, default: false },
    },
    emits: ['closeMenu'],
    setup(props) {
        const store = useStore();
        const currentRoute = useRoute();
        const router = useRouter();
        const dropDownOpen = ref(false);
        const workSpaceDropdown = ref(null);
        const logout = () => __awaiter(this, void 0, void 0, function* () {
            yield store.dispatch(GeneralActionTypes.SET_STATUS_LOGIN_SPINNER, true);
            dropDownOpen.value = false;
            yield logoutUser();
            yield store.dispatch(GeneralActionTypes.SET_STATUS_LOGIN_SPINNER, false);
            window.location.href = '/logout';
        });
        const goToSelection = () => {
            if (store.getters.isAdmin) {
                return router.push({
                    name: 'AdminVaultsOverview',
                });
            }
            return router.push({
                name: 'Selection',
            });
        };
        const canGoToSelection = computed(() => {
            var _a, _b;
            if (currentRoute.name === 'AdminVaultsOverview') {
                return false;
            }
            return !props.noSelection && ((_b = (_a = store.getters) === null || _a === void 0 ? void 0 : _a.personalWorkspaces) === null || _b === void 0 ? void 0 : _b.length) > 1;
        });
        const close = e => {
            var _a;
            const target = e.target;
            if (workSpaceDropdown.value !== target && !((_a = workSpaceDropdown.value) === null || _a === void 0 ? void 0 : _a.contains(target))) {
                dropDownOpen.value = false;
                return;
            }
        };
        onMounted(() => {
            document.addEventListener('click', close);
        });
        onUnmounted(() => close);
        return {
            workSpaceDropdown,
            dropDownOpen,
            canGoToSelection,
            currentRoute,
            close,
            logout,
            goToSelection,
        };
    },
    computed: Object.assign({}, mapGetters(['currentUser', 'statusLoginSpinner'])),
});
