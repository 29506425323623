import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue";
const _hoisted_1 = ["for"];
const _hoisted_2 = ["autocomplete", "type", "name", "id", "disabled", "placeholder", "minlength", "maxlength", "autofocus"];
const _hoisted_3 = {
    key: 0,
    class: "text-red-600 text-sm"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _withDirectives(_createElementVNode("label", {
            class: "text-primary",
            for: _ctx.inputDetails.name
        }, _toDisplayString(_ctx.$t(`forms.labels.${_ctx.inputDetails.label}`)) + " " + _toDisplayString(_ctx.inputDetails.validation.required ? '*' : ''), 9, _hoisted_1), [
            [_vShow, _ctx.inputDetails.label]
        ]),
        _withDirectives(_createElementVNode("input", {
            class: "p-1 mt-1 border border-gray-300",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.fieldValue) = $event)),
            autocomplete: _ctx.inputDetails.autocomplete ? 'on' : 'off',
            type: _ctx.inputDetails.type,
            name: _ctx.inputDetails.name,
            id: _ctx.inputDetails.name,
            disabled: _ctx.inputDetails.readonly,
            placeholder: _ctx.inputDetails.placeholder,
            minlength: _ctx.inputDetails.validation.minLength,
            maxlength: _ctx.inputDetails.validation.maxLength,
            autofocus: _ctx.inputDetails.autofocus
        }, null, 8, _hoisted_2), [
            [_vModelDynamic, _ctx.fieldValue]
        ]),
        _createElementVNode("div", {
            class: _normalizeClass(["flex", {
                    'justify-end': _ctx.inputDetails.validation && _ctx.inputDetails.validation.maxLength,
                    'justify-between': _ctx.validationError && _ctx.validationError.errors && _ctx.validationError.errors.length > 0 && _ctx.inputDetails.validation && _ctx.inputDetails.validation.maxLength,
                }])
        }, [
            (_ctx.validationError && _ctx.validationError.errors && _ctx.validationError.errors.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t(`error.${_ctx.validationError.errors[0].message}`)), 1))
                : _createCommentVNode("", true),
            (_ctx.inputDetails.validation && _ctx.inputDetails.validation.maxLength)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass([_ctx.validationError && _ctx.validationError.errors && _ctx.validationError.errors.length > 0 ? ['text-red-600'] : '', "text-sm flex text-gray-300"])
                }, _toDisplayString(_ctx.fieldValue.length) + "/" + _toDisplayString(_ctx.inputDetails.validation.maxLength), 3))
                : _createCommentVNode("", true)
        ], 2)
    ], 64));
}
