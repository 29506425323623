import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, withModifiers as _withModifiers, withCtx as _withCtx, createSlots as _createSlots } from "vue";
const _hoisted_1 = { class: "fixed right-0 mr-5 mt-5 hidden xl:flex flex-row items-center justify-center z-10" };
const _hoisted_2 = { class: "mx-3" };
const _hoisted_3 = { class: "h-screen flex flex-col items-center justify-center" };
const _hoisted_4 = { class: "text-primary font-bold text-xl" };
const _hoisted_5 = { class: "p-4" };
const _hoisted_6 = { class: "pt-2" };
const _hoisted_7 = ["href"];
export function render(_ctx, _cache) {
    const _component_LanguageSelector = _resolveComponent("LanguageSelector");
    const _component_avatar = _resolveComponent("avatar");
    const _component_qr_code = _resolveComponent("qr-code");
    const _component_card = _resolveComponent("card");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_LanguageSelector)
            ])
        ]),
        _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_card, {
                width: "32",
                heigth: "32",
                class: "container flex flex-row flex-wrap"
            }, _createSlots({
                header: _withCtx(() => [
                    _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('invite.details')), 1)
                ]),
                actions: _withCtx(() => [
                    _createElementVNode("span", {
                        onClick: _cache[7] || (_cache[7] = ($event) => (_ctx.goToLogin())),
                        class: "cursor-pointer"
                    }, _toDisplayString(_ctx.$t('misc.toLogin')) + " > ", 1)
                ]),
                _: 2
            }, [
                (!_ctx.invitation)
                    ? {
                        name: "default",
                        fn: _withCtx(() => [
                            _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("div", null, _toDisplayString(_ctx.$t('invite.inviteCouldNotBeFound')), 1),
                                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('invite.contactInfo')), 1),
                                _createElementVNode("div", null, [
                                    _createElementVNode("a", {
                                        href: 'mailto:' + _ctx.contactEmail
                                    }, _toDisplayString(_ctx.contactEmail), 9, _hoisted_7)
                                ])
                            ])
                        ])
                    }
                    : {
                        name: "default",
                        fn: _withCtx(() => [
                            (_ctx.invitation.status === _ctx.InvitationStatus.PENDING)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    (_ctx.invitation.type === 'workspace')
                                        ? (_openBlock(), _createBlock(_component_avatar, {
                                            key: 0,
                                            class: "w-24 h-24",
                                            "round-size": "full",
                                            src: `https://picsum.photos/seed/${_ctx.invitation.workspace.id}/300/300`
                                        }, null, 8, ["src"]))
                                        : _createCommentVNode("", true),
                                    _createElementVNode("div", { class: "px-4 py-2" }, [
                                        _createElementVNode("div", { class: "flex flex-row" }, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('invite.receivedInvite', { companyName: _ctx.config.companyName })) + " ", 1),
                                            _createElementVNode("br"),
                                            _createElementVNode("br"),
                                            _createTextVNode(" " + _toDisplayString(_ctx.$t('invite.receivedInviteExpiringDate', {
                                                expireDate: _ctx.formatDate(_ctx.invitation.expirationDate),
                                            })), 1)
                                        ])
                                    ]),
                                    (!_ctx.hasPrivacyPolicy || (_ctx.terms.acceptPrivacy && _ctx.terms.acceptTerms))
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                            _createElementVNode("div", { class: "flex flex-row justify-center pb-4" }, [
                                                (_ctx.isMobile())
                                                    ? (_openBlock(), _createElementBlock("button", {
                                                        key: 0,
                                                        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.openAppUrl())),
                                                        class: "capitalize"
                                                    }, "Open App"))
                                                    : _createCommentVNode("", true)
                                            ]),
                                            _createElementVNode("div", { class: "flex flex-row justify-center pb-3" }, [
                                                (_openBlock(), _createBlock(_component_qr_code, {
                                                    size: 200,
                                                    level: "H",
                                                    value: _ctx.appUrl,
                                                    key: _ctx.appUrl
                                                }, null, 8, ["value"]))
                                            ])
                                        ], 64))
                                        : _createCommentVNode("", true),
                                    (_ctx.hasPrivacyPolicy)
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                            _createElementVNode("div", { class: "px-3 flex flex-row items-center text-sm" }, [
                                                _withDirectives(_createElementVNode("input", {
                                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.terms.acceptTerms) = $event)),
                                                    autocomplete: "off",
                                                    type: "checkbox",
                                                    class: "w-6"
                                                }, null, 512), [
                                                    [_vModelCheckbox, _ctx.terms.acceptTerms]
                                                ]),
                                                _createElementVNode("span", {
                                                    class: "select-none",
                                                    onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.terms.acceptTerms = !_ctx.terms.acceptTerms))
                                                }, [
                                                    _createTextVNode(_toDisplayString(_ctx.$t('misc.readAndAccept')) + " ", 1),
                                                    _createElementVNode("a", {
                                                        onClick: _cache[2] || (_cache[2] = _withModifiers(() => { }, ["stop"])),
                                                        href: "/privacy/terms_of_service.pdf",
                                                        download: ""
                                                    }, "Terms Of Service.")
                                                ])
                                            ]),
                                            _createElementVNode("div", { class: "px-3 flex flex-row items-center text-sm" }, [
                                                _withDirectives(_createElementVNode("input", {
                                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.terms.acceptPrivacy) = $event)),
                                                    autocomplete: "off",
                                                    type: "checkbox",
                                                    class: "w-6"
                                                }, null, 512), [
                                                    [_vModelCheckbox, _ctx.terms.acceptPrivacy]
                                                ]),
                                                _createElementVNode("span", {
                                                    class: "select-none",
                                                    onClick: _cache[6] || (_cache[6] = ($event) => (_ctx.terms.acceptPrivacy = !_ctx.terms.acceptPrivacy))
                                                }, [
                                                    _createTextVNode(_toDisplayString(_ctx.$t('misc.readAndAccept')) + " ", 1),
                                                    _createElementVNode("a", {
                                                        onClick: _cache[5] || (_cache[5] = _withModifiers(() => { }, ["stop"])),
                                                        href: "/privacy/privacy_statement.pdf",
                                                        download: ""
                                                    }, "Privacy Policy.")
                                                ])
                                            ])
                                        ], 64))
                                        : _createCommentVNode("", true)
                                ], 64))
                                : (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    class: "p-4"
                                }, _toDisplayString(_ctx.$t('invite.inviteHasAlreadyBeen', {
                                    status: _ctx.$t(`invite.statuses.${_ctx.invitation.status.toLowerCase()}`),
                                })), 1))
                        ])
                    }
            ]), 1024)
        ])
    ], 64));
}
