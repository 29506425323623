import { __awaiter } from "tslib";
import axios from 'axios';
import { config } from '@/config';
// Spaces Passwords
export const createSpacePassword = (spaceId, passwordPostDto) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    return (_a = (yield axios.post(`${config.value.apiEndPoint}/spaces/${spaceId}/passwords`, passwordPostDto))) === null || _a === void 0 ? void 0 : _a.data;
});
export const createSpacePasswordFolder = (spaceId, passwordFolderPostDto) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    return (_b = (yield axios.post(`${config.value.apiEndPoint}/spaces/${spaceId}/passwords/folders`, passwordFolderPostDto))) === null || _b === void 0 ? void 0 : _b.data;
});
export const getSpacePasswordItems = (spaceId, categoryId) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    return (_c = (yield axios.get(`${config.value.apiEndPoint}/spaces/${spaceId}/passwords`, {
        params: {
            categoryId,
        },
    }))) === null || _c === void 0 ? void 0 : _c.data;
});
export const deleteSpaceItem = (spaceId, passwordItemDeleteDto) => __awaiter(void 0, void 0, void 0, function* () {
    return axios.delete(`${config.value.apiEndPoint}/spaces/${spaceId}/passwords`, {
        data: passwordItemDeleteDto,
    });
});
export const editSpacePassword = (spaceId, passwordId, passwordPutDto) => __awaiter(void 0, void 0, void 0, function* () {
    var _d;
    return (_d = (yield axios.put(`${config.value.apiEndPoint}/spaces/${spaceId}/passwords/${passwordId}`, passwordPutDto))) === null || _d === void 0 ? void 0 : _d.data;
});
export const getShareableUsersForPassword = (spaceId, passwordId) => __awaiter(void 0, void 0, void 0, function* () {
    var _e;
    return (_e = (yield axios.get(`${config.value.apiEndPoint}/spaces/${spaceId}/passwords/${passwordId}/shareable-users`))) === null || _e === void 0 ? void 0 : _e.data;
});
export const getPasswordUsers = (spaceId, passwordId) => __awaiter(void 0, void 0, void 0, function* () {
    var _f;
    return (_f = (yield axios.get(`${config.value.apiEndPoint}/spaces/${spaceId}/passwords/${passwordId}/users`))) === null || _f === void 0 ? void 0 : _f.data;
});
export const deleteUserFromShare = (spaceId, passwordId, userId) => __awaiter(void 0, void 0, void 0, function* () {
    return axios.delete(`${config.value.apiEndPoint}/spaces/${spaceId}/passwords/${passwordId}/share/${userId}`);
});
// User Vault calls
export const decryptSpacePassword = ({ userId, passwordId, vaultId }) => __awaiter(void 0, void 0, void 0, function* () {
    var _g;
    return (_g = (yield axios.get(`${config.value.vaultSpaceApi}/${userId}/spaces/${vaultId}/passwords/${passwordId}`))) === null || _g === void 0 ? void 0 : _g.data;
});
export const shareSpacePassword = ({ userId, passwordId, vaultId, passwordShares, }) => __awaiter(void 0, void 0, void 0, function* () {
    var _h;
    return (_h = (yield axios.post(`${config.value.vaultSpaceApi}/${userId}/spaces/${vaultId}/passwords/${passwordId}/share`, passwordShares))) === null || _h === void 0 ? void 0 : _h.data;
});
// Personal Passwords
export const createPersonalPassword = (passwordPostDto) => __awaiter(void 0, void 0, void 0, function* () {
    var _j;
    return (_j = (yield axios.post(`${config.value.apiEndPoint}/passwords`, passwordPostDto))) === null || _j === void 0 ? void 0 : _j.data;
});
export const createPersonalPasswordFolder = (passwordFolderPostDto) => __awaiter(void 0, void 0, void 0, function* () {
    var _k;
    return (_k = (yield axios.post(`${config.value.apiEndPoint}/passwords/folders`, passwordFolderPostDto))) === null || _k === void 0 ? void 0 : _k.data;
});
export const getPersonalPasswordItems = (categoryId) => __awaiter(void 0, void 0, void 0, function* () {
    var _l;
    return (_l = (yield axios.get(`${config.value.apiEndPoint}/passwords`, {
        params: {
            categoryId,
        },
    }))) === null || _l === void 0 ? void 0 : _l.data;
});
export const deletePersonalItem = (passwordItemDeleteDto) => __awaiter(void 0, void 0, void 0, function* () {
    return yield axios.delete(`${config.value.apiEndPoint}/passwords`, {
        data: passwordItemDeleteDto,
    });
});
export const editPersonalPassword = (passwordId, data) => __awaiter(void 0, void 0, void 0, function* () {
    var _m;
    return (_m = (yield axios.put(`${config.value.apiEndPoint}/passwords/${passwordId}`, data))) === null || _m === void 0 ? void 0 : _m.data;
});
export const decryptedPersonalPassword = ({ userId, passwordId }) => __awaiter(void 0, void 0, void 0, function* () {
    var _o;
    return (_o = (yield axios.get(`${config.value.vaultSpaceApi}/${userId}/passwords/${passwordId}`))) === null || _o === void 0 ? void 0 : _o.data;
});
