import { __awaiter } from "tslib";
import axios from 'axios';
import { config } from '@/config';
import { useStore } from '@/store';
export const createNewWorkspace = (name) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    return (_a = (yield axios.post(`${config.value.apiEndPoint}/spaces/`, { name }))) === null || _a === void 0 ? void 0 : _a.data;
});
export const getWorkspaceDetails = (spaceId) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    return (_b = (yield axios.get(`${config.value.apiEndPoint}/spaces/${spaceId}/`))) === null || _b === void 0 ? void 0 : _b.data;
});
export const getUsersInWorkspace = (options) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    return (_c = (yield axios.get(`${config.value.apiEndPoint}/spaces/${options.vaultId}/users`, {
        params: {
            q: options === null || options === void 0 ? void 0 : options.value,
            order: options === null || options === void 0 ? void 0 : options.order,
            field: options === null || options === void 0 ? void 0 : options.field,
            skip: options === null || options === void 0 ? void 0 : options.skip,
            take: options === null || options === void 0 ? void 0 : options.take,
        },
    }))) === null || _c === void 0 ? void 0 : _c.data;
});
export const updateLastAccessedTime = (spaceId) => __awaiter(void 0, void 0, void 0, function* () {
    var _d;
    return (_d = (yield axios.put(`${config.value.apiEndPoint}/spaces/${spaceId}/accessed`))) === null || _d === void 0 ? void 0 : _d.data;
});
export const updateSpaceUserRights = (spaceId, userId, data) => __awaiter(void 0, void 0, void 0, function* () {
    var _e;
    const store = useStore();
    return (_e = (yield axios.put(`${config.value.vaultSpaceApi}/${store.getters.currentUser.id}/spaces/${spaceId}/users/${userId}`, data))) === null || _e === void 0 ? void 0 : _e.data;
});
export const createWorkspaceFolder = (spaceId, name, parentUuid, rootFolderId, metaData) => __awaiter(void 0, void 0, void 0, function* () {
    var _f;
    const postData = {
        name,
        parentUuid,
        rootFolderId,
        metaData,
    };
    return (_f = (yield axios.post(`${config.value.apiEndPoint}/folders/${spaceId}`, postData))) === null || _f === void 0 ? void 0 : _f.data;
});
export const createOfficeFile = (userId, spaceId, data) => __awaiter(void 0, void 0, void 0, function* () {
    var _g;
    return (_g = (yield axios.post(`${config.value.vaultSpaceApi}/${userId}/spaces/${spaceId}/officeFile`, data))) === null || _g === void 0 ? void 0 : _g.data;
});
export const getVaults = (options) => __awaiter(void 0, void 0, void 0, function* () {
    var _h;
    return (_h = (yield axios.get(`${config.value.apiEndPoint}/spaces`, {
        params: {
            q: options === null || options === void 0 ? void 0 : options.value,
            order: options === null || options === void 0 ? void 0 : options.order,
            field: options === null || options === void 0 ? void 0 : options.field,
            skip: options === null || options === void 0 ? void 0 : options.skip,
            take: options === null || options === void 0 ? void 0 : options.take,
        },
    }))) === null || _h === void 0 ? void 0 : _h.data;
});
export const doesUserExistOnPlatform = (spaceId, email) => __awaiter(void 0, void 0, void 0, function* () {
    var _j;
    return (_j = (yield axios.post(`${config.value.apiEndPoint}/spaces/${spaceId}/user/exists`, {
        email: email,
    }))) === null || _j === void 0 ? void 0 : _j.data;
});
