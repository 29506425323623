export var InvitationStatus;
(function (InvitationStatus) {
    InvitationStatus["PENDING"] = "pending";
    InvitationStatus["ACCEPTED"] = "accepted";
    InvitationStatus["CANCELLED"] = "canceled";
})(InvitationStatus || (InvitationStatus = {}));
export var InvitationType;
(function (InvitationType) {
    InvitationType["WORKSPACE"] = "workspace";
    InvitationType["VAULT_ADMIN"] = "vault admin";
    InvitationType["RECOVERY"] = "recover";
})(InvitationType || (InvitationType = {}));
export var InvitationRole;
(function (InvitationRole) {
    InvitationRole["VAULT_ADMIN"] = "vault admin";
    InvitationRole["ADVANCED_VAULT_USER"] = "advanced user";
    InvitationRole["BASIC_VAULT_USER"] = "basic user";
    InvitationRole["LIMITED_VAULT_USER"] = "limited user";
})(InvitationRole || (InvitationRole = {}));
