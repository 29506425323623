import { defineComponent, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';
export default defineComponent({
    name: 'Home',
    setup() {
        const router = useRouter();
        const { getters } = useStore();
        const { isAdmin } = getters;
        onBeforeMount(() => {
            if (isAdmin) {
                return router.push({ name: 'AdminVaultsOverview' });
            }
            return router.push({ name: 'Selection' });
        });
    },
});
