import { defineComponent } from 'vue';
export default defineComponent({
    name: 'EmptyTableState',
    props: {
        action: {
            type: Boolean,
            default: false,
        },
    },
});
