import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache) {
    const _component_alert = _resolveComponent("alert");
    return (_ctx.show)
        ? (_openBlock(), _createBlock(_component_alert, {
            key: 0,
            onClose: _cache[2] || (_cache[2] = ($event) => (_ctx.closeModal()))
        }, {
            header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('passwordManager.modals.removeShare')), 1)
            ]),
            default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('passwordManager.modals.removeShareMessage')), 1)
            ]),
            actions: _withCtx(() => [
                _createElementVNode("button", {
                    type: "button",
                    class: "mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 sm:w-auto sm:text-sm",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
                    //@ts-ignore
                    (...args) => (_ctx.closeModal && _ctx.closeModal(...args)), ["stop"]))
                }, _toDisplayString(_ctx.$t(`forms.buttons.cancel`)), 1),
                _createElementVNode("button", {
                    type: "button",
                    class: "mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 ml-2 sm:w-auto sm:text-sm",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(
                    //@ts-ignore
                    (...args) => (_ctx.handleDelete && _ctx.handleDelete(...args)), ["stop"]))
                }, _toDisplayString(_ctx.$t(`forms.buttons.remove`)), 1)
            ]),
            _: 1
        }))
        : _createCommentVNode("", true);
}
