import { VaultRole } from '@/types';
const fr = {
    selection: {
        whereDoYouWantToGo: 'Où voulez-vous aller ?',
        selectVault: 'Sélectionnez votre @:vault.vault',
        yourVaults: 'Vos @:vault.vaults',
        brandOnly: '{ companyName } only section',
    },
    user: {
        user: 'utilisateur',
        users: 'utilisateurs',
        hasNoUsers: 'Aucun utilisateur',
        hasNoUserFor: 'Aucun utilisateur trouvé pour : {search}.',
        hasNoLoginHistory: "n'a pas d'historique de connexion.",
        noUserFoundFor: 'Aucun utilisateur trouvé pour : ',
        name: 'Nom complet',
        removeUser: 'Supprimer @:user.user',
        removeUserConfirm: 'Êtes-vous sûr de vouloir supprimer {username} ?',
        editUser: 'Modifier @:user.user',
        userAdd: 'Sélectionnez les utilisateurs que vous souhaitez ajouter :',
        noUsersToAdd: "Aucun utilisateur n'est disponible pour être ajouté à cette coffre-fort.",
        renewAccess: "Renouveler l'accès à la coffre-fort pour l'utilisateur",
        username: "Nom d'utilisateur",
        email: 'Email',
        noUserSelected: 'Sélectionnez un utilisateur pour afficher ses détails',
        sureToDelete: 'Êtes-vous sûr de vouloir supprimer les utilisateurs suivants de la coffre-fort "{spaceName}" ?',
        demote: {
            demote: 'Dégrader en',
            confirmation: "Dégrader l'utilisateur de la coffre-fort",
            confirmationMessage: 'Êtes-vous sûr de vouloir dégrader cet utilisateur ?',
        },
        promote: {
            promote: 'Promouvoir en',
            confirmation: "Promouvoir l'utilisateur de la coffre-fort",
            confirmationMessage: 'Êtes-vous sûr de vouloir promouvoir cet utilisateur ?',
        },
        revoke: {
            revoke: "Révoquer l'accès",
            confirmation: "Révoquer l'accès à l'utilisateur de la coffre-fort",
            confirmationMessage: "Êtes-vous sûr de vouloir révoquer l'accès à cet utilisateur ?",
        },
    },
    listHeader: {
        vaultName: 'Nom',
        userName: 'Nom',
        vaultUserSpaces: 'Coffre-fort',
        invitedAt: 'Invité à',
        userRoles: 'Rôle',
        vaultLastAccess: 'Dernière visite',
        vaultActions: 'Actions',
        userActions: 'Actions',
    },
    role: {
        role: 'rôle',
        vaultAdmin: 'administrateur de la coffre-fort',
        vaultUser: 'utilisateur de la coffre-fort',
    },
    file: {
        fileManagement: 'Gestion des fichiers',
        file: 'fichier',
        files: 'fichiers',
        fileListing: 'Aucun @:file.files | 1 @:file.file | {count} @:file.files',
        accessHistory: "Historique d'accès",
        noAccessHistory: "Aucun historique d'accès disponible",
        noPermissions: "Il n'y a pas encore d'utilisateurs dans cette coffre-fort auxquels accorder des permissions.",
        allowedUsers: 'Permissions',
        created: 'Créé',
        size: 'Taille',
        lastAccessed: 'Dernière visite',
        lastModified: 'Dernière modification',
        removeFile: 'Supprimer @:file.file',
        removeFileConfirm: 'Êtes-vous sûr de vouloir supprimer {filename} ?',
        createNewFile: 'Créer un nouveau @:file.file',
        newFile: 'Nouveau @:file.file',
        uploadFile: 'Télécharger @:file.files',
        duplicateFiles: 'Fichiers en double détectés',
        uploadFolder: 'Télécharger un @:folder.folder',
        selectAFile: 'Sélectionner un @:file.file',
        totalFiles: 'Nombre de @:file.files',
        toBeClassified: '@:folder.to_be_classified',
        notes: 'Notes',
        deleted: 'Supprimé',
        noNotes: "Ce fichier n'a pas de notes",
        rename: 'Renommer @:file.file',
        removeFilesConfirm: 'Êtes-vous sûr de vouloir supprimer les fichiers suivants :',
        noFileSelected: 'Veuillez sélectionner un fichier pour voir ses détails',
        wantToDownloadFIle: 'Voulez-vous télécharger le fichier ?',
        fileOverride: 'Ce fichier sera écrasé',
        deleteFile: "Supprimer l'élément | Supprimer les éléments",
        dropToUpload: 'Déposez le @:file.file pour le télécharger.',
        confirmationMessage: 'Êtes-vous sûr de vouloir supprimer cet élément ? | Êtes-vous sûr de vouloir supprimer ces éléments ?',
        unsavedChanges: 'Modifications non enregistrées détectées',
        unsavedChangesMessage: 'Vous avez des modifications non enregistrées, êtes-vous sûr de vouloir continuer ?',
    },
    filePermissions: {
        readPermissions: 'Lecture',
        writePermissions: 'Écriture',
    },
    filter: {
        search: 'Rechercher',
        fileOrFolderNotFound: 'Aucun fichier ou dossier ne correspond au filtre.',
        noFilesFound: 'Aucun fichier à afficher.',
        foldersNotFound: 'Aucun document ou dossier trouvé.',
        addItemsMessage: 'Commencez par ajouter un nouveau fichier ou dossier.',
        usersNotFound: 'Aucun utilisateur trouvé.',
        userInviteMessage: 'Commencez par inviter un utilisateur.',
        vaultsNotFound: 'Aucune coffre-fort trouvée.',
        vaultCreateMessage: 'Commencez par créer une coffre-fort.',
        noLoginHistoryFound: "Aucun historique de connexion trouvé pour l'utilisateur.",
        filter: 'Filtrer',
        filterBy: 'Filtrer par',
        sort: 'Trier',
        sortBy: 'Trier par',
        filterAndSort: '@:filter.filter et @:filter.sort',
        equal: 'Égal',
        isEqualTo: 'Est égal à',
        contains: 'Contient',
        start: 'Commence par',
        end: 'Se termine par',
        name: 'Nom',
        extension: 'Extension',
        size: 'Taille',
        createdOn: 'Créé le',
        lastModified: 'Dernière modification',
        lastAccessed: 'Dernière visite',
        asc: 'A>Z',
        desc: 'Z>A',
        filterValue: 'filtre',
    },
    vault: {
        vaultName: '@:vault.nom de la coffre-fort',
        vaults: 'coffre-forts digitale',
        vault: 'coffre-fort digitale',
        hasNoVaultsFor: "n'a pas de @:vault.vaults pour : {search}",
        hasNoVaults: "n'a pas de @:vault.vaults",
        userHasNoVaults: "Vous n'avez pas encore de @:vault.vaults",
        createNewVault: 'Créer un nouveau @:vault.vaults',
        addUserToVault: 'Ajouter des utilisateurs',
        vaultHasNoNotes: 'Ajoutez une description',
        editVault: 'Modifier la coffre-fort',
        noVaultSelected: 'Sélectionnez une coffre-fort pour voir ses détails',
        renewAccess: "Renouveler l'accès",
        noLimit: 'Pas de limite',
        restarting: 'Votre coffre-fort redémarre',
        revokeAdmins: 'Révoquer les administrateurs existants de la coffre-fort',
        permissions: {
            fullAccess: 'Accès en lecture et écriture',
            readAccess: 'Accès en lecture seule',
            noAccess: 'Aucun accès',
        },
        roles: {
            [VaultRole.LIMITED_VAULT_USER]: '@:user.utilisateur limité',
            [VaultRole.BASIC_VAULT_USER]: '@:user.utilisateur de base',
            [VaultRole.ADVANCED_VAULT_USER]: '@:user.utilisateur avancé',
            [VaultRole.VAULT_ADMIN]: 'administrateur',
        },
    },
    folder: {
        folder: 'dossier',
        createNewFolder: 'Nouveau @:folder.folder',
        editFolder: 'Modifier @:folder.folder',
        editCategory: 'Modifier la catégorie',
        createNewCategory: 'Nouvelle catégorie',
        categoryIconAndColor: 'Sélectionnez une icône et une couleur pour la nouvelle catégorie',
        editCategoryIconAndColor: 'Sélectionnez une icône et une couleur pour la catégorie à éditer',
        foldername: '@:folder.nom du dossier',
        all_files: 'Tous les fichiers',
        to_be_classified: 'À classer',
        archived: 'Archivé',
        default_folder_select: 'Sélectionnez un nouveau dossier',
        personal_data: 'Données personnelles',
        legal_entities: 'Entités légales',
        taxation: 'Fiscalité',
        estate_planning: 'Planification successorale',
        financial_institutions: 'Investissements et portefeuilles',
        real_estate: 'Immobilier',
        private_equity: 'Fonds propres/Dettes privées',
        credits_debts: 'Crédits/Dettes',
        life_pension_insurance: 'Assurance vie et pension',
        other_investments: 'Autres investissements',
        miscellaneous: 'Divers',
        confirmation: 'Supprimer {name}',
        confirmationMessage: 'Êtes-vous sûr de vouloir supprimer ce dossier ?',
        categoryConfirmationMessage: 'Êtes-vous sûr de vouloir supprimer cette catégorie ?',
        rename: 'Renommer le dossier',
        notEmpty: 'Dossier non vide',
        categoryNotEmpty: 'Catégorie non vide',
        moveToClassified: "Voulez-vous déplacer ces fichiers vers la catégorie 'À classer' ?",
        showMoreFolders: 'Afficher plus de dossiers',
        showLessFolders: 'Afficher moins de dossiers',
    },
    invite: {
        invite: 'Inviter',
        newInvite: 'Inviter un utilisateur',
        details: "Détails de l'invitation",
        writeDownSeedPhrase: 'Veuillez noter cette phrase de récupération sur le papier fourni.',
        controlWords: 'Veuillez remplir les mots correspondant aux numéros suivants.',
        inviteMailNotification: 'Remplissez les informations de la personne que vous souhaitez inviter.',
        inviteDuration: "Cette personne recevra un lien d'invitation valide pendant les {duration} jours suivants.",
        addAdmin: 'Ajouter un administrateur',
        addUser: 'Ajouter un utilisateur',
        receivedInvite: "Vous avez été invité à rejoindre la coffre-fort. Après avoir accepté les Conditions d'utilisation et la Politique de confidentialité, vous recevrez un code QR à scanner avec l'application { companyName } sur votre smartphone. Cela complétera l'inscription.",
        receivedInviteExpiringDate: "L'invitation expire le {expireDate}.",
        inviteCouldNotBeFound: "L'invitation n'a pas pu être trouvée ou a déjà été réclamée, veuillez vérifier si vous utilisez la bonne URL d'invitation.",
        inviteHasAlreadyBeen: "L'invitation est {status}.",
        sureToAdd: 'Êtes-vous sûr de vouloir ajouter :',
        seedPhraseWarning: 'La phrase de récupération a été régénérée depuis que vous avez cliqué sur le bouton "précédent". Veuillez NOTER la phrase de récupération.',
        userInfo: "Informations sur l'utilisateur",
        selectUsername: "Sélectionnez un nom d'utilisateur",
        confirmSeedphrase: 'Confirmez la phrase de récupération',
        statuses: {
            pending: 'en attente',
            accepted: 'acceptée',
            expired: 'expirée',
            rejected: 'rejetée',
            canceled: 'annulée',
        },
        extend: 'Prolonger',
        add: 'Ajouter un utilisateur',
        contactInfo: 'Si nécessaire, veuillez contacter',
        singleVault: {
            enterVault: 'Accéder à la coffre-fort',
            receivedInvite: "Vous avez été invité à rejoindre la coffre-fort. Si vous continuez, vous devrez vous connecter via l'application { companyName } sur votre smartphone. Cela complétera l'inscription et vous permettra d'accéder à la coffre-fort.",
        },
        grabSeedPhrase: "Vous aurez besoin de votre phrase de récupération de 24 mots. Assurez-vous de l'avoir prête pour les prochaines étapes.",
    },
    session: {
        sessionWillExpire: 'Votre session expirera bientôt ...',
        idleBrowser: 'Vous avez laissé ce navigateur inactif trop longtemps.',
        timeLeft: 'Dans {seconds} secondes, vous serez redirigé vers la page de connexion.',
    },
    error: {
        uploadingProgress: 'État : {status}',
        title: {
            spaceExpiryReminder: "Vous avez accès jusqu'au { expiryDate } !",
            uploadingProgress: 'Téléchargement de { name } en cours',
        },
        userAlreadyInSpace: 'Cet utilisateur est déjà dans cette coffre-fort.',
        successfullyRenewedExpiryDate: "L'accès à la coffre-fort pour { username } a été prolongé avec succès.",
        FailedToRenewExpiryDate: "L'accès à la coffre-fort pour { username } n'a pas pu être prolongé avec succès.",
        spaceExpiryReminder: "Après cela, vous n'aurez plus accès à cette coffre-fort !",
        internalServerError: "Une erreur s'est produite",
        fileNotFound: 'Fichier introuvable',
        noFileFoundFor: 'Aucun fichier trouvé pour : {filename}.',
        noFileInFolder: "Il n'y a pas de fichiers dans le dossier : {folderName}.",
        noFilesInTotal: "Il n'y a pas encore de fichiers.",
        email_not_valid: "Il semble que votre adresse e-mail n'ait pas été vérifiée.",
        folderDoesNotExist: "Le dossier n'existe pas.",
        page_not_found: "La page suivante n'existe pas.",
        ip_blocked: 'Votre adresse IP a été bloquée pendant 24 heures car vous avez effectué trop de tentatives de connexion infructueuses.',
        login_failed: 'Échec de la connexion.',
        login_cancelled: "L'utilisateur a annulé la connexion.",
        authentication_failed: 'Échec de la connexion, veuillez réessayer.',
        invalid_user: "Votre utilisateur n'a pas pu être trouvé sur la plateforme, demandez une invitation à la coffre-fort via votre administrateur de coffre-fort.",
        something_went_wrong: "Une erreur s'est produite",
        failedToCloseVault: 'Échec de la fermeture de la coffre-fort',
        dnsLookupFailed: 'Impossible de joindre la coffre-fort',
        unAuthorized: "Vous n'êtes pas autorisé",
        folderAlreadyExist: 'Le dossier existe déjà',
        folderAlreadyDeleted: 'Le dossier a déjà été supprimé',
        fileAlreadyDeleted: '{name} a déjà été supprimé.',
        folderIsDeleted: 'Le dossier est supprimé',
        fileDoesNotExist: "Le @:file.file demandé n'existe pas",
        uploadingFailed: 'Échec du téléchargement de {name}',
        fileMissing: 'Le @:file.file est manquant',
        invalidFileFormat: 'Format de fichier invalide',
        fileMissingData: 'Les données du @:file.file sont manquantes',
        spaceDoesNotExist: "La coffre-fort demandée @:vault.vault n'existe pas",
        noAccessToSpace: "Vous n'avez pas accès à cette @:vault.vault",
        userDoesNotExist: "L'utilisateur n'existe pas",
        invitationNotFound: "Impossible de trouver l'invitation",
        invalidEmailAddress: 'Adresse e-mail non valide',
        invalidEmailFillIn: 'Vous devez remplir une adresse e-mail valide',
        invalidEmailForInvite: "Votre adresse e-mail ne correspond pas à l'adresse e-mail de l'invitation",
        invalidInvitationStatus: "Type d'invitation invalide",
        invalidInvitationType: "Type d'invitation invalide",
        successfullySendInvite: 'Invitation envoyée avec succès à {user}',
        invitationFailed: "La demande d'invitation a échoué",
        invitationAccept: "Vous avez {status} l'invitation",
        alreadyInSpace: 'Vous êtes déjà dans cette @:vault.vault',
        alreadyVaultAdmin: 'Vous êtes déjà un @:role.vaultAdmin',
        invalidFolderName: 'Vous devez remplir un nom de dossier valide',
        successFullyCreated: '{name} créé avec succès',
        successFullyDeleted: '{name} supprimé avec succès',
        successFullyRecovered: '{name} récupéré avec succès',
        successFullyRename: 'Fichier renommé avec succès en {name}',
        successFullyRenameFolder: 'Dossier renommé avec succès en {name}',
        successFullySharedItem: 'Partage réussi',
        successFullyEditedSettings: 'Paramètres édités avec succès',
        successFullyCreatedCategory: 'Catégorie {name} créée avec succès',
        successFullyEditedCategory: 'La catégorie a été mise à jour avec succès.',
        failedToDelete: 'Échec de la suppression de {name}',
        failedToCreate: 'Échec de la création de {name}',
        failedToChange: "Échec de l'enregistrement des modifications",
        failedToRenameFile: 'Échec du renommage de {name}',
        cannotDeleteYourself: 'Vous ne pouvez pas vous supprimer de la @:vault.vault.',
        cannotRemoveFromSpace: 'Vous ne pouvez pas supprimer cet utilisateur de cette @:vault.vault.',
        savedChanges: 'Vos modifications ont été enregistrées',
        folderNameTooLong: 'Le nom du dossier donné est trop long',
        successFullyUpdated: 'La coffre-fort a été mise à jour avec succès',
        phraseAlreadyClaimed: 'Cette invitation est déjà utilisée',
        invalidEmail: "L'adresse e-mail fournie est invalide.",
        invalidSite: 'Le site web fourni est invalide.',
        lengthToHigh: 'Dépasse le nombre maximal de caractères.',
        lengthToSmall: 'Le champ ne satisfait pas la quantité minimale de caractères.',
        cantSave: 'Les informations fournies ne sont pas correctes.',
        fieldRequired: 'Ce champ est requis.',
        duplicateFileName: 'Un fichier portant ce nom existe déjà dans ce dossier.',
        userSuccessFullyUpdated: "L'utilisateur a été mis à jour avec succès",
        failedToEdit: "Échec de l'édition de l'utilisateur.",
        forbidden: 'Interdit',
        roleNotSupported: "Le rôle donné n'est pas pris en charge",
        wordInSeedIncorrect: 'Les mots donnés ne correspondent pas',
        invalidSeedPhrase: "La phrase de récupération donnée n'est pas correcte",
        userRecoverySent: "Un e-mail de récupération du compte a été envoyé à l'utilisateur",
        filenameTooLong: 'Le nom du fichier téléchargé est trop long',
        notEditableSupportedFileExtension: "L'extension { extension } n'est pas prise en charge pour l'édition.",
        fileTooBig: 'Le fichier téléchargé est trop volumineux.',
        failedToCreateUser: "La combinaison de l'adresse e-mail, du prénom et du nom donnée n'est pas unique.",
        emailAlreadyTaken: "L'adresse e-mail donnée est déjà utilisée.",
        usernameAlreadyExists: "Le nom d'utilisateur donné existe déjà dans l'application d'authentification.",
        usernameMismatch: 'Seules les lettres et les chiffres sont des caractères autorisés',
        notSupportedFileExtension: 'Les @:file.files avec extension { extension } ne sont pas pris en charge.',
        fileTypeNotSupported: 'Les @:file.files de type { extension } ne sont pas pris en charge.',
        fileTypeUnknown: 'Les types de @:file.files inconnus ne sont pas pris en charge.',
        copiedToClipboard: 'Le texte a été copié dans le presse-papiers',
        willBeDownloaded: 'Le fichier { filename } sera téléchargé',
        fileCannotBeOpened: 'Le fichier ne peut pas être ouvert',
        spaceNameAlreadyExists: 'Le nom de la coffre-fort donné existe déjà',
        pleasePickCategory: 'Veuillez choisir une catégorie',
        cannotUploadFolder: "Vous n'êtes pas autorisé à télécharger des dossiers",
        uploadSuccessfully: '{name} a été téléchargé avec succès',
        spaceNameIsEmpty: 'Veuillez entrer un nom de coffre-fort valide',
        moveSuccessfully: "L'élément sélectionné a été déplacé avec succès vers {name}",
        cannotDeleteFolder: "Vous ne pouvez pas supprimer '{name}'",
        cannotMoveDeletedFolder: 'Vous ne pouvez pas déplacer un dossier supprimé',
        moveMultipleSuccessfully: 'Les éléments sélectionnés ont été déplacés avec succès vers {name}',
        moveFailed: "{sourceName} n'a pas pu être déplacé vers {destinationName}",
        moveArchivedFailed: 'Impossible de déplacer des fichiers hors des archives',
        moveMultipleFailed: "Les éléments n'ont pas pu être déplacés vers {destinationName}",
        duplicateFolder: 'Il existe déjà un dossier portant le même nom dans ce dossier.',
        duplicateFile: 'Il existe déjà un fichier portant le même nom dans ce dossier.',
        multipleFolderUpload: "Vous ne pouvez télécharger qu'un seul dossier par lui-même.",
        invalidFileType: 'Type de fichier invalide.',
        fileTooBigError: 'Fichier trop volumineux.',
        duplicateFileError: 'Fichier en double.',
        itemNameAlreadyExist: 'Le nom donné est déjà utilisé',
        FailEditSettings: "Quelque chose s'est mal passé lors de l'édition de vos paramètres.",
        acceptTerms: "Assurez-vous d'accepter les Conditions d'utilisation et la Politique de confidentialité pour continuer.",
        successfullyInvited: "L'utilisateur {name} a été invité avec succès",
        parentIsAlreadyMounted: 'Il y a déjà un dossier parent monté.',
        duplicateUserVault: "L'invitation n'a pas pu être acceptée car il existe déjà un utilisateur avec cet authentificateur dans la coffre-fort.",
        filenameMismatch: 'Le nom de fichier donné contient des caractères non valides.',
        foldernameMismatch: 'Le nom du dossier donné contient des caractères non valides.',
        categoryNameMismatch: 'Le nom de la catégorie donné contient des caractères non valides.',
    },
    misc: {
        movingItem: "Déplacement de l'élément",
        secureDocuments: 'documents sécurisés',
        cancel: 'Annuler',
        previous: 'précédent',
        registered: 'Invité le',
        logout: 'déconnexion',
        readOnlyClosed: "L'environnement en lecture seule est fermé. Consultez votre courrier électronique pour un nouveau lien.",
        youHaveBeenLoggedOut: 'Vous avez été déconnecté',
        selection: 'Retour à la sélection',
        goBack: 'Retour',
        history: 'historique',
        firstname: 'prénom',
        lastname: 'nom de famille',
        notes: 'notes',
        open: 'ouvrir',
        ok: 'ok',
        next: 'suivant',
        accept: 'accepter',
        seeDetails: 'voir les détails',
        redirect: 'Redirection vers la coffre-fort',
        noFilesFound: 'Aucun fichier trouvé ',
        imagePreview: "Aperçu de l'image",
        warning: 'Avertissement',
        actions: {
            open: 'Ouvrir',
            edit: 'Éditer',
            print: 'Imprimer',
            download: 'Télécharger',
            delete: 'Supprimer',
            move: 'Déplacer',
            SHARE: 'Partager',
            rename: 'Renommer',
            recover: 'Récupérer',
            save: 'Enregistrer',
            share: 'Partager',
            viewInvitation: "Voir l'invitation",
            uploadFile: 'Télécharger des fichiers',
            preview: 'Aperçu',
            info: 'Informations',
            createFolder: 'Créer un dossier',
            close: 'Fermer',
            logout: 'Déconnexion',
            home: "Aller à la page d'accueil",
            switchVault: 'Changer de coffre-fort',
            pickLanguage: 'Choisir votre langue',
            invite: 'Inviter',
            typeText: 'Tapez votre texte ici',
            copyToClipboard: 'Copier dans le presse-papiers',
            copied: 'Copié',
        },
        neverAccessed: 'Pas encore ouvert',
        neverLoggedIn: 'Pas encore connecté',
        neverModified: 'Pas encore modifié',
        givePermission: 'Je veux donner un accès complet à la coffre-fort à ces personnes et leur permettre de voir et de gérer cette coffre-fort.',
        showQR: 'Afficher le code QR',
        hideQR: 'Masquer le code QR',
        toLogin: 'Pour se connecter',
        showCategories: 'Afficher les catégories',
        hideCategories: 'Masquer les catégories',
        brandAdmin: '{ companyName } Admin',
        new: 'Nouveau',
        readAndAccept: "J'ai lu et j'accepte les",
    },
    tables: {
        headers: {
            ip: 'Adresse IP',
            status: 'Statut',
            date: 'Date',
            geolocation: 'Emplacement',
            accessedOn: 'Accédé le',
            type: 'Action',
            user: 'Utilisateur',
            name: 'Nom',
            rights: 'Droits',
            lastSignedIn: 'Dernière connexion',
            lastAccessed: 'Dernière visite',
            validUntil: "Valable jusqu'au",
            vaults: 'Coffres-fort',
            userRoles: 'Rôle',
            totalFiles: 'Fichiers au total',
            toBeClassified: 'à classer',
            username: "Nom d'utilisateur",
            email: 'E-mail',
            neverLoggedIn: 'Jamais connecté',
            neverAccessed: 'Jamais accédé',
            deleted: 'supprimé',
            add: 'Sélectionner',
            actions: 'Actions',
            filePermissions: 'Permissions de fichier',
            timeLimit: 'Limite de temps',
            canRead: 'Lecture',
            canWrite: 'Écriture',
            size: 'Taille',
            createdOn: 'Créé le',
        },
        loadingState: {
            vaultLoading: 'Récupération des coffres-fort',
            userLoading: 'Récupération des utilisateurs',
            folderLoading: 'Récupération des dossiers',
        },
        actions: {
            EDIT: 'Éditer',
            OVERWRITE: 'Écrasé',
            READ: 'Ouvert',
            MOVED: 'Déplacé',
            RENAME: 'Renommé',
            DETAILS: 'Détails modifiés',
            DELETED: 'Supprimé',
        },
        succeed: 'réussi',
        failed: 'échoué',
        more: 'Charger plus',
        login: {
            success: 'Connecté',
            failed: 'Tentative de connexion échouée',
        },
    },
    spinner: {
        logout: 'Déconnexion sécurisée en cours',
        restartingDocker: 'Configuration de @:vault.vault en cours',
    },
    settings: {
        globalTitle: 'Paramètres globaux',
        globalSubtitle: 'Modifiez vos paramètres globaux ici',
        brandingTitle: 'Paramètres de marque',
        brandingSubtitle: 'Modifiez des éléments liés à la marque ici',
        companyName: "Nom de l'entreprise",
        DefaultLanguage: 'Langue par défaut',
        DefaultLanguageSubtitle: "Il s'agit de la langue par défaut sur tous les appareils.",
        logoSubtitle: "Il s'agit du logo standard qui s'affichera dans toute l'application.",
        faviconSubtitle: "Il s'agit de l'icône qui s'affiche dans l'onglet de votre navigateur.",
    },
    forms: {
        labels: {
            notes: 'Notes',
            vaultName: 'Nom du coffre',
            companyName: "Nom de l'entreprise",
            email: 'E-mail',
            firstname: 'Prénom',
            lastname: 'Nom de famille',
            vaultFolderName: 'Dossier',
            role: 'Rôle',
            seedPhrase: 'Phrase de récupération',
            file: 'Fichiers',
            note: 'Note',
            folder: 'Dossier',
            language: 'Langue',
            username: "Nom d'utilisateur",
            newFolderName: 'Nom du nouveau dossier',
            newCategoryName: 'Nom de la nouvelle catégorie',
            editCategoryName: 'Nom de la catégorie à modifier',
            newFileName: 'Nom du nouveau @:file.file',
            newFolderCategory: 'Catégorie pour le nouveau dossier',
            newFileCategory: 'Catégorie pour le nouveau @:file.file',
            emailRecipient: "Destinataire de l'e-mail",
            filename: 'Nom du fichier',
            foldername: 'Nom du dossier',
            fileType: 'Type de fichier',
            shareUntil: "Partager jusqu'à",
            passwordName: 'Nom',
            websiteUrl: 'Site web',
            password: 'Mot de passe',
            category: 'Catégorie',
            nameIdentifier: "Nom d'utilisateur/E-mail",
        },
        buttons: {
            edit: 'Éditer',
            submit: 'Soumettre',
            confirm: 'Confirmer',
            create: 'Créer',
            previous: 'Précédent',
            cancel: 'Annuler',
            next: 'Suivant',
            share: 'Partager',
            recover: 'Récupérer',
            back: 'Retour',
            uploadFile: 'Télécharger',
            save: 'Enregistrer',
            rename: 'Renommer',
            remove: 'Supprimer',
            ignore: 'Ignorer',
            overwrite: 'Écraser',
            invite: 'Inviter',
        },
        messages: {
            accessMessage: 'Je veux donner à cette personne un accès complet à la plateforme et lui permettre de voir et de gérer tous les coffres-fort et utilisateurs.',
            addFileComments: 'Ajouter un commentaire de fichier',
            duplicateFiles: 'Ces fichiers existent déjà dans le dossier sélectionné, ils seront écrasés.',
        },
    },
    tabs: {
        edit: 'Éditer',
        recover: 'Récupérer',
        loginHistory: 'Historique de connexion',
        vault: 'Coffres-fort',
    },
    setup: {
        openApp: "Ouvrir l'application",
        appSetup: "La dernière étape du processus de configuration consiste à configurer votre application. Scannez le code QR avec votre téléphone et ouvrez l'URL pour connecter votre authentificateur au coffre.",
    },
    calendar: {
        days: {
            monday: 'Lundi',
            tuesday: 'Mardi',
            wednesday: 'Mercredi',
            thursday: 'Jeudi',
            friday: 'Vendredi',
            saturday: 'Samedi',
            sunday: 'Dimanche',
        },
        months: {
            january: 'Janvier',
            february: 'Février',
            march: 'Mars',
            april: 'Avril',
            may: 'Mai',
            june: 'Juin',
            july: 'Juillet',
            august: 'Août',
            september: 'Septembre',
            october: 'Octobre',
            november: 'Novembre',
            december: 'Décembre',
        },
    },
    share: {
        temporaryShareTitle: 'Créer un partage en lecture seule temporaire',
        temporaryShareMessage: 'Créez un partage en lecture seule temporaire pour un utilisateur',
        readonlyTitle: 'Partages en lecture seule',
        expiryDate: "Date d'expiration",
    },
    webdav: {
        mount: 'Monter sur WebDAV',
        createdTitle: 'Nouvel élément monté',
        createdBody: "Nouvel élément monté sur le système WebDAV. Vous pouvez les consulter en utilisant le lien ci-dessous dans l'explorateur de votre système d'exploitation",
        sessionWarning: 'Assurez-vous de sauvegarder fréquemment vos fichiers!',
        username: "Nom d'utilisateur",
        password: 'Mot de passe',
    },
    passwordManager: {
        vaultPasswords: 'Mots de passe',
        personalPasswords: 'Mots de passe personnels',
        items: 'Aucun élément | 1 élément | {count} éléments',
        modals: {
            addPassword: 'Ajouter un mot de passe',
            editPassword: 'Modifier le mot de passe',
            addCategory: 'Ajouter une catégorie',
            removePassword: 'Supprimer le mot de passe',
            removeCategory: 'Supprimer la catégorie',
            removePasswordMessage: 'Êtes-vous sûr de vouloir supprimer ce mot de passe?',
            removeCategoryMessage: 'Êtes-vous sûr de vouloir supprimer cette catégorie? Tous les mots de passe de cette catégorie seront perdus!',
            removeShare: 'Supprimer le partage de mot de passe',
            removeShareMessage: "Êtes-vous sûr de vouloir supprimer le partage de mot de passe avec l'utilisateur?",
            sharePassword: 'Partager le mot de passe',
            fields: {
                readOnly: 'Droits en lecture seule',
                canModify: 'Droits complets',
            },
        },
        list: {
            emptyItemMessageAdmin: 'Commencez par ajouter un nouveau mot de passe ou une nouvelle catégorie.',
            emptyItemMessageUser: "Vous n'avez pas accès à de mot de passe ou de catégorie dans ce coffre.",
        },
        details: {
            vaultPassword: 'Mot de passe',
            personalPassword: 'Mot de passe personnel',
            addedOn: 'ajouté le',
            accountInfo: 'Informations sur le compte',
            accountDetails: 'Détails du compte',
            sharedWith: 'Partagé avec',
            fields: {
                nameIdentifier: "Nom d'utilisateur/E-mail",
                password: 'Mot de passe',
                website: 'Site web',
                createdOn: 'Date de création',
                modifiedOn: 'Modifié le',
            },
        },
        buttons: {
            addPassword: 'Ajouter un mot de passe',
            addCategory: 'Ajouter une catégorie',
            share: 'Partager',
            edit: 'Éditer',
            remove: 'Supprimer',
        },
    },
    navigation: {
        admin: {
            vaults: 'Coffres-fort',
            users: 'Utilisateurs',
            settings: 'Paramètres',
        },
        vault: {
            documents: 'Documents',
            passwords: 'Mots de passe',
            users: 'Utilisateurs',
        },
        personal: {
            passwords: 'Mots de passe personnels',
        },
    },
    explanation: {
        [VaultRole.LIMITED_VAULT_USER]: "Cet utilisateur peut uniquement créer et télécharger des fichiers dans 'À classer'. Par défaut, cet utilisateur ne voit pas de fichiers, sauf s'il reçoit un accès à un fichier spécifique.",
        [VaultRole.BASIC_VAULT_USER]: "Cet utilisateur peut uniquement créer et télécharger des fichiers dans 'À classer'. Par défaut, cet utilisateur voit tous les fichiers, mais ne peut pas les modifier, sauf s'il reçoit un accès à un fichier spécifique.",
        [VaultRole.ADVANCED_VAULT_USER]: "Cet utilisateur peut créer et télécharger des fichiers n'importe où. Par défaut, cet utilisateur voit tous les fichiers, dossiers et catégories et peut les modifier. Cependant, cet utilisateur ne peut pas apporter de modifications à d'autres utilisateurs ni aux mots de passe du coffre-fort d'autres utilisateurs.",
        [VaultRole.VAULT_ADMIN]: "Cet utilisateur a accès à tout par défaut. L'administrateur peut modifier d'autres utilisateurs et voir et modifier tous les mots de passe du coffre.",
    },
    search: {
        names: 'Noms',
        content: 'Contenu',
    },
};
export default fr;
