import { generateMnemonic } from 'bip39';
export const generateSeedPhrase = () => {
    return generateMnemonic(256);
};
export const isValidWord = (words, index, wordToCheck) => {
    if (!wordToCheck) {
        return false;
    }
    const a = words[index].trim();
    const b = wordToCheck.trim();
    return wordToCheck && a == b;
};
export const generateRandomNumber = (min = 0, max = 24) => {
    return Math.floor(Math.random() * max) + min;
};
export const generateRandomWordIndexes = (amount = 3) => {
    const numbers = [];
    while (numbers.length < amount) {
        const newNumber = generateRandomNumber();
        if (numbers.indexOf(newNumber) === -1)
            numbers.push(newNumber);
    }
    return numbers.sort((a, b) => a - b);
};
