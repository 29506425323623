import { __awaiter } from "tslib";
import { AdminMutationTypes } from './admin.mutations';
import { getVaultUsers } from '@/services/admin.service';
import { createNewWorkspace } from '@/services/space.service';
export var AdminActionTypes;
(function (AdminActionTypes) {
    AdminActionTypes["SET_VAULT_USERS"] = "SET_VAULT_USERS";
    AdminActionTypes["ADD_WORKSPACE"] = "ADD_WORKSPACE";
})(AdminActionTypes || (AdminActionTypes = {}));
export const actions = {
    [AdminActionTypes.SET_VAULT_USERS]({ commit }) {
        return __awaiter(this, void 0, void 0, function* () {
            const vaultUsers = yield getVaultUsers();
            commit(AdminMutationTypes.SET_VAULT_USERS, vaultUsers);
        });
    },
    [AdminActionTypes.ADD_WORKSPACE]({ commit }, workspaceName) {
        return __awaiter(this, void 0, void 0, function* () {
            const workspace = yield createNewWorkspace(workspaceName);
            if (!workspace) {
                return;
            }
            return workspace;
        });
    },
};
