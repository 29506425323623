import { __awaiter } from "tslib";
import { computed, defineComponent } from 'vue';
import Button from '@/components/general/Button.vue';
import Modal from '@/components/general/Modal.vue';
import { useStore } from '@/store';
import { deleteUserFromSpace } from '@/services/admin.service';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
import { Table } from '@jimber/shared-components';
import { translate } from '@/filters';
export default defineComponent({
    name: 'RemoveUserModal',
    components: {
        Button,
        Modal,
        Table,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        selectedWorkspace: Object,
        users: {
            type: Array,
        },
    },
    emits: ['update:show', 'deleteSuccess', 'cleanupList'],
    setup({ users, show, selectedWorkspace }, ctx) {
        const store = useStore();
        const headersVerify = computed(() => [
            {
                key: 'username',
                displayName: translate('tables.headers.username'),
                enableSorting: true,
            },
            {
                key: 'email',
                displayName: translate('tables.headers.email'),
                enableSorting: true,
            },
        ]);
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        const confirmDelete = () => __awaiter(this, void 0, void 0, function* () {
            for (const user of users) {
                const response = yield deleteUserFromSpace(selectedWorkspace.id, user.id);
                if (response.status !== 204) {
                    store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                        type: ToastType.ERROR,
                        message: `failedToDelete`,
                        data: {
                            name: user.username,
                        },
                    });
                    continue;
                }
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.SUCCESS,
                    message: `successFullyDeleted`,
                    data: {
                        name: user.username,
                    },
                });
                ctx.emit('deleteSuccess', user);
                ctx.emit('cleanupList');
            }
            closeModal();
        });
        return {
            headersVerify,
            closeModal,
            confirmDelete,
        };
    },
});
