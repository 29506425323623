import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Card',
    props: {
        width: {
            type: String,
            default: 'full',
        },
        height: {
            type: String,
        },
    },
});
