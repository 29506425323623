import icons from './icons';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import SearchBar from '@/components/vault/SearchBar.vue';
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
    name: 'IconPicker',
    components: { SearchBar, Popover, PopoverButton, PopoverPanel },
    props: {
        color: String,
        icon: String,
    },
    setup(props, ctx) {
        const filteredIcons = ref(icons);
        const iconSearch = ref('');
        const iconKey = ref(0);
        //TODO: get /set computed
        watch(iconSearch, newValue => {
            filterIcons(newValue);
        });
        const filterIcons = searchTerm => {
            const search = searchTerm.trim();
            let filter = [];
            if (search.length > 1) {
                filter = icons.filter(item => {
                    const regex = new RegExp(search, 'gi');
                    return item.name.match(regex);
                });
                filteredIcons.value = filter;
            }
            else if (search.length === 0) {
                filteredIcons.value = icons;
            }
        };
        return {
            filterIcons,
            icons,
            filteredIcons,
            iconSearch,
            iconKey,
        };
    },
});
