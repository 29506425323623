import { mapGetters } from 'vuex';
import { AccessOperations, AccessType } from '@/types/authorization';
import { computed } from 'vue';
import { useStore } from '@/store';
import { useRoute } from 'vue-router';
import { convertToTEntry } from '@/utils';
export default {
    name: 'MultiSelectSidebar',
    props: {
        colorIcon: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['preview-item', 'download-item', 'delete-item', 'share-item'],
    setup({ items }) {
        const store = useStore();
        const route = useRoute();
        const canDelete = computed(() => {
            return (store.getters.hasAccessToOperation({ accessType: AccessType.folders, operation: AccessOperations.delete }) &&
                store.getters.selectedItemsIds.filter(item => { var _a; return (_a = store.getters.getItemsByParentId(route.params.selectedFolderId).find(workspaceItem => convertToTEntry(workspaceItem).id === item)) === null || _a === void 0 ? void 0 : _a.isArchived; }).length <= 0);
        });
        const canDownload = computed(() => {
            return (store.getters.selectedItemsIds.filter(item => { var _a; return (_a = convertToTEntry(store.getters.getItemsByParentId(route.params.selectedFolderId).find(workspaceItem => convertToTEntry(workspaceItem).id === item))) === null || _a === void 0 ? void 0 : _a.isFolder; }).length <= 0);
        });
        return {
            canDelete,
            canDownload,
            AccessType,
            AccessOperations,
        };
    },
    computed: Object.assign({}, mapGetters(['hasAccessToOperation', 'isVaultAdmin'])),
};
