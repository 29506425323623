import { __awaiter } from "tslib";
import Card from '@/components/general/Card.vue';
import Button from '@/components/general/Button.vue';
import { setupSingleVault } from '@/services/setup.service';
export default {
    name: 'SingleVaultSetup',
    components: {
        Button,
        Card,
    },
    setup() {
        const proceedToSingleVaultSetup = () => __awaiter(this, void 0, void 0, function* () {
            yield setupSingleVault();
        });
        return {
            proceedToSingleVaultSetup,
        };
    },
};
