import { defineComponent, ref, computed } from 'vue';
import { Listbox, ListboxLabel, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';
import { translate } from '@/filters';
export default defineComponent({
    name: 'CustomSelect',
    components: {
        Listbox,
        ListboxLabel,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
    },
    props: {
        modelValue: {
            type: [Object, String],
        },
        inputs: {
            type: Array,
            default: [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        objectValue: {
            type: String,
            default: null,
        },
        translation: {
            type: String,
            default: '',
        },
    },
    emits: ['update:modelValue'],
    setup(props, ctx) {
        const dropdownOpen = ref(false);
        const selectedProp = computed({
            get: () => {
                return props.modelValue;
            },
            set: newVal => {
                ctx.emit('update:modelValue', newVal);
            },
        });
        const getSelectedItem = computed(() => option => {
            return props.objectValue ? option[props.objectValue] : option;
        });
        return {
            dropdownOpen,
            selectedProp,
            getSelectedItem,
            translate,
        };
    },
});
