import { __awaiter } from "tslib";
import { computed, defineComponent, ref } from 'vue';
import { isMobile, translate } from '@/filters';
import folderCard from '@/components/general/FolderCard.vue';
import { useStore } from '@/store';
import { convertToTEntry } from '@/utils';
import { mapGetters } from 'vuex';
import { getFilesInSpaceByFolderId } from '@/services/folder.service';
import ConfirmModal from '@/components/vault/modals/ConfirmModal.vue';
import { FileManagerActionTypes } from '@/store/modules/FileManager/fileManager.actions';
import CreateFolderModal from '@/components/vault/modals/CreateFolderModal.vue';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
import { defaultFolders } from '@/types/folder/defaultFolders.enum';
import { FileManagerMutationTypes } from '@/store/modules/FileManager/fileManager.mutations';
import { AccessOperations, AccessType } from '@/types/authorization';
export default defineComponent({
    name: 'FolderBar',
    props: {
        showDetails: {
            type: Boolean,
            default: false,
        },
        activeFolderId: {
            type: String,
        },
        highlightBaseFolder: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        folderCard,
        ConfirmModal,
        CreateFolderModal,
    },
    emits: ['openFolder'],
    setup(props, ctx) {
        const store = useStore();
        const maxFoldersToShow = isMobile() ? 1 : 20;
        const showConfirmDeletion = ref(false);
        const showFolderNotEmpty = ref(false);
        const showAddCategory = ref(false);
        const categoryToRemove = ref();
        const categoryToEdit = ref();
        const openFolder = folder => {
            ctx.emit('openFolder', folder);
        };
        const rootFolders = computed(() => store.getters.getItemsByParentId(null).map(item => convertToTEntry(item)));
        const folderCount = computed(() => rootFolders.value.length);
        const itemsToShow = computed(() => {
            return rootFolders.value;
            // if (!isMobile()) {
            //   //show folders unsorted if on desktop
            //   return rootFolders.value.slice(0, maxFoldersToShow);
            // }
            // return rootFolders.value.slice(0, maxFoldersToShow);
        });
        const showCategoryEditModal = folder => {
            categoryToEdit.value = folder;
            showAddCategory.value = true;
        };
        const showCategoryDeletionModal = folder => {
            if (folder.name === defaultFolders.ToBeClassified || folder.name === defaultFolders.Archived) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: `cannotDeleteFolder`,
                    data: {
                        name: translate(`folder.${folder.name}`, folder.name),
                    },
                });
                return;
            }
            categoryToRemove.value = folder;
            showConfirmDeletion.value = true;
        };
        const showAddCategoryModal = () => {
            categoryToEdit.value = null;
            showAddCategory.value = true;
        };
        const checkContent = () => __awaiter(this, void 0, void 0, function* () {
            const items = yield getFilesInSpaceByFolderId(store.getters.selectedWorkspaceId, categoryToRemove.value.id);
            if (items.length > 0) {
                showFolderNotEmpty.value = true;
                return;
            }
            return removeCategory(categoryToRemove.value.id);
        });
        const moveToBeClassified = () => {
            var _a;
            const toBeClassifiedId = (_a = store.getters.getItemsByParentId(null).find(folder => folder['name'] === 'to_be_classified')) === null || _a === void 0 ? void 0 : _a['folderUuid'];
            return removeCategory(categoryToRemove.value.id, toBeClassifiedId);
        };
        const removeCategory = (sourceFolderId, destinationFolderId) => __awaiter(this, void 0, void 0, function* () {
            return store.dispatch(FileManagerActionTypes.DELETE_ITEMS, {
                sourceFolderId,
                destinationFolderId,
            });
        });
        const updateFolder = folder => {
            store.commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, [folder]);
        };
        return {
            folderCount,
            maxFoldersToShow,
            itemsToShow,
            showConfirmDeletion,
            showFolderNotEmpty,
            categoryToRemove,
            showAddCategory,
            categoryToEdit,
            translate,
            openFolder,
            showCategoryEditModal,
            removeCategory,
            moveToBeClassified,
            showCategoryDeletionModal,
            showAddCategoryModal,
            checkContent,
            updateFolder,
            AccessType,
            AccessOperations,
            rootFolders,
        };
    },
    computed: Object.assign({}, mapGetters(['hasAccessToOperation', 'selectedItemDetails'])),
});
