import { __awaiter } from "tslib";
import { defineComponent, onMounted, ref } from 'vue';
import Modal from '@/components/general/Modal.vue';
import { downScaleImage } from '@/utils';
import { BrandingImage } from '@/types/admin/branding.enum';
import { config } from '@/config';
export default defineComponent({
    name: 'ImagePreviewModal.vue',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        preview: Object,
        type: BrandingImage,
    },
    components: { Modal },
    emits: ['update:show', 'clearLogo', 'uploadLogo'],
    setup({ show, preview, type }, ctx) {
        const downscaledImage = ref('');
        onMounted(() => {
            setPreview();
        });
        const setPreview = () => __awaiter(this, void 0, void 0, function* () {
            const resolution = getResolution();
            downscaledImage.value = yield downScaleImage(preview, resolution.width, resolution.height);
        });
        const getResolution = () => {
            switch (type) {
                case BrandingImage.LOGO:
                    return config.value.branding.logoResolution;
                case BrandingImage.FAVICON:
                    return config.value.branding.faviconResolution;
            }
        };
        const closeModal = () => {
            ctx.emit('clearLogo');
            ctx.emit('update:show', false);
        };
        const saveImage = () => {
            ctx.emit('uploadLogo', downscaledImage.value, type);
            ctx.emit('update:show', false);
        };
        return {
            closeModal,
            saveImage,
            downscaledImage,
        };
    },
});
