import Button from '@/components/general/Button.vue';
import Alert from '@/components/general/Alert.vue';
export default {
    name: 'RemovePasswordModal',
    components: {
        Alert,
        Button,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:show'],
    setup(_, ctx) {
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        const handleDelete = () => {
            closeModal();
        };
        return {
            closeModal,
            handleDelete,
        };
    },
};
