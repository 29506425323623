import { __awaiter } from "tslib";
import { computed, defineComponent, ref } from 'vue';
import { ChevronRightIcon, FolderIcon, HomeIcon, TrashIcon } from '@heroicons/vue/solid';
import Button from '@/components/general/Button.vue';
import { getPersonalPasswordItems, getSpacePasswordItems } from '@/services/password.service';
import { useRoute, useRouter } from 'vue-router';
import { mapGetters } from 'vuex';
import { Role } from '@/types';
import { AccessOperations, AccessType } from '@/types/authorization';
export default defineComponent({
    name: 'PasswordList',
    components: {
        ChevronRightIcon,
        FolderIcon,
        HomeIcon,
        TrashIcon,
        Button,
    },
    props: {
        items: Object,
        selectedCategory: Object,
        selectedItem: Object,
        personal: Boolean,
    },
    emits: ['update:selected-item', 'update:items', 'update:selected-category', 'show-remove-item', 'show-add-category', 'show-add-password'],
    setup(props, ctx) {
        const route = useRoute();
        const router = useRouter();
        const currentSelectedFolder = ref();
        const breadCrumbItems = ref([]);
        const getFavIconOfSite = computed(() => index => {
            const password = props.items[index];
            if (!password) {
                return;
            }
            return `https://www.google.com/s2/favicons?sz=64&domain_url=${password.site}`;
        });
        const sortedItems = computed(() => {
            var _a;
            return (_a = props.items) === null || _a === void 0 ? void 0 : _a.sort((a, b) => {
                return Number(JSON.parse(a.isFolder)) - Number(JSON.parse(b.isFolder));
            });
        });
        const changeSelectedItem = (item) => {
            if (item.isFolder) {
                return;
            }
            ctx.emit('update:selected-item', item);
        };
        const openCategory = (item) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c;
            if (item && !(item === null || item === void 0 ? void 0 : item.isFolder)) {
                return;
            }
            if (item && (item === null || item === void 0 ? void 0 : item.id) === ((_a = props.selectedCategory) === null || _a === void 0 ? void 0 : _a.id)) {
                return;
            }
            const currentRootFolder = (_b = route.params) === null || _b === void 0 ? void 0 : _b.selectedRootFolderId;
            if (!currentRootFolder) {
                yield router.push({
                    name: props.personal ? 'PersonalPasswords' : 'VaultPasswords',
                    params: Object.assign(Object.assign({}, route.params), { selectedRootFolderId: item === null || item === void 0 ? void 0 : item.id }),
                });
            }
            const selectedWorkspace = (_c = route.params) === null || _c === void 0 ? void 0 : _c.spaceId;
            let newItems;
            if (!props.personal) {
                newItems = yield getSpacePasswordItems(selectedWorkspace, item === null || item === void 0 ? void 0 : item.id);
            }
            else {
                newItems = yield getPersonalPasswordItems(item === null || item === void 0 ? void 0 : item.id);
            }
            if (!newItems) {
                return;
            }
            updateBreadCrumb(item);
            ctx.emit('update:items', newItems);
        });
        const updateBreadCrumb = (item) => {
            if (!item) {
                breadCrumbItems.value = [];
                currentSelectedFolder.value = null;
                ctx.emit('update:selected-category', null);
                router.push({
                    name: props.personal ? 'PersonalPasswords' : 'VaultPasswords',
                    params: Object.assign(Object.assign({}, route.params), { selectedRootFolderId: undefined }),
                });
                return;
            }
            const itemIndex = breadCrumbItems.value.findIndex(breadCrumbItem => item.id === breadCrumbItem.id);
            if (itemIndex !== -1) {
                breadCrumbItems.value.splice(itemIndex + 1, breadCrumbItems.value.length);
                return;
            }
            breadCrumbItems.value.push(item);
            ctx.emit('update:selected-category', item);
        };
        return {
            changeSelectedItem,
            openCategory,
            breadCrumbItems,
            sortedItems,
            getFavIconOfSite,
            Role,
            AccessType,
            AccessOperations,
        };
    },
    computed: Object.assign({}, mapGetters(['hasAccessToOperation'])),
});
