import { translate } from '@/filters';
import { VaultRole } from '@/types';
export const generateVaultRoleExplanation = () => {
    const vaultRoles = [VaultRole.LIMITED_VAULT_USER, VaultRole.BASIC_VAULT_USER, VaultRole.ADVANCED_VAULT_USER, VaultRole.VAULT_ADMIN];
    return vaultRoles.map(vaultRole => {
        return {
            title: translate(`vault.roles.${vaultRole}`),
            explanation: translate(`explanation.${vaultRole}`),
        };
    });
};
