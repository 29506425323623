import FolderCard from '@/components/general/FolderCard.vue';
import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'OverflowBreadCrumb',
    components: { FolderCard },
    emits: ['openFolder'],
    props: {
        folders: {
            type: Array,
            default: [],
        },
    },
    setup(_, ctx) {
        const openMenu = ref(false);
        const openFolder = folder => {
            openMenu.value = false;
            ctx.emit('openFolder', folder.folderUuid);
        };
        const open = () => {
            openMenu.value = !openMenu.value;
        };
        return {
            openFolder,
            open,
            openMenu,
        };
    },
});
