import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys } from "vue";
const _hoisted_1 = { class: "px-6 py-2" };
const _hoisted_2 = { class: "px-6 py-4 -mb-4 mt-2 rounded-b-lg w-full bg-gray-50 flex flex-row justify-end" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_form_select = _resolveComponent("form-select");
    const _component_form_file = _resolveComponent("form-file");
    const _component_form_text_area = _resolveComponent("form-text-area");
    const _component_form_text = _resolveComponent("form-text");
    const _component_Button = _resolveComponent("Button");
    return (_openBlock(), _createElementBlock("form", {
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => { }, ["prevent"])),
        onKeypress: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
        //@ts-ignore
        (...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["stop"]), ["enter"])),
        onKeyup: _cache[2] || (_cache[2] = _withKeys(
        //@ts-ignore
        (...args) => (_ctx.cancelForm && _ctx.cancelForm(...args)), ["esc"])),
        class: "w-full"
    }, [
        _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "custom-append"),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.inputs, (input, i) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", { key: i }, [
                    _renderSlot(_ctx.$slots, 'input-' + input.name, { input: input }, () => [
                        (input.type === _ctx.InputType.SELECT)
                            ? (_openBlock(), _createBlock(_component_form_select, {
                                key: input.name,
                                onUpdateField: _ctx.updateFormField,
                                onDisableButton: _ctx.disable,
                                validationError: _ctx.formErrors[input.name],
                                "input-details": input
                            }, null, 8, ["onUpdateField", "onDisableButton", "validationError", "input-details"]))
                            : (input.type === _ctx.InputType.FILE)
                                ? (_openBlock(), _createBlock(_component_form_file, {
                                    key: input.name,
                                    onUpdateField: _ctx.updateFormField,
                                    validationError: _ctx.formErrors[input.name],
                                    "input-details": input
                                }, null, 8, ["onUpdateField", "validationError", "input-details"]))
                                : (input.type === _ctx.InputType.TEXT_AREA)
                                    ? (_openBlock(), _createBlock(_component_form_text_area, {
                                        key: input.name,
                                        onUpdateField: _ctx.updateFormField,
                                        validationError: _ctx.formErrors[input.name],
                                        "input-details": input
                                    }, null, 8, ["onUpdateField", "validationError", "input-details"]))
                                    : (_openBlock(), _createBlock(_component_form_text, {
                                        key: input.name,
                                        onUpdateField: _ctx.updateFormField,
                                        validationError: _ctx.formErrors[input.name],
                                        "input-details": input
                                    }, null, 8, ["onUpdateField", "validationError", "input-details"]))
                    ])
                ])), [
                    [_vShow, !input.hidden]
                ]);
            }), 128)),
            _renderSlot(_ctx.$slots, "custom-prepend")
        ]),
        _createElementVNode("div", _hoisted_2, [
            (_ctx.formData.cancel)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    onMousedown: _ctx.cancelForm,
                    class: "capitalize mr-2"
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(`forms.buttons.${_ctx.formData.cancel}`)), 1)
                    ]),
                    _: 1
                }, 8, ["onMousedown"]))
                : _createCommentVNode("", true),
            _createVNode(_component_Button, {
                colored: "",
                onMousedown: _ctx.submitForm,
                class: _normalizeClass(["px-2 mr-0 capitalize", _ctx.disableButton || _ctx.loading ? 'bg-gray-300' : 'bg-primary text-white']),
                disabled: _ctx.disableButton || _ctx.loading
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t(`forms.buttons.${_ctx.formData.submit}`)), 1)
                ]),
                _: 1
            }, 8, ["onMousedown", "disabled", "class"])
        ])
    ], 32));
}
