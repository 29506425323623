import { __awaiter } from "tslib";
import { defineComponent, ref } from 'vue';
import Avatar from '@/components/general/Avatar.vue';
import UserDropdown from '@/components/general/UserDropdown.vue';
import { useRoute, useRouter } from 'vue-router';
import LanguageSelector from '@/components/general/LanguageSelector/LanguageSelector.vue';
import { mapGetters } from 'vuex';
import { logoutUser } from '@/services/authentication.service';
import MobileMenu from '@/components/general/MobileMenu.vue';
import MobileLanguageSelector from '@/components/general/LanguageSelector/MobileLanguageSelector.vue';
import { GeneralActionTypes } from '@/store/modules/general/general.actions';
import { useStore } from '@/store';
import SpinnerModal from '@/components/vault/modals/SpinnerModal.vue';
export default defineComponent({
    name: 'Topbar',
    components: {
        MobileLanguageSelector,
        MobileMenu,
        UserDropdown,
        Avatar,
        LanguageSelector,
        SpinnerModal,
    },
    setup() {
        const currentRoute = useRoute();
        const router = useRouter();
        const dropDownOpen = ref(false);
        const allDropdowns = ref(null);
        const showPopUp = ref(false);
        const store = useStore();
        const goToSelection = () => {
            showPopUp.value = false;
            return router.push({ name: 'Selection' });
        };
        const closeDropdown = () => {
            dropDownOpen.value = false;
        };
        const doLogout = () => __awaiter(this, void 0, void 0, function* () {
            yield store.dispatch(GeneralActionTypes.SET_STATUS_LOGIN_SPINNER, true);
            yield logoutUser();
            window.location.href = '/logout';
            yield store.dispatch(GeneralActionTypes.SET_STATUS_LOGIN_SPINNER, false);
        });
        return {
            currentRoute,
            dropDownOpen,
            allDropdowns,
            showPopUp,
            closeDropdown,
            goToSelection,
            doLogout,
        };
    },
    computed: Object.assign({}, mapGetters(['currentUser', 'statusLoginSpinner'])),
});
