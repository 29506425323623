import { __awaiter } from "tslib";
import { defineComponent, ref } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';
import Avatar from '@/components/general/Avatar.vue';
import SearchBar from '@/components/vault/SearchBar.vue';
import TabLayout from '@/layout/TabLayout.vue';
import WorkspaceDetails from '@/components/vault/sidebars/WorkspaceDetails.vue';
import CreateWorkspaceModal from '@/components/vault/modals/CreateWorkspaceModal.vue';
import SpinnerModal from '@/components/vault/modals/SpinnerModal.vue';
import AdminFileDetails from '@/components/vault/sidebars/AdminFileDetails.vue';
import EditWorkspaceModal from '@/components/vault/modals/EditWorkspaceModal.vue';
import AdminVaultOverview from '@/components/admin/AdminVaultOverview.vue';
export default defineComponent({
    name: 'AdminVaults',
    components: {
        AdminVaultOverview,
        SpinnerModal,
        Avatar,
        WorkspaceDetails,
        SearchBar,
        TabLayout,
        CreateWorkspaceModal,
        EditWorkspaceModal,
        AdminFileDetails,
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const showCreateWorkspace = ref(false);
        const selectedWorkspace = ref(undefined);
        const vaults = ref([]);
        const updateSideBar = (item) => __awaiter(this, void 0, void 0, function* () {
            if (item.selectedItems.length <= 0) {
                selectedWorkspace.value = null;
                return;
            }
            selectedWorkspace.value = item.selectedItems[0];
        });
        const handleUpdateVault = (vault) => {
            const vaultIndex = vaults.value.findIndex(v => v.id === vault.id);
            if (vaultIndex === -1) {
                return;
            }
            vaults.value[vaultIndex] = vault;
        };
        const openWorkSpaceOverview = item => {
            let selectedSpace = item;
            if (!(selectedSpace === null || selectedSpace === void 0 ? void 0 : selectedSpace.id)) {
                selectedSpace = store.getters.selectedWorkspace;
            }
            return router.push({
                name: 'Files',
                params: {
                    spaceId: selectedSpace.id,
                    spaceName: selectedSpace.name,
                },
            });
        };
        return {
            showCreateWorkspace,
            selectedWorkspace,
            vaults,
            handleUpdateVault,
            openWorkSpaceOverview,
            updateSideBar,
        };
    },
});
