import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "flex-shrink-0" };
const _hoisted_2 = { class: "flex flex-row" };
const _hoisted_3 = ["title"];
const _hoisted_4 = ["title"];
const _hoisted_5 = ["title"];
export function render(_ctx, _cache) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_ctx.canDownload)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "border-none hover:text-blue-500",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (_ctx.$emit('download-item')), ["exact"])),
                    title: `${_ctx.$t('misc.actions.download')}`
                }, [
                    _createElementVNode("i", {
                        class: _normalizeClass(["fas fa-download", [_ctx.colorIcon ? '' : 'text-gray-500']])
                    }, null, 2)
                ], 8, _hoisted_3))
                : _createCommentVNode("", true),
            (_ctx.canDelete)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "border-none h-auto hover:text-blue-500",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event) => (_ctx.$emit('delete-item')), ["exact"])),
                    title: `${_ctx.$t('misc.actions.delete')}`
                }, [
                    _createElementVNode("i", {
                        class: _normalizeClass(["fas fa-trash", [_ctx.colorIcon ? '' : 'text-gray-500']])
                    }, null, 2)
                ], 8, _hoisted_4))
                : _createCommentVNode("", true),
            (_ctx.isVaultAdmin && _ctx.canDelete)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 2,
                    class: "border-none h-auto hover:text-blue-500",
                    onClick: _cache[2] || (_cache[2] = _withModifiers(($event) => (_ctx.$emit('share-item')), ["exact"])),
                    title: `${_ctx.$t('misc.actions.share')}`
                }, [
                    _createElementVNode("i", {
                        class: _normalizeClass(["fas fa-share-alt", [_ctx.colorIcon ? '' : 'text-gray-500']])
                    }, null, 2)
                ], 8, _hoisted_5))
                : _createCommentVNode("", true)
        ])
    ]));
}
