import { __awaiter } from "tslib";
//import { app } from '@/main';
import { mnemonicToEntropy } from 'bip39';
import libSodium from 'libsodium-wrappers';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
export const formatDate = (date, withHoursAndMinutes = true, withFullTime = false) => {
    let format = 'DD/MM/yy';
    if (withHoursAndMinutes) {
        format = format.concat(' HH:mm');
    }
    if (withFullTime) {
        format = format.concat(' HH:mm:ss');
    }
    return moment(date).format(format);
};
export const formatBytes = (bytes, decimal) => {
    let marker = 1000;
    let kiloBytes = marker;
    let megaBytes = marker * marker;
    let gigaBytes = marker * marker * marker;
    // return bytes if less than a KB
    if (bytes < kiloBytes)
        return bytes + ' Bytes';
    // return KB if less than a MB
    else if (bytes < megaBytes)
        return (bytes / kiloBytes).toFixed(decimal) + ' KB';
    // return MB if less than a GB
    else if (bytes < gigaBytes)
        return (bytes / megaBytes).toFixed(decimal) + ' MB';
    // return GB if less than a TB
    else
        return (bytes / gigaBytes).toFixed(decimal) + ' GB';
};
export const formatLongDate = (date) => {
    return moment(date).format('DD MMM yyyy');
};
export const isSeedPhrase = (seedPhrase) => __awaiter(void 0, void 0, void 0, function* () {
    yield libSodium.ready;
    try {
        mnemonicToEntropy(seedPhrase.trim());
        return true;
    }
    catch (e) {
        return false;
    }
});
export const isValidUser = (userDetails) => {
    let valid = true;
    const failedFields = [];
    const fieldsToValidate = ['email', 'firstname', 'lastname'];
    fieldsToValidate.forEach(objectKey => {
        if (userDetails[objectKey].length > 50 || userDetails[objectKey] == '') {
            failedFields.push(objectKey);
            valid = false;
        }
    });
    return {
        isValid: valid,
        failedFields,
    };
};
export const isMobile = () => {
    return !!(navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/Windows Phone/i));
};
export const translate = (translatePath, toBeTranslated = '', data) => {
    const translation = useI18n().t(translatePath, data);
    if (translation.toLocaleLowerCase() === translatePath.toLocaleLowerCase()) {
        return toBeTranslated;
    }
    return translation;
};
