import { __awaiter } from "tslib";
import { GeneralMutationTypes } from './general.mutations';
import { getWorkspaceDetails } from '@/services/space.service';
import { store } from '@/store';
import { acceptPrivacyTerms } from '@/services/user.service';
import { SocketActionTypes } from '@/store/modules/socket/socket.actions';
import { Namespace } from '@/namespaces/namespace.enum';
import { VaultAction } from '@/types/socket/vaultAction.enum';
export var GeneralActionTypes;
(function (GeneralActionTypes) {
    GeneralActionTypes["SET_SELECTED_WORKSPACE"] = "SET_SELECTED_WORKSPACE";
    GeneralActionTypes["SET_STATUS_LOGIN_SPINNER"] = "SET_STATUS_LOGIN_SPINNER";
    GeneralActionTypes["UPDATE_PRIVACY_STATEMENT"] = "UPDATE_PRIVACY_STATEMENT";
})(GeneralActionTypes || (GeneralActionTypes = {}));
export const generalActions = {
    [GeneralActionTypes.SET_SELECTED_WORKSPACE]({ commit, getters }, workspaceId) {
        return __awaiter(this, void 0, void 0, function* () {
            const workspaceDetails = yield getWorkspaceDetails(workspaceId);
            if (!workspaceDetails) {
                return;
            }
            yield store.dispatch(SocketActionTypes.CREATE_NAMESPACE_CONNECTION, Namespace.VAULT);
            getters.getNamespaceSocket(Namespace.VAULT).emit(VaultAction.OPEN_VAULT, workspaceId);
            commit(GeneralMutationTypes.SET_SELECTED_WORKSPACE, workspaceDetails);
        });
    },
    [GeneralActionTypes.SET_STATUS_LOGIN_SPINNER]({ commit }, statusSpinner) {
        return __awaiter(this, void 0, void 0, function* () {
            commit(GeneralMutationTypes.SET_STATUS_SPINNER_LOGIN, statusSpinner);
        });
    },
    [GeneralActionTypes.UPDATE_PRIVACY_STATEMENT]({}, userId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield acceptPrivacyTerms(userId);
        });
    },
};
