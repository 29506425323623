import { __awaiter } from "tslib";
import { defineComponent, onBeforeMount, ref } from 'vue';
import { useStore } from '@/store';
import AddPasswordModal from '@/components/vault/modals/AddPasswordModal.vue';
import AddCategoryModal from '@/components/vault/modals/AddCategoryModal.vue';
import RemoveCategoryModal from '@/components/vault/modals/RemoveCategoryModal.vue';
import EditPasswordModal from '@/components/vault/modals/EditPasswordModal.vue';
import RemovePasswordModal from '@/components/vault/modals/RemovePasswordModal.vue';
import PasswordList from '@/components/general/PasswordList.vue';
import PasswordDetail from '@/components/general/PasswordDetail.vue';
import WorkSpaceDropdown from '@/components/WorkSpaceDropDown.vue';
import TabLayout from '@/layout/TabLayout.vue';
import { ChevronRightIcon, HomeIcon } from '@heroicons/vue/solid';
import { formatDate } from '@/filters';
import { Role, UserRoles } from '@/types';
import { deletePersonalItem, getPersonalPasswordItems } from '@/services/password.service';
import { GeneralMutationTypes } from '@/store/modules/general/general.mutations';
export default defineComponent({
    name: 'VaultPasswordManager',
    components: {
        ChevronRightIcon,
        HomeIcon,
        TabLayout,
        PasswordList,
        PasswordDetail,
        WorkSpaceDropdown,
        AddPasswordModal,
        AddCategoryModal,
        RemoveCategoryModal,
        EditPasswordModal,
        RemovePasswordModal,
    },
    setup(_, ctx) {
        const store = useStore();
        const showAddCategory = ref(false);
        const showRemoveCategory = ref(false);
        const showAddPassword = ref(false);
        const showEditPassword = ref(false);
        const showRemovePassword = ref(false);
        const isPasswordSelected = ref(false);
        const userSearch = ref('');
        const isLoading = ref(true);
        const items = ref([]);
        const currentPassword = ref(undefined);
        const currentCategory = ref(undefined);
        // maybe compute with currentPasswords & currentCategory
        const currentItem = ref(undefined);
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            var _a;
            items.value = yield getPersonalPasswordItems((_a = currentCategory.value) === null || _a === void 0 ? void 0 : _a.id);
            if (items.value.length > 0) {
                currentPassword.value = items.value.find(password => !password.isFolder);
            }
            store.commit(GeneralMutationTypes.SET_SELECTED_WORKSPACE, null);
            isLoading.value = false;
        }));
        const updateItem = (passwordItem) => {
            const itemIndex = items.value.findIndex(item => passwordItem.id === item.id);
            if (itemIndex === -1) {
                items.value.push(passwordItem);
                return;
            }
            if (currentPassword.value.id === passwordItem.id) {
                currentPassword.value = Object.assign(Object.assign({}, passwordItem), { password: null });
            }
            items.value[itemIndex] = passwordItem;
        };
        const handleItemDeletion = (passwordItem) => __awaiter(this, void 0, void 0, function* () {
            let response;
            if (!passwordItem.isFolder) {
                response = yield deletePersonalItem({
                    passwordId: passwordItem.id,
                });
            }
            else {
                response = yield deletePersonalItem({
                    folderId: passwordItem.id,
                });
            }
            if (response.status !== 204) {
                return;
            }
            deleteItem(passwordItem);
        });
        const deleteItem = (passwordItem) => {
            const itemIndex = items.value.findIndex(item => passwordItem.id === item.id);
            if (itemIndex === -1) {
                return;
            }
            items.value.splice(itemIndex, 1);
            //if (items.value.length > 0 && currentPassword.value.id === passwordItem.id) {
            //  currentPassword.value = items.value[itemIndex];
            //}
            currentItem.value = null;
            currentPassword.value = null;
        };
        const setItemToDelete = (item) => {
            currentItem.value = item;
            showRemoveCategory.value = true;
        };
        return {
            userSearch,
            items,
            currentItem,
            currentPassword,
            currentCategory,
            isPasswordSelected,
            store,
            isLoading,
            showEditPassword,
            showRemovePassword,
            showAddCategory,
            showRemoveCategory,
            showAddPassword,
            updateItem,
            handleItemDeletion,
            setItemToDelete,
            formatDate,
            VaultRoles: UserRoles,
            Role,
        };
    },
});
