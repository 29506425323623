import { Role } from '@/types';
export const userGetters = {
    personalWorkspaces: state => state.personalSpaces || [],
    currentUser: state => state.currentUser,
    isAdmin: state => { var _a; return ((_a = state.currentUser) === null || _a === void 0 ? void 0 : _a.role) === Role.ADMIN; },
    isUser: state => { var _a, _b; return ((_a = state.currentUser) === null || _a === void 0 ? void 0 : _a.role) === Role.ADMIN || ((_b = state.currentUser) === null || _b === void 0 ? void 0 : _b.role) === Role.USER; },
    hasAccessToOperation: state => ({ accessType, operation }) => {
        const accessPermission = state.currentUser.vaultPermissions[accessType];
        if (!accessPermission) {
            return false;
        }
        return accessPermission === null || accessPermission === void 0 ? void 0 : accessPermission[operation];
    },
    getRole: state => state.currentUser.role,
    selectedLanguage: state => state.selectedLanguage,
};
