import { __awaiter } from "tslib";
import Button from '@/components/general/Button.vue';
import Modal from '@/components/general/Modal.vue';
import { InputType } from '@/types/input/inputDetails';
import Form from '@/components/general/form/Form.vue';
import { defineComponent, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import validator from 'validator';
import { createPersonalPassword, createSpacePassword } from '@/services/password.service';
export default defineComponent({
    name: 'AddPasswordModal',
    components: { Button, Modal, Form },
    emits: ['update:show', 'create-password'],
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        personal: {
            type: Boolean,
            default: false,
        },
        selectedCategory: Object,
    },
    setup(props, ctx) {
        const route = useRoute();
        const form = computed(() => {
            const { isURL } = validator;
            return {
                name: 'createPasswordForm',
                inputs: [
                    {
                        name: 'name',
                        label: 'passwordName',
                        type: InputType.TEXT,
                        validation: {
                            required: true,
                            maxLength: 100,
                        },
                        autofocus: true,
                    },
                    {
                        name: 'site',
                        label: 'websiteUrl',
                        type: InputType.TEXT,
                        validation: {
                            required: true,
                            validator: isURL,
                        },
                    },
                    {
                        name: 'nameIdentifier',
                        label: 'nameIdentifier',
                        type: InputType.TEXT,
                        validation: {
                            required: true,
                            maxLength: 100,
                        },
                    },
                    {
                        name: 'password',
                        label: 'password',
                        type: InputType.PASSWORD,
                        validation: {
                            required: true,
                        },
                    },
                ],
                submit: 'create',
                cancel: 'cancel',
            };
        });
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        const submitPassword = (formData) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            if (!formData.isValid) {
                return;
            }
            const selectedSpaceId = (_a = route.params) === null || _a === void 0 ? void 0 : _a.spaceId;
            const passwordPostDto = {
                name: formData.values.name.value,
                site: formData.values.site.value,
                nameIdentifier: formData.values.nameIdentifier.value,
                password: formData.values.password.value,
                parentId: (_b = props === null || props === void 0 ? void 0 : props.selectedCategory) === null || _b === void 0 ? void 0 : _b.id,
            };
            let password;
            if (!props.personal) {
                password = yield createSpacePassword(selectedSpaceId, passwordPostDto);
            }
            else {
                password = yield createPersonalPassword(passwordPostDto);
            }
            ctx.emit('create-password', password);
            closeModal();
        });
        onMounted(() => {
            focus();
        });
        const focus = () => {
            setTimeout(function () {
                if (document.querySelectorAll('[autofocus]')[0]) {
                    //@ts-ignore
                    document.querySelectorAll('[autofocus]')[0].focus();
                }
            }, 20);
        };
        return {
            closeModal,
            submitPassword,
            form,
        };
    },
});
