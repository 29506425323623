import { store } from '@/store';
import { FileManagerMutationTypes } from '@/store/modules/FileManager/fileManager.mutations';
import { AdminMutationTypes } from '@/store/modules/admin/admin.mutations';
import { VaultMutationTypes } from '@/store/modules/vault/vault.mutations';
import { convertToTEntry } from '@/utils';
import { defaultFolders } from '@/types/folder/defaultFolders.enum';
import { AccessFileOperations } from '@/types/authorization';
import { router } from '@/main';
export const updateItemDetails = (data) => {
    if (!store.getters.selectedFolderId) {
        store.commit(FileManagerMutationTypes.SET_SELECTED_FOLDER_ID, data.folderId);
    }
    if (data.folderId !== store.getters.selectedFolderId) {
        return;
    }
    const itemsInFolder = store.getters.getItemsByParentId(store.getters.selectedFolderId);
    if (data.items.length > 0) {
        store.commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, data.items);
    }
    const removedItems = itemsInFolder.filter(itemInFolder => !data.items.find(item => convertToTEntry(item).id === convertToTEntry(itemInFolder).id));
    store.commit(FileManagerMutationTypes.REMOVE_ITEMS_FROM_FOLDER, removedItems);
    const selectedItem = [...data.items, ...removedItems].find(item => { var _a; return ((_a = store.getters) === null || _a === void 0 ? void 0 : _a.selectedItemDetails) && convertToTEntry(store.getters.selectedItemDetails).id === convertToTEntry(item).id; });
    const isSelectedItemRemoved = selectedItem && !!removedItems.find(item => convertToTEntry(item).id === convertToTEntry(selectedItem).id);
    if (isSelectedItemRemoved) {
        store.commit(FileManagerMutationTypes.SET_SELECTED_ITEM_DETAILS, undefined);
        return;
    }
    if (!selectedItem) {
        return;
    }
    const currentSelectedFile = store.getters.selectedItemDetails;
    store.commit(FileManagerMutationTypes.SET_SELECTED_ITEM_DETAILS, Object.assign(currentSelectedFile, selectedItem));
};
export const updateVaultItems = ({ items }) => {
    if (router.currentRoute.value.name !== 'Files') {
        return;
    }
    const selectedItem = items.find(item => { var _a; return ((_a = store.getters) === null || _a === void 0 ? void 0 : _a.selectedItemDetails) && convertToTEntry(store.getters.selectedItemDetails).id === convertToTEntry(item).id; });
    if (!!selectedItem) {
        const currentSelectedFile = store.getters.selectedItemDetails;
        store.commit(FileManagerMutationTypes.SET_SELECTED_ITEM_DETAILS, Object.assign(currentSelectedFile, selectedItem));
    }
    store.commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, items);
    if (router.currentRoute.value.params.selectedFolderId) {
        return;
    }
    const selectedFolderId = store.getters.getItemsByParentId(null)[0]['folderUuid'];
    store.commit(FileManagerMutationTypes.SET_SELECTED_FOLDER_ID, selectedFolderId);
    return router.push({
        name: 'Files',
        params: {
            spaceId: router.currentRoute.value.params.spaceId,
            spaceName: router.currentRoute.value.params.spaceName,
            selectedFolderId,
        },
    });
};
export const deleteVaultItems = ({ items, folderId }) => {
    const userVaultPermissions = store.getters.currentUser.vaultPermissions;
    if (!userVaultPermissions.files[AccessFileOperations.readArchived]) {
        items = items.filter(item => item.name !== defaultFolders.Archived);
    }
    if (userVaultPermissions.files.delete || userVaultPermissions.folders.delete) {
        return updateVaultItems({ items });
    }
    return updateItemDetails({ folderId, items });
};
export const updateVaultItem = ({ item }) => {
    var _a;
    if (!item) {
        return;
    }
    const entry = convertToTEntry(item);
    if (((_a = store.getters.selectedItemDetails) === null || _a === void 0 ? void 0 : _a.id) === entry.id) {
        const currentSelectedFile = store.getters.selectedItemDetails;
        store.commit(FileManagerMutationTypes.SET_SELECTED_ITEM_DETAILS, Object.assign(currentSelectedFile, item));
        return;
    }
    store.commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, [item]);
};
export const addVaultUser = user => {
    if (router.currentRoute.value.name === 'Admin') {
        store.commit(AdminMutationTypes.UPDATE_VAULT_USER, user);
        return;
    }
    store.commit(VaultMutationTypes.UPDATE_VAULT_USER, user);
};
export const removeVaultUser = user => {
    if (router.currentRoute.value.name === 'Admin') {
        store.commit(AdminMutationTypes.REMOVE_VAULT_USER, user);
        return;
    }
    store.commit(VaultMutationTypes.REMOVE_VAULT_USER, user);
};
export const updateSearch = data => {
    const searchResults = store.getters.searchResults;
    const { searchId, file } = data.items;
    if (searchId !== store.getters.currentSearchId) {
        return;
    }
    if (searchResults.some(e => e.fileUuid === file.fileUuid)) {
        return;
    }
    searchResults.push(file);
    store.commit(FileManagerMutationTypes.SET_SEARCH_RESULTS, searchResults);
};
