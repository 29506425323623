import { VaultRole } from '@/types';
const nl = {
    selection: {
        whereDoYouWantToGo: 'Naar waar wil je gaan?',
        selectVault: 'Selecteer je @:vault.vault',
        yourVaults: 'Jouw @:vault.vaults',
        brandOnly: `{ companyName } sectie`,
    },
    user: {
        user: 'gebruiker',
        users: 'gebruikers',
        hasNoUsers: 'Heeft geen @:user.users',
        hasNoUserFor: 'Geen @:user.users gevondend voor: {search}',
        hasNoLoginHistory: 'heeft geen login geschiedenis',
        noUserFoundFor: 'Geen gebruiker gevonden voor',
        name: 'Naam',
        removeUser: 'Verwijder @:user.user',
        removeUserConfirm: 'Ben je zeker dat je {username} wilt verwijderen?',
        renewAccess: 'Vault toegang verlengen',
        editUser: '@:user.user',
        userAdd: 'Selecteer de gebruikers die je wilt toevoegen:',
        noUsersToAdd: 'Er zijn gebruikers gevonden die je kan toevoegen aan de @:vault.vault.',
        username: 'Gebruikersnaam',
        email: 'E-mail',
        noUserSelected: 'Selecteer een gebruiker om de details ervan te bekijken',
        sureToDelete: 'Ben je zeker dat je onderstaande gebruikers wilt verwijderen uit de vault "{spaceName}"?',
        demote: {
            demote: 'Degradeer tot',
            confirmation: 'Degradeer vault gebruiker',
            confirmationMessage: 'Bent u zeker dat u deze gebruiker wilt degraderen?',
        },
        promote: {
            promote: 'Promoveer tot',
            confirmation: 'Promoveer vault gebruiker',
            confirmationMessage: 'Bent u zeker dat u deze gebruiker wilt promoveren?',
        },
        revoke: {
            revoke: 'Verwijder gebruiker',
            confirmation: 'Verwijder vault gebruiker',
            confirmationMessage: 'Bent u zeker dat u deze gebruiker wilt verwijderen van deze vault?',
        },
    },
    role: {
        role: 'Rol',
        workspaceAdmin: 'Workspace admin',
        vaultAdmin: 'Vault admin',
        vaultUser: 'Vault user',
        workspaceUser: 'Workspace user',
    },
    file: {
        fileManagement: 'Bestandsbeheer',
        file: 'bestand',
        files: 'bestanden',
        fileListing: 'Geen @:file.files | 1 bestand | {count} bestanden',
        accessHistory: 'Toegangsgeschiedenis',
        noAccessHistory: 'Geen toegangsgeschiedenis beschikbaar',
        noPermissions: 'Er zijn in deze vault nog geen gebruikers om permissies aan te geven.',
        allowedUsers: 'Permissies',
        created: 'Aangemaakt',
        size: 'Grootte',
        lastAccessed: 'Laatst bekeken',
        lastModified: 'Laatst gewijzigd',
        removeFile: 'Verwijder @:file.file',
        removeFileConfirm: 'Ben je zeker dat je {filename} wilt verwijderen?',
        createNewFile: 'Nieuw @:file.file aanmaken',
        newFile: 'Nieuw @:file.file',
        uploadFile: 'Bestanden opladen',
        duplicateFiles: 'Dubbele bestanden gevonden',
        uploadFolder: 'Map opladen',
        selectAFile: 'Selecteer een bestand',
        totalFiles: 'Aantal bestanden',
        toBeClassified: '@:folder.to_be_classified',
        notes: 'Notities',
        deleted: 'Verwijderd',
        noNotes: 'Dit bestand heeft geen notities',
        rename: 'Bestand hernoemen',
        removeFilesConfirm: 'Ben je zeker dat je volgende bestanden wilt verwijderen:',
        noFileSelected: 'Selecteer een bestand om de details ervan te bekijken',
        wantToDownloadFIle: 'Wil je het bestand downloaden?',
        fileOverride: 'Het bestand zal overschreven worden',
        deleteFile: 'Verwijder item | Verwijder items',
        dropToUpload: 'Bestand neerzetten om op te laden',
        confirmationMessage: 'Bent u zeker dat u dit item wilt verwijderen? | Bent u zeker dat u deze items wilt verwijderen?',
        unsavedChanges: 'Niet-opgeslagen wijzigingen',
        unsavedChangesMessage: 'U heeft nog niet-opgeslagen wijzigingen, bent u zeker dat u wilt doorgaan?',
    },
    filePermissions: {
        readPermissions: 'Lees',
        writePermissions: 'Schrijf',
    },
    filter: {
        search: 'Zoek',
        fileOrFolderNotFound: 'Er zijn geen bestanden of mappen gevonden die met de filter overeen komen.',
        noFilesFound: 'Er zijn geen bestanden om weer te geven.',
        foldersNotFound: 'Geen documenten of mappen gevonden.',
        addItemsMessage: 'Start met het toevoegen van een nieuw bestand of map.',
        usersNotFound: 'Geen gebruikers gevonden.',
        userInviteMessage: 'Start met het uitnodigen van een gebruiker.',
        vaultsNotFound: 'Geen vaults gevonden.',
        vaultCreateMessage: 'Start met het maken van een vault.',
        noLoginHistoryFound: 'Er is geen login geschiedenis gevonden voor deze gebruiker.',
        filter: 'Filter',
        filterBy: 'Filter op',
        sort: 'Sorteer',
        sortBy: 'Sorteer op',
        filterAndSort: '@:filter.filter en @:filter.sort',
        equal: 'Is gelijk aan',
        contains: 'Bevat',
        start: 'Start met',
        end: 'Eindigt op',
        filename: 'Naam',
        extension: 'Extensie',
        size: 'Grootte',
        createdOn: 'Gemaakt op',
        lastAccessed: 'Laatst bekeken',
        lastModified: 'Laatst gewijzigd',
        asc: 'A>Z',
        desc: 'Z>A',
        filterValue: 'filter',
    },
    vault: {
        vaultName: 'vaultnaam',
        vaults: 'vaults',
        vault: 'vault',
        hasNoVaultsFor: 'heeft geen @:vault.vaults voor: {search}',
        hasNoVaults: 'heeft geen @:vault.vaults',
        userHasNoVaults: 'Je hebt nog geen @:vault.vaults',
        createNewVault: 'Nieuwe @:vault.vault',
        addUserToVault: 'Gebruikers toevoegen',
        vaultHasNoNotes: 'Voeg een beschrijving toe',
        editVault: 'Vault aanpassen',
        noVaultSelected: 'Selecteer een vault om de details ervan te bekijken',
        renewAccess: 'Opnieuw toegang verlenen',
        noLimit: 'Geen limiet',
        restarting: 'Je vault start opnieuw op',
        revokeAdmins: 'Trek de rechten van de admins in deze vault in',
        permissions: {
            fullAccess: 'Lees- en schrijfrechten',
            readAccess: 'Leesrechten',
            noAccess: 'Geen toegang',
        },
        roles: {
            [VaultRole.LIMITED_VAULT_USER]: 'gelimiteerde @:user.user',
            [VaultRole.BASIC_VAULT_USER]: 'standaard @:user.user',
            [VaultRole.ADVANCED_VAULT_USER]: 'gevorderde @:user.user',
            [VaultRole.VAULT_ADMIN]: 'admin',
        },
    },
    folder: {
        folder: 'map',
        folders: 'mappen',
        createNewFolder: 'Nieuwe @:folder.folder',
        editFolder: 'Bewerk @:folder.folder',
        editCategory: 'Bewerk categorie',
        createNewCategory: 'Nieuwe categorie',
        categoryIconAndColor: 'Selecteer een icoon en kleur voor de nieuwe categorie',
        editCategoryIconAndColor: 'Selecteer een icoon en kleur voor de te bewerken categorie',
        foldername: 'mapnaam',
        all_files: 'Alle bestanden',
        to_be_classified: 'Nog te klasseren',
        archived: 'Prullenbak',
        personal_data: 'Persoonlijke gegevens',
        default_folder_select: 'Selecteer een nieuwe @:folder.folder',
        legal_entities: 'Rechtspersonen',
        taxation: 'Fiscaliteit',
        estate_planning: 'Estate Planning',
        financial_institutions: 'Beleggingen en portefeuilles',
        real_estate: 'Vastgoed',
        private_equity: 'Private Equity/Debt',
        credits_debts: 'Kredieten/schulden',
        life_pension_insurance: 'Schade- en pensioenverzekeringen',
        other_investments: 'Andere investeringen',
        miscellaneous: 'Diversen',
        confirmation: 'Verwijder {name}',
        confirmationMessage: 'Bent u zeker dat u deze map wilt verwijderen?',
        categoryConfirmationMessage: 'Bent u zeker dat u deze categorie wilt verwijderen?',
        rename: 'Hernoem map',
        notEmpty: 'Niet-lege map',
        categoryNotEmpty: 'Niet-lege categorie',
        moveToClassified: "Wil je de bestanden in deze categorie verplaatsen naar de 'Te classificeren' categorie?",
        showMoreFolders: 'Toon meer categorieën',
        showLessFolders: 'Toon minder categorieën',
    },
    invite: {
        invite: 'Nodig uit',
        newInvite: 'Nodig een gebruiker uit',
        details: 'Uitnodigingdetails',
        writeDownSeedPhrase: 'Gelieve deze seed phrase neer te schrijven op een van de voorziene kaartjes.',
        controlWords: 'Gelieve de corresponderende woorden voor de cijfers in te vullen.',
        inviteMailNotification: 'Vul de gegevens in van de persoon die je wilt uitnodigen.',
        inviteDuration: 'Deze persoon ontvangt een uitnodigingslink die voor de volgende {duration} dagen geldig is.',
        addAdmin: 'Voeg admin toe',
        addUser: 'Voeg gebruiker toe',
        receivedInvite: `Je bent uitgenodigd voor de vault. Na goedkeuring van de Terms Of Service en Privacy policy krijg je een QR code om te scannen met de { companyName } app op je smartphone. Dit zal de registratie vervolledigen.`,
        receivedInviteExpiringDate: 'De uitnodiging vervalt op {expireDate}.',
        inviteCouldNotBeFound: 'De uitnodiging kon niet gevonden worden of is al in gebruik, controleer of je de juiste uitnodigingslink hebt ingevoerd.',
        inviteHasAlreadyBeen: 'De uitnodiging is {status}.',
        sureToAdd: 'Ben je zeker dat je onderstaande personen wilt toevoegen:',
        seedPhraseWarning: 'De seed phrase is al reeds gegenereerd bij de vorige stap. Schrijf deze zeker op.',
        userInfo: 'Info gebruiker',
        selectUsername: 'Gebruikersnaam',
        confirmSeedphrase: 'Bevestig 24 woorden',
        statuses: {
            pending: 'in afwachting',
            accepted: 'geaccepteerd',
            expired: 'vervallen',
            rejected: 'afgekeurd',
            canceled: 'geannuleerd',
        },
        extend: 'Verlengen',
        add: 'Toevoegen',
        contactInfo: 'Indien nodig, gelieve contact op te nemen met',
        singleVault: {
            enterVault: 'Betreed de vault',
            receivedInvite: `Je bent uitgenodigd voor de vault. Als u hier verder gaat, moet u inloggen via de { companyName } app op je smartphone om de registratie te vervolledigen. Vanaf dan zult u toegang krijgen tot de vault.`,
        },
        grabSeedPhrase: 'U zult uw 24 woorden seed phrase nodig hebben. Gelieve deze al klaar te leggen.',
    },
    session: {
        sessionWillExpire: 'Je sessie zal weldra verlopen ...',
        idleBrowser: 'U heeft deze browser te lang inactief gelaten.',
        timeLeft: 'Binnen {seconds} seconden word je doorverwezen naar de login pagina.',
    },
    error: {
        uploadingProgress: 'Status: {status}',
        title: {
            spaceExpiryReminder: 'U heeft nog tot en met { expiryDate } toegang!',
            uploadingProgress: '{name} opladen',
        },
        userAlreadyInSpace: 'Deze gebruiker zit alreeds in deze vault.',
        successfullyRenewedExpiryDate: 'De toegang voor { username } is successvol verlengd.',
        FailedToRenewExpiryDate: 'De toegang voor { username } kon niet verlengd worden.',
        spaceExpiryReminder: 'Hierna zult u geen toegang meer hebben tot ze u weer toevoegen in de vault!',
        fileNotFound: 'Bestand niet gevonden',
        noFileFoundFor: 'Geen bestand gevonden voor: {filename}.',
        noFileInFolder: 'Er zijn geen bestanden in de map: {folderName}.',
        noFilesInTotal: 'Er zijn nog geen bestanden.',
        email_not_valid: 'Het lijkt erop dat jouw e-mailadres nog niet geverifieerd is.',
        page_not_found: 'Deze pagina bestaat niet.',
        ip_blocked: 'Jouw IP is geblokkeerd voor de komende 24 uur wegens teveel gefaalde login pogingen.',
        login_failed: 'Authenticatie is mislukt.',
        login_cancelled: 'De gebruiker heeft de authenticatie poging geannuleerd.',
        authentication_failed: 'Het lukte niet om jouw profielgegevens op te halen.',
        invalid_user: 'Jouw gebruiker kon niet gevonden worden op het platform, vraag een uitnodiging voor een vault via jouw vault admin.',
        something_went_wrong: 'Er ging iets mis.',
        internalServerError: 'Er ging iets mis.',
        failedToCloseVault: 'Het lukte niet om jouw vault te sluiten.',
        dnsLookupFailed: 'Het lukte niet om jouw vault te bereiken.',
        unAuthorized: 'Je hebt geen toegang.',
        folderAlreadyDeleted: 'De gevraagde map is al verwijderd.',
        fileAlreadyDeleted: '{name} is al verwijderd.',
        folderIsDeleted: 'De map is verwijderd',
        folderAlreadyExist: 'De gevraagde map bestaat al.',
        folderDoesNotExist: 'De gevraagde map bestaat niet.',
        fileDoesNotExist: 'Het gevraagde @:file.file bestaat niet.',
        fileMissing: 'Het @:file.file ontbreekt.',
        uploadingFailed: '{name} kon niet opgeladen worden.',
        savedChanges: 'Je aanpassingen werden opgeslagen.',
        invalidFileFormat: '@:file.file wordt niet ondersteund.',
        fileMissingData: 'De bestandsdata ontbreekt.',
        spaceDoesNotExist: 'De gevraagde @:vault.vault bestaat niet.',
        noAccessToSpace: 'Je hebt geen toegang tot deze @:vault.vault.',
        userDoesNotExist: 'De gevraagde @:user.user kon niet gevonden worden.',
        invitationNotFound: 'De gevraagde uitnodiging kon niet gevonden worden.',
        invalidEmailAddress: 'Geen geldig e-mailadres',
        invalidEmailFillIn: 'Je e-mailadres is niet geldig.',
        invalidEmailForInvite: 'Incorrecte e-mail voor de gevraagde uitnodiging.',
        invalidInvitationStatus: 'Incorrecte status voor uitnodiging.',
        invalidInvitationType: 'Incorrecte type voor uitnodiging.',
        successfullySendInvite: '{user} is successvol uitgenodigd.',
        invitationFailed: 'Je aanvraag voor de uitnodiging is mislukt,',
        invitationAccept: 'Je hebt de uitnodiging {status}.',
        alreadyInSpace: 'Je behoort al tot deze @:vault.vault.',
        alreadyVaultAdmin: 'Je behoort al tot de @:role.vaultAdmin.',
        invalidFolderName: 'De ingegeven mapnaam is niet geldig.',
        successFullyCreated: '{name} is successvol aangemaakt.',
        successFullyDeleted: '{name} is successvol verwijderd.',
        successFullyRecovered: '{name} is successvol teruggezet',
        successFullyRename: 'De bestandsnaam is successvol gewijzigd naar {name}.',
        successFullyRenameFolder: 'De naam van de map is succesvol gewijzigd naar {name}',
        successFullySharedItem: 'Succesvol gedeeld',
        successFullyEditedSettings: 'Instellingen succesvol aangepast.',
        successFullyCreatedCategory: 'Categorie {name} is successvol aangemaakt.',
        successFullyEditedCategory: 'Categorie is successvol upgedate.',
        failedToDelete: '{name} kon niet worden verwijderd.',
        failedToChange: 'De aanpassingen konden niet opgeslagen worden.',
        failedToCreate: '{name} kon niet gemaakt worden.',
        failedToRenameFile: '{name} kon niet hernoemd worden.',
        cannotDeleteYourself: 'Het is niet mogelijk om jezelf te verwijderen van de @:vault.vault .',
        cannotRemoveFromSpace: 'Je kan deze gebruiker niet verwijderen uit deze @:vault.vault .',
        folderNameTooLong: 'De mapnaam is te lang.',
        successFullyUpdated: 'De werkruimte is succesvol aangepast.',
        phraseAlreadyClaimed: 'Deze zin is al gebruikt.',
        invalidEmail: 'Gebruik het correcte formaat voor een e-mailadres.',
        invalidSite: 'Gebruik het correcte formaat voor een website.',
        lengthToHigh: 'Het maximale aantal karakters is bereikt.',
        lengthToSmall: 'Het veld voldoet niet aan het minimum aantal karakters.',
        cantSave: 'De opgegeven informatie is niet correct.',
        fieldRequired: 'Dit veld is verplicht.',
        duplicateFileName: 'Een bestand met deze naam bestaat al in deze map.',
        userSuccessFullyUpdated: 'De gebruiker is succesvol aangepast.',
        failedToEdit: 'Gebruiker kon niet worden bewerkt',
        forbidden: 'Geen toegang',
        roleNotSupported: 'De opgegeven rol bestaat niet.',
        wordInSeedIncorrect: 'De ingegeven woorden komen niet overeen.',
        invalidSeedPhrase: 'De ingegeven seedphrase is foutief.',
        userRecoverySent: 'De gebruiker heeft een mail ontvangen voor het herstelprocess.',
        filenameTooLong: 'De opgegeven bestandsnaam is te lang.',
        notEditableSupportedFileExtension: '{ extension } extensie wordt niet ondersteund om te bewerken.',
        fileTooBig: 'Het opgegeven bestand is te groot.',
        failedToCreateUser: 'De combinatie van de opgegeven e-mail, voornaam en achternaam is niet uniek.',
        emailAlreadyTaken: 'Het opgegeven e-mailadres bestaat al.',
        usernameAlreadyExists: 'De opgegeven gebruikersnaam bestaat reeds in de authenticator app.',
        usernameMismatch: 'Enkel cijfers en letters zijn toegelaten karakters',
        notSupportedFileExtension: '{ extension } bestanden worden niet ondersteund.',
        fileTypeNotSupported: '{ extension } type @:file.files worden niet ondersteund.',
        fileTypeUnknown: 'Onbekende bestandstypes worden niet ondersteund.',
        copiedToClipboard: 'De tekst is beschikbaar op uw klembord',
        willBeDownloaded: 'Het bestand { filename } wordt gedownload',
        fileCannotBeOpened: 'Het bestand kan niet worden geopend',
        spaceNameAlreadyExists: 'De opgegeven kluisnaam bestaat reeds',
        pleasePickCategory: 'Kies een bestaande categorie',
        cannotUploadFolder: 'Je hebt geen rechten om mappen op te laden',
        uploadSuccessfully: '{name} is succesvol geüpload',
        spaceNameIsEmpty: 'De vault naam mag niet leeg zijn',
        moveSuccessfully: 'Het geselecteerde item is succesvol verplaatst naar {name}',
        cannotDeleteFolder: "Je kan '{name}' niet verwijderen",
        cannotMoveDeletedFolder: 'Je kan een verwijderde map niet verplaatsen',
        moveMultipleSuccessfully: 'De geselecteerde items zijn succesvol verplaatst naar {name}',
        moveFailed: '{sourceName} kon niet verplaatst worden naar {destinationName}',
        moveArchivedFailed: 'Kan geen items uit de prullenbak verplaatsen',
        moveMultipleFailed: 'Enkele items konden niet verplaatst worden naar {destinationName}',
        duplicateFolder: 'Er bestaat al een map met dezelfde naam in deze map.',
        duplicateFile: 'Er bestaat al een file met dezelfde naam in deze map.',
        multipleFolderUpload: 'Je kan maar 1 map op zichzelf uploaden.',
        invalidFileType: 'Ongeldig bestandstype',
        fileTooBigError: 'Bestand te groot.',
        duplicateFileError: 'Bestaand bestand.',
        itemNameAlreadyExist: 'De opgegeven naam is al in gebruik',
        acceptTerms: 'Accepteer de Terms Of Service en Privacy Policy voordat u verder kunt gaan.',
        FailEditSettings: 'Iets ging mis bij het aanpassen van je instellingen.',
        successfullyInvited: 'Gebruiker {name} is succesvol uitgenodigd',
        parentIsAlreadyMounted: 'Er is al een bovenliggende map gekoppeld.',
        duplicateUserVault: 'Invitatie kon niet worden geaccepteerd, er is al een gebruiker met deze authenticator in deze vault.',
        filenameMismatch: 'De bestandsnaam bevat ongeldige tekens.',
        foldernameMismatch: 'De mapnaam bevat ongeldige tekens.',
        categoryNameMismatch: 'De categorienaam bevat ongeldige tekens.',
    },
    misc: {
        movingItem: 'Item verplaatsen',
        secureDocuments: 'Beveiligde documenten',
        cancel: 'Annuleer',
        previous: 'Vorige',
        registered: 'Uitgenodigd op',
        history: 'Geschiedenis',
        logout: 'Uitloggen',
        youHaveBeenLoggedOut: 'Je bent uitgelogd',
        readOnlyClosed: 'De alleen lezen omgeving is afgesloten. Gelieve je e-mail te raadplegen voor een nieuwe link.',
        selection: 'Terug naar selectie',
        goBack: 'Ga terug',
        firstname: 'Voornaam',
        lastname: 'Naam',
        notes: 'Notities',
        open: 'Open',
        ok: 'Ok',
        next: 'Volgende',
        accept: 'bevestig',
        seeDetails: 'zie details',
        redirect: 'Doorverwijzen naar de vault',
        filterValue: 'Filter waarde',
        noFilesFound: 'Er zijn geen bestanden gevonden voor',
        imagePreview: 'Voorbeeld afbeelding',
        warning: 'Waarschuwing',
        actions: {
            open: 'Open',
            edit: 'Bewerk',
            print: 'Print',
            download: 'Download',
            delete: 'Verwijder',
            move: 'Verplaats',
            rename: 'Hernoem',
            recover: 'Herstel',
            save: 'Opslaan',
            share: 'Deel',
            viewInvitation: 'Bekijk uitnodiging',
            uploadFile: 'Bestanden opladen',
            preview: 'Bekijken',
            info: 'Info',
            createFolder: 'Map aanmaken',
            close: 'Sluiten',
            logout: 'Uitloggen',
            home: 'Startpagina',
            switchVault: 'Verander van vault',
            pickLanguage: 'Kies een taal',
            invite: 'Inviteer',
            typeText: 'Typ hier',
            copyToClipboard: 'Kopieer naar klembord',
            copied: 'Gekopieerd',
        },
        neverAccessed: 'Nooit bekeken',
        neverModified: 'Nooit gewijzigd',
        neverLoggedIn: 'Nooit ingelogd',
        givePermission: 'Ik wil bovenstaande gebruikers volledige toegang geven tot de vault en wil dat de gebruikers alle bestanden kunnen zien en bewerken.',
        showQR: 'Toon QR-code',
        hideQR: 'Verberg QR-code',
        toLogin: 'Naar login',
        showCategories: 'Toon categorieën',
        hideCategories: 'Verberg categorieën',
        brandAdmin: `{ companyName } Admin`,
        new: 'Nieuw',
        readAndAccept: 'Ik ga akkoord met de',
    },
    tables: {
        headers: {
            ip: 'IP adres',
            status: 'status',
            date: 'datum',
            geolocation: 'locatie',
            accessedOn: 'Geopend op',
            type: 'Actie',
            user: 'Gebruiker',
            name: 'Naam',
            rights: 'Rechten',
            lastSignedIn: 'Laatst ingelogd',
            lastAccessed: 'Laatst bekeken',
            validUntil: 'Geldig tot',
            vaults: 'Vaults',
            userRoles: 'Rol',
            totalFiles: 'Aantal bestanden',
            toBeClassified: '@:folder.to_be_classified',
            username: '@:user.username',
            email: '@:user.email',
            neverLoggedIn: '@:misc.neverLoggedIn',
            neverAccessed: '@:misc.neverAccessed',
            deleted: '@:file.deleted',
            add: 'Selecteer',
            actions: 'Acties',
            filePermissions: 'Bestandsrechten',
            timeLimit: 'Tijdslimiet',
            canRead: 'Lees',
            canWrite: 'Schrijf',
            size: 'Bestandsgrootte',
            createdOn: 'Aangemaakt op',
        },
        loadingState: {
            vaultLoading: 'Opvragen van vaults',
            userLoading: 'Opvragen van gebruikers',
        },
        actions: {
            EDIT: 'Bewerkt',
            OVERWRITE: 'Overschreven',
            READ: 'Geopend',
            MOVED: 'Verplaatst',
            RENAME: 'Hernoemd',
            DETAILS: 'Details veranderd',
            DELETED: 'Gewist',
        },
        succeed: 'geslaagd',
        failed: 'mislukt',
        more: 'Laad meer',
        login: {
            success: 'Ingelogd',
            failed: 'Mislukte login poging',
        },
    },
    spinner: {
        logout: 'Je wordt op een veilige manier uitgelogd, even geduld',
        restartingDocker: 'De @:vault.vault wordt opgezet.',
    },
    settings: {
        globalTitle: 'Globale Instellingen',
        globalSubtitle: 'Verander hier de globale instellingen',
        brandingTitle: 'Branding Instellingen',
        brandingSubtitle: 'Verander merk gerelateerde dingen',
        companyName: 'Bedrijfsnaam',
        DefaultLanguage: 'Standaard Taal',
        DefaultLanguageSubtitle: 'Dit is de standaardtaal op alle toestellen.',
        logoSubtitle: 'Dit is het algemene logo dat getoond wordt doorheen de hele applicatie.',
        faviconSubtitle: 'Dit is het icoontje dat verschijnt in je browser tabblad.',
    },
    forms: {
        labels: {
            notes: 'Notities',
            note: 'Notitie',
            vaultName: 'Vault naam',
            companyName: 'Bedrijfsnaam',
            email: 'E-mail',
            firstname: 'Voornaam',
            lastname: 'Achternaam',
            vaultFolderName: 'Map naam',
            role: 'Rol',
            seedPhrase: '24 woorden',
            file: 'Bestanden',
            folder: 'Map',
            language: 'Taal',
            username: 'Gebruikersnaam',
            newFolderName: 'Naam van de nieuwe map',
            newCategoryName: 'Naam van nieuwe categorie',
            editCategoryName: 'Naam van de te bewerken categorie',
            newFileName: 'Naam van het nieuwe @:file.file',
            newFolderCategory: 'Categorie om de nieuwe map in te maken',
            newFileCategory: 'Categorie om het nieuwe @:file.file in te maken',
            emailRecipient: 'E-mail ontvanger',
            filename: 'Bestandsnaam',
            foldername: 'Mapnaam',
            fileType: 'Bestands type',
            passwordName: 'Naam',
            websiteUrl: 'Website',
            password: 'Wachtwoord',
            category: 'Categorie',
            nameIdentifier: 'Gebruikersnaam/E-mail',
            shareUntil: 'Delen tot',
        },
        buttons: {
            edit: 'Aanpassen',
            submit: 'Aanpassen',
            confirm: 'Bevestig',
            create: 'Aanmaken',
            cancel: 'Annuleren',
            previous: 'Previous',
            next: 'Volgende',
            share: 'Deel',
            recover: 'Herstel',
            back: 'Terug',
            uploadFile: 'Opladen',
            save: 'Opslaan',
            rename: 'Hernoem',
            remove: 'Verwijder',
            ignore: 'Negeer',
            overwrite: 'Overschrijf',
            invite: 'Inviteren',
        },
        messages: {
            accessMessage: 'Ik wil deze persoon toegang geven tot het volledige platform. De gebruiker zal toegang krijgen tot het beheer van alle vaults en gebruikers.',
            addFileComments: 'Voeg notities toe',
            duplicateFiles: 'Volgende bestanden bestaan al in de geselecteerde map, deze zullen overschreven worden.',
        },
    },
    tabs: {
        edit: 'Bewerk',
        recover: 'Herstel',
        loginHistory: 'Login geschiedenis',
        vault: 'Vaults',
    },
    setup: {
        openApp: 'Open App',
        appSetup: 'De laatste stap is de app opzetten. Scan de qr-code met je GSM en open de url om de app te connecteren aan de vault.',
    },
    calendar: {
        days: {
            monday: 'Maandag',
            tuesday: 'Dinsdag',
            wednesday: 'Woensdag',
            thursday: 'Donderdag',
            friday: 'Vrijdag',
            saturday: 'Zaterdag',
            sunday: 'Zondag',
        },
        months: {
            january: 'Januari',
            february: 'Februari',
            march: 'Maart',
            april: 'April',
            may: 'Mei',
            june: 'Juni',
            july: 'Juli',
            august: 'Augustus',
            september: 'September',
            october: 'Oktober',
            november: 'November',
            december: 'December',
        },
    },
    share: {
        temporaryShareTitle: 'Deel tijdelijk als alleen-lezen',
        temporaryShareMessage: 'Deel tijdelijk bestand(en) als alleen lezen met een gebruiker',
        readonlyTitle: 'Gedeelde Alleen-Lezen Bestanden',
        expiryDate: 'Vervaldatum',
    },
    webdav: {
        mount: 'Koppel WebDAV',
        createdTitle: 'Nieuw item gekoppeld',
        createdBody: 'Nieuw item gekoppeld aan het WebDAV systeem. Je kan deze raadplegen door onderstaande link in de verkenner van uw besturingssysteem te plakken',
        sessionWarning: 'Zorg dat u tijdig opslaat!',
        username: 'Gebruikersnaam',
        password: 'Wachtwoord',
    },
    passwordManager: {
        vaultPasswords: 'Wachtwoorden',
        personalPasswords: 'Persoonlijke Wachtwoorden',
        items: 'geen items | 1 item | {count} items',
        modals: {
            addPassword: 'Wachtwoord toevoegen',
            editPassword: 'Wachtwoord wijzigen',
            addCategory: 'Categorie toevoegen',
            removePassword: 'Wachtwoord verwijderen',
            removeCategory: 'Categorie verwijderen',
            removePasswordMessage: 'Bent u zeker dat u dit wachtwoord wilt verwijderen?',
            removeCategoryMessage: 'Bent u zekder dat u deze categorie wilt verwijderen? Alle wachtwoorden in deze categorie zullen verloren gaan!',
            removeShare: 'Gedeeld wachtwoord verwijderen',
            removeShareMessage: 'Bent u zeker dat u het gedeelde wachtwoord voor deze @:user.user wilt verwijderen?',
            sharePassword: 'Wachtwoord delen',
            fields: {
                readOnly: 'Leesrechten',
                canModify: 'Lees- en schrijfrechten',
            },
        },
        list: {
            emptyItemMessageAdmin: 'Start met het maken van nieuwe wachtwoorden en categorieën.',
            emptyItemMessageUser: 'U heeft geen toegang tot een wachtwoord of categorie in deze vault.',
        },
        details: {
            vaultPassword: 'Vault wachtwoord',
            personalPassword: 'Persoonlijk wachtwoord',
            addedOn: 'Toegevoegd op',
            accountInfo: 'Account Informatie',
            accountDetails: 'Account details',
            sharedWith: 'Gedeeld met',
            fields: {
                nameIdentifier: 'Gebruikersnaam/E-mail',
                password: 'Wachtwoord',
                website: 'Website',
                createdOn: 'Gemaakt op',
                modifiedOn: 'Gewijzigd op',
            },
        },
        buttons: {
            addPassword: 'Wachtwoord toevoegen',
            addCategory: 'Categorie toevoegen',
            share: 'Deel',
            edit: 'Wijzig',
            remove: 'Verwijder',
        },
    },
    navigation: {
        admin: {
            vaults: 'Vaults',
            users: 'Gebruikers',
            settings: 'Instellingen',
        },
        vault: {
            documents: 'Documenten',
            passwords: 'Wachtwoorden',
            users: 'Gebruikers',
        },
        personal: {
            passwords: 'Persoonlijke Wachtwoorden',
        },
    },
    explanation: {
        [VaultRole.LIMITED_VAULT_USER]: 'Deze gebruiker kan enkel bestanden maken en opladen in "te classificeren". Deze gebruiker ziet standaard geen enkel bestand tenzij hem toegang gegeven wordt op een specifiek bestand.',
        [VaultRole.BASIC_VAULT_USER]: 'Deze gebruiker kan enkel bestanden maken en opladen in "te classificeren". Deze gebruiker ziet standaard alle bestanden maar kan deze niet aanpassen tenzij de gebruiker hier toegang voor krijgt op een specifiek bestand.',
        [VaultRole.ADVANCED_VAULT_USER]: 'Deze gebruiker kan enkel bestanden maken en opladen in "te classificeren". Deze gebruiker ziet standaard alle bestanden, folders en categorieën en mag deze ook aanpassen. Deze gebruiker kan echter geen aanpassingen doen aan andere gebruikers, en kan geen andere gebruikers hun vault wachtwoorden aanpassen.',
        [VaultRole.VAULT_ADMIN]: 'De admin heeft standaard toegang tot alles. De admin kan andere gebruikers aanpassen en kan alle vault wachtwoorden inkijken en aanpassen.',
    },
    search: {
        names: 'Namen',
        content: 'Inhoud',
    },
};
export default nl;
