import { __awaiter } from "tslib";
import { defineComponent, onBeforeMount, ref } from 'vue';
import { editSettings, getSettings } from '@/services/admin.service';
import DefaultLanguageSelector from '@/components/general/LanguageSelector/DefaultLanguageSelector.vue';
import Button from '@/components/general/Button.vue';
import { store } from '@/store';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
import { config } from '@/config';
export default defineComponent({
    name: 'BrandingSettings',
    components: { DefaultLanguageSelector, Button },
    setup() {
        const settings = ref({});
        onBeforeMount(() => {
            setSettings();
        });
        const setSettings = () => __awaiter(this, void 0, void 0, function* () {
            settings.value = yield getSettings();
        });
        const changeLanguage = (lang) => __awaiter(this, void 0, void 0, function* () {
            settings.value.defaultLanguage = lang;
        });
        const pushSettings = () => __awaiter(this, void 0, void 0, function* () {
            const updatedSettings = yield editSettings(settings.value);
            if (!updatedSettings) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: `FailEditSettings`,
                });
                return;
            }
            store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                type: ToastType.SUCCESS,
                message: `successFullyEditedSettings`,
            });
            Object.assign(config.value, updatedSettings);
        });
        return {
            settings,
            changeLanguage,
            pushSettings,
        };
    },
});
