import { Namespace } from '@/namespaces/namespace';
import { RootAction } from '@/types/socket/rootAction.enum';
import { store } from '@/store';
import { joinVault, leaveVault, updateDockerStatus, updateFileAccessHistory, updateVault, updateVaultUser } from '@/namespaces/root/rootSocket.actions';
export default class Root extends Namespace {
    constructor(namespace, options) {
        super(namespace, options);
    }
    init() {
        this.socket.on(store.getters.currentUser.id, data => {
            console.log(data);
            switch (data.type) {
                case RootAction.JOIN_VAULT:
                    return joinVault(data.space);
                case RootAction.LEAVE_VAULT:
                    return leaveVault(data.space);
                case RootAction.UPDATE_VAULT_USER:
                    return updateVaultUser(data.item);
                case RootAction.VAULT_STATUS:
                    return updateDockerStatus(data.status);
                case RootAction.UPDATE_FILE_ACCESS_HISTORY:
                    return updateFileAccessHistory(data);
                case RootAction.UPDATE_VAULT:
                    return updateVault(data);
            }
        });
    }
}
