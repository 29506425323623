import { __awaiter } from "tslib";
import { defineComponent, ref, onMounted, onUnmounted, computed, onBeforeMount } from 'vue';
import Avatar from '@/components/general/Avatar.vue';
import { useStore } from '@/store';
import { UserActionTypes } from '@/store/modules/users/user.actions';
import { useRoute, useRouter } from 'vue-router';
import { FileManagerActionTypes } from '@/store/modules/FileManager/fileManager.actions';
export default defineComponent({
    name: 'WorkSpaceDropdown',
    components: {
        Avatar,
    },
    emits: ['closeMenu'],
    setup(_, ctx) {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const dropDownOpen = ref(false);
        const workSpaceDropdown = ref(null);
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            yield store.dispatch(UserActionTypes.SET_PERSONAL_SPACES);
        }));
        const openWorkSpace = (personalSpace) => __awaiter(this, void 0, void 0, function* () {
            dropDownOpen.value = false;
            yield store.dispatch(FileManagerActionTypes.SET_SELECTED_WORKSPACE_ID, personalSpace.id);
            ctx.emit('closeMenu');
            dropDownOpen.value = false;
            return router.push({
                name: route.name,
                params: {
                    spaceId: personalSpace.id,
                    spaceName: personalSpace.name,
                    selectedFolderId: store.getters.selectedFolderId ? store.getters.selectedFolderId : '',
                },
            });
        });
        const close = e => {
            var _a;
            const target = e.target;
            if (workSpaceDropdown.value !== target && !((_a = workSpaceDropdown.value) === null || _a === void 0 ? void 0 : _a.contains(target))) {
                dropDownOpen.value = false;
                return;
            }
        };
        onMounted(() => {
            document.addEventListener('click', close);
        });
        const selectedVaultId = computed(() => store.getters.selectedWorkspaceId);
        const selectedVaultName = computed(() => { var _a; return (_a = store.getters.selectedWorkspace) === null || _a === void 0 ? void 0 : _a.name; });
        const personalWorkspaces = computed(() => { var _a; return (_a = store.getters.personalWorkspaces) === null || _a === void 0 ? void 0 : _a.filter(space => space.id !== selectedVaultId.value); });
        onUnmounted(() => close);
        return {
            workSpaceDropdown,
            dropDownOpen,
            selectedVaultName,
            selectedVaultId,
            personalWorkspaces,
            openWorkSpace,
            close,
        };
    },
});
