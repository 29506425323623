export var AccessType;
(function (AccessType) {
    AccessType["files"] = "files";
    AccessType["folders"] = "folders";
    AccessType["users"] = "users";
    AccessType["passwords"] = "passwords";
})(AccessType || (AccessType = {}));
export var AccessCrudOperations;
(function (AccessCrudOperations) {
    AccessCrudOperations["read"] = "read";
    AccessCrudOperations["create"] = "create";
    AccessCrudOperations["delete"] = "delete";
    AccessCrudOperations["edit"] = "edit";
})(AccessCrudOperations || (AccessCrudOperations = {}));
export var AccessFileOperations;
(function (AccessFileOperations) {
    AccessFileOperations["write"] = "write";
    AccessFileOperations["upload"] = "upload";
    AccessFileOperations["move"] = "move";
    AccessFileOperations["rename"] = "rename";
    AccessFileOperations["permissions"] = "permissions";
    AccessFileOperations["readArchived"] = "read-archived";
})(AccessFileOperations || (AccessFileOperations = {}));
export const AccessOperations = Object.assign(Object.assign({}, AccessFileOperations), AccessCrudOperations);
