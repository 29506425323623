import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "w-full flex flex-col justify-center" };
export function render(_ctx, _cache) {
    const _component_Form = _resolveComponent("Form");
    const _component_modal = _resolveComponent("modal");
    return (_ctx.show)
        ? (_openBlock(), _createBlock(_component_modal, {
            key: 0,
            onClose: _cache[1] || (_cache[1] = ($event) => (_ctx.closeModal()))
        }, {
            header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('passwordManager.modals.addCategory')), 1)
            ]),
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_Form, {
                        "form-data": _ctx.form,
                        "onUpdate:form-data": _cache[0] || (_cache[0] = ($event) => ((_ctx.form) = $event)),
                        onSubmit: _ctx.createCategory,
                        onCancel: _ctx.closeModal,
                        loading: _ctx.creatingCategory
                    }, null, 8, ["form-data", "onSubmit", "onCancel", "loading"])
                ])
            ]),
            _: 1
        }))
        : _createCommentVNode("", true);
}
