import { defineComponent, ref } from 'vue';
import Avatar from '@/components/general/Avatar.vue';
import { useStore } from '@/store';
import { mapGetters } from 'vuex';
import { UserMutationTypes } from '@/store/modules/users/user.mutations';
import { Language } from '@/types';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    name: 'LanguageSelector',
    components: {
        Avatar,
    },
    setup() {
        const store = useStore();
        const i18n = useI18n();
        const dropDownOpen = ref(false);
        const selectedLanguage = ref(localStorage.getItem('selectedLanguage'));
        const languages = [
            {
                key: Language.NL,
                shorthand: 'nl',
                longhand: 'Nederlands',
            },
            {
                key: Language.EN,
                shorthand: 'en',
                longhand: 'English',
            },
            {
                key: Language.FR,
                shorthand: 'fr',
                longhand: 'Français',
            },
        ];
        const setLang = lang => {
            i18n.locale.value = lang;
            const store = useStore();
            store.commit(UserMutationTypes.SET_CURRENT_LANGUAGE, lang);
            dropDownOpen.value = !dropDownOpen.value;
        };
        const clickedLang = lang => {
            selectedLanguage.value = lang;
            const store = useStore();
            store.commit(UserMutationTypes.SET_CURRENT_LANGUAGE, lang);
            i18n.locale.value = lang;
        };
        const getLanguage = () => {
            if (i18n.locale.value) {
                return languages.find(lang => lang.shorthand == i18n.locale.value).shorthand;
            }
            return languages.find(lang => lang.shorthand == i18n.fallbackLocale.value).shorthand;
        };
        const closeDropdown = () => {
            dropDownOpen.value = false;
        };
        return {
            store,
            dropDownOpen,
            languages,
            selectedLanguage,
            Language,
            setLang,
            closeDropdown,
            getLanguage,
            clickedLang,
        };
    },
    computed: Object.assign({}, mapGetters(['currentUser'])),
});
