import { computed, defineComponent } from 'vue';
import TabLayout from '@/layout/TabLayout.vue';
import FolderCard from '@/components/general/FolderCard.vue';
import { useRoute } from 'vue-router';
import { store } from '@/store';
import { convertToTEntry } from '@/utils';
export default defineComponent({
    name: 'MobileFolders',
    components: { FolderCard, TabLayout },
    emits: ['close', 'setSelectedFolder'],
    props: {},
    setup() {
        const route = useRoute();
        const selectedFolderId = computed(() => route.params.selectedFolderId);
        const folders = computed(() => store.getters.getItemsByParentId(null).map(item => convertToTEntry(item)));
        return {
            selectedFolderId,
            folders,
        };
    },
});
