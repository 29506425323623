import { __awaiter } from "tslib";
import Modal from '@/components/general/Modal.vue';
import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue';
import Button from '@/components/general/Button.vue';
import { useStore } from '@/store';
import Input from '@/components/general/Input.vue';
import SearchBar from '@/components/vault/SearchBar.vue';
import { SortType, Table } from '@jimber/shared-components';
import Dropdown from '@/components/general/Dropdown.vue';
import { getVaultUsers, inviteSpaceUser } from '@/services/admin.service';
import { Permissions } from '@/types/file/permission.enum';
import Selection from '@/views/Selection.vue';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
import { translate } from '@/filters';
import DatePicker from '@/components/general/datePicker/DatePicker.vue';
import EmptyTableState from '@/components/general/emptyStates/EmptyTableState.vue';
import UserNavigationIcon from '@/components/icons/navigation/UsersNavigationIcon.vue';
import { config } from '@/config';
export default defineComponent({
    name: 'AddUserToWorkspaceModal',
    components: {
        UserNavigationIcon,
        EmptyTableState,
        DatePicker,
        Selection,
        Button,
        Modal,
        Input,
        SearchBar,
        Dropdown,
        Table,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        selectedSpace: {
            type: Object,
        },
    },
    emits: ['update:show', 'addSuccess'],
    setup({ selectedSpace, show }, ctx) {
        const isLoading = ref(false);
        const store = useStore();
        const isLoadingUsers = ref(true);
        const revokeAdmins = ref(false);
        const userPermissions = ref([]);
        const searchedUserPermissions = ref([]);
        const selectedUsers = ref([]);
        const sortedField = ref({
            prop: 'username',
            order: SortType.ASCENDING,
        });
        const step = ref(1);
        const userSearch = ref('');
        const searchBar = ref(null);
        const headers = computed(() => [
            {
                key: 'checkbox',
                displayName: translate('tables.headers.add'),
            },
            {
                key: 'username',
                displayName: translate('tables.headers.username'),
                enableSorting: true,
            },
            {
                key: 'email',
                displayName: translate('tables.headers.email'),
                enableSorting: true,
            },
            {
                key: 'timeLimit',
                displayName: translate('tables.headers.timeLimit'),
            },
        ]);
        const headersVerify = computed(() => [
            {
                key: 'username',
                displayName: translate('tables.headers.username'),
                enableSorting: true,
            },
            {
                key: 'email',
                displayName: translate('tables.headers.email'),
                enableSorting: true,
            },
            {
                key: 'permissions',
                displayName: translate('tables.headers.filePermissions'),
            },
        ]);
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            const users = yield getVaultUsers({
                vaultId: selectedSpace.id,
                value: userSearch.value !== '' ? userSearch.value : undefined,
                skip: 0,
                take: 10,
                field: (_a = sortedField.value) === null || _a === void 0 ? void 0 : _a.prop,
                order: (_b = sortedField.value) === null || _b === void 0 ? void 0 : _b.order,
            });
            userPermissions.value = users.map(mapUserToPermission);
            isLoadingUsers.value = false;
        }));
        watch(userSearch, (value) => __awaiter(this, void 0, void 0, function* () {
            var _c, _d;
            if (value === '') {
                return;
            }
            const newSearchedUsers = yield getVaultUsers({
                vaultId: selectedSpace.id,
                value: userSearch.value !== '' ? userSearch.value : undefined,
                skip: 0,
                take: 10,
                field: (_c = sortedField.value) === null || _c === void 0 ? void 0 : _c.prop,
                order: (_d = sortedField.value) === null || _d === void 0 ? void 0 : _d.order,
            });
            if (newSearchedUsers.length <= 0) {
                searchedUserPermissions.value = [];
                return;
            }
            searchedUserPermissions.value = newSearchedUsers.map(mapUserToPermission);
        }));
        const sortUsers = () => __awaiter(this, void 0, void 0, function* () {
            var _e, _f;
            const newUsers = yield getVaultUsers({
                vaultId: selectedSpace.id,
                value: userSearch.value !== '' ? userSearch.value : undefined,
                skip: 0,
                take: 10,
                field: (_e = sortedField.value) === null || _e === void 0 ? void 0 : _e.prop,
                order: (_f = sortedField.value) === null || _f === void 0 ? void 0 : _f.order,
            });
            if (newUsers.length <= 0) {
                return;
            }
            if (userSearch.value !== '') {
                searchedUserPermissions.value = newUsers.map(mapUserToPermission);
                return newUsers;
            }
            userPermissions.value = newUsers.map(mapUserToPermission);
        });
        const getMoreUsers = ({ index }) => __awaiter(this, void 0, void 0, function* () {
            var _g, _h;
            const newUsers = yield getVaultUsers({
                vaultId: selectedSpace.id,
                value: userSearch.value !== '' ? userSearch.value : undefined,
                skip: index * 10,
                take: 10,
                field: (_g = sortedField.value) === null || _g === void 0 ? void 0 : _g.prop,
                order: (_h = sortedField.value) === null || _h === void 0 ? void 0 : _h.order,
            });
            if (newUsers.length <= 0) {
                return [];
            }
            if (userSearch.value !== '') {
                searchedUserPermissions.value = searchedUserPermissions.value.concat(newUsers.map(mapUserToPermission));
                return newUsers;
            }
            userPermissions.value = userPermissions.value.concat(newUsers.map(mapUserToPermission));
            return newUsers;
        });
        const mapUserToPermission = user => {
            return {
                userId: user.id,
                username: user.username,
                email: user.email,
                role: user.role,
                permission: Permissions.LIMITED_VAULT_USER,
                filePermissions: [],
                selected: false,
            };
        };
        const users = computed(() => (userSearch.value !== '' ? searchedUserPermissions.value : userPermissions.value));
        const closeModal = () => {
            userSearch.value = '';
            step.value = 1;
            userPermissions.value = [];
            ctx.emit('update:show', false);
        };
        const toggleUserToAdd = permissionData => {
            const selectedUserIndex = selectedUsers.value.findIndex(data => data.userId === permissionData.userId);
            if (selectedUserIndex !== -1) {
                selectedUsers.value.splice(selectedUserIndex, 1);
                return;
            }
            selectedUsers.value.push(Object.assign(Object.assign({}, permissionData), { selected: true }));
        };
        const isSelectedUser = computed(() => userId => {
            const selectedUserIndex = selectedUsers.value.findIndex(data => data.userId === userId);
            if (selectedUserIndex === -1) {
                return false;
            }
            return selectedUsers.value[selectedUserIndex].selected;
        });
        const updateUserPermission = (e, permission) => {
            const permissionIndex = selectedUsers.value.findIndex(data => data.userId === permission.userId);
            if (permissionIndex === -1) {
                return;
            }
            selectedUsers.value[permissionIndex].permission = e.target.value;
        };
        const addUsersToSpace = () => __awaiter(this, void 0, void 0, function* () {
            if (isLoading.value) {
                return;
            }
            isLoading.value = true;
            const userPermissionList = {};
            selectedUsers.value.forEach((permissionData) => {
                userPermissionList[permissionData.userId] = {
                    permission: permissionData.permission,
                    expiryDate: permissionData.expiryDate,
                    filePermissions: permissionData.filePermissions,
                };
            });
            const spaceUsers = yield inviteSpaceUser(selectedSpace.id, {
                revokeAdmins: revokeAdmins.value,
                permissions: userPermissionList,
            });
            if ((spaceUsers === null || spaceUsers === void 0 ? void 0 : spaceUsers.length) <= 0) {
                return;
            }
            spaceUsers.forEach(userPermission => {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.SUCCESS,
                    message: 'successfullyInvited',
                    data: {
                        name: userPermission.username,
                    },
                });
            });
            ctx.emit('addSuccess', spaceUsers);
            closeModal();
        });
        const setSelectedUserExpiry = (row, date) => {
            const selectedUserIndex = selectedUsers.value.findIndex(data => data.userId === row.userId);
            if (selectedUserIndex === -1) {
                return;
            }
            selectedUsers.value[selectedUserIndex].expiryDate = date;
        };
        return {
            headers,
            headersVerify,
            step,
            users,
            userSearch,
            userPermissions,
            searchBar,
            sortedField,
            isLoadingUsers,
            revokeAdmins,
            config,
            selectedUsers,
            isSelectedUser,
            Permissions,
            setSelectedUserExpiry,
            sortUsers,
            getMoreUsers,
            closeModal,
            addUsersToSpace,
            toggleUserToAdd,
            updateUserPermission,
        };
    },
});
