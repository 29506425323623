import { __awaiter } from "tslib";
import { VaultMutationTypes } from './vault.mutations';
import { getUsersInWorkspace } from '@/services/space.service';
export var VaultActionTypes;
(function (VaultActionTypes) {
    VaultActionTypes["SET_SELECTED_WORKSPACE_USERS"] = "SET_SELECTED_WORKSPACE_USERS";
})(VaultActionTypes || (VaultActionTypes = {}));
export const vaultActions = {
    [VaultActionTypes.SET_SELECTED_WORKSPACE_USERS]({ commit, getters }) {
        return __awaiter(this, void 0, void 0, function* () {
            const selectedVaultUsers = yield getUsersInWorkspace({ vaultId: getters.selectedWorkspace.id });
            commit(VaultMutationTypes.SET_SELECTED_VAULT_USERS, selectedVaultUsers);
        });
    },
};
