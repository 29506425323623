import { __awaiter } from "tslib";
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@/store';
import { FileManagerActionTypes } from '@/store/modules/FileManager/fileManager.actions';
import { FileManagerMutationTypes } from '@/store/modules/FileManager/fileManager.mutations';
import AdminFileDetails from '@/components/vault/sidebars/AdminFileDetails.vue';
import AdminFolderDetails from '@/components/vault/sidebars/AdminFolderDetails.vue';
import MultiSelectSidebar from '@/components/vault/sidebars/MultiSelectSidebar.vue';
import { fileComparer, FileManager, getIcon, getIconColor, getName, ScreenWidth } from '@jimber/shared-components';
import { formatBytes, formatDate, isMobile, translate } from '@/filters';
import folderBar from '@/components/vault/FolderBar.vue';
import TabLayout from '@/layout/TabLayout.vue';
import EmptyTableState from '@/components/general/emptyStates/EmptyTableState.vue';
import WorkSpaceDropdown from '@/components/WorkSpaceDropDown.vue';
import BreadCrumb from '@/components/general/BreadCrumb.vue';
import { getFolderPath } from '@/services/folder.service';
import { cancelContentSearch } from '@/services/file.service';
import CreateFolderModal from '@/components/vault/modals/CreateFolderModal.vue';
import FileUploadModal from '@/components/vault/modals/FileUploadModal.vue';
import FolderUploadModal from '@/components/vault/modals/FolderUploadModal.vue';
import Button from '@/components/general/Button.vue';
import { ViewType } from '@/types/views/views.enum';
import MountModal from '@/components/vault/modals/MountModal.vue';
import DeleteFileModal from '@/components/vault/modals/DeleteFileModal.vue';
import ChangeItemNameModal from '@/components/vault/modals/ChangeItemNameModal.vue';
import { convertToTEntry, downloadFile } from '@/utils';
import { buildItemList } from '@/utils/file.utils';
import MobileFolders from '@/components/vault/MobileFolders.vue';
import CreateFileModal from '@/components/vault/modals/CreateFileModal.vue';
import { WebdavActionTypes } from '@/store/modules/webdav/webdav.actions';
import OverflowBreadCrumb from '@/components/general/OverflowBreadCrumb.vue';
import { PlusIcon, XIcon } from '@heroicons/vue/outline';
import TemporaryItemShareModal from '@/components/vault/modals/TemporaryItemShareModal.vue';
import { getSpaceFolders } from '@/services/vaultSpace.service';
import { mapGetters } from 'vuex';
import { AccessOperations, AccessType } from '@/types/authorization';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
import { v4 as uuidv4 } from 'uuid';
import { config } from '@/config';
import { SearchType } from '@/types/file/searchType.enum';
export default defineComponent({
    name: 'Workspaces',
    components: {
        TemporaryItemShareModal,
        OverflowBreadCrumb,
        MobileFolders,
        FileManager,
        folderBar,
        AdminFileDetails,
        MultiSelectSidebar,
        TabLayout,
        WorkSpaceDropdown,
        BreadCrumb,
        CreateFolderModal,
        Button,
        FileUploadModal,
        FolderUploadModal,
        DeleteFileModal,
        ChangeItemNameModal,
        AdminFolderDetails,
        CreateFileModal,
        MountModal,
        PlusIcon,
        XIcon,
        EmptyTableState,
    },
    setup() {
        const route = useRoute();
        const store = useStore();
        const router = useRouter();
        const isLoading = ref(true);
        const selectedWorkspaceId = route.params.spaceId;
        const routedFolderId = route.params.selectedFolderId;
        const breadcrumbValues = ref([]);
        const showCreateFolder = ref(false);
        const showFileUpload = ref(false);
        const showFolderUpload = ref(false);
        const showFileCreation = ref(false);
        const showDeleteFile = ref(false);
        const showMountCreated = ref(false);
        const changeItemName = ref(false);
        const shareItem = ref(false);
        const isDragging = ref(false);
        const isInternalDragging = ref(false);
        const searchType = ref(SearchType.NAME);
        const isSearching = ref(false);
        const currentSearchValue = ref(null);
        const currentSearchId = ref(null);
        const uploadedDragFiles = ref(undefined);
        let unWatchFolders;
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            yield store.dispatch(FileManagerActionTypes.SET_SELECTED_WORKSPACE_ID, selectedWorkspaceId);
            const rootFolders = yield getSpaceFolders(selectedWorkspaceId);
            const toBeClassifiedFolder = rootFolders.find(folder => (folder === null || folder === void 0 ? void 0 : folder['name']) == 'to_be_classified');
            if (toBeClassifiedFolder) {
                store.dispatch(FileManagerActionTypes.SET_SELECTED_FOLDER_ID, toBeClassifiedFolder['folderUuid']);
            }
            store.commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, rootFolders);
            if (routedFolderId) {
                yield store.dispatch(FileManagerActionTypes.SET_SELECTED_FOLDER_ID, routedFolderId);
                breadcrumbValues.value = yield getFolderPath(selectedWorkspaceId, routedFolderId);
                isLoading.value = false;
                return;
            }
            const defaultFolder = rootFolders[0];
            if (defaultFolder && 'folderUuid' in defaultFolder) {
                yield store.dispatch(FileManagerActionTypes.SET_SELECTED_FOLDER_ID, defaultFolder.folderUuid);
                yield router.push({
                    name: 'Files',
                    params: {
                        spaceId: route.params.spaceId,
                        spaceName: route.params.spaceName,
                        selectedFolderId: defaultFolder === null || defaultFolder === void 0 ? void 0 : defaultFolder.folderUuid,
                    },
                });
                breadcrumbValues.value = yield getFolderPath(selectedWorkspaceId, defaultFolder.folderUuid);
            }
            isLoading.value = false;
        }));
        unWatchFolders = store.watch((state, getters) => getters.selectedFolderId, (newFolderId, oldFolderId) => __awaiter(this, void 0, void 0, function* () {
            if (!newFolderId) {
                breadcrumbValues.value = [];
                return;
            }
            if (newFolderId !== oldFolderId) {
                breadcrumbValues.value = yield getFolderPath(store.getters.selectedWorkspaceId, newFolderId);
            }
        }));
        onBeforeUnmount(() => {
            unWatchFolders();
        });
        watch(route, (newRoute) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            if (((_a = newRoute.params) === null || _a === void 0 ? void 0 : _a.spaceId) && ((_b = newRoute.params) === null || _b === void 0 ? void 0 : _b.spaceId) !== selectedWorkspaceId) {
                const rootFolders = yield getSpaceFolders((_c = route.params) === null || _c === void 0 ? void 0 : _c.spaceId);
                if (rootFolders.length <= 0) {
                    return;
                }
                store.commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, rootFolders);
                if ((_d = route.params) === null || _d === void 0 ? void 0 : _d.selectedFolderId) {
                    return;
                }
                openFolderById(rootFolders[0].folderUuid);
            }
            store.commit(FileManagerMutationTypes.SET_SELECTED_ITEMS_IDS, []);
        }));
        const displaySidebar = computed(() => 0 < store.getters.selectedItemsIds.length);
        const displayMultiFileSidebar = computed(() => 1 < store.getters.selectedItemsIds.length);
        const itemsToShow = computed(() => {
            const itemsToMap = isSearching.value ? store.getters.searchResults : store.getters.getItemsByParentId(store.getters.selectedFolderId);
            return itemsToMap.map(item => convertToTEntry(item));
        });
        const collapseSidebar = () => {
            store.dispatch(FileManagerActionTypes.SET_SELECTED_ITEMS_IDS, []);
        };
        const openItem = (item) => {
            isSearching.value = false;
            if (item['folderUuid'] || item['isFolder']) {
                openFolderById(item['folderUuid'] || item['id']);
                return;
            }
            openFileById(item['fileUuid'] || item['id']);
        };
        const openFolderById = (folderId = null) => {
            store.dispatch(FileManagerActionTypes.SET_SELECTED_FOLDER_ID, folderId);
            if (!folderId) {
                return;
            }
            router.push({
                name: 'Files',
                params: {
                    spaceId: route.params.spaceId,
                    spaceName: route.params.spaceName,
                    selectedFolderId: folderId,
                },
            });
        };
        const openFileById = (fileId) => {
            const routeData = router.resolve({
                name: 'DocumentBrowser',
                params: {
                    spaceId: route.params.spaceId,
                    spaceName: route.params.spaceName,
                    fileUuid: fileId,
                },
            });
            window.open(routeData.href, '_blank');
        };
        watch(searchType, newSearchType => {
            doSearch(currentSearchValue.value);
        });
        const doSearch = (searchValue) => __awaiter(this, void 0, void 0, function* () {
            currentSearchValue.value = searchValue;
            if (!currentSearchValue.value) {
                cancelContentSearch(selectedWorkspaceId, currentSearchId.value);
                yield store.dispatch(FileManagerActionTypes.SET_SELECTED_FOLDER_ID, store.getters.selectedFolderId);
                isSearching.value = false;
                return;
            }
            if (currentSearchId) {
                cancelContentSearch(selectedWorkspaceId, currentSearchId.value);
            }
            currentSearchId.value = uuidv4();
            store.commit(FileManagerMutationTypes.SET_CURRENT_SEARCH_ID, currentSearchId.value);
            yield store.dispatch(FileManagerActionTypes.SEARCH_IN_SELECTED_WORKSPACE, {
                searchString: currentSearchValue.value,
                searchId: currentSearchId.value,
                searchType: searchType.value,
            });
            isSearching.value = true;
        });
        const selectedChanged = (selectedChange) => __awaiter(this, void 0, void 0, function* () {
            store.dispatch(FileManagerActionTypes.SET_SELECTED_ITEMS_IDS, selectedChange.selectedItems);
        });
        const moveItems = (data) => {
            const folderChangeDetails = data.source.map(item => {
                return {
                    item,
                    newFolderId: data.destination.id,
                    oldFolderId: store.getters.selectedFolderId,
                    newFolderName: data.destination.name,
                    isFolder: item.isFolder,
                };
            });
            store.dispatch(FileManagerActionTypes.CHANGE_FOLDER_OF_FILE, folderChangeDetails);
        };
        const adminSideBarActions = (action) => __awaiter(this, void 0, void 0, function* () {
            switch (action) {
                case 'preview-item':
                    store.getters.selectedItemsIds.forEach(fileId => {
                        openFileById(fileId);
                    });
                    break;
                case 'write-item':
                    changeItemName.value = true;
                    break;
                case 'download-item':
                    for (const fileId of store.getters.selectedItemsIds) {
                        const foundFile = store.getters.workspaceItems.find(file => {
                            return 'fileUuid' in file && 'filename' in file && 'extension' in file && file.fileUuid == fileId;
                        });
                        downloadFile(foundFile);
                    }
                    break;
                case 'delete-item':
                    if (store.getters.selectedItemsIds.length >= 0)
                        showDeleteFile.value = true;
                    break;
                case 'save-item':
                    break;
                case 'mount-item':
                    for (const fileId of store.getters.selectedItemsIds) {
                        const result = yield store.dispatch(WebdavActionTypes.ADD_MOUNT, fileId);
                        if (!result) {
                            return;
                        }
                        showMountCreated.value = true;
                    }
                    break;
                case 'share-item':
                    shareItem.value = true;
                    break;
            }
        });
        const addNewFolder = (folder) => {
            store.commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, [folder]);
        };
        const viewType = computed(() => {
            return store.getters.hasAccessToOperation({ accessType: AccessType.files, operation: AccessOperations.edit }) ? ViewType.ADMIN : ViewType.USER;
        });
        const headers = computed(() => {
            const headersToReturn = [
                {
                    displayName: translate('tables.headers.name'),
                    key: 'name',
                    enableSorting: true,
                    comparer: (entry1, entry2, i) => fileComparer(entry1, entry2, i),
                },
                {
                    displayName: translate('tables.headers.lastAccessed'),
                    key: 'lastAccessed',
                    enableSorting: true,
                    formatter: entry => {
                        if (entry.isFolder)
                            return '';
                        if (!entry.lastAccessed)
                            return translate('tables.headers.neverAccessed');
                        const date = new Date(entry.lastAccessed);
                        return formatDate(date);
                    },
                    displayWidth: ScreenWidth.Desktop,
                },
                {
                    displayName: translate('tables.headers.size'),
                    key: 'size',
                    enableSorting: true,
                    formatter: entry => {
                        if (entry.isFolder)
                            return '';
                        return formatBytes(entry.size, 2);
                    },
                    displayWidth: ScreenWidth.Desktop,
                },
                {
                    displayName: translate('tables.headers.createdOn'),
                    key: 'createdOn',
                    enableSorting: true,
                    formatter: entry => {
                        if (entry.isFolder)
                            return '';
                        return formatDate(entry.createdOn);
                    },
                    displayWidth: ScreenWidth.Desktop,
                },
            ];
            return headersToReturn;
        });
        const dragStart = e => {
            const isFileDragging = Object.values(e.dataTransfer.items).filter((item) => item.kind === 'file').length > 0;
            if (!isFileDragging) {
                return;
            }
            if (!isInternalDragging.value) {
                isDragging.value = true;
            }
        };
        const dragStop = () => {
            isDragging.value = false;
        };
        const startInternalDrag = () => {
            if (!store.getters.hasAccessToOperation({ accessType: AccessType.files, operation: AccessOperations.move })) {
                isInternalDragging.value = false;
                return;
            }
            isInternalDragging.value = true;
        };
        const stopInternalDrag = () => {
            if (!store.getters.hasAccessToOperation({ accessType: AccessType.files, operation: AccessOperations.move })) {
                isInternalDragging.value = false;
                return;
            }
            isInternalDragging.value = false;
        };
        const dropFile = (e) => __awaiter(this, void 0, void 0, function* () {
            e.preventDefault();
            e.stopPropagation();
            if (!isDragging.value) {
                return;
            }
            isDragging.value = false;
            uploadedDragFiles.value = yield Promise.all(Object.values(e.dataTransfer.items)
                .filter((item) => item.kind === 'file')
                .map((item) => __awaiter(this, void 0, void 0, function* () { return yield buildItemList(item.webkitGetAsEntry()); })));
            const folders = uploadedDragFiles.value.filter(item => {
                return !!(item === null || item === void 0 ? void 0 : item.find(i => {
                    return i.isDirectory;
                }));
            });
            if (folders.length > 0) {
                if (!store.getters.hasAccessToOperation({ accessType: AccessType.folders, operation: AccessOperations.create })) {
                    return store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                        type: ToastType.ERROR,
                        message: `cannotUploadFolder`,
                    });
                }
                showFolderUpload.value = true;
                return;
            }
            showFileUpload.value = true;
        });
        return {
            itemsToShow,
            breadcrumbValues,
            showCreateFolder,
            isSearching,
            showDeleteFile,
            showFileUpload,
            showFolderUpload,
            showFileCreation,
            showMountCreated,
            changeItemName,
            shareItem,
            viewType,
            headers,
            isDragging,
            uploadedDragFiles,
            displaySidebar,
            displayMultiFileSidebar,
            isInternalDragging,
            isLoading,
            searchType,
            openItem,
            isMobile,
            translate,
            doSearch,
            addNewFolder,
            adminSideBarActions,
            collapseSidebar,
            selectedChanged,
            moveItems,
            dropFile,
            dragStart,
            dragStop,
            startInternalDrag,
            stopInternalDrag,
            openFolderById,
            getIcon,
            getIconColor,
            getName,
            AccessType,
            AccessOperations,
            SearchType,
            config,
        };
    },
    computed: Object.assign({}, mapGetters(['selectedWorkspace', 'selectedFolderId', 'selectedItemDetails', 'isAdmin', 'isVaultAdmin', 'workspaceItems', 'hasAccessToOperation'])),
});
