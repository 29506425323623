import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "px-4 py-2 ml-2 flex flex-col w-full h-full overflow-hidden border-l-0 sm:border-l border-gray-200" };
const _hoisted_2 = { class: "pb-5" };
const _hoisted_3 = { class: "h-full w-full overflow-auto bg-white z-0 px-1" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "header")
        ]),
        _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "content")
        ])
    ]));
}
