import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_invitation_single_vault = _resolveComponent("invitation-single-vault");
    const _component_invitation_vault = _resolveComponent("invitation-vault");
    return (_ctx.isAppPreregistrationEnabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_invitation_single_vault)
        ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_invitation_vault)
        ]));
}
