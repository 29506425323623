import { __awaiter } from "tslib";
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { getFileByUuid } from '@/services/vaultSpace.service';
import { dockerFromUser } from '@/services/url.service';
import { createAccessToken, getFileChecksum } from '@/services/file.service';
import { useStore } from '@/store';
import editableFileExtensions from '@/constants/editableFileExtensions.json';
import { config } from '@/config';
import Button from '@/components/general/Button.vue';
import { downloadFile } from '@/utils';
import { isMobile } from '@/filters';
import IdleComponent from '@/components/general/IdleComponent.vue';
import { AccessOperations, AccessType } from '@/types/authorization';
export default defineComponent({
    name: 'DocumentBrowser',
    components: { Button, IdleComponent },
    setup() {
        const store = useStore();
        const { hasAccessToOperation, currentUser } = store.getters;
        const { params } = useRoute();
        const extension = ref('');
        const idleTimer = ref(null);
        const selectedFile = ref(null);
        onMounted(() => {
            renderDocumentBrowser();
        });
        const isEditableInDocumentServer = ref(true);
        const imageUrl = ref(null);
        const downloadEidFile = () => __awaiter(this, void 0, void 0, function* () {
            downloadFile(selectedFile.value);
        });
        const renderDocumentBrowser = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            selectedFile.value = yield getFileByUuid(params.fileUuid.toString());
            if (!selectedFile.value) {
                return;
            }
            const accessToken = yield createAccessToken(selectedFile.value.fileUuid, hasAccessToOperation({ accessType: AccessType.files, operation: AccessOperations.edit }) && selectedFile.value.permission.canWrite);
            const fileChecksum = yield getFileChecksum(selectedFile.value.spaceId, selectedFile.value.fileUuid);
            const dockerName = dockerFromUser(currentUser.id);
            extension.value = selectedFile.value.extension;
            isEditableInDocumentServer.value = editableFileExtensions.includes(extension.value);
            if (isEditableInDocumentServer.value) {
                const config = {
                    events: {
                        onAppReady: function () {
                            const iframe = document.getElementsByName('frameEditor')[0];
                            if (!iframe.contentWindow)
                                return;
                            iframe.contentWindow.onkeyup = function (e) {
                                idleTimer.value.resetTimer();
                            };
                            iframe.contentWindow.onclick = function (e) {
                                idleTimer.value.resetTimer();
                            };
                            iframe.contentWindow.onwheel = function (e) {
                                idleTimer.value.resetTimer();
                            };
                            iframe.contentWindow.onmousemove = function (e) {
                                idleTimer.value.resetTimer();
                            };
                        },
                    },
                    document: {
                        fileType: extension.value,
                        key: fileChecksum + selectedFile.value.fileUuid,
                        //IF a document is readonly, the extension is not added to the name when downloading
                        title: hasAccessToOperation({ accessType: AccessType.files, operation: AccessOperations.edit }) && extension.value !== 'pdf'
                            ? selectedFile.value.filename
                            : selectedFile.value.filename + '.' + selectedFile.value.extension,
                        url: `http://${dockerName}/user/api/v1/${currentUser.id}/documentserver/${selectedFile.value.spaceId}/files/${selectedFile.value.fileUuid}/${accessToken}`,
                        permissions: {
                            download: false,
                            print: false,
                        },
                    },
                    height: '100%',
                    width: '100%',
                    editorConfig: {
                        callbackUrl: `http://${dockerName}/user/api/v1/${currentUser.id}/documentserver/callback/${selectedFile.value.spaceId}/${selectedFile.value.fileUuid}/${accessToken}`,
                        customization: {
                            autosave: true,
                            forcesave: true,
                        },
                        user: {
                            id: store.getters.currentUser.id,
                            name: store.getters.currentUser.username,
                        },
                        mode: selectedFile.value.permission.canWrite && !selectedFile.value.isArchived && !isMobile() ? 'edit' : 'view',
                    },
                };
                //@ts-ignore
                new window.DocsAPI.DocEditor('placeholder', config);
            }
            else {
                imageUrl.value = `${config.value.vaultSpaceApi}/${(_a = store.getters.currentUser) === null || _a === void 0 ? void 0 : _a.id}/documentserver/${selectedFile.value.spaceId}/files/${selectedFile.value.fileUuid}/${accessToken}`;
            }
        });
        return {
            isEditableInDocumentServer,
            imageUrl,
            extension,
            idleTimer,
            downloadEidFile,
        };
    },
});
