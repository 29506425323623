import { __awaiter } from "tslib";
import Avatar from '@/components/general/Avatar.vue';
import Button from '@/components/general/Button.vue';
import SearchBar from '@/components/vault/SearchBar.vue';
import { formatDate } from '@/filters';
import { translate } from '@/filters';
import { SortType, Table } from '@jimber/shared-components';
import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue';
import { getVaults } from '@/services/space.service';
import EmptyTableState from '@/components/general/emptyStates/EmptyTableState.vue';
import VaultNavigationIcon from '@/components/icons/navigation/VaultNavigationIcon.vue';
import { config } from '@/config';
export default defineComponent({
    name: 'AdminVaultOverview',
    components: {
        VaultNavigationIcon,
        Avatar,
        SearchBar,
        Button,
        Table,
        EmptyTableState,
    },
    props: {
        vaults: {
            type: Object,
            require: false,
        },
    },
    emits: ['update:vaults', 'selected-item', 'open-item', 'show-create-workspace'],
    setup(props, ctx) {
        const isLoading = ref(true);
        const vaultSearch = ref('');
        const workspaces = ref(props.vaults);
        const searchedVaults = ref([]);
        const sortedField = ref({
            prop: 'lastAccessed',
            order: SortType.DESCENDING,
        });
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            workspaces.value = yield getVaults({
                skip: 0,
                take: 25,
                field: (_a = sortedField.value) === null || _a === void 0 ? void 0 : _a.prop,
                order: (_b = sortedField.value) === null || _b === void 0 ? void 0 : _b.order,
            });
            ctx.emit('update:vaults', workspaces.value);
            isLoading.value = false;
        }));
        const headers = computed(() => [
            {
                key: 'name',
                displayName: translate('tables.headers.name', ''),
                enableSorting: true,
            },
            {
                key: 'totalFiles',
                displayName: translate('tables.headers.totalFiles', ''),
                enableSorting: true,
            },
            {
                key: 'toBeClassified',
                displayName: translate('tables.headers.toBeClassified', ''),
                enableSorting: true,
            },
            {
                key: 'lastAccessed',
                displayName: translate('tables.headers.lastSignedIn', ''),
                enableSorting: true,
                formatter: entry => {
                    const date = new Date(entry.lastAccessed);
                    return formatDate(date);
                },
            },
        ]);
        watch(vaultSearch, (value) => __awaiter(this, void 0, void 0, function* () {
            var _c, _d;
            if (value === '') {
                return;
            }
            const newSearchedVaults = yield getVaults({
                value,
                skip: 0,
                take: 25,
                field: (_c = sortedField.value) === null || _c === void 0 ? void 0 : _c.prop,
                order: (_d = sortedField.value) === null || _d === void 0 ? void 0 : _d.order,
            });
            if (newSearchedVaults.length <= 0) {
                searchedVaults.value = [];
                return;
            }
            searchedVaults.value = newSearchedVaults;
        }));
        const sortVaults = () => __awaiter(this, void 0, void 0, function* () {
            var _e, _f;
            const newVaults = yield getVaults({
                value: vaultSearch.value !== '' ? vaultSearch.value : undefined,
                skip: 0,
                take: 25,
                field: (_e = sortedField.value) === null || _e === void 0 ? void 0 : _e.prop,
                order: (_f = sortedField.value) === null || _f === void 0 ? void 0 : _f.order,
            });
            if (newVaults.length <= 0) {
                return;
            }
            if (vaultSearch.value !== '') {
                searchedVaults.value = newVaults;
                return newVaults;
            }
            workspaces.value = newVaults;
        });
        const getMoreWorkspaces = ({ index }) => __awaiter(this, void 0, void 0, function* () {
            var _g, _h;
            const newVaults = yield getVaults({
                value: vaultSearch.value !== '' ? vaultSearch.value : undefined,
                skip: index * 25,
                take: 25,
                field: (_g = sortedField.value) === null || _g === void 0 ? void 0 : _g.prop,
                order: (_h = sortedField.value) === null || _h === void 0 ? void 0 : _h.order,
            });
            if (newVaults.length <= 0) {
                return [];
            }
            if (vaultSearch.value !== '') {
                searchedVaults.value = searchedVaults.value.concat(newVaults);
                return newVaults;
            }
            workspaces.value = workspaces.value.concat(newVaults);
            ctx.emit('update:vaults', workspaces.value);
            return newVaults;
        });
        const selectedChanged = item => {
            ctx.emit('selected-item', item);
        };
        const openItem = (item) => {
            if (!Boolean(item.hasSpaceAccess)) {
                return;
            }
            ctx.emit('open-item', item);
        };
        const vaults = computed(() => (vaultSearch.value !== '' ? searchedVaults.value : workspaces.value));
        return {
            isLoading,
            headers,
            vaults,
            vaultSearch,
            sortedField,
            sortVaults,
            getMoreWorkspaces,
            formatDate,
            selectedChanged,
            openItem,
            singleVaultMode: config.value.env.isSingleVault,
        };
    },
});
