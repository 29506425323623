import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "px-6" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_date_picker = _resolveComponent("date-picker");
    const _component_Table = _resolveComponent("Table");
    const _component_Button = _resolveComponent("Button");
    const _component_modal = _resolveComponent("modal");
    return (_ctx.show)
        ? (_openBlock(), _createBlock(_component_modal, {
            key: 0,
            onClose: _cache[3] || (_cache[3] = ($event) => (_ctx.closeModal())),
            sticky: ""
        }, {
            header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('user.renewAccess')), 1)
            ]),
            actions: _withCtx(() => [
                _createVNode(_component_Button, {
                    colored: "",
                    disabled: !_ctx.accessExpiryDate,
                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.extendUserExpiryDate()))
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('invite.extend')), 1)
                    ]),
                    _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_Button, {
                    class: "capitalize mr-2",
                    onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.closeModal()))
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('misc.cancel')), 1)
                    ]),
                    _: 1
                })
            ]),
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_Table, {
                        headers: _ctx.headers,
                        data: [_ctx.user],
                        emptyMessage: _ctx.$t('filter.usersNotFound')
                    }, {
                        "data-timeLimit": _withCtx(({ row }) => [
                            _createVNode(_component_date_picker, {
                                modelValue: _ctx.accessExpiryDate,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.accessExpiryDate) = $event))
                            }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                    }, 8, ["headers", "data", "emptyMessage"])
                ])
            ]),
            _: 1
        }))
        : _createCommentVNode("", true);
}
