import AdminUserOverview from '@/components/admin/AdminUserOverview.vue';
import EditUserModal from '@/components/vault/modals/EditUserModal.vue';
import InvitationModal from '@/components/vault/modals/InvitationModal.vue';
import { formatDate } from '@/filters';
import TabLayout from '@/layout/TabLayout.vue';
import { useStore } from '@/store';
import { defineComponent, ref } from 'vue';
import { mapGetters } from 'vuex';
import { AdminActionTypes } from '@/store/modules/admin/admin.actions';
export default defineComponent({
    name: 'AdminUsers',
    components: {
        AdminUserOverview,
        TabLayout,
        InvitationModal,
        EditUserModal,
    },
    setup() {
        const store = useStore();
        const showEditUserModal = ref(false);
        const showInvitationModal = ref(false);
        const editUserValue = ref(undefined);
        const setEditUser = (user) => {
            editUserValue.value = user;
            showEditUserModal.value = true;
        };
        const updateUserList = () => {
            store.dispatch(AdminActionTypes.SET_VAULT_USERS);
        };
        return {
            showInvitationModal,
            showEditUserModal,
            editUserValue,
            formatDate,
            setEditUser,
            updateUserList,
        };
    },
    computed: Object.assign({}, mapGetters(['selectedWorkspace'])),
});
