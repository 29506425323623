import { __awaiter } from "tslib";
import DetailsSidebar from '@/components/vault/sidebars/DetailsSidebar.vue';
import { computed, onBeforeMount, ref, watch } from 'vue';
import PermissionComponent from '@/components/vault/sidebars/PermissionComponent.vue';
import Dropdown from '@/components/general/Dropdown.vue';
import { translate } from '@/filters';
import { useStore } from '@/store';
import DataTable from '@/components/general/DataTable.vue';
import Button from '@/components/general/Button.vue';
import { config } from '@/config';
import { getFoldersAndSubFolders } from '@/services/admin.service';
import { mapSubFolders } from '@/utils';
import CustomSelect from '@/components/general/CustomSelect.vue';
import { useRoute } from 'vue-router';
import { mapGetters } from 'vuex';
import { AccessOperations, AccessType } from '@/types/authorization';
import { convertToTEntry } from '@/utils';
import { FileManagerActionTypes } from '@/store/modules/FileManager/fileManager.actions';
export default {
    name: 'AdminFileDetails',
    props: {
        folderDetails: {
            type: Object,
            default: null,
        },
    },
    components: {
        Button,
        Dropdown,
        DetailsSidebar,
        PermissionComponent,
        DataTable,
        CustomSelect,
    },
    emits: ['collapse-sidebar', 'open-folder', 'edit-folder-name', 'share-item'],
    setup(props) {
        const store = useStore();
        const route = useRoute();
        const fileNotes = ref(undefined);
        const allFolders = ref([]);
        const selectedFolder = ref(undefined);
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            if (!store.getters.hasAccessToOperation({ accessType: AccessType.folders, operation: AccessOperations.move })) {
                return;
            }
            const foldersToMap = yield getFoldersAndSubFolders(route.params.spaceId);
            allFolders.value = mapSubFolders(foldersToMap);
            selectedFolder.value = workspaceFolders.value.find(folder => folder.folderUuid === props.folderDetails.parentUuid);
        }));
        const workspaceFolders = computed(() => mapSubFolders(allFolders.value));
        watch(() => props.folderDetails, f => {
            selectedFolder.value = workspaceFolders.value.find(folder => folder.folderUuid === f.parentUuid);
        });
        const recoverFolders = () => __awaiter(this, void 0, void 0, function* () {
            const toBeClassified = store.getters.getItemsByParentId(null).find(item => (item['name'] = 'to_be_classified'));
            const folderChangeDetails = {
                newFolderId: toBeClassified.folderUuid,
                isFolder: true,
                oldFolderId: props.folderDetails.folderUuid,
                item: convertToTEntry(props.folderDetails),
                newFolderName: 'to_be_classified',
            };
            store.dispatch(FileManagerActionTypes.CHANGE_FOLDER_OF_FILE, [folderChangeDetails]);
        });
        return {
            workspaceFolders,
            selectedFolder,
            translate,
            config,
            AccessType,
            AccessOperations,
            recoverFolders,
        };
    },
    computed: Object.assign({}, mapGetters(['hasAccessToOperation', 'isVaultAdmin'])),
};
