import { __awaiter } from "tslib";
import { getLogos, getSettings } from '@/services/admin.service';
import { ref } from 'vue';
import { Language } from '@/types';
import { getEnvironmentConfig } from '@/services/environment.service';
export const config = ref({
    apiEndPoint: `${window.location.origin}/api/v1`,
    vaultSpaceApi: `${window.location.origin}/user/api/v1`,
    defaultLanguage: Language.NL,
});
export const setConfigVariables = () => __awaiter(void 0, void 0, void 0, function* () {
    const flagSmithConfig = yield getEnvironmentConfig();
    const settingConfig = yield getSettings();
    const logo = yield getLogos();
    Object.assign(config.value, flagSmithConfig);
    Object.assign(config.value, settingConfig);
    Object.assign(config.value, logo);
});
