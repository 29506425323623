import { vaultActions } from './vault.actions';
import { vaultGetters } from './vault.getters';
import { vaultMutations } from './vault.mutations';
import { vaultState } from './vault.state';
export const vaultStore = {
    state: vaultState,
    getters: vaultGetters,
    mutations: vaultMutations,
    actions: vaultActions,
    //namespaced: true,
};
