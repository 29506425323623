import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "px-6" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Table = _resolveComponent("Table");
    const _component_Button = _resolveComponent("Button");
    const _component_modal = _resolveComponent("modal");
    return (_ctx.show)
        ? (_openBlock(), _createBlock(_component_modal, {
            key: 0,
            onClose: _cache[2] || (_cache[2] = ($event) => (_ctx.closeModal())),
            sticky: ""
        }, {
            header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('user.removeUser')), 1)
            ]),
            actions: _withCtx(() => [
                _createVNode(_component_Button, {
                    colored: "",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.confirmDelete()))
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('misc.ok')), 1)
                    ]),
                    _: 1
                }),
                _createVNode(_component_Button, {
                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.closeModal())),
                    class: "mr-2"
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('misc.cancel')), 1)
                    ]),
                    _: 1
                })
            ]),
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createTextVNode(_toDisplayString(_ctx.$t('user.sureToDelete', { spaceName: _ctx.selectedWorkspace.name })) + " ", 1),
                    _createVNode(_component_Table, {
                        headers: _ctx.headersVerify,
                        data: _ctx.users,
                        emptyMessage: _ctx.$t('filter.usersNotFound')
                    }, null, 8, ["headers", "data", "emptyMessage"])
                ])
            ]),
            _: 1
        }))
        : _createCommentVNode("", true);
}
