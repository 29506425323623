import { __awaiter } from "tslib";
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import { ArrowNarrowLeftIcon, LockClosedIcon, CalendarIcon, LinkIcon, PencilIcon, TrashIcon, CheckIcon, ThumbUpIcon, UserIcon, LoginIcon, EyeIcon, EyeOffIcon, DocumentDuplicateIcon, } from '@heroicons/vue/solid';
import Button from '@/components/general/Button.vue';
import { formatDate } from '@/filters';
import { useStore } from '@/store';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
import { decryptedPersonalPassword, decryptSpacePassword, getPasswordUsers } from '@/services/password.service';
import { useRoute } from 'vue-router';
import { mapGetters } from 'vuex';
import RemoveShareModal from '@/components/vault/modals/RemoveUserFromSharedModal.vue';
import SharePasswordModal from '@/components/vault/modals/SharePasswordModal.vue';
import Avatar from '@/components/general/Avatar.vue';
import { isBrowserIsolated } from '@/utils';
import { AccessOperations, AccessType } from '@/types/authorization';
export default defineComponent({
    name: 'PasswordDetail',
    components: {
        ArrowNarrowLeftIcon,
        LockClosedIcon,
        CalendarIcon,
        LinkIcon,
        PencilIcon,
        TrashIcon,
        CheckIcon,
        ThumbUpIcon,
        UserIcon,
        LoginIcon,
        EyeOffIcon,
        EyeIcon,
        DocumentDuplicateIcon,
        Button,
        RemoveShareModal,
        SharePasswordModal,
        Avatar,
    },
    props: {
        currentPassword: Object,
        isPasswordSelected: Boolean,
        personal: Boolean,
    },
    emits: ['update:is-password-selected', 'update:current-password', 'show-edit-password', 'show-remove-item', 'show-remove-share'],
    setup(props, ctx) {
        var _a;
        const store = useStore();
        const route = useRoute();
        const showPassword = ref(false);
        const showRemoveShare = ref(false);
        const showSharePassword = ref(false);
        const passwordUsers = ref([]);
        const sharedUserToRemove = ref(null);
        const selectedSpaceId = (_a = route.params) === null || _a === void 0 ? void 0 : _a.spaceId;
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            showPassword.value = false;
            if (props.personal ||
                (!store.getters.hasAccessToOperation({ accessType: AccessType.passwords, operation: AccessOperations.edit }) && !Boolean(props.currentPassword.canModify))) {
                return;
            }
            passwordUsers.value = yield getPasswordUsers(selectedSpaceId, props.currentPassword.id);
        }));
        const addSharedUsers = (users) => {
            passwordUsers.value.push(...users);
        };
        const deleteSharedUser = () => {
            passwordUsers.value = passwordUsers.value.filter(passwordUser => sharedUserToRemove.value.id !== passwordUser.id);
            sharedUserToRemove.value = null;
        };
        const setPasswordShareUser = (user) => {
            sharedUserToRemove.value = user;
            showRemoveShare.value = true;
        };
        const goBack = () => {
            ctx.emit('update:current-password', null);
        };
        const getFavIconOfSite = computed(() => {
            if (!props.currentPassword) {
                return;
            }
            return `http://www.google.com/s2/favicons?sz=64&domain_url=${props.currentPassword.site}`;
        });
        const viewPassword = () => __awaiter(this, void 0, void 0, function* () {
            showPassword.value = !showPassword.value;
            const decryptedPassword = yield getDecryptedPassword();
            ctx.emit('update:current-password', Object.assign(Object.assign({}, props.currentPassword), { password: decryptedPassword }));
        });
        const copyPassword = () => __awaiter(this, void 0, void 0, function* () {
            var _b, _c;
            if (!!((_b = props.currentPassword) === null || _b === void 0 ? void 0 : _b.password)) {
                return copyTextToClipboard((_c = props.currentPassword) === null || _c === void 0 ? void 0 : _c.password);
            }
            const decryptedPassword = yield getDecryptedPassword();
            copyTextToClipboard(decryptedPassword);
            ctx.emit('update:current-password', Object.assign(Object.assign({}, props.currentPassword), { password: decryptedPassword }));
        });
        const getDecryptedPassword = () => __awaiter(this, void 0, void 0, function* () {
            var _d;
            if (!!props.currentPassword.password) {
                return props.currentPassword.password;
            }
            const selectedWorkspace = (_d = route.params) === null || _d === void 0 ? void 0 : _d.spaceId;
            let decryptedData;
            const data = {
                userId: store.getters.currentUser.id,
                passwordId: props.currentPassword.id,
                vaultId: selectedWorkspace,
            };
            if (selectedWorkspace) {
                decryptedData = yield decryptSpacePassword(data);
            }
            else {
                decryptedData = yield decryptedPersonalPassword(data);
            }
            if (!decryptedData) {
                return;
            }
            return Buffer.from(decryptedData.password, 'base64').toString('utf-8');
        });
        const openUrl = () => {
            window.open(formatUrl.value, '_blank');
        };
        const formatUrl = computed(() => {
            let url = props.currentPassword.site;
            if (!/^https?:\/\//i.test(url)) {
                url = 'https://' + props.currentPassword.site;
            }
            return url;
        });
        const copyTextToClipboard = (text) => {
            navigator.clipboard.writeText(text).then(() => {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.SUCCESS,
                    message: `copiedToClipboard`,
                });
            });
        };
        const isPasswordVisible = computed(() => { var _a; return !!((_a = props.currentPassword) === null || _a === void 0 ? void 0 : _a.password) && showPassword.value; });
        return {
            getFavIconOfSite,
            formatUrl,
            isPasswordVisible,
            passwordUsers,
            showRemoveShare,
            showSharePassword,
            sharedUserToRemove,
            deleteSharedUser,
            setPasswordShareUser,
            addSharedUsers,
            goBack,
            formatDate,
            openUrl,
            copyTextToClipboard,
            viewPassword,
            copyPassword,
            isBrowserIsolated,
            AccessType,
            AccessOperations,
        };
    },
    computed: Object.assign({}, mapGetters(['selectedWorkspace', 'hasAccessToOperation', 'currentUser'])),
});
