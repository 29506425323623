import { __awaiter } from "tslib";
import { getMe } from '@/services/authentication.service';
import { useStore } from '@/store';
import { UserMutationTypes } from '@/store/modules/users/user.mutations';
import { defineComponent, onBeforeMount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { config } from '@/config';
export default defineComponent({
    name: 'Callback',
    setup() {
        const isLoading = ref(true);
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            const user = yield getMe();
            if (!user) {
                return;
            }
            store.commit(UserMutationTypes.SET_CURRENT_USER, user);
            isLoading.value = false;
        }));
        watch(isLoading, value => {
            if (value) {
                return;
            }
            const redirect = localStorage.getItem('redirect');
            localStorage.removeItem('redirect');
            if (route.query['invitationIdSingleVault'] && config.value.env.isAppPreregistrationEnabled) {
                return router.push({ name: 'Selection' });
            }
            if (store.getters.isAdmin) {
                return router.push({ name: 'AdminVaultsOverview' });
            }
            return router.push(redirect ? redirect : { name: 'Selection' });
        });
    },
});
