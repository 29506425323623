import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'CategoryPopover',
    components: { Menu, MenuButton, MenuItems, MenuItem },
    emits: ['editCategory', 'removeCategory'],
    props: {
        customClasses: String,
    },
    setup(props, ctx) {
        const editCategory = () => {
            ctx.emit('editCategory');
        };
        const deleteCategory = () => {
            ctx.emit('removeCategory');
        };
        return {
            editCategory,
            deleteCategory,
        };
    },
});
