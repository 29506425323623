import { __awaiter } from "tslib";
import axios from 'axios';
import { config } from '@/config';
export const inviteSingleVaultUser = (vaultId, data) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    return (_a = (yield axios.post(`${config.value.apiEndPoint}/invitation-single-vault/${vaultId}`, data))) === null || _a === void 0 ? void 0 : _a.data;
});
export const getSingleVaultInvitationDetails = (invitationId) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    return (_b = (yield axios.get(`${config.value.apiEndPoint}/invitation-single-vault/${invitationId}`))) === null || _b === void 0 ? void 0 : _b.data;
});
