import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex flex-col w-full h-full overflow-y-auto pr-1" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_folder_card = _resolveComponent("folder-card");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.folders, (folder, index) => {
            return (_openBlock(), _createBlock(_component_folder_card, {
                key: `${folder.id}_${index}`,
                folder: folder,
                showClickIcon: "",
                "show-file-count": "",
                class: "w-full pb-2 cursor-pointer rounded",
                onClick: _withModifiers(($event) => (_ctx.$emit('setSelectedFolder', folder.id)), ["stop"])
            }, null, 8, ["folder", "onClick"]));
        }), 128))
    ]));
}
