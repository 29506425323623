import { __awaiter } from "tslib";
import { defineComponent, onBeforeMount, ref } from 'vue';
import ToastContainer from '@/components/ToastContainer.vue';
import { config } from '@/config';
import { useFavicon } from '@vueuse/core';
import SpinnerModal from '@/components/vault/modals/SpinnerModal.vue';
import { mapGetters } from 'vuex';
import { DockerStatus } from '@/types/socket/dockerStatus.enum';
import { useStore } from '@/store';
import { useRoute } from 'vue-router';
import { GeneralActionTypes } from '@/store/modules/general/general.actions';
export default defineComponent({
    name: 'App',
    components: { ToastContainer, SpinnerModal },
    setup() {
        const store = useStore();
        const route = useRoute();
        const icon = useFavicon();
        const isLoading = ref(true);
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            icon.value = config.value.favicon;
            isLoading.value = false;
            const selectedWorkspaceId = route.params.spaceId;
            if (selectedWorkspaceId && (!selectedWorkspaceId || store.getters.selectedWorkspaceId !== selectedWorkspaceId)) {
                yield store.dispatch(GeneralActionTypes.SET_SELECTED_WORKSPACE, selectedWorkspaceId);
            }
        }));
        return {
            DockerStatus,
            isLoading,
        };
    },
    computed: Object.assign({}, mapGetters(['currentUser'])),
});
