import { Role } from '@/types';
import { computed } from 'vue';
import { translate } from '@/filters';
import { Table } from '@jimber/shared-components';
import { useStore } from '@/store';
export default {
    name: 'PermissionComponent',
    props: {
        permissions: Object,
        disabled: Boolean,
    },
    components: {
        Table,
    },
    emits: ['update:permissions'],
    setup({ permissions }, ctx) {
        const store = useStore();
        const handleRead = (permission) => {
            if (!canChangePermissions(permission)) {
                return;
            }
            const permissionIndex = permissions.findIndex(p => p.id === permission.id);
            if (permissionIndex === -1) {
                return;
            }
            permissions[permissionIndex].canRead = !permission.canRead;
            ctx.emit('update:permissions', permissions);
        };
        const handleWrite = (permission) => {
            if (!canChangePermissions(permission)) {
                return;
            }
            const permissionIndex = permissions.findIndex(p => p.id === permission.id);
            if (permissionIndex === -1) {
                return;
            }
            permissions[permissionIndex].canRead = true;
            permissions[permissionIndex].canWrite = !permission.canWrite;
            ctx.emit('update:permissions', permissions);
        };
        const headers = computed(() => [
            {
                key: 'username',
                displayName: translate('tables.headers.user'),
                enableSorting: true,
            },
            {
                key: 'canRead',
                displayName: translate('tables.headers.canRead'),
            },
            {
                key: 'canWrite',
                displayName: translate('tables.headers.canWrite'),
            },
        ]);
        const canChangePermissions = permission => store.getters.isVaultAdmin && permission.role !== Role.VAULT_ADMIN && (permission === null || permission === void 0 ? void 0 : permission.id) !== store.getters.currentUser.id;
        return {
            Role,
            headers,
            handleRead,
            handleWrite,
            canChangePermissions,
        };
    },
};
