import { __awaiter } from "tslib";
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { config } from '@/config';
import { logoutUser } from '@/services/authentication.service';
import axios from 'axios';
import { store } from '@/store';
import { Role } from '@/types';
export default defineComponent({
    name: 'IdleComponent',
    emits: ['trigger-logout'],
    setup(props, ctx) {
        const events = ref(['click', 'mousemove', 'mousedown', 'scroll', 'keypress']);
        const warningZone = ref(false);
        const countdownValue = ref(null);
        const timer = ref();
        onMounted(() => {
            events.value.forEach(event => {
                window.addEventListener(event, resetTimer);
            });
            resetDate('lastRenew');
            resetDate('lastActive');
            startInactiveTimer();
        });
        onUnmounted(() => {
            events.value.forEach(event => {
                window.removeEventListener(event, resetTimer);
            });
            clearInterval(timer.value);
        });
        const doRenew = () => __awaiter(this, void 0, void 0, function* () {
            resetDate('lastRenew');
            yield axios.get(`${config.value.apiEndPoint}/auth/renew`);
        });
        const doLogout = () => __awaiter(this, void 0, void 0, function* () {
            ctx.emit('trigger-logout');
            yield logoutUser();
            localStorage.removeItem('lastRenew');
            localStorage.removeItem('lastActive');
            store.getters.currentUser.role === Role.TEMPORARY_USER ? (window.location.href = '/readOnlyClosed') : (window.location.href = '/logout');
        });
        const startInactiveTimer = () => __awaiter(this, void 0, void 0, function* () {
            timer.value = setInterval(() => {
                const lastMove = getDate('lastActive');
                const lastRenew = getDate('lastRenew');
                countdownValue.value = config.value.env.logoutIdle - Math.round((Date.now() - lastMove) / 1000);
                // check if needs to be logged out
                if (Date.now() - lastMove > config.value.env.logoutIdle * 1000) {
                    doLogout();
                    return;
                }
                // check if popup needs to come
                if (Date.now() - lastMove > config.value.env.warningIdle * 1000) {
                    warningZone.value = true;
                    return;
                }
                // check if user session should be renewed on backend
                if (lastMove - lastRenew > config.value.env.renewTimer * 1000) {
                    doRenew();
                }
                warningZone.value = false;
            }, 1000);
        });
        const resetTimer = () => __awaiter(this, void 0, void 0, function* () {
            resetDate('lastActive');
            warningZone.value = false;
        });
        const resetDate = (key) => {
            localStorage.setItem(key, Date.now().toString());
        };
        const getDate = (key) => {
            return parseInt(localStorage.getItem(key)) || Date.now();
        };
        return {
            warningZone,
            resetTimer,
            countdownValue,
        };
    },
});
