import { defineComponent } from 'vue';
import Toast from '@/components/general/Toast.vue';
import { useStore } from '@/store';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { mapGetters } from 'vuex';
export default defineComponent({
    name: 'ToastContainer',
    components: {
        Toast,
    },
    setup() {
        const store = useStore();
        const removeToastFromQueue = (index) => {
            store.commit(ToastMutationTypes.REMOVE_TOAST_FROM_QUEUE, index);
        };
        return {
            removeToastFromQueue,
        };
    },
    computed: Object.assign({}, mapGetters(['toasts'])),
});
