export var GeneralMutationTypes;
(function (GeneralMutationTypes) {
    GeneralMutationTypes["SET_SELECTED_WORKSPACE"] = "SET_SELECTED_WORKSPACE";
    GeneralMutationTypes["SET_STATUS_SPINNER_LOGIN"] = "SET_STATUS_SPINNER_LOGIN";
})(GeneralMutationTypes || (GeneralMutationTypes = {}));
export const generalMutations = {
    [GeneralMutationTypes.SET_SELECTED_WORKSPACE](state, workspace) {
        state.selectedWorkspace = workspace;
    },
    [GeneralMutationTypes.SET_STATUS_SPINNER_LOGIN](state, status) {
        state.statusLoginSpinner = status;
    },
};
