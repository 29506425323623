import { __awaiter } from "tslib";
import axios from 'axios';
import { config } from '@/config';
export const getPersonalSpaces = () => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    return (_a = (yield axios.get(`${config.value.apiEndPoint}/users/spaces`))) === null || _a === void 0 ? void 0 : _a.data;
});
export const acceptPrivacyTerms = (userId) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    return (_b = (yield axios.put(`${config.value.apiEndPoint}/users/${userId}/privacy`, {
        privacyPolicyVersion: config.value.privacy.privacyStatementVersion,
        userPolicyVersion: config.value.privacy.termsOfServiceVersion,
    }))) === null || _b === void 0 ? void 0 : _b.data;
});
