import { __awaiter } from "tslib";
import { UserMutationTypes } from '@/store/modules/users/user.mutations';
import { useStore } from '@/store';
import { getFileType } from '@jimber/shared-components';
import { config } from '@/config';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
import { useRouter } from 'vue-router';
export const setLanguage = () => {
    const store = useStore();
    let language = localStorage.getItem('selectedLanguage');
    if (!language) {
        language = config.value.defaultLanguage;
    }
    store.commit(UserMutationTypes.SET_CURRENT_LANGUAGE, language);
};
export const openFolder = (folder, route) => __awaiter(void 0, void 0, void 0, function* () {
    const router = useRouter();
    if (folder.parentUuid) {
        return router.push({
            name: 'SubFolder',
            params: {
                spaceId: route.params.spaceId,
                spaceName: route.params.spaceName,
                subFolderId: folder.folderUuid,
            },
        });
    }
    return router.push({
        name: 'Files',
        params: {
            spaceId: route.params.spaceId,
            spaceName: route.params.spaceName,
        },
    });
});
export const mapSubFolders = (allFolders, parentUuid = null, deepLevel = 0) => {
    const res = [];
    const currentFolders = allFolders.filter(folder => (parentUuid ? folder.parentUuid === parentUuid : !folder.parentUuid));
    currentFolders.forEach(currentFolder => {
        const folderToPush = Object.assign(Object.assign({}, currentFolder), { deepLevel });
        res.push(folderToPush);
        res.push(...mapSubFolders(allFolders, currentFolder.folderUuid, deepLevel + 1));
    });
    return res;
};
export const convertToTEntry = (data) => {
    if (!data) {
        return;
    }
    const isFolder = !!(data === null || data === void 0 ? void 0 : data['folderUuid']);
    const extension = isFolder ? 'dir' : data['extension'];
    const lastAccessed = (data === null || data === void 0 ? void 0 : data['lastAccessed']) || null;
    const fileAmount = (data === null || data === void 0 ? void 0 : data['fileAmount']) || null;
    const createdOn = (data === null || data === void 0 ? void 0 : data['createdOn']) || null;
    const metaData = (data === null || data === void 0 ? void 0 : data['metaData']) || null;
    return {
        id: isFolder ? data['folderUuid'] : data['fileUuid'],
        name: isFolder ? data['name'] : data['filename'],
        fileType: getFileType(extension),
        isArchived: data === null || data === void 0 ? void 0 : data.isArchived,
        size: isFolder ? undefined : data['size'],
        isFolder,
        extension,
        createdOn,
        metaData,
        lastAccessed,
        fileAmount,
    };
};
export const convertToTShareEntry = (data) => {
    if (!data) {
        return;
    }
    const isFolder = !!(data === null || data === void 0 ? void 0 : data['folderUuid']);
    const extension = isFolder ? 'dir' : data['extension'];
    const fileAmount = (data === null || data === void 0 ? void 0 : data['fileAmount']) || null;
    return {
        id: isFolder ? data['folderUuid'] : data['fileUuid'],
        name: isFolder ? data['name'] : data['filename'],
        isArchived: data === null || data === void 0 ? void 0 : data.isArchived,
        size: isFolder ? undefined : data['size'],
        isFolder,
        extension,
        fileAmount,
    };
};
export const downloadFile = (fileDto) => {
    const store = useStore();
    store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
        type: ToastType.INFO,
        message: `willBeDownloaded`,
        data: {
            filename: `${fileDto.filename}`,
        },
    });
    const fileUrl = document.createElement('a');
    fileUrl.href = `${config.value.vaultSpaceApi}/${store.getters.currentUser.id}/spaces/${fileDto.spaceId}/files/${fileDto.fileUuid}`;
    fileUrl.download = `${fileDto.filename}.${fileDto.extension}`;
    fileUrl.click();
};
export const downScaleImage = (image, maxWidth, maxHeight) => __awaiter(void 0, void 0, void 0, function* () {
    const reader = yield new FileReader();
    const img = document.createElement('img');
    yield reader.readAsDataURL(image);
    return new Promise(resolve => {
        reader.onload = function (e) {
            return __awaiter(this, void 0, void 0, function* () {
                img.src = e.target.result;
                //Creating & initialising canvas
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                yield ctx.drawImage(img, 0, 0);
                //Setting dimensions
                const dimensions = calcImageDimensions(img, maxWidth, maxHeight);
                canvas.width = dimensions.width;
                canvas.height = dimensions.height;
                //Drawing downscaled image on canvas
                yield ctx.drawImage(img, 0, 0, dimensions.width, dimensions.height);
                resolve(canvas.toDataURL('image/png'));
            });
        };
    });
});
const calcImageDimensions = (img, maxWidth, maxHeight) => {
    let width = img.width;
    let height = img.height;
    if (width > height) {
        if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
        }
    }
    else {
        if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
        }
    }
    return { width: width, height: height };
};
export const dataURLtoFile = (dataUrl, filename) => __awaiter(void 0, void 0, void 0, function* () {
    let arr = dataUrl.split(','), dataType = arr[0].match(/:(.*?);/)[1], imageData = window.atob(arr[1]), dataLength = imageData.length, u8arr = new Uint8Array(dataLength);
    while (dataLength--) {
        u8arr[dataLength] = imageData.charCodeAt(dataLength);
    }
    return new File([u8arr], filename, { type: dataType });
});
export const isBrowserIsolated = () => {
    var _a;
    if (!((_a = config.value) === null || _a === void 0 ? void 0 : _a.browser.userAgent)) {
        return false;
    }
    const { desktop, mobile } = config.value.browser.userAgent;
    return desktop === navigator.userAgent || mobile === navigator.userAgent;
};
