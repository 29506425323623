import { __awaiter } from "tslib";
import Modal from '@/components/general/Modal.vue';
import Form from '@/components/general/form/Form.vue';
import { useStore } from '@/store';
import { InputType } from '@/types/input/inputDetails';
import { convertToTShareEntry } from '@/utils';
import { computed, onBeforeMount, ref } from 'vue';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
import DatePicker from '@/components/general/datePicker/DatePicker.vue';
import validator from 'validator';
import { shareItem } from '@/services/share.service';
export default {
    components: {
        Form,
        Modal,
        DatePicker,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:show'],
    setup(props, ctx) {
        const store = useStore();
        const currentItems = ref([]);
        const shareExpiryDate = ref(new Date());
        const shareItemForm = computed(() => {
            return {
                name: 'shareItemForm',
                inputs: [
                    {
                        name: `email`,
                        label: 'emailRecipient',
                        type: InputType.EMAIL,
                        validation: {
                            maxLength: 100,
                            required: true,
                            validator: validator.isEmail,
                            lazy: true,
                        },
                        autofocus: true,
                    },
                ],
                submit: 'share',
                cancel: 'cancel',
            };
        });
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            const items = store.getters.workspaceItems.filter(file => store.getters.selectedItemsIds.includes(file['fileUuid'] || file['folderUuid']));
            currentItems.value = items.map(item => convertToTShareEntry(item));
        }));
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        const shareVaultItem = (formData) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (!formData) {
                return;
            }
            const share = {
                items: currentItems.value,
                email: (_a = formData.values) === null || _a === void 0 ? void 0 : _a.email.value,
                expiryDate: shareExpiryDate.value,
            };
            const sharedItem = yield shareItem(store.getters.selectedWorkspace.id, share);
            if (!sharedItem) {
                return;
            }
            store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                type: ToastType.SUCCESS,
                message: `successFullySharedItem`,
            });
            closeModal();
        });
        return {
            shareItemForm,
            shareExpiryDate,
            open,
            closeModal,
            shareVaultItem,
        };
    },
};
