import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, vModelText as _vModelText } from "vue";
const _hoisted_1 = { class: "w-full flex flex-col justify-center" };
const _hoisted_2 = ["for"];
const _hoisted_3 = {
    key: 1,
    class: "fas fa-folder"
};
const _hoisted_4 = { class: "text-primary" };
const _hoisted_5 = { class: "flex flex-row items-center align-middle" };
const _hoisted_6 = { class: "text-primary" };
const _hoisted_7 = { class: "flex flex-row items-center align-middle" };
export function render(_ctx, _cache) {
    const _component_CustomSelect = _resolveComponent("CustomSelect");
    const _component_Form = _resolveComponent("Form");
    const _component_icon_picker = _resolveComponent("icon-picker");
    const _component_modal = _resolveComponent("modal");
    return (_ctx.show)
        ? (_openBlock(), _createBlock(_component_modal, {
            key: 0,
            onClose: _cache[8] || (_cache[8] = ($event) => (_ctx.closeModal()))
        }, {
            header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.categoryToEdit ? _ctx.$t('folder.editCategory') : _ctx.category ? _ctx.$t('folder.createNewCategory') : _ctx.$t('folder.createNewFolder')), 1)
            ]),
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    (!_ctx.categoryToEdit && !_ctx.category)
                        ? (_openBlock(), _createBlock(_component_Form, {
                            key: 0,
                            "form-data": _ctx.form,
                            "onUpdate:form-data": _cache[1] || (_cache[1] = ($event) => ((_ctx.form) = $event)),
                            onSubmit: _ctx.createFolder,
                            onCancel: _ctx.closeModal,
                            loading: _ctx.creatingCat
                        }, {
                            "input-fileFolder": _withCtx(({ input }) => [
                                _withDirectives(_createElementVNode("label", {
                                    class: "text-primary",
                                    for: input.name
                                }, _toDisplayString(_ctx.$t(`forms.labels.${input.label}`)) + " " + _toDisplayString(input.required ? '*' : ''), 9, _hoisted_2), [
                                    [_vShow, input.label]
                                ]),
                                (_ctx.selectedFolder && input.items)
                                    ? (_openBlock(), _createBlock(_component_CustomSelect, {
                                        key: 0,
                                        modelValue: _ctx.selectedFolder,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.selectedFolder) = $event)),
                                        "default-icon": "fas fa-folder",
                                        "object-value": input.objectValue,
                                        translation: input.translation,
                                        inputs: input.items.map(item => item.value)
                                    }, {
                                        icon: _withCtx(({ selected }) => {
                                            var _a, _b;
                                            return [
                                                (_openBlock(), _createElementBlock("div", {
                                                    key: selected.folderUuid
                                                }, [
                                                    (selected.metaData)
                                                        ? (_openBlock(), _createElementBlock("em", {
                                                            key: 0,
                                                            class: _normalizeClass(["fas", `fa-${(_a = selected.metaData) === null || _a === void 0 ? void 0 : _a.icon}`]),
                                                            style: _normalizeStyle(`color: ${(_b = selected.metaData) === null || _b === void 0 ? void 0 : _b.color}`)
                                                        }, null, 6))
                                                        : (_openBlock(), _createElementBlock("em", _hoisted_3))
                                                ]))
                                            ];
                                        }),
                                        _: 2
                                    }, 1032, ["modelValue", "object-value", "translation", "inputs"]))
                                    : _createCommentVNode("", true)
                            ]),
                            _: 1
                        }, 8, ["form-data", "onSubmit", "onCancel", "loading"]))
                        : _createCommentVNode("", true),
                    (_ctx.category && !_ctx.categoryToEdit)
                        ? (_openBlock(), _createBlock(_component_Form, {
                            key: 1,
                            "form-data": _ctx.categoryForm,
                            "onUpdate:form-data": _cache[4] || (_cache[4] = ($event) => ((_ctx.categoryForm) = $event)),
                            onSubmit: _ctx.createCategory,
                            onCancel: _ctx.closeModal,
                            loading: _ctx.creatingCat
                        }, {
                            "custom-append": _withCtx(() => [
                                _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('folder.categoryIconAndColor')), 1),
                                _createElementVNode("div", _hoisted_5, [
                                    _createVNode(_component_icon_picker, {
                                        color: _ctx.selectedColor,
                                        icon: _ctx.selectedIcon,
                                        "onUpdate:icon": _cache[2] || (_cache[2] = ($event) => ((_ctx.selectedIcon) = $event))
                                    }, null, 8, ["color", "icon"]),
                                    (!_ctx.isBrowserIsolated())
                                        ? _withDirectives((_openBlock(), _createElementBlock("input", {
                                            key: 0,
                                            type: "color",
                                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.selectedColor) = $event)),
                                            class: "w-12 h-12 m-1 bg-white cursor-pointer border-gray-300 rounded-md group hover:text-opacity-100 focus:outline-none hover:ring-blue-500 hover:border-blue-500"
                                        }, null, 512)), [
                                            [_vModelText, _ctx.selectedColor]
                                        ])
                                        : _createCommentVNode("", true)
                                ])
                            ]),
                            _: 1
                        }, 8, ["form-data", "onSubmit", "onCancel", "loading"]))
                        : _createCommentVNode("", true),
                    (_ctx.categoryToEdit)
                        ? (_openBlock(), _createBlock(_component_Form, {
                            key: 2,
                            "form-data": _ctx.categoryForm,
                            "onUpdate:form-data": _cache[7] || (_cache[7] = ($event) => ((_ctx.categoryForm) = $event)),
                            onSubmit: _ctx.editCategory,
                            onCancel: _ctx.closeModal,
                            loading: _ctx.creatingCat
                        }, {
                            "custom-append": _withCtx(() => [
                                _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('folder.editCategoryIconAndColor')), 1),
                                _createElementVNode("div", _hoisted_7, [
                                    _createVNode(_component_icon_picker, {
                                        color: _ctx.categoryToEdit.metaData.color,
                                        icon: _ctx.categoryToEdit.metaData.icon,
                                        "onUpdate:icon": _cache[5] || (_cache[5] = ($event) => ((_ctx.categoryToEdit.metaData.icon) = $event))
                                    }, null, 8, ["color", "icon"]),
                                    (!_ctx.isBrowserIsolated())
                                        ? _withDirectives((_openBlock(), _createElementBlock("input", {
                                            key: 0,
                                            type: "color",
                                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((_ctx.categoryToEdit.metaData.color) = $event)),
                                            class: "w-12 h-12 m-1 bg-white cursor-pointer border-gray-300 rounded-md group hover:text-opacity-100 focus:outline-none hover:ring-blue-500 hover:border-blue-500"
                                        }, null, 512)), [
                                            [_vModelText, _ctx.categoryToEdit.metaData.color]
                                        ])
                                        : _createCommentVNode("", true)
                                ])
                            ]),
                            _: 1
                        }, 8, ["form-data", "onSubmit", "onCancel", "loading"]))
                        : _createCommentVNode("", true)
                ])
            ]),
            _: 1
        }))
        : _createCommentVNode("", true);
}
