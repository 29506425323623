import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "flex flex-row text-center justify-center" };
const _hoisted_2 = ["onClick"];
const _hoisted_3 = { class: "text-primary text-sm pt-2" };
export function render(_ctx, _cache) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
            return (_openBlock(), _createElementBlock("div", {
                key: language.longhand,
                class: _normalizeClass(["p-2 mx-2 w-32 border-2 cursor-pointer rounded", _ctx.selectedLanguage === language.shorthand ? 'border-primary' : 'border-gray-200 opacity-60']),
                onClick: ($event) => (_ctx.clickedLang(language.shorthand))
            }, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(language.longhand), 1)
            ], 10, _hoisted_2));
        }), 128))
    ]));
}
