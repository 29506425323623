import { __awaiter } from "tslib";
import axios from 'axios';
import { config } from '@/config';
import { useStore } from '@/store';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
import { replaceIllegalCharacters } from '@/utils/file.utils';
export const exportVaultSpace = (spaceId) => __awaiter(void 0, void 0, void 0, function* () {
    const store = useStore();
    console.log(store.getters.selectedLanguage);
    const url = `${config.value.apiEndPoint}/spaces/${spaceId}/export?language=${store.getters.selectedLanguage}`;
    const fileUrl = document.createElement('a');
    fileUrl.href = url;
    fileUrl.download = `export.csv`;
    fileUrl.click();
});
export const getSpaceFolders = (spaceId) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    return (_a = (yield axios.get(`${config.value.apiEndPoint}/spaces/${spaceId}/folders`))) === null || _a === void 0 ? void 0 : _a.data;
});
export const uploadFile = (spaceId, file, folderId, fileUuid, notes) => __awaiter(void 0, void 0, void 0, function* () {
    var _b, _c;
    const store = useStore();
    const formData = new FormData();
    formData.append('uploadedFile', file);
    formData.append('filename', replaceIllegalCharacters(file.name));
    if (folderId) {
        formData.append('folderId', folderId);
    }
    if (notes) {
        formData.append('notes', notes);
    }
    return (_c = (yield axios.put(`${config.value.vaultSpaceApi}/${(_b = store.getters.currentUser) === null || _b === void 0 ? void 0 : _b.id}/spaces/${spaceId}/files?fileUuid=${fileUuid ? fileUuid : ''}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: function (progressEvent) {
            store.commit(ToastMutationTypes.UPDATE_TOAST_PROGRESSION, {
                type: ToastType.INFO,
                message: `uploadingProgress`,
                data: {
                    name: `${file.name}`,
                    progress: Math.round((progressEvent.loaded / progressEvent.total) * 100),
                },
            });
        }.bind(this),
    }))) === null || _c === void 0 ? void 0 : _c.data;
});
export const getFileByUuid = (fileUuid) => __awaiter(void 0, void 0, void 0, function* () {
    var _d;
    return (_d = (yield axios.get(`${config.value.apiEndPoint}/files/${fileUuid}`))) === null || _d === void 0 ? void 0 : _d.data;
});
export const getTempFileByUuid = (fileUuid) => __awaiter(void 0, void 0, void 0, function* () {
    var _e;
    return (_e = (yield axios.get(`${config.value.apiEndPoint}/files/tempFile/${fileUuid}`))) === null || _e === void 0 ? void 0 : _e.data;
});
export const mount = (userId, spaceId, id, isFile) => __awaiter(void 0, void 0, void 0, function* () {
    var _f;
    const store = useStore();
    return (_f = (yield axios.put(`${config.value.vaultSpaceApi}/${userId}/mounts/${spaceId}`, {
        id: id,
        isFile: isFile,
    }))) === null || _f === void 0 ? void 0 : _f.data;
});
