import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "text-center py-4" };
const _hoisted_2 = { class: "mt-2 text-sm font-medium text-gray-900" };
const _hoisted_3 = { class: "mt-1 text-sm text-gray-500" };
const _hoisted_4 = { class: "mt-6 flex flex-row" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "icon"),
        _createElementVNode("h3", _hoisted_2, [
            _renderSlot(_ctx.$slots, "header")
        ]),
        _createElementVNode("p", _hoisted_3, [
            _renderSlot(_ctx.$slots, "content")
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "actions")
        ], 512), [
            [_vShow, _ctx.action]
        ])
    ]));
}
