import { actions } from './admin.actions';
import { getters } from './admin.getters';
import { mutations } from './admin.mutations';
import { adminState } from './admin.state';
export const adminStore = {
    state: adminState,
    getters,
    mutations,
    actions,
    //namespaced: true,
};
