import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "fixed right-0 mr-5 mt-5 hidden md:flex flex-row items-center justify-center z-10" };
const _hoisted_2 = { class: "mx-3" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LanguageSelector = _resolveComponent("LanguageSelector");
    const _component_SingleVaultSetup = _resolveComponent("SingleVaultSetup");
    const _component_CommonVaultSetup = _resolveComponent("CommonVaultSetup");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_LanguageSelector)
            ])
        ]),
        (_ctx.isSingleVault)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_SingleVaultSetup)
            ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_CommonVaultSetup)
            ]))
    ], 64));
}
