<template>
  <button
    class="inline-flex items-center px-3 py-2 shadow-sm text-sm font-medium cursor-pointer rounded-md"
    :class="{
      'bg-primary text-white': colored && !disabled,
      'bg-white border-gray-300 text-gray-700 border': !colored && !disabled,
      'border-gray-200  border': !colored || disabled,
      'cursor-not-allowed text-gray-200': disabled,
    }"
    :disabled="disabled"
  >
    <slot />
  </button>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'ButtonNew',
    props: {
      colored: Boolean,
      disabled: Boolean,
    },
  });
</script>

<style scoped></style>
