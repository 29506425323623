import { __awaiter } from "tslib";
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import Avatar from '@/components/general/Avatar.vue';
import Card from '@/components/general/Card.vue';
import TopBar from '@/components/Topbar.vue';
import LanguageSelector from '@/components/general/LanguageSelector/LanguageSelector.vue';
import { formatBytes, formatDate, translate } from '@/filters';
import Button from '@/components/general/Button.vue';
import { fileComparer, FileManager, getFileType, getIcon, getIconColor, getName } from '@jimber/shared-components';
import EmptyTableState from '@/components/general/emptyStates/EmptyTableState.vue';
import { getShares } from '@/services/share.service';
import SpinnerModal from '@/components/vault/modals/SpinnerModal.vue';
import IdleComponent from '@/components/general/IdleComponent.vue';
import BreadCrumb from '@/components/general/BreadCrumb.vue';
import OverflowBreadCrumb from '@/components/general/OverflowBreadCrumb.vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';
export default defineComponent({
    name: 'ReadOnlyShare',
    components: {
        Button,
        Avatar,
        Card,
        LanguageSelector,
        FileManager,
        EmptyTableState,
        TopBar,
        SpinnerModal,
        IdleComponent,
        BreadCrumb,
        OverflowBreadCrumb,
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const logoutSpinner = ref(false);
        const temporaryShares = ref([]);
        const headers = computed(() => {
            return [
                {
                    displayName: translate('tables.headers.name'),
                    key: 'name',
                    enableSorting: true,
                    comparer: (entry1, entry2, i) => fileComparer(entry1, entry2, i),
                },
                {
                    displayName: translate('file.size'),
                    key: 'size',
                    enableSorting: true,
                    formatter: entry => {
                        return formatBytes(entry.size, 2);
                    },
                },
                {
                    displayName: translate('share.expiryDate'),
                    key: 'expiryDate',
                    formatter: entry => {
                        return formatDate(new Date(entry.expiryDate * 1000), false);
                    },
                },
            ];
        });
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            temporaryShares.value = yield getShares();
        }));
        const openFileById = (file) => {
            if (file.isArchived) {
                return;
            }
            const routeData = router.resolve({
                name: 'TemporaryDocumentBrowser',
                params: {
                    fileUuid: file.fileId,
                    tempUserId: store.getters.currentUser.id,
                },
            });
            window.open(routeData.href, '_blank');
        };
        return {
            temporaryShares,
            headers,
            logoutSpinner,
            getIcon,
            getIconColor,
            getName,
            getFileType,
            openFileById,
        };
    },
});
