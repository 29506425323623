import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "fixed right-0 top-0 mr-5 mt-5" };
const _hoisted_2 = { class: "h-screen flex flex-col items-center justify-center" };
const _hoisted_3 = ["src"];
const _hoisted_4 = { class: "text-primary font-bold text-xl p-4" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LanguageSelector = _resolveComponent("LanguageSelector");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_LanguageSelector)
        ]),
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
                src: $setup.logoUrl,
                alt: "Logo"
            }, null, 8, _hoisted_3),
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('misc.readOnlyClosed')), 1)
        ])
    ], 64));
}
