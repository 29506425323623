import LanguageSelector from '@/components/general/LanguageSelector/LanguageSelector.vue';
import { config } from '@/config';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@/store';
export default {
    name: 'ErrorScreen',
    components: { LanguageSelector },
    setup() {
        const currentRoute = useRoute();
        const router = useRouter();
        const error = currentRoute.query.type;
        const store = useStore();
        const { isAdmin } = store.getters;
        const goBack = () => {
            if (isAdmin) {
                if (currentRoute.name === 'AdminVaultsOverview') {
                    return;
                }
                return router.push({ name: 'AdminVaultsOverview' });
            }
            return router.push({ name: 'Selection' });
        };
        return {
            error,
            logoUrl: config.value.logo,
            goBack,
        };
    },
};
