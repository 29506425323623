import { computed, defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@/store';
import { config } from '@/config';
import SideMenu from '@/components/general/SideMenu.vue';
import { AccessOperations } from '@/types/authorization';
export default defineComponent({
    name: 'Sidebar',
    components: {
        SideMenu,
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const currentRoute = useRoute();
        const store = useStore();
        const { isAdmin, hasAccessToOperation } = store.getters;
        const goToSelection = () => {
            if (isAdmin) {
                if (currentRoute.name === 'AdminVaultsOverview') {
                    return;
                }
                return router.push({ name: 'AdminVaultsOverview' });
            }
            return router.push({ name: 'Selection' });
        };
        const routes = computed(() => {
            if (isAdmin && route.matched[0].name !== 'Vault') {
                const matchedRoute = router.getRoutes().find(route => route.name === 'Admin');
                // Parse metadata of parent into child
                return matchedRoute.children.map(child => {
                    return Object.assign(Object.assign({}, child), { meta: Object.assign(Object.assign({}, child.meta), matchedRoute.meta) });
                });
            }
            const matchedRoute = router.getRoutes().find(route => route.name === 'Vault');
            return matchedRoute.children.map(child => {
                return Object.assign(Object.assign({}, child), { meta: Object.assign(Object.assign({}, child.meta), matchedRoute.meta) });
            });
        });
        const isHidden = computed(() => route => { var _a; return (_a = route.meta) === null || _a === void 0 ? void 0 : _a.isHidden; });
        return {
            routes,
            isHidden,
            goToSelection,
            isAdmin,
            hasAccessToOperation,
            config,
            AccessOperations,
        };
    },
});
