export var SocketMutationTypes;
(function (SocketMutationTypes) {
    SocketMutationTypes["ADD_SOCKET_NAMESPACE"] = "ADD_SOCKET_NAMESPACE";
})(SocketMutationTypes || (SocketMutationTypes = {}));
export const socketMutations = {
    [SocketMutationTypes.ADD_SOCKET_NAMESPACE](state, namespace) {
        const namespaceSocket = state.namespaces[namespace.name];
        if (namespaceSocket) {
            return;
        }
        state.namespaces[namespace.name] = namespace.socket;
    },
};
