import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "flex flex-col overflow-auto max-h-72"
};
const _hoisted_2 = { class: "text-left capitalize flex items-center" };
const _hoisted_3 = ["title"];
const _hoisted_4 = { class: "text-xs fas fa-crown" };
const _hoisted_5 = ["onClick", "checked", "disabled"];
const _hoisted_6 = ["onClick", "checked", "disabled"];
const _hoisted_7 = {
    key: 1,
    class: "text-center text-gray-600 p-5 text-sm"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Table = _resolveComponent("Table");
    return ($props.permissions && $props.permissions.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_Table, {
                headers: $setup.headers,
                data: $props.permissions
            }, {
                "data-username": _withCtx(({ row }) => [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("span", {
                            class: _normalizeClass(["text-sm truncate w-44", { 'mr-2': row.role === $setup.Role.ADMIN }]),
                            title: row.username
                        }, _toDisplayString(row.username), 11, _hoisted_3),
                        _withDirectives(_createElementVNode("span", _hoisted_4, null, 512), [
                            [_vShow, row.role === $setup.Role.ADMIN]
                        ])
                    ])
                ]),
                "data-canRead": _withCtx(({ row }) => [
                    _createElementVNode("input", {
                        type: "checkbox",
                        onClick: ($event) => ($setup.handleRead(row)),
                        checked: row.canRead,
                        disabled: !$setup.canChangePermissions(row) || row.canWrite || $props.disabled
                    }, null, 8, _hoisted_5)
                ]),
                "data-canWrite": _withCtx(({ row }) => [
                    _createElementVNode("input", {
                        type: "checkbox",
                        onClick: ($event) => ($setup.handleWrite(row)),
                        checked: row.canWrite,
                        disabled: !$setup.canChangePermissions(row) || $props.disabled
                    }, null, 8, _hoisted_6)
                ]),
                _: 1
            }, 8, ["headers", "data"])
        ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('file.noPermissions')), 1));
}
