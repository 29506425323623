import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-681f29c0"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("img", {
        class: _normalizeClass(`rounded-${_ctx.roundSize}`),
        src: _ctx.src,
        alt: ""
    }, null, 10, _hoisted_1));
}
