import { __awaiter } from "tslib";
import { WebdavMutationTypes } from '@/store/modules/webdav/webdav.mutations';
import { mount } from '@/services/vaultSpace.service';
export var WebdavActionTypes;
(function (WebdavActionTypes) {
    WebdavActionTypes["ADD_MOUNT"] = "ADD_MOUNT";
})(WebdavActionTypes || (WebdavActionTypes = {}));
export const webdavActions = {
    [WebdavActionTypes.ADD_MOUNT]({ commit, getters }, id) {
        return __awaiter(this, void 0, void 0, function* () {
            const credentials = yield mount(getters.currentUser.id, getters.selectedWorkspaceId, id, !!getters.workspaceItems.find(file => {
                return 'fileUuid' in file && 'filename' in file && 'extension' in file && file.fileUuid == id;
            }));
            if (credentials)
                commit(WebdavMutationTypes.ADD_MOUNT, credentials);
            return !!credentials;
        });
    },
};
