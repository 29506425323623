export const connectToNamespace = (namespace, options) => {
    try {
        const SocketNamespace = require(`../namespaces/${namespace}/${namespace}.namespace`).default;
        const namespacedSocket = new SocketNamespace(namespace, options);
        console.log(`[SocketIo] initialized ${namespace} namespace`);
        return namespacedSocket.socket;
    }
    catch (e) {
        console.log('could not connect to namespace:', namespace);
    }
};
