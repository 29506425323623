import { __awaiter } from "tslib";
import { config } from '@/config';
import axios from 'axios';
import { useStore } from '@/store';
export const createAccessToken = (fileUuid, isEdit) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const route = isEdit ? 'edit' : 'read';
    return (_a = (yield axios.post(`${config.value.apiEndPoint}/files/${route}/${fileUuid}`))) === null || _a === void 0 ? void 0 : _a.data;
});
export const getFileChecksum = (spaceUuid, fileUuid) => __awaiter(void 0, void 0, void 0, function* () {
    var _b, _c;
    const store = useStore();
    return (_c = (yield axios.get(`${config.value.vaultSpaceApi}/${(_b = store.getters.currentUser) === null || _b === void 0 ? void 0 : _b.id}/documentserver/${spaceUuid}/getFileChecksum/${fileUuid}`))) === null || _c === void 0 ? void 0 : _c.data;
});
export const getFileAccessHistory = (fileUuid, startIndex, limit) => __awaiter(void 0, void 0, void 0, function* () {
    var _d;
    const store = useStore();
    return (_d = (yield axios.get(`${config.value.apiEndPoint}/files/${store.getters.selectedWorkspace.id}/${fileUuid}/history?startIndex=${startIndex}&limit=${limit}`))) === null || _d === void 0 ? void 0 : _d.data;
});
export const renameFile = (spaceId, fileUuid, fileInternalUploadDTO) => __awaiter(void 0, void 0, void 0, function* () {
    var _e;
    return (_e = (yield axios.put(`${config.value.apiEndPoint}/spaces/${spaceId}/files/${fileUuid}/rename`, fileInternalUploadDTO))) === null || _e === void 0 ? void 0 : _e.data;
});
export const globalSearchFiles = (spaceId, filename, admin = false) => __awaiter(void 0, void 0, void 0, function* () {
    var _f, _g;
    if (!filename) {
        return;
    }
    if (!admin) {
        return (_f = (yield axios.get(`${config.value.apiEndPoint}/files/${spaceId}/search?filename=${filename}`))) === null || _f === void 0 ? void 0 : _f.data;
    }
    return (_g = (yield axios.get(`${config.value.apiEndPoint}/admin/${spaceId}/files/search?filename=${filename}`))) === null || _g === void 0 ? void 0 : _g.data;
});
export const globalSearchFilesContent = (spaceId, searchId, content) => __awaiter(void 0, void 0, void 0, function* () {
    var _h;
    if (!content) {
        return;
    }
    const store = useStore();
    return (_h = (yield axios.get(`${config.value.vaultSpaceApi}/${store.getters.currentUser.id}/spaces/${spaceId}/search/${searchId}`, {
        params: {
            search: content,
        },
    }))) === null || _h === void 0 ? void 0 : _h.data;
});
export const cancelContentSearch = (spaceId, searchId) => __awaiter(void 0, void 0, void 0, function* () {
    var _j;
    const store = useStore();
    if (!config.value.env.canSearchInFileContent) {
        return;
    }
    return (_j = (yield axios.delete(`${config.value.vaultSpaceApi}/${store.getters.currentUser.id}/spaces/${spaceId}/search/${searchId}`))) === null || _j === void 0 ? void 0 : _j.data;
});
export const convertFile = (spaceId, fileUuid) => __awaiter(void 0, void 0, void 0, function* () {
    const store = useStore();
    return axios({
        method: 'get',
        url: `${config.value.vaultSpaceApi}/${store.getters.currentUser.id}/spaces/print/${spaceId}/files/${fileUuid}`,
        responseType: 'blob',
    });
});
export const printFile = (selectedWorkspaceId, fileUuid) => __awaiter(void 0, void 0, void 0, function* () {
    const resp = yield convertFile(selectedWorkspaceId, fileUuid);
    var ifrm = document.createElement('iframe');
    ifrm.setAttribute('id', 'iframe');
    ifrm.style.width = '640px';
    ifrm.style.height = '480px';
    document.body.appendChild(ifrm);
    ifrm.addEventListener('load', function () {
        console.log('iframe is ready');
        ifrm.contentWindow.print();
    });
    const urll = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));
    ifrm.setAttribute('src', urll);
});
