import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "w-full flex flex-col justify-center" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Form = _resolveComponent("Form");
    const _component_modal = _resolveComponent("modal");
    const _component_spinner_modal = _resolveComponent("spinner-modal");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _withDirectives(_createVNode(_component_modal, {
            onClose: _cache[1] || (_cache[1] = ($event) => (_ctx.closeModal()))
        }, {
            header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('vault.createNewVault')), 1)
            ]),
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_Form, {
                        "form-data": _ctx.form,
                        "onUpdate:form-data": _cache[0] || (_cache[0] = ($event) => ((_ctx.form) = $event)),
                        onSubmit: _ctx.createWorkspace,
                        onCancel: _ctx.closeModal
                    }, null, 8, ["form-data", "onSubmit", "onCancel"])
                ])
            ]),
            _: 1
        }, 512), [
            [_vShow, _ctx.show]
        ]),
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_spinner_modal, { key: 0 }, {
                message: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('spinner.restartingDocker')), 1)
                ]),
                _: 1
            }))
            : _createCommentVNode("", true)
    ], 64));
}
