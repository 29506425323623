import LanguageSelector from '@/components/general/LanguageSelector/LanguageSelector.vue';
import { config } from '@/config';
export default {
    name: 'ReadOnlyClosedScreen',
    components: { LanguageSelector },
    setup() {
        return {
            logoUrl: config.value.logo,
        };
    },
};
