export var Role;
(function (Role) {
    Role["ADMIN"] = "admin";
    Role["USER"] = "user";
    Role["VAULT_ADMIN"] = "vault admin";
    Role["ADVANCED_VAULT_USER"] = "advanced user";
    Role["BASIC_VAULT_USER"] = "basic user";
    Role["LIMITED_VAULT_USER"] = "limited user";
    Role["TEMPORARY_USER"] = "temporary user";
})(Role || (Role = {}));
export var UserRoles;
(function (UserRoles) {
    UserRoles["ADMIN"] = "admin";
    UserRoles["USER"] = "user";
})(UserRoles || (UserRoles = {}));
export var VaultRole;
(function (VaultRole) {
    VaultRole["VAULT_ADMIN"] = "vault admin";
    VaultRole["ADVANCED_VAULT_USER"] = "advanced user";
    VaultRole["BASIC_VAULT_USER"] = "basic user";
    VaultRole["LIMITED_VAULT_USER"] = "limited user";
    VaultRole["TEMPORARY_USER"] = "temporary user";
})(VaultRole || (VaultRole = {}));
export const vaultPromotionRoles = [VaultRole.LIMITED_VAULT_USER, VaultRole.BASIC_VAULT_USER, VaultRole.ADVANCED_VAULT_USER, VaultRole.VAULT_ADMIN];
export var Language;
(function (Language) {
    Language["NL"] = "nl";
    Language["EN"] = "en";
    Language["FR"] = "fr";
})(Language || (Language = {}));
export var OptionTypes;
(function (OptionTypes) {
    OptionTypes["OPEN"] = "open";
    OptionTypes["EDIT"] = "edit";
    OptionTypes["RENAME"] = "rename";
    OptionTypes["PRINT"] = "print";
    OptionTypes["DOWNLOAD"] = "download";
    OptionTypes["DELETE"] = "delete";
    OptionTypes["RECOVER"] = "restore";
    OptionTypes["FOLDER_CHANGE"] = "folder change";
})(OptionTypes || (OptionTypes = {}));
