<template>
  <svg width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-sideBarIcons stroke-current fill-current">
    <path
      d="M19.6667 13.4444V14.3444H20.5667V13.4444H19.6667ZM7.22222 13.4444H6.32222V14.3444H7.22222V13.4444ZM14.3444 19.6667C14.3444 19.1696 13.9415 18.7667 13.4444 18.7667C12.9474 18.7667 12.5444 19.1696 12.5444 19.6667H14.3444ZM12.5444 22.7778C12.5444 23.2748 12.9474 23.6778 13.4444 23.6778C13.9415 23.6778 14.3444 23.2748 14.3444 22.7778H12.5444ZM4.11111 14.3444H22.7778V12.5444H4.11111V14.3444ZM24.9889 16.5556V25.8889H26.7889V16.5556H24.9889ZM22.7778 28.1H4.11111V29.9H22.7778V28.1ZM1.9 25.8889V16.5556H0.1V25.8889H1.9ZM4.11111 28.1C2.88995 28.1 1.9 27.1101 1.9 25.8889H0.1C0.1 28.1042 1.89584 29.9 4.11111 29.9V28.1ZM24.9889 25.8889C24.9889 27.1101 23.9989 28.1 22.7778 28.1V29.9C24.9931 29.9 26.7889 28.1042 26.7889 25.8889H24.9889ZM22.7778 14.3444C23.9989 14.3444 24.9889 15.3344 24.9889 16.5556H26.7889C26.7889 14.3403 24.9931 12.5444 22.7778 12.5444V14.3444ZM4.11111 12.5444C1.89584 12.5444 0.1 14.3403 0.1 16.5556H1.9C1.9 15.3344 2.88995 14.3444 4.11111 14.3444V12.5444ZM18.7667 7.22222V13.4444H20.5667V7.22222H18.7667ZM19.6667 12.5444H7.22222V14.3444H19.6667V12.5444ZM8.12222 13.4444V7.22222H6.32222V13.4444H8.12222ZM13.4444 1.9C16.3838 1.9 18.7667 4.28284 18.7667 7.22222H20.5667C20.5667 3.28873 17.3779 0.1 13.4444 0.1V1.9ZM13.4444 0.1C9.51095 0.1 6.32222 3.28873 6.32222 7.22222H8.12222C8.12222 4.28284 10.5051 1.9 13.4444 1.9V0.1ZM12.5444 19.6667V22.7778H14.3444V19.6667H12.5444Z"
    />
  </svg>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'VaultPasswordNavigationIcon',
  });
</script>

<style scoped></style>
