import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-12a84ab4"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "w-full flex flex-row justify-center text-center" };
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
            return (_openBlock(), _createElementBlock("div", {
                class: "w-full rounded-md",
                key: tab.name,
                onClick: ($event) => (_ctx.changeTab(tab))
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass([[tab === _ctx.selectedTab ? 'activated-button' : 'other-buttons', tab === _ctx.tabs[0] ? 'rounded-l-md' : '', tab === _ctx.tabs[_ctx.tabs.length - 1] ? 'rounded-r-md' : ''], "font-medium cursor-pointer text-sm p-2"])
                }, _toDisplayString(_ctx.$t(`tabs.${tab}`)), 3)
            ], 8, _hoisted_2));
        }), 128))
    ]));
}
