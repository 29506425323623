import { __awaiter } from "tslib";
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import { useStore } from '@/store';
import Avatar from '@/components/general/Avatar.vue';
import EmptyTableState from '@/components/general/emptyStates/EmptyTableState.vue';
import UserNavigationIcon from '@/components/icons/navigation/UsersNavigationIcon.vue';
import SearchBar from '@/components/vault/SearchBar.vue';
import TabLayout from '@/layout/TabLayout.vue';
import { SortType, Table } from '@jimber/shared-components';
import { formatDate, translate } from '@/filters';
import { Role, vaultPromotionRoles, VaultRole } from '@/types';
import WorkSpaceDropdown from '@/components/WorkSpaceDropDown.vue';
import InvitationSingleVaultModal from '@/components/vault/modals/InvitationSingleVaultModal.vue';
import Button from '@/components/general/Button.vue';
import { config } from '@/config';
import AdminUserVaultOverview from '@/components/admin/AdminUserVaultOverview.vue';
import { PromotionType } from '@/types/vault';
import { getUsersInWorkspace, updateSpaceUserRights } from '@/services/space.service';
import { useRoute } from 'vue-router';
import CategoryPopover from '@/components/general/CategoryMenu.vue';
import { MenuItem } from '@headlessui/vue';
import ConfirmModal from '@/components/vault/modals/ConfirmModal.vue';
import { deleteUserFromSpace } from '@/services/admin.service';
import { AccessOperations, AccessType } from '@/types/authorization';
import { generateVaultRoleExplanation } from '@/utils/role.util';
import Tooltip from '@/components/Tooltip.vue';
var Type;
(function (Type) {
    Type["PROMOTE"] = "user.promote";
    Type["DEMOTE"] = "user.demote";
    Type["EXTEND_ACCESS"] = "user.extendExpiry";
    Type["REVOKE"] = "user.revoke";
})(Type || (Type = {}));
export default defineComponent({
    name: 'Users',
    components: {
        ConfirmModal,
        CategoryPopover,
        SearchBar,
        TabLayout,
        Avatar,
        Table,
        Button,
        MenuItem,
        InvitationSingleVaultModal,
        EmptyTableState,
        WorkSpaceDropdown,
        UserNavigationIcon,
        AdminUserVaultOverview,
        Tooltip,
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const { selectedWorkspace, selectedVaultUsers, isAdmin, currentUser, hasAccessToOperation } = store.getters;
        const showInvitationSingleVaultModal = ref(false);
        const showConfirmation = ref(false);
        const confirmationData = ref(undefined);
        const selectedVaultId = route.params.spaceId;
        const userSearch = ref('');
        const isLoading = ref(true);
        const vaultUsers = ref([]);
        const searchedVaultUsers = ref([]);
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            vaultUsers.value = yield getUsersInWorkspace({
                vaultId: selectedVaultId,
                skip: 0,
                take: 15,
                field: (_a = sortedField.value) === null || _a === void 0 ? void 0 : _a.prop,
                order: (_b = sortedField.value) === null || _b === void 0 ? void 0 : _b.order,
            });
            isLoading.value = false;
        }));
        const sortedField = ref({
            prop: 'lastAccessed',
            order: SortType.DESCENDING,
        });
        const headers = computed(() => [
            {
                key: 'username',
                displayName: translate('tables.headers.username'),
                enableSorting: true,
            },
            {
                key: 'lastAccessed',
                displayName: translate('tables.headers.lastAccessed'),
                enableSorting: true,
                formatter: entry => {
                    if (!entry.lastAccessed) {
                        return translate('tables.headers.neverLoggedIn');
                    }
                    const date = new Date(entry.lastAccessed);
                    return formatDate(date);
                },
            },
            {
                key: 'role',
                displayName: translate('tables.headers.userRoles'),
                enableSorting: true,
                formatter: entry => translate('vault.roles.' + entry.vaultRole),
            },
            {
                key: 'actions',
                displayName: '',
            },
        ]);
        watch(userSearch, (value) => __awaiter(this, void 0, void 0, function* () {
            var _c, _d;
            if (value.trim() === '') {
                return;
            }
            const newSearchedUsers = yield getUsersInWorkspace({
                vaultId: selectedVaultId,
                value,
                skip: 0,
                take: 15,
                field: (_c = sortedField.value) === null || _c === void 0 ? void 0 : _c.prop,
                order: (_d = sortedField.value) === null || _d === void 0 ? void 0 : _d.order,
            });
            if (newSearchedUsers.length <= 0) {
                searchedVaultUsers.value = [];
                return;
            }
            searchedVaultUsers.value = newSearchedUsers;
        }));
        const sortUsers = () => __awaiter(this, void 0, void 0, function* () {
            var _e, _f;
            const newUsers = yield getUsersInWorkspace({
                vaultId: selectedVaultId,
                value: userSearch.value !== '' ? userSearch.value : undefined,
                skip: 0,
                take: 15,
                field: (_e = sortedField.value) === null || _e === void 0 ? void 0 : _e.prop,
                order: (_f = sortedField.value) === null || _f === void 0 ? void 0 : _f.order,
            });
            if (newUsers.length <= 0) {
                return;
            }
            if (userSearch.value !== '') {
                searchedVaultUsers.value = newUsers;
                return newUsers;
            }
            vaultUsers.value = newUsers;
        });
        const getMoreUsers = ({ index }) => __awaiter(this, void 0, void 0, function* () {
            var _g, _h;
            const newUsers = yield getUsersInWorkspace({
                vaultId: selectedVaultId,
                value: userSearch.value !== '' ? userSearch.value : undefined,
                skip: index * 15,
                take: 15,
                field: (_g = sortedField.value) === null || _g === void 0 ? void 0 : _g.prop,
                order: (_h = sortedField.value) === null || _h === void 0 ? void 0 : _h.order,
            });
            if (newUsers.length <= 0) {
                return [];
            }
            if (userSearch.value !== '') {
                searchedVaultUsers.value = searchedVaultUsers.value.concat(newUsers);
                return newUsers;
            }
            vaultUsers.value = vaultUsers.value.concat(newUsers);
            return newUsers;
        });
        const users = computed(() => {
            return userSearch.value.trim() !== '' ? searchedVaultUsers.value : vaultUsers.value;
        });
        const addUser = (user) => {
            const userIndex = vaultUsers.value.findIndex(u => u.id === user.id);
            if (userIndex !== -1) {
                vaultUsers.value[userIndex] = user;
                return;
            }
            vaultUsers.value.push(user);
        };
        const openConfirmation = (data) => {
            confirmationData.value = data;
            showConfirmation.value = true;
        };
        const closeConfirmation = () => {
            confirmationData.value = undefined;
            showConfirmation.value = false;
        };
        const findNewPromotionRole = (oldRole, type) => {
            const roleIndex = vaultPromotionRoles.findIndex(item => item === oldRole);
            if (roleIndex === -1) {
                return;
            }
            if (!config.value.env.isVaultAdminRolePromotionDemotionEnabled && oldRole === VaultRole.VAULT_ADMIN) {
                return;
            }
            if (type === PromotionType.DEMOTE) {
                const newRoleIndex = roleIndex - 1;
                if (newRoleIndex < 0) {
                    return;
                }
                return vaultPromotionRoles[newRoleIndex];
            }
            if (type === PromotionType.PROMOTE) {
                const newRoleIndex = roleIndex + 1;
                if (newRoleIndex > vaultPromotionRoles.length - 1) {
                    return;
                }
                const newRole = vaultPromotionRoles[newRoleIndex];
                if (!config.value.env.isVaultAdminRolePromotionDemotionEnabled && newRole === VaultRole.VAULT_ADMIN) {
                    return;
                }
                return newRole;
            }
        };
        const handleConfirmation = () => __awaiter(this, void 0, void 0, function* () {
            const user = confirmationData.value.item;
            switch (confirmationData.value.type) {
                case Type.DEMOTE:
                case Type.PROMOTE:
                    //case Type.EXTEND_ACCESS:
                    const updatedUser = yield updateSpaceUserRights(selectedVaultId, user.id, {
                        type: confirmationData.value.type === Type.PROMOTE ? PromotionType.PROMOTE : confirmationData.value.type === Type.DEMOTE ? PromotionType.DEMOTE : undefined,
                    });
                    if (!updatedUser) {
                        return;
                    }
                    {
                        const index = users.value.findIndex(u => u.id === user.id);
                        if (index === -1) {
                            return;
                        }
                        vaultUsers.value[index] = Object.assign(Object.assign({}, vaultUsers.value[index]), updatedUser);
                    }
                    break;
                case Type.REVOKE:
                    const response = yield deleteUserFromSpace(selectedVaultId, user.id);
                    if (response.status !== 204) {
                        return;
                    }
                    const index = users.value.findIndex(u => u.id === user.id);
                    if (index === -1) {
                        return;
                    }
                    vaultUsers.value.splice(index, 1);
                    break;
            }
        });
        onBeforeUnmount(() => {
            unwatchRoute();
        });
        const unwatchRoute = watch(route, (newRoute) => __awaiter(this, void 0, void 0, function* () {
            var _j, _k;
            vaultUsers.value = yield getUsersInWorkspace({
                vaultId: store.getters.selectedWorkspaceId,
                skip: 0,
                take: 15,
                field: (_j = sortedField.value) === null || _j === void 0 ? void 0 : _j.prop,
                order: (_k = sortedField.value) === null || _k === void 0 ? void 0 : _k.order,
            });
        }));
        return {
            isAppPreregistrationEnabled: config.value.env.isAppPreregistrationEnabled,
            selectedWorkspace,
            selectedVaultUsers,
            isAdmin,
            currentUser,
            hasAccessToOperation,
            headers,
            userSearch,
            users,
            sortedField,
            isLoading,
            showConfirmation,
            confirmationData,
            showInvitationSingleVaultModal,
            findNewPromotionRole,
            addUser,
            closeConfirmation,
            openConfirmation,
            handleConfirmation,
            getMoreUsers,
            formatDate,
            sortUsers,
            config,
            Role,
            Type,
            AccessType,
            AccessOperations,
            PromotionType,
            generateVaultRoleExplanation,
            translate,
        };
    },
});
