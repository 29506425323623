import { __awaiter } from "tslib";
import { createRouter, createWebHistory } from 'vue-router';
import { useStore } from '@/store';
import { getMe } from '@/services/authentication.service';
import { GeneralActionTypes } from '@/store/modules/general/general.actions';
import { Role } from '@/types';
import AppLayout from '@/layout/AppLayout.vue';
import BaseLayout from '@/layout/BaseLayout.vue';
import Home from '@/views/Home.vue';
import Logout from '@/views/Logout.vue';
import Callback from '@/views/Callback.vue';
import ErrorScreen from '@/views/Error.vue';
import Setup from '@/views/Setup.vue';
import Selection from '@/views/Selection.vue';
import Invitation from '@/views/Invitation.vue';
import ReadOnlyShare from '@/views/readonlyShare/ReadOnlyShare.vue';
import ReadOnlyClosed from '@/views/readonlyShare/ReadOnlyClosed.vue';
import ReadOnlyShareSetup from '@/views/readonlyShare/ReadOnlySetup.vue';
import Users from '@/views/vault/Users.vue';
import DocumentBrowser from '@/views/vault/DocumentBrowser.vue';
import FileManager from '@/views/vault/FileManager.vue';
import VaultPasswordManager from '@/views/vault/VaultPasswordManager.vue';
import PersonalPasswordManager from '@/views/personal/PersonalPasswordManager.vue';
import AdminUsers from '@/views/admin/AdminUsers.vue';
import AdminVaultOverview from '@/views/admin/AdminVaults.vue';
import AdminSettings from '@/components/admin/settings/AdminSettingsTabs.vue';
import GlobalSettings from '@/views/admin/settings/GlobalSettings.vue';
import BrandingSettings from '@/views/admin/settings/BrandingSettings.vue';
import TemporaryDocumentBrowser from '@/views/vault/TemporaryDocumentBrowser.vue';
import { UserMutationTypes } from '@/store/modules/users/user.mutations';
import { AccessType } from '@/types/authorization';
import { config } from '@/config';
import { computed } from 'vue';
const routes = computed(() => [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/callback',
        name: 'Callback',
        component: Callback,
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
    },
    {
        path: '/readOnlyClosed',
        name: 'ReadOnlyClosed',
        component: ReadOnlyClosed,
    },
    {
        path: '/setup',
        name: 'Setup',
        component: Setup,
    },
    {
        path: '/invitation/:invitationId',
        name: 'Invitation',
        component: Invitation,
    },
    {
        path: '/readonly-share',
        name: 'ReadOnlyShare',
        component: BaseLayout,
        children: [
            {
                path: ':share',
                name: 'ReadOnlyShareSetup',
                component: ReadOnlyShareSetup,
            },
            {
                path: 'file/:fileUuid/:tempUserId',
                name: 'TemporaryDocumentBrowser',
                component: TemporaryDocumentBrowser,
                meta: {
                    requiresAuth: true,
                    authorization: [Role.TEMPORARY_USER],
                },
            },
            {
                path: '',
                name: 'ReadOnlyShareDetail',
                component: ReadOnlyShare,
                meta: {
                    requiresAuth: true,
                    authorization: [Role.TEMPORARY_USER],
                },
            },
        ],
    },
    {
        path: '/selection',
        name: 'Selection',
        component: Selection,
        meta: {
            requiresAuth: true,
            authorization: [Role.USER, Role.ADMIN],
        },
    },
    {
        path: '/error',
        name: 'Error',
        component: ErrorScreen,
    },
    {
        path: '/vault/:spaceId/file/:fileUuid',
        name: 'DocumentBrowser',
        component: DocumentBrowser,
        meta: {
            requiresAuth: true,
            authorization: [Role.BASIC_VAULT_USER, Role.LIMITED_VAULT_USER, Role.ADVANCED_VAULT_USER, Role.VAULT_ADMIN],
        },
    },
    {
        path: '/vault/:spaceId?/:spaceName?',
        name: 'Vault',
        component: AppLayout,
        meta: {
            requiresAuth: true,
            showVaultSelection: true,
            authorization: [Role.BASIC_VAULT_USER, Role.LIMITED_VAULT_USER, Role.ADVANCED_VAULT_USER, Role.VAULT_ADMIN],
        },
        children: [
            {
                path: 'files/:selectedFolderId?',
                name: 'Files',
                component: FileManager,
                meta: {
                    requiresAuth: true,
                    name: 'vault.documents',
                    icon: 'fas fa-folder-open',
                },
            },
            {
                path: 'passwords/:selectedRootFolderId?',
                name: 'VaultPasswords',
                component: VaultPasswordManager,
                meta: {
                    requiresAuth: true,
                    name: 'vault.passwords',
                    icon: 'fas fa-lock',
                    isHidden: !config.value.env.isVaultPasswordEnabled,
                },
            },
            {
                path: 'users',
                name: 'Users',
                component: Users,
                meta: {
                    requiresAuth: true,
                    name: 'vault.users',
                    icon: 'fas fa-users',
                    accessType: AccessType.users,
                },
            },
        ],
    },
    {
        path: '/personal',
        name: 'Personal',
        component: AppLayout,
        redirect: '/personal/passwords',
        meta: {
            requiresAuth: true,
            authorization: [Role.USER, Role.ADMIN],
            isHidden: !config.value.env.isPersonalPasswordEnabled,
        },
        children: [
            {
                path: 'passwords/:selectedRootFolderId?',
                name: 'PersonalPasswords',
                component: PersonalPasswordManager,
                meta: {
                    name: 'personal.passwords',
                },
            },
        ],
    },
    {
        path: '/admin',
        name: 'Admin',
        component: AppLayout,
        meta: {
            requiresAuth: true,
            isAdmin: true,
            authorization: [Role.ADMIN],
        },
        children: [
            {
                path: 'vaults',
                name: 'AdminVaultsOverview',
                component: AdminVaultOverview,
                meta: {
                    icon: 'fas fa-newspaper',
                    name: 'admin.vaults',
                },
            },
            {
                path: 'users',
                name: 'AdminUsers',
                component: AdminUsers,
                meta: {
                    name: 'admin.users',
                    icon: 'fas fa-users',
                },
            },
            {
                path: 'settings',
                name: 'Settings',
                component: AdminSettings,
                redirect: '/admin/settings/global',
                meta: {
                    icon: 'fas fa-cog',
                    name: 'admin.settings',
                },
                children: [
                    {
                        path: 'global',
                        name: 'GlobalSettings',
                        component: GlobalSettings,
                    },
                    {
                        path: 'branding',
                        name: 'BrandingSettings',
                        component: BrandingSettings,
                    },
                ],
            },
        ],
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/error?type=page_not_found',
    },
]);
function initRouter() {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise(resolve => {
            const router = createRouter({
                history: createWebHistory(process.env.BASE_URL),
                routes: routes.value,
            });
            router.beforeEach((to, from, next) => __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                const store = useStore();
                const { authorization, requiresAuth, isAdmin } = to.meta;
                if (!requiresAuth) {
                    return next();
                }
                const currentUser = yield getMe((_a = to.params) === null || _a === void 0 ? void 0 : _a.spaceId);
                store.commit(UserMutationTypes.SET_CURRENT_USER, currentUser);
                if (currentUser.role !== Role.TEMPORARY_USER) {
                    localStorage.setItem('redirect', to.path);
                }
                if (!currentUser) {
                    window.location.href = '/signin';
                    return;
                }
                if (((_b = to.params) === null || _b === void 0 ? void 0 : _b.spaceId) && ((_c = store.getters.selectedWorkspace) === null || _c === void 0 ? void 0 : _c.id) !== ((_d = to.params) === null || _d === void 0 ? void 0 : _d.spaceId)) {
                    yield store.dispatch(GeneralActionTypes.SET_SELECTED_WORKSPACE, to.params.spaceId);
                }
                if (isAdmin && store.getters.isAdmin) {
                    return next();
                }
                if (!!((_e = to.meta) === null || _e === void 0 ? void 0 : _e.isHidden)) {
                    return next({
                        name: 'Selection',
                        params: { error: 'You do not have the rights to access this page' },
                    });
                }
                if ((store.getters.selectedWorkspace &&
                    !((_f = authorization) === null || _f === void 0 ? void 0 : _f.includes((_g = store.getters.selectedWorkspace) === null || _g === void 0 ? void 0 : _g.role)) &&
                    to.name !== 'Selection' &&
                    to.name !== 'PersonalPasswords') ||
                    (!((_h = authorization) === null || _h === void 0 ? void 0 : _h.includes(currentUser.role)) && !store.getters.selectedWorkspace)) {
                    if (currentUser.role === Role.TEMPORARY_USER) {
                        window.location.href = '/signin';
                        return;
                    }
                    return next({
                        name: 'Selection',
                        params: { error: 'You do not have the rights to access this page' },
                    });
                }
                return next();
            }));
            resolve(router);
        });
    });
}
export { initRouter };
