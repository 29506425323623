import { openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e7b12ba6"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = { class: "text-xxs font-medium pt-2 text-center" };
const _hoisted_3 = { class: "text-xxs font-medium pt-2 text-center" };
const _hoisted_4 = { class: "text-xxs font-medium pt-2 text-center" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_link = _resolveComponent("router-link");
    const _component_side_menu = _resolveComponent("side-menu");
    return (_openBlock(), _createBlock(_component_side_menu, { class: "flex flex-row items-center w-full md:w-22 md:block mt-1 md:mt-0" }, {
        title: _withCtx(() => [
            (_openBlock(), _createElementBlock("img", {
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.goToSelection && _ctx.goToSelection(...args))),
                class: "cursor-pointer w-14 h-14",
                alt: "logo",
                key: _ctx.config.logo,
                src: _ctx.config.logo
            }, null, 8, _hoisted_1))
        ]),
        default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route) => {
                return _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                    key: route.name,
                    class: _normalizeClass(["bg-white md:bg-transparent flex flex-col items-center justify-center w-full h-20 text-sideBarIcons border-transparent border-t-2 md:border-t-0 md:border-r-2 cursor-pointer px-4 hover:border-primary", [_ctx.$route.matched.some(r => r.name === route.name) ? 'border-sideBarIcons text-sideBarIcons' : 'opacity-50']]),
                    to: {
                        name: route.name,
                        params: _ctx.$route.params,
                    }
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("span", {
                            class: _normalizeClass(["h-6 text-sideBarIcons text-2xl", route.meta.icon])
                        }, null, 2),
                        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t(`navigation.${route.meta.name}`)), 1)
                    ]),
                    _: 2
                }, 1032, ["class", "to"])), [
                    [_vShow,
                        (!route.meta.accessType || (route.meta.accessType && _ctx.hasAccessToOperation({ accessType: route.meta.accessType, operation: _ctx.AccessOperations.read }))) && !_ctx.isHidden(route)
                    ]
                ]);
            }), 128)),
            (!_ctx.isHidden(_ctx.$router.getRoutes().find(route => route.name === 'Personal')))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    class: _normalizeClass(["md:hidden bg-white md:bg-transparent flex flex-col items-center justify-center w-full h-20 text-sideBarIcons border-transparent border-t-2 md:border-t-0 md:border-r-2 cursor-pointer px-4 hover:border-primary", [_ctx.$route.matched[1].name === 'PersonalPasswords' ? 'border-sideBarIcons text-sideBarIcons' : 'opacity-50']]),
                    to: {
                        name: 'PersonalPasswords',
                        params: _ctx.$route.params,
                    }
                }, {
                    default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent("personalPasswordNavigationIcon"), { class: "w-8" })),
                        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(`navigation.personal.passwords`)), 1)
                    ]),
                    _: 1
                }, 8, ["class", "to"]))
                : _createCommentVNode("", true)
        ]),
        append: _withCtx(() => [
            (!_ctx.isHidden(_ctx.$router.getRoutes().find(route => route.name === 'Personal')))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    class: _normalizeClass(["flex bg-white md:bg-transparent flex-col items-center justify-center w-full h-20 text-sideBarIcons border-transparent border-t-2 md:border-t-0 md:border-r-2 cursor-pointer px-4 hover:border-primary", [_ctx.$route.matched[1].name === 'PersonalPasswords' ? 'border-primary text-primary' : 'opacity-50']]),
                    to: {
                        name: 'PersonalPasswords',
                        params: _ctx.$route.params,
                    }
                }, {
                    default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent("personalPasswordNavigationIcon"), { class: "w-8" })),
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(`navigation.personal.passwords`)), 1)
                    ]),
                    _: 1
                }, 8, ["class", "to"]))
                : _createCommentVNode("", true)
        ]),
        _: 1
    }));
}
