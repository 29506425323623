import { __awaiter } from "tslib";
import Modal from '@/components/general/Modal.vue';
import Form from '@/components/general/form/Form.vue';
import { editFolder } from '@/services/folder.service';
import { useStore } from '@/store';
import { FileManagerActionTypes } from '@/store/modules/FileManager/fileManager.actions';
import { InputType } from '@/types/input/inputDetails';
import { convertToTEntry } from '@/utils';
import { computed } from 'vue';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
import { replaceIllegalCharacters } from '@/utils/file.utils';
export default {
    components: {
        Form,
        Modal,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:show'],
    setup(props, ctx) {
        const store = useStore();
        const currentItem = computed(() => convertToTEntry(store.getters.selectedItemDetails));
        const itemType = computed(() => { var _a; return (((_a = store.getters.selectedItemDetails) === null || _a === void 0 ? void 0 : _a['fileUuid']) ? 'file' : 'folder'); });
        const renameItemForm = computed(() => {
            var _a;
            return {
                name: 'itemRenameForm',
                inputs: [
                    {
                        name: `${itemType.value}name`,
                        label: `${itemType.value}name`,
                        type: InputType.TEXT,
                        value: (_a = currentItem.value) === null || _a === void 0 ? void 0 : _a.name,
                        validation: {
                            maxLength: itemType.value === 'file' ? 260 : 50,
                            required: true,
                            regex: '^[^/\\\\?%*:|"<>]+$',
                        },
                        autofocus: true,
                    },
                ],
                submit: 'rename',
                cancel: 'cancel',
            };
        });
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        const renameFile = (formData) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            if (!formData) {
                return;
            }
            // If the user opens the edit name modal, and just pressing OK without changing the name => close modal
            if (currentItem.value.name == ((_b = (_a = formData.values) === null || _a === void 0 ? void 0 : _a.foldername) === null || _b === void 0 ? void 0 : _b.value) || currentItem.value.name == ((_d = (_c = formData.values) === null || _c === void 0 ? void 0 : _c.filename) === null || _d === void 0 ? void 0 : _d.value)) {
                closeModal();
                return;
            }
            if (currentItem.value.isFolder) {
                const legalName = replaceIllegalCharacters(formData.values.foldername.value, '_');
                const changedDetails = { name: legalName };
                const renamedFolder = yield editFolder(store.getters.selectedWorkspace.id, currentItem.value.id, changedDetails);
                if (!renamedFolder) {
                    return;
                }
                store.dispatch(FileManagerActionTypes.UPDATE_FOLDER, renamedFolder);
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.SUCCESS,
                    message: `successFullyRenameFolder`,
                    data: {
                        name: legalName,
                    },
                });
                closeModal();
                return;
            }
            yield store.dispatch(FileManagerActionTypes.UPDATE_ITEM_NAME, replaceIllegalCharacters(formData.values.filename.value));
            closeModal();
        });
        return {
            renameItemForm,
            itemType,
            open,
            closeModal,
            renameFile,
        };
    },
};
