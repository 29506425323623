import { __awaiter } from "tslib";
import axios from 'axios';
import { config } from '@/config';
import { useStore } from '@/store';
import { UserMutationTypes } from '@/store/modules/users/user.mutations';
import { SocketActionTypes } from '@/store/modules/socket/socket.actions';
import { Namespace } from '@/namespaces/namespace.enum';
export const getMe = (spaceId) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const store = useStore();
    const user = (_a = (yield axios.get(`${config.value.apiEndPoint}/auth/me`, {
        params: {
            spaceId,
        },
    }))) === null || _a === void 0 ? void 0 : _a.data;
    if (!user) {
        return;
    }
    store.commit(UserMutationTypes.SET_CURRENT_USER, user);
    yield store.dispatch(SocketActionTypes.CREATE_NAMESPACE_CONNECTION, Namespace.ROOT);
    return user;
});
export const logoutUser = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield axios.get(`${config.value.apiEndPoint}/auth/signout`);
    }
    catch (e) {
        console.error('Failed to logout user');
    }
});
