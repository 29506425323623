import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "max-h-44 overflow-y-auto" };
const _hoisted_2 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Button = _resolveComponent("Button");
    const _component_alert = _resolveComponent("alert");
    return ($props.show)
        ? (_openBlock(), _createBlock(_component_alert, {
            key: 0,
            onClose: _cache[0] || (_cache[0] = ($event) => ($setup.closeModal()))
        }, {
            header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$tc(`file.deleteFile`, $setup.items.length)), 1)
            ]),
            default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$tc('file.confirmationMessage', $setup.items.length)) + " ", 1),
                _createElementVNode("div", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item) => {
                        return (_openBlock(), _createElementBlock("p", {
                            class: "",
                            key: item.id
                        }, [
                            _createTextVNode(_toDisplayString(item.name), 1),
                            (!item.isFolder)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "." + _toDisplayString(item.extension), 1))
                                : _createCommentVNode("", true)
                        ]));
                    }), 128))
                ])
            ]),
            actions: _withCtx(() => [
                _createVNode(_component_Button, {
                    onClick: _withModifiers($setup.closeModal, ["stop"]),
                    class: "capitalize"
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(`forms.buttons.cancel`)), 1)
                    ]),
                    _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_Button, {
                    colored: "",
                    class: "px-2 mr-0 ml-2 capitalize",
                    onClick: _withModifiers($setup.handleDelete, ["stop"])
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(`forms.buttons.remove`)), 1)
                    ]),
                    _: 1
                }, 8, ["onClick"])
            ]),
            _: 1
        }))
        : _createCommentVNode("", true);
}
