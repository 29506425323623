import { convertToTEntry } from '@/utils';
export var FileManagerMutationTypes;
(function (FileManagerMutationTypes) {
    FileManagerMutationTypes["SET_WORKSPACES"] = "SET_WORKSPACES";
    FileManagerMutationTypes["UPDATE_WORKSPACE"] = "UPDATE_WORKSPACE";
    FileManagerMutationTypes["SET_SELECTED_WORKSPACE_ID"] = "SET_SELECTED_WORKSPACE_ID";
    FileManagerMutationTypes["SET_SELECTED_VAULT_USER"] = "SET_SELECTED_VAULT_USER";
    FileManagerMutationTypes["SET_ALL_FILES"] = "SET_ALL_FILES";
    FileManagerMutationTypes["ADD_ITEMS_TO_ALL_ITEMS"] = "ADD_ITEMS_TO_ALL_ITEMS";
    FileManagerMutationTypes["CLEAR_ITEMS"] = "CLEAR_ITEMS";
    FileManagerMutationTypes["UPDATE_FOLDER"] = "UPDATE_FOLDER";
    FileManagerMutationTypes["SET_SELECTED_ITEMS_IDS"] = "SET_SELECTED_ITEMS_IDS";
    FileManagerMutationTypes["REMOVE_ITEMS_FROM_FOLDER"] = "REMOVE_ITEMS_FROM_FOLDER";
    FileManagerMutationTypes["SET_SELECTED_FOLDER_ID"] = "SET_SELECTED_FOLDER_ID";
    FileManagerMutationTypes["SET_SEARCH_RESULTS"] = "SET_SEARCH_RESULTS";
    FileManagerMutationTypes["CLEAR_SEARCH_RESULTS"] = "CLEAR_SEARCH_RESULTS";
    FileManagerMutationTypes["SET_SELECTED_ITEM_DETAILS"] = "SET_SELECTED_ITEM_DETAILS";
    FileManagerMutationTypes["REMOVE_FOLDER_ID"] = "REMOVE_FOLDER_ID";
    FileManagerMutationTypes["SET_CURRENT_SEARCH_ID"] = "SET_CURRENT_SEARCH_ID";
})(FileManagerMutationTypes || (FileManagerMutationTypes = {}));
export const mutations = {
    [FileManagerMutationTypes.SET_WORKSPACES](state, workspaces) {
        state.workspaces = workspaces;
    },
    [FileManagerMutationTypes.UPDATE_WORKSPACE](state, workspace) {
        const spaceIndex = state.workspaces.findIndex(vault => vault.id === workspace.id);
        if (spaceIndex === -1) {
            return;
        }
        state.workspaces[spaceIndex] = workspace;
    },
    [FileManagerMutationTypes.SET_SELECTED_WORKSPACE_ID](state, selectedId) {
        state.selectedWorkspaceId = selectedId;
    },
    [FileManagerMutationTypes.UPDATE_FOLDER](state, newFolder) {
        const folderIndex = state.workspaceItems.findIndex(item => (item === null || item === void 0 ? void 0 : item['folderUuid']) === newFolder.folderUuid);
        if (folderIndex === -1) {
            return;
        }
        state.workspaceItems[folderIndex] = newFolder;
    },
    [FileManagerMutationTypes.SET_ALL_FILES](state, workspaceFolders) {
        state.workspaceItems = workspaceFolders;
    },
    [FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS](state, items) {
        items === null || items === void 0 ? void 0 : items.forEach(item => {
            const foundWorkspaceItem = state.workspaceItems.find(workspaceItem => convertToTEntry(workspaceItem).id === convertToTEntry(item).id);
            if (foundWorkspaceItem) {
                Object.assign(foundWorkspaceItem, item);
                return;
            }
            state.workspaceItems.push(item);
        });
    },
    [FileManagerMutationTypes.CLEAR_ITEMS](state) {
        state.workspaceItems = [];
        state.selectedFolderId = null;
        state.selectedItemDetails = undefined;
        state.selectedItemsIds = [];
        state.searchResults = undefined;
    },
    [FileManagerMutationTypes.SET_SEARCH_RESULTS](state, results) {
        state.searchResults = results;
    },
    [FileManagerMutationTypes.CLEAR_SEARCH_RESULTS](state) {
        state.searchResults = [];
    },
    [FileManagerMutationTypes.SET_SELECTED_ITEMS_IDS](state, fileIds) {
        state.selectedItemsIds = fileIds;
    },
    [FileManagerMutationTypes.REMOVE_ITEMS_FROM_FOLDER](state, removeItems) {
        removeItems.forEach(item => {
            const entry = convertToTEntry(item);
            if (entry.isFolder) {
                const itemIndex = state.workspaceItems.findIndex(i => i.parentUuid === entry.id);
                if (itemIndex === -1) {
                    return;
                }
                state.workspaceItems.splice(itemIndex, 1);
            }
            const itemIndex = state.workspaceItems.findIndex(i => convertToTEntry(i).id === entry.id);
            if (itemIndex === -1) {
                return;
            }
            state.workspaceItems.splice(itemIndex, 1);
        });
    },
    [FileManagerMutationTypes.SET_SELECTED_FOLDER_ID](state, folderId) {
        state.selectedFolderId = folderId;
    },
    [FileManagerMutationTypes.SET_SELECTED_ITEM_DETAILS](state, details) {
        state.selectedItemDetails = details;
    },
    [FileManagerMutationTypes.REMOVE_FOLDER_ID](state, folderUuid) {
        const itemIndex = state.workspaceItems.findIndex(i => {
            return folderUuid === i.folderUuid;
        });
        if (itemIndex === -1) {
            return;
        }
        state.workspaceItems.splice(itemIndex, 1);
        if (state.selectedFolderId === folderUuid) {
            const toBeClassified = state.workspaceItems.find(item => {
                item = item;
                return item.name == 'to_be_classified';
            });
            state.selectedFolderId = toBeClassified.folderUuid;
        }
    },
    [FileManagerMutationTypes.SET_SELECTED_ITEM_DETAILS](state, details) {
        state.selectedItemDetails = details;
    },
    [FileManagerMutationTypes.SET_CURRENT_SEARCH_ID](state, currentSearchId) {
        state.currentSearchId = currentSearchId;
    },
};
