import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex flex-col-reverse md:flex-row h-full" };
const _hoisted_2 = { class: "flex flex-col w-full h-full overflow-hidden" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Navigation = _resolveComponent("Navigation");
    const _component_router_view = _resolveComponent("router-view");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Navigation),
        _createElementVNode("main", _hoisted_2, [
            _createVNode(_component_router_view)
        ])
    ]));
}
