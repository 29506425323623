import { __awaiter } from "tslib";
import axios from 'axios';
import { config } from '@/config';
export const getInvitationDetails = (invitationId) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    return (_a = (yield axios.get(`${config.value.apiEndPoint}/invitation/${invitationId}`))) === null || _a === void 0 ? void 0 : _a.data;
});
export const inviteUser = (data) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    return (_b = (yield axios.post(`${config.value.apiEndPoint}/invitation/user/`, data))) === null || _b === void 0 ? void 0 : _b.data;
});
export const getPublicUrl = () => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    return ((_c = (yield axios.get(`${config.value.apiEndPoint}/invitation/getPublicUrl`))) === null || _c === void 0 ? void 0 : _c.data) + '/api/v1';
});
