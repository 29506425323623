import { __awaiter } from "tslib";
import { useStore } from '@/store';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
import axios from 'axios';
import { useRouter } from 'vue-router';
axios.interceptors.response.use(response => {
    return response;
}, (error) => __awaiter(void 0, void 0, void 0, function* () {
    const store = useStore();
    const errorResponse = error === null || error === void 0 ? void 0 : error.response;
    switch (errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.status) {
        case 502:
            store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                type: ToastType.ERROR,
                message: 'internalServerError',
            });
            break;
        case 401:
            window.location.href = '/signin';
            break;
        case 403:
            const router = useRouter();
            yield router.push({ name: 'Selection' });
            break;
        case 413:
            store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                type: ToastType.ERROR,
                message: 'fileTooBig',
            });
            break;
        default:
            const errorData = errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.data;
            store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                type: ToastType.ERROR,
                message: (errorData === null || errorData === void 0 ? void 0 : errorData.type) ? errorData.type : errorData.message ? errorData.message : errorData,
                data: (errorData === null || errorData === void 0 ? void 0 : errorData.metaData) || null,
            });
            break;
    }
}));
