import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8bdfa0ae"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "overlay top-0 left-0 right-0 bottom-0 fixed flex items-center justify-center z-50"
};
const _hoisted_2 = { class: "modal bg-white p-3" };
const _hoisted_3 = { class: "p-3" };
const _hoisted_4 = { class: "p-3" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_ctx.warningZone)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('session.sessionWillExpire')), 1),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('session.idleBrowser')), 1),
                    (_openBlock(), _createElementBlock("p", { key: _ctx.countdownValue }, _toDisplayString(_ctx.$t('session.timeLeft', {
                        seconds: _ctx.countdownValue > 0 ? _ctx.countdownValue : 0,
                    })), 1))
                ])
            ])
        ]))
        : _createCommentVNode("", true);
}
