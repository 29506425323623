import { defineComponent, onBeforeUnmount } from 'vue';
import { onBeforeMount } from '@vue/runtime-core';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { ExclamationIcon, InformationCircleIcon, CheckCircleIcon } from '@heroicons/vue/outline';
export default defineComponent({
    name: 'Alert',
    components: {
        ExclamationIcon,
        InformationCircleIcon,
        CheckCircleIcon,
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    },
    props: {
        type: {
            type: String,
            default: 'warning',
        },
        sticky: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['close'],
    setup(props, ctx) {
        let escListener = null;
        const close = () => {
            if (props.sticky) {
                return;
            }
            ctx.emit('close');
        };
        onBeforeMount(() => {
            escListener = e => {
                if (e.key !== 'Escape') {
                    return;
                }
                ctx.emit('close');
            };
            document.addEventListener('keyup', escListener);
        });
        onBeforeUnmount(() => {
            document.removeEventListener('keyup', escListener);
        });
        return {
            close,
        };
    },
});
