<template>
  <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-sideBarIcons stroke-current fill-current">
    <path
      d="M6.25 6.28571C6.25 7.68878 5.25106 8.67857 4.2 8.67857C3.14894 8.67857 2.15 7.68878 2.15 6.28571C2.15 4.88264 3.14894 3.89286 4.2 3.89286C5.25106 3.89286 6.25 4.88264 6.25 6.28571ZM25.85 6.28571C25.85 7.68878 24.8511 8.67857 23.8 8.67857C22.7489 8.67857 21.75 7.68878 21.75 6.28571C21.75 4.88264 22.7489 3.89286 23.8 3.89286C24.8511 3.89286 25.85 4.88264 25.85 6.28571ZM24.3544 16.5357C23.9891 14.6628 23.0308 13.0327 21.6853 11.9004C21.9106 11.8019 22.1517 11.75 22.4 11.75H25.2C26.2511 11.75 27.25 12.7398 27.25 14.1429V15.7143C27.25 16.2531 26.8811 16.5357 26.6 16.5357H24.3544ZM18.15 5.5C18.15 8.20932 16.2148 10.25 14 10.25C11.7852 10.25 9.85 8.20932 9.85 5.5C9.85 2.79068 11.7852 0.75 14 0.75C16.2148 0.75 18.15 2.79068 18.15 5.5ZM14 14.1071C15.1477 14.1071 16.2202 13.8102 17.1814 13.3214H17.36C19.6492 13.3214 21.65 15.4358 21.65 18.2286V19.6429C21.65 20.6138 20.9661 21.25 20.3 21.25H7.7C7.03394 21.25 6.35 20.6138 6.35 19.6429V18.2286C6.35 15.4358 8.35082 13.3214 10.64 13.3214H10.8193C11.7826 13.8097 12.8512 14.1071 14 14.1071ZM5.6 11.75C5.84832 11.75 6.08941 11.8019 6.31473 11.9004C4.96895 13.0328 4.01025 14.6631 3.6423 16.5357H1.4C1.11894 16.5357 0.75 16.2531 0.75 15.7143V14.1429C0.75 12.7398 1.74894 11.75 2.8 11.75H5.6Z"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'UserNavigationIcon',
  });
</script>

<style scoped></style>
