export var VaultMutationTypes;
(function (VaultMutationTypes) {
    VaultMutationTypes["SET_SELECTED_VAULT_USERS"] = "SET_SELECTED_VAULT_USERS";
    VaultMutationTypes["UPDATE_VAULT_USER"] = "UPDATE_VAULT_USER";
    VaultMutationTypes["REMOVE_VAULT_USER"] = "REMOVE_VAULT_USER";
})(VaultMutationTypes || (VaultMutationTypes = {}));
export const vaultMutations = {
    [VaultMutationTypes.SET_SELECTED_VAULT_USERS](state, selectedVaultUsers) {
        state.selectedVaultUsers = selectedVaultUsers;
    },
    [VaultMutationTypes.UPDATE_VAULT_USER](state, user) {
        const userIndex = state.selectedVaultUsers.findIndex(vaultUser => vaultUser.id === user.id);
        if (userIndex === -1) {
            state.selectedVaultUsers.push(user);
        }
        state.selectedVaultUsers[userIndex] = user;
    },
    [VaultMutationTypes.REMOVE_VAULT_USER](state, user) {
        const userIndex = state.selectedVaultUsers.findIndex(vaultUser => vaultUser.id === user.id);
        if (userIndex === -1) {
            return;
        }
        state.selectedVaultUsers.splice(userIndex, 1);
    },
};
