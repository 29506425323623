import { userActions } from './user.actions';
import { userGetters } from './user.getters';
import { userMutations } from './user.mutations';
import { userState } from './user.state';
export const userStore = {
    state: userState,
    getters: userGetters,
    mutations: userMutations,
    actions: userActions,
    //namespaced: true,
};
