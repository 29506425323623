import { actions } from './fileManager.actions';
import { getters } from './fileManager.getters';
import { mutations } from './fileManager.mutations';
import { fileManagerState } from './fileManager.state';
export const fileManagerStore = {
    state: fileManagerState,
    getters,
    mutations,
    actions,
    //namespaced: true,
};
