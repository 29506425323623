import Alert from '@/components/general/Alert.vue';
export default {
    name: 'RemoveCategoryModal',
    components: {
        Alert,
    },
    props: {
        modelValue: Object,
        show: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:show'],
    setup(props, ctx) {
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        const handleDelete = () => {
            ctx.emit('remove-item', props.modelValue);
            closeModal();
        };
        return {
            closeModal,
            handleDelete,
        };
    },
};
