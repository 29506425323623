import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue";
const _hoisted_1 = { class: "fixed right-0 mr-5 mt-5 hidden xl:flex flex-row items-center justify-center z-10" };
const _hoisted_2 = { class: "mx-3" };
const _hoisted_3 = { class: "h-screen flex flex-col items-center justify-center" };
const _hoisted_4 = { class: "text-primary font-bold text-xl" };
const _hoisted_5 = { class: "p-4" };
const _hoisted_6 = { class: "pt-2" };
const _hoisted_7 = ["href"];
export function render(_ctx, _cache) {
    const _component_LanguageSelector = _resolveComponent("LanguageSelector");
    const _component_avatar = _resolveComponent("avatar");
    const _component_card = _resolveComponent("card");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_LanguageSelector)
            ])
        ]),
        _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_card, {
                width: "32",
                heigth: "32",
                class: "container flex flex-row flex-wrap"
            }, _createSlots({
                header: _withCtx(() => [
                    _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('invite.details')), 1)
                ]),
                _: 2
            }, [
                (!_ctx.invitation)
                    ? {
                        name: "default",
                        fn: _withCtx(() => [
                            _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("div", null, _toDisplayString(_ctx.$t('invite.inviteCouldNotBeFound')), 1),
                                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('invite.contactInfo')), 1),
                                _createElementVNode("div", null, [
                                    _createElementVNode("a", {
                                        href: 'mailto:' + _ctx.contactEmail
                                    }, _toDisplayString(_ctx.contactEmail), 9, _hoisted_7)
                                ])
                            ])
                        ])
                    }
                    : {
                        name: "default",
                        fn: _withCtx(() => [
                            (_ctx.invitation.status === _ctx.InvitationStatus.PENDING)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    (_ctx.invitation.type === 'workspace')
                                        ? (_openBlock(), _createBlock(_component_avatar, {
                                            key: 0,
                                            class: "w-24 h-24",
                                            "round-size": "full",
                                            src: `https://picsum.photos/seed/${_ctx.invitation.workspace.id}/300/300`
                                        }, null, 8, ["src"]))
                                        : _createCommentVNode("", true),
                                    _createElementVNode("div", { class: "px-4 py-2" }, [
                                        _createElementVNode("div", { class: "flex flex-row" }, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('invite.singleVault.receivedInvite', { companyName: _ctx.companyName })) + " ", 1),
                                            _createElementVNode("br"),
                                            _createElementVNode("br"),
                                            _createTextVNode(" " + _toDisplayString(_ctx.$t('invite.receivedInviteExpiringDate', {
                                                expireDate: _ctx.formatDate(_ctx.invitation.expirationDate),
                                            })) + " ", 1),
                                            _createElementVNode("br"),
                                            _createElementVNode("br"),
                                            _createTextVNode(" " + _toDisplayString(_ctx.$t('invite.grabSeedPhrase')), 1)
                                        ])
                                    ]),
                                    _createElementVNode("div", { class: "flex flex-row justify-center pb-4 pt-4" }, [
                                        _createElementVNode("button", {
                                            colored: "",
                                            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.goToInviteLogin())),
                                            class: "capitalize bg-primary text-white"
                                        }, _toDisplayString(_ctx.$t('invite.singleVault.enterVault')), 1)
                                    ])
                                ], 64))
                                : (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    class: "p-4"
                                }, _toDisplayString(_ctx.$t('invite.inviteHasAlreadyBeen', {
                                    status: _ctx.$t(`invite.statuses.${_ctx.invitation.status.toLowerCase()}`),
                                })), 1))
                        ])
                    }
            ]), 1024)
        ])
    ], 64));
}
