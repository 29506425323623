import { __awaiter } from "tslib";
import Button from '@/components/general/Button.vue';
import { computed, defineComponent, ref } from 'vue';
import { InputEvent, InputType } from '@/types/input/inputDetails';
import FormTextArea from '@/components/general/form/FormTextArea.vue';
import FormText from '@/components/general/form/FormText.vue';
import FormSelect from '@/components/general/form/FormSelect.vue';
import FormFile from '@/components/general/form/FormFile.vue';
export default defineComponent({
    name: 'Form',
    components: {
        FormFile,
        FormText,
        FormTextArea,
        FormSelect,
        Button,
    },
    props: {
        formData: Object,
        loading: Boolean,
    },
    emits: ['update:formData', 'submit', 'cancel', 'error', 'input'],
    setup({ formData }, ctx) {
        const formErrors = ref({});
        const isFormValid = ref(true);
        const disableButton = ref(false);
        const formValues = computed(() => {
            const values = {};
            formData.inputs.forEach(inputDetail => {
                var _a;
                values[inputDetail.name] = {
                    value: ((_a = values[inputDetail.name]) === null || _a === void 0 ? void 0 : _a.value) || inputDetail.value,
                    valid: true,
                    errors: [],
                };
            });
            return values;
        });
        const disable = (disabled) => {
            disableButton.value = disabled;
        };
        const updateFormField = (inputData) => __awaiter(this, void 0, void 0, function* () {
            formErrors.value[inputData.name] = {};
            const inputDetails = formData.inputs.find(field => field.name === inputData.name);
            const validation = yield validateField(inputDetails === null || inputDetails === void 0 ? void 0 : inputDetails.validation, inputData);
            formValues.value[inputData.name] = Object.assign(Object.assign({}, inputData.data), validation);
            formErrors.value[inputData.name] = validation;
            const inputIndex = formData.inputs.findIndex(input => input.name === inputData.name);
            formData.inputs[inputIndex].value = inputData.data.value;
            ctx.emit('update:formData', formData);
            ctx.emit('input', formValues.value);
        });
        const submitForm = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            isFormValid.value = true;
            for (const fieldKey of Object.keys(formValues.value)) {
                const field = formValues.value[fieldKey];
                const fieldValidation = (_a = formData.inputs.find(input => input.name === fieldKey)) === null || _a === void 0 ? void 0 : _a.validation;
                if (!fieldValidation) {
                    continue;
                }
                formErrors.value[fieldKey] = yield validateField(fieldValidation, {
                    name: fieldKey,
                    event: InputEvent.SUBMIT,
                    data: {
                        value: field.value,
                    },
                });
                if (!formErrors.value[fieldKey].valid) {
                    isFormValid.value = false;
                }
            }
            if (!isFormValid.value) {
                return;
            }
            ctx.emit('submit', {
                values: formValues.value,
                errors: formErrors.value,
                isValid: isFormValid.value,
            });
        });
        const addErrorMessage = (fieldKey, errorMessage) => {
            formErrors.value[fieldKey].valid = false;
            formErrors.value[fieldKey].errors.push({ message: errorMessage });
        };
        const validateField = (validation, input) => __awaiter(this, void 0, void 0, function* () {
            var _b, _c, _d;
            let valid = true;
            const errors = [];
            const { data, event } = input;
            if (!validation && event !== InputEvent.SUBMIT) {
                return {
                    valid,
                    errors,
                };
            }
            const { minLength, maxLength, required, lazy } = validation;
            if (required && (!data.value || ((_b = data.value) === null || _b === void 0 ? void 0 : _b.length) <= 0)) {
                errors.push({
                    message: 'fieldRequired',
                });
                valid = false;
            }
            if (data.value && ((_c = data.value) === null || _c === void 0 ? void 0 : _c.length) > maxLength) {
                errors.push({
                    message: 'lengthToHigh',
                });
                valid = false;
            }
            if ((minLength && !data.value) || ((_d = data.value) === null || _d === void 0 ? void 0 : _d.length) < minLength) {
                errors.push({
                    message: 'lengthToSmall',
                });
                valid = false;
            }
            if ((validation.required && !data.value) || (validation.validator && !(yield validation.validator(data.value)))) {
                errors.push({
                    message: `invalid${input.name.charAt(0).toUpperCase() + input.name.slice(1)}`,
                });
                valid = false;
            }
            if (required && validation.regex) {
                const reg = new RegExp(validation.regex);
                const validRegex = reg.test(data.value);
                if (!validRegex) {
                    errors.push({
                        message: `${input.name}Mismatch`,
                    });
                    valid = false;
                }
            }
            if (lazy && event === InputEvent.BLUR) {
                return {
                    valid,
                };
            }
            return {
                valid,
                errors,
            };
        });
        const cancelForm = () => {
            ctx.emit('cancel');
        };
        return {
            submitForm,
            cancelForm,
            updateFormField,
            disable,
            addErrorMessage,
            disableButton,
            formValues,
            formErrors,
            isFormValid,
            InputType,
        };
    },
});
