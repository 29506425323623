import { __awaiter } from "tslib";
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { getTempFileByUuid } from '@/services/vaultSpace.service';
import { dockerFromUser } from '@/services/url.service';
import { getFileChecksum } from '@/services/file.service';
import editableFileExtensions from '@/constants/editableFileExtensions.json';
import { config } from '@/config';
import Button from '@/components/general/Button.vue';
import { downloadFile } from '@/utils';
import IdleComponent from '@/components/general/IdleComponent.vue';
import { getTemporaryUserById } from '@/services/share.service';
export default defineComponent({
    name: 'TemporaryDocumentBrowser',
    components: { Button, IdleComponent },
    setup() {
        const { params } = useRoute();
        const extension = ref('');
        const idleTimer = ref(null);
        onMounted(() => {
            renderTemporaryDocumentBrowser();
        });
        const isEditableInDocumentServer = ref(true);
        const imageUrl = ref(null);
        const downloadEidFile = () => __awaiter(this, void 0, void 0, function* () {
            const selectedFile = yield getTempFileByUuid(params.fileUuid.toString());
            downloadFile(selectedFile);
        });
        const renderTemporaryDocumentBrowser = () => __awaiter(this, void 0, void 0, function* () {
            const user = yield getTemporaryUserById(params.tempUserId);
            const selectedFile = yield getTempFileByUuid(params.fileUuid.toString());
            const fileChecksum = yield getFileChecksum(selectedFile.spaceId, selectedFile.fileUuid);
            const dockerName = dockerFromUser(user.id);
            if (!selectedFile) {
                return;
            }
            extension.value = selectedFile.extension;
            isEditableInDocumentServer.value = editableFileExtensions.includes(extension.value);
            if (isEditableInDocumentServer.value) {
                const config = {
                    events: {
                        onAppReady: function () {
                            const iframe = document.getElementsByName('frameEditor')[0];
                            if (!iframe.contentWindow)
                                return;
                            iframe.contentWindow.onkeyup = function (e) {
                                idleTimer.value.resetTimer();
                            };
                            iframe.contentWindow.onclick = function (e) {
                                idleTimer.value.resetTimer();
                            };
                            iframe.contentWindow.onwheel = function (e) {
                                idleTimer.value.resetTimer();
                            };
                        },
                    },
                    document: {
                        fileType: extension.value,
                        key: fileChecksum + selectedFile.fileUuid,
                        //IF a document is readonly, the extension is not added to the name when downloading
                        title: extension.value !== 'pdf' ? selectedFile.filename : selectedFile.filename + '.' + selectedFile.extension,
                        url: `http://${dockerName}/user/api/v1/${user.id}/documentserver/tempFile/${selectedFile.spaceId}/files/${selectedFile.fileUuid}/${user.id}`,
                        permissions: {
                            comment: false,
                            copy: false,
                            download: false,
                            edit: false,
                            print: false,
                            rename: false,
                        },
                    },
                    height: '100%',
                    width: '100%',
                    editorConfig: {
                        callbackUrl: `http://${dockerName}/user/api/v1/${user.id}/documentserver/callback/${selectedFile.spaceId}/${selectedFile.fileUuid}`,
                        user: {
                            id: user.id,
                            name: user.email,
                        },
                        mode: 'view',
                        customization: {
                            chat: false,
                        },
                    },
                };
                //@ts-ignore
                new window.DocsAPI.DocEditor('placeholder', config);
            }
            else {
                imageUrl.value = `${config.value.vaultSpaceApi}/${user.id}/documentserver/tempFile/${selectedFile.spaceId}/files/${selectedFile.fileUuid}/${user.id}`;
            }
        });
        return {
            isEditableInDocumentServer,
            imageUrl,
            extension,
            idleTimer,
            downloadEidFile,
        };
    },
});
