import { __awaiter } from "tslib";
import { computed, defineComponent, ref, onBeforeMount, watch } from 'vue';
import Avatar from '@/components/general/Avatar.vue';
import Input from '@/components/general/Input.vue';
import SearchBar from '@/components/vault/SearchBar.vue';
import { formatDate } from '@/filters';
import { translate } from '@/filters';
import { SortType, Table } from '@jimber/shared-components';
import { mapGetters } from 'vuex';
import Button from '@/components/general/Button.vue';
import RenewVaultAccessModal from '@/components/vault/modals/RenewVaultAccessModal.vue';
import { getUsersInWorkspace } from '@/services/space.service';
import EmptyTableState from '@/components/general/emptyStates/EmptyTableState.vue';
import UserNavigationIcon from '@/components/icons/navigation/UsersNavigationIcon.vue';
export default defineComponent({
    name: 'AdminUserVaultOverview',
    props: {
        modelValue: Object,
        workspaceDetails: Object,
    },
    components: {
        UserNavigationIcon,
        RenewVaultAccessModal,
        Button,
        Input,
        SearchBar,
        Avatar,
        Table,
        EmptyTableState,
    },
    emits: ['update:modelValue', 'select-user-to-delete'],
    setup({ workspaceDetails }, ctx) {
        const userSearch = ref('');
        const usersToRemove = ref({});
        const isLoading = ref(true);
        const vaultUsers = ref([]);
        const searchedVaultUsers = ref([]);
        const sortedField = ref({
            prop: 'lastAccessed',
            order: SortType.DESCENDING,
        });
        const renewUser = ref();
        const showRenewAccess = ref(false);
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            vaultUsers.value = yield getUsersInWorkspace({
                vaultId: workspaceDetails.id,
                skip: 0,
                take: 15,
                field: (_a = sortedField.value) === null || _a === void 0 ? void 0 : _a.prop,
                order: (_b = sortedField.value) === null || _b === void 0 ? void 0 : _b.order,
            });
            isLoading.value = false;
        }));
        const headers = computed(() => {
            return [
                { key: 'checkbox', displayName: '' },
                {
                    key: 'username',
                    displayName: translate('tables.headers.username'),
                    enableSorting: true,
                },
                {
                    key: 'lastAccessed',
                    displayName: translate('tables.headers.lastSignedIn'),
                    enableSorting: true,
                    formatter: entry => {
                        if (!entry.lastAccessed) {
                            return translate('tables.headers.neverLoggedIn');
                        }
                        const date = new Date(entry.lastAccessed);
                        return formatDate(date);
                    },
                },
                {
                    key: 'expiryDate',
                    displayName: translate('tables.headers.validUntil'),
                    enableSorting: true,
                },
            ];
        });
        const addUserToList = user => {
            ctx.emit('select-user-to-delete', user);
        };
        watch(userSearch, (value) => __awaiter(this, void 0, void 0, function* () {
            var _c, _d;
            if (value === '') {
                return;
            }
            const newSearchedUsers = yield getUsersInWorkspace({
                vaultId: workspaceDetails.id,
                value,
                skip: 0,
                take: 15,
                field: (_c = sortedField.value) === null || _c === void 0 ? void 0 : _c.prop,
                order: (_d = sortedField.value) === null || _d === void 0 ? void 0 : _d.order,
            });
            if (newSearchedUsers.length <= 0) {
                searchedVaultUsers.value = [];
                return;
            }
            searchedVaultUsers.value = newSearchedUsers;
        }));
        const sortUsers = () => __awaiter(this, void 0, void 0, function* () {
            var _e, _f;
            const newUsers = yield getUsersInWorkspace({
                vaultId: workspaceDetails.id,
                value: userSearch.value !== '' ? userSearch.value : undefined,
                skip: 0,
                take: 15,
                field: (_e = sortedField.value) === null || _e === void 0 ? void 0 : _e.prop,
                order: (_f = sortedField.value) === null || _f === void 0 ? void 0 : _f.order,
            });
            if (newUsers.length <= 0) {
                return;
            }
            if (userSearch.value !== '') {
                searchedVaultUsers.value = newUsers;
                return newUsers;
            }
            vaultUsers.value = newUsers;
        });
        const getMoreUsers = ({ index }) => __awaiter(this, void 0, void 0, function* () {
            var _g, _h;
            const newUsers = yield getUsersInWorkspace({
                vaultId: workspaceDetails.id,
                value: userSearch.value !== '' ? userSearch.value : undefined,
                skip: index * 15,
                take: 15,
                field: (_g = sortedField.value) === null || _g === void 0 ? void 0 : _g.prop,
                order: (_h = sortedField.value) === null || _h === void 0 ? void 0 : _h.order,
            });
            if (newUsers.length <= 0) {
                return [];
            }
            if (userSearch.value !== '') {
                searchedVaultUsers.value = searchedVaultUsers.value.concat(newUsers);
                return newUsers;
            }
            vaultUsers.value = vaultUsers.value.concat(newUsers);
            return newUsers;
        });
        const hasSpaceAccess = computed(() => Boolean(workspaceDetails.hasSpaceAccess));
        const users = computed(() => {
            const u = userSearch.value !== '' ? searchedVaultUsers.value : vaultUsers.value;
            ctx.emit('update:modelValue', u);
            return u;
        });
        const showRenewAccessForUser = (user) => {
            renewUser.value = user;
            showRenewAccess.value = true;
        };
        const updateRenewedUser = (renewedUser) => {
            const renewedUserIndex = vaultUsers.value.findIndex(u => u.id === renewedUser.id);
            if (renewedUserIndex === -1) {
                return;
            }
            vaultUsers.value[renewedUserIndex] = Object.assign(Object.assign({}, vaultUsers.value[renewedUserIndex]), { expiryDate: renewedUser.expiryDate });
        };
        return {
            users,
            headers,
            userSearch,
            usersToRemove,
            showRenewAccess,
            renewUser,
            sortedField,
            isLoading,
            hasSpaceAccess,
            sortUsers,
            getMoreUsers,
            updateRenewedUser,
            showRenewAccessForUser,
            formatDate,
            addUserToList,
        };
    },
    computed: Object.assign({}, mapGetters(['currentUser'])),
});
