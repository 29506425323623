export const getters = {
    workspaces: state => state.workspaces,
    selectedWorkspaceId: state => state.selectedWorkspaceId,
    workspaceItems: state => state.workspaceItems,
    getItemsByParentId: state => (folderUuid) => {
        return state.workspaceItems.filter(item => (item === null || item === void 0 ? void 0 : item.parentUuid) === folderUuid);
    },
    selectedItemsIds: state => state.selectedItemsIds,
    selectedItemDetails: state => state.selectedItemDetails,
    selectedFolderId: state => state.selectedFolderId,
    searchResults: state => state.searchResults,
    currentSearchId: state => state.currentSearchId,
};
