import { __awaiter, __rest } from "tslib";
import DetailsSidebar from '@/components/vault/sidebars/DetailsSidebar.vue';
import { computed, onBeforeMount, ref, watch } from 'vue';
import PermissionComponent from '@/components/vault/sidebars/PermissionComponent.vue';
import Dropdown from '@/components/general/Dropdown.vue';
import { getFoldersAndSubFolders, updateFileDetails } from '@/services/admin.service';
import { ToastType } from '@/types/toast/toast.enum';
import { formatBytes, formatDate, translate } from '@/filters';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { useStore } from '@/store';
import DataTable from '@/components/general/DataTable.vue';
import { getFileAccessHistory } from '@/services/file.service';
import Button from '@/components/general/Button.vue';
import fileIcons from '@/constants/fileIcons.json';
import { config } from '@/config';
import { mapSubFolders } from '@/utils';
import CustomSelect from '@/components/general/CustomSelect.vue';
import { useRoute } from 'vue-router';
import { getFileType, getIcon } from '@jimber/shared-components';
import { Role } from '@/types';
import { cloneDeep, isEqual } from 'lodash';
import ConfirmModal from '@/components/vault/modals/ConfirmModal.vue';
import { mapGetters } from 'vuex';
import { AccessOperations, AccessType } from '@/types/authorization';
import { FileManagerMutationTypes } from '@/store/modules/FileManager/fileManager.mutations';
import { getFileByUuid } from '@/services/vaultSpace.service';
export default {
    name: 'AdminFileDetails',
    props: {
        fileDetails: {
            type: Object,
            default: null,
        },
    },
    components: {
        ConfirmModal,
        Button,
        Dropdown,
        DetailsSidebar,
        PermissionComponent,
        DataTable,
        CustomSelect,
    },
    emits: ['preview-item', 'write-item', 'download-item', 'delete-item', 'mount-item', 'save-detail', 'collapse-sidebar', 'save-item', 'click-outside', 'share-item'],
    setup({ fileDetails }, ctx) {
        const store = useStore();
        const route = useRoute();
        const selectedFile = ref(Object.assign({ note: '' }, cloneDeep(fileDetails)));
        const filePermissions = ref(null);
        const accessHistory = ref(fileDetails.accessHistory ? fileDetails.accessHistory.sort((x, y) => (x.accessedOn < y.accessedOn ? 1 : -1)) : []);
        const hasMoreDisabled = ref(false);
        const allFolders = ref([]);
        const selectedFolder = ref(undefined);
        const unsavedChanges = ref(false);
        const unsavedChangesModal = ref(false);
        const isCollapsingSidebar = ref(false);
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            const currentFile = yield getFileByUuid(selectedFile.value.fileUuid);
            filePermissions.value = store.getters.currentUser.vaultRole === Role.VAULT_ADMIN ? { canRead: true, canWrite: true } : currentFile.permission;
            if (!store.getters.hasAccessToOperation({ accessType: AccessType.folders, operation: AccessOperations.move })) {
                return;
            }
            const foldersToMap = yield getFoldersAndSubFolders(route.params.spaceId);
            allFolders.value = mapSubFolders(foldersToMap);
            selectedFolder.value = allFolders.value.find(folder => folder.folderUuid === fileDetails.parentUuid);
        }));
        const workspaceFolders = computed(() => allFolders.value.filter(folder => !folder.isArchived));
        watch(() => fileDetails, f => {
            selectedFolder.value = workspaceFolders.value.find(folder => folder.folderUuid === f.parentUuid);
            selectedFile.value = f;
        });
        watch(() => selectedFile, file => {
            unsavedChanges.value = verifyFileChanges(file.value);
        }, {
            deep: true,
        });
        watch(() => selectedFolder, () => {
            unsavedChanges.value = verifyFileChanges(selectedFile.value);
        }, {
            deep: true,
        });
        const addMore = () => __awaiter(this, void 0, void 0, function* () {
            if (hasMoreDisabled.value) {
                return;
            }
            const nextHistory = yield getFileAccessHistory(fileDetails.fileUuid, accessHistory.value.length, 5);
            if (nextHistory.length <= 0 || nextHistory.length < 5) {
                hasMoreDisabled.value = true;
                return;
            }
            accessHistory.value = accessHistory.value.concat(nextHistory);
        });
        const verifyFileChanges = (file) => {
            var _a;
            const clone = original => {
                return ((_a) => {
                    var { accessHistory, lastAccessed, lastModified, size, spaceId } = _a, o = __rest(_a, ["accessHistory", "lastAccessed", "lastModified", "size", "spaceId"]);
                    return o;
                })(original);
            };
            const fileClone = clone(file);
            const fileDetailsClone = clone(fileDetails);
            return !isEqual(fileClone, fileDetailsClone) || file.parentUuid !== ((_a = selectedFolder.value) === null || _a === void 0 ? void 0 : _a.folderUuid);
        };
        const abandonChanges = () => {
            if (isCollapsingSidebar.value) {
                ctx.emit('collapse-sidebar');
                isCollapsingSidebar.value = false;
            }
            selectedFile.value.note = fileDetails === null || fileDetails === void 0 ? void 0 : fileDetails.note;
            selectedFolder.value.folderUuid = fileDetails === null || fileDetails === void 0 ? void 0 : fileDetails.parentUuid;
            selectedFolder.value.name = fileDetails === null || fileDetails === void 0 ? void 0 : fileDetails.folderName;
            unsavedChanges.value = false;
        };
        const collapseSidebar = () => {
            if (unsavedChanges.value && store.getters.hasAccessToOperation({ accessType: AccessType.files, operation: AccessOperations.edit })) {
                isCollapsingSidebar.value = true;
                unsavedChangesModal.value = true;
                return;
            }
            isCollapsingSidebar.value = false;
            ctx.emit('collapse-sidebar');
        };
        const recoverFiles = () => __awaiter(this, void 0, void 0, function* () {
            const toBeClassified = store.getters.getItemsByParentId(null).find(item => (item['name'] = 'to_be_classified'));
            const fileUploadDTO = {
                oldFolderId: selectedFile.value.fileUuid,
                newFolderId: toBeClassified.folderUuid,
            };
            const updatedItemDetails = yield updateFileDetails(route.params.spaceId, selectedFile.value.fileUuid, fileUploadDTO);
            if (updatedItemDetails) {
                store.commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, updatedItemDetails);
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.SUCCESS,
                    message: `successFullyRecovered`,
                    data: {
                        name: selectedFile.value.filename,
                    },
                });
            }
        });
        const saveDetails = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (!selectedFile.value) {
                return;
            }
            let newFolderId = (_a = allFolders.value.find(folder => { var _a; return folder.folderUuid == ((_a = selectedFolder.value) === null || _a === void 0 ? void 0 : _a.folderUuid); })) === null || _a === void 0 ? void 0 : _a.folderUuid;
            if (!newFolderId) {
                newFolderId = selectedFile.value.parentUuid;
            }
            let userPermissions = undefined;
            if (store.getters.isVaultAdmin) {
                userPermissions = {};
                selectedFile.value.permissions.forEach(permission => {
                    // Not really how it should be fixed
                    if (permission.role === Role.ADMIN) {
                        return;
                    }
                    userPermissions[permission.id] = {
                        username: permission.username,
                        role: permission.role,
                        canRead: permission.canRead,
                        canWrite: permission.canWrite,
                    };
                });
            }
            const fileUpdateDTO = {
                oldFolderId: fileDetails.parentUuid,
                newFolderId: newFolderId,
                notes: selectedFile.value.note,
                permissions: userPermissions,
            };
            if (selectedFile.value.parentUuid !== newFolderId) {
                const isAllowed = isMoveFileAllowed(selectedFile.value, newFolderId);
                if (!isAllowed) {
                    return store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                        type: ToastType.ERROR,
                        message: 'duplicateFile',
                    });
                }
            }
            const updatedItemDetails = yield updateFileDetails(route.params.spaceId, selectedFile.value.fileUuid, fileUpdateDTO);
            if (!updatedItemDetails) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: 'failedToChange',
                });
                return;
            }
            store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                type: ToastType.SUCCESS,
                message: 'savedChanges',
            });
            unsavedChanges.value = false;
            ctx.emit('save-item');
        });
        const getFileIcon = computed(() => {
            return getIcon(getFileType(selectedFile.value.extension));
        });
        const isMoveFileAllowed = (file, newFolder) => { var _a; return !((_a = store.getters.workspaceItems.filter(file => file.parentUuid === newFolder)) === null || _a === void 0 ? void 0 : _a.find(item => (item === null || item === void 0 ? void 0 : item['filename']) === file.filename)); };
        return {
            workspaceFolders,
            selectedFolder,
            selectedFile,
            fileIcons,
            getFileIcon,
            unsavedChanges,
            unsavedChangesModal,
            accessHistory,
            hasMoreDisabled,
            filePermissions,
            config,
            AccessType,
            AccessOperations,
            collapseSidebar,
            formatDate,
            formatBytes,
            translate,
            saveDetails,
            addMore,
            abandonChanges,
            recoverFiles,
        };
    },
    computed: Object.assign({}, mapGetters(['isVaultAdmin', 'hasAccessToOperation'])),
};
