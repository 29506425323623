import { VaultRole } from '@/types';
const en = {
    selection: {
        whereDoYouWantToGo: 'Where do you want to go?',
        selectVault: 'Select your @:vault.vault',
        yourVaults: 'Your @:vault.vaults',
        brandOnly: `{ companyName } only section`,
    },
    user: {
        user: 'user',
        users: 'users',
        hasNoUsers: 'Has no users',
        hasNoUserFor: 'No users found for: {search}.',
        hasNoLoginHistory: 'has no login history.',
        noUserFoundFor: 'No user found for: ',
        name: 'Full name',
        removeUser: 'Remove @:user.user',
        removeUserConfirm: 'Are you sure that you want to remove {username}?',
        editUser: 'Edit @:user.user',
        userAdd: 'Select the users you want to add:',
        noUsersToAdd: 'There are no users available to add to this vault.',
        renewAccess: 'Renew user vault access',
        username: 'Username',
        email: 'Email',
        noUserSelected: 'Select a user to view its details',
        sureToDelete: 'Are you sure you want to delete the following users from vault "{spaceName}"?',
        demote: {
            demote: 'Demote to',
            confirmation: 'Demote vault user',
            confirmationMessage: 'Are you sure that you want to demote this user?',
        },
        promote: {
            promote: 'Promote to',
            confirmation: 'Promote vault user',
            confirmationMessage: 'Are you sure that you want to promote this user?',
        },
        revoke: {
            revoke: 'Revoke Access',
            confirmation: 'Revoke vault user',
            confirmationMessage: 'Are you sure you want to revoke access for this user?',
        },
    },
    listHeader: {
        vaultName: 'Name',
        userName: 'Name',
        vaultUserSpaces: 'Vaults',
        invitedAt: 'Invited at',
        userRoles: 'Role',
        vaultLastAccess: 'Last access',
        vaultActions: 'Actions',
        userActions: 'Actions',
    },
    role: {
        role: 'role',
        vaultAdmin: 'vault admin',
        vaultUser: 'vault user',
    },
    file: {
        fileManagement: 'File management',
        file: 'file',
        files: 'files',
        fileListing: 'No @:file.files | 1 @:file.file | {count} @:file.files',
        accessHistory: 'Access history',
        noAccessHistory: 'No access history available',
        noPermissions: 'There are no users yet in this vault to give permissions to.',
        allowedUsers: 'Permissions',
        created: 'Created',
        size: 'Size',
        lastAccessed: 'Last accessed',
        lastModified: 'Last modified',
        removeFile: 'Remove @:file.file',
        removeFileConfirm: 'Are you sure that you want to remove {filename}?',
        createNewFile: 'Create new @:file.file',
        newFile: 'New @:file.file',
        uploadFile: 'Upload @:file.files',
        duplicateFiles: 'Duplicate files detected',
        uploadFolder: 'Upload a @:folder.folder',
        selectAFile: 'Select a @:file.file',
        totalFiles: 'Total @:file.files',
        toBeClassified: '@:folder.to_be_classified',
        notes: 'Notes',
        deleted: 'Deleted',
        noNotes: 'This file has no notes',
        rename: 'Rename @:file.file',
        removeFilesConfirm: 'Are you sure that you want to remove the following files:',
        noFileSelected: 'Please select a file to view its details',
        wantToDownloadFIle: 'Do you want to download the file?',
        fileOverride: 'This file will be overwritten',
        deleteFile: 'Delete item | Delete items',
        dropToUpload: 'Drop @:file.file to upload.',
        confirmationMessage: 'Are you sure that you want to delete this item? | Are you sure that you want to delete these items?',
        unsavedChanges: 'Unsaved changes detected',
        unsavedChangesMessage: 'You have unsaved changes, Are you sure you want to continue?',
    },
    filePermissions: {
        readPermissions: 'Read',
        writePermissions: 'Write',
    },
    filter: {
        search: 'Search',
        fileOrFolderNotFound: 'No files or folder match the filter.',
        noFilesFound: 'There are no files to display.',
        foldersNotFound: 'No documents or folders found.',
        addItemsMessage: 'Get started by adding a new file or folder.',
        usersNotFound: 'No users found.',
        userInviteMessage: 'Get started by inviting an user.',
        vaultsNotFound: 'No vaults found.',
        vaultCreateMessage: 'Get started by creating a vault.',
        noLoginHistoryFound: 'No login history found for user.',
        filter: 'Filter',
        filterBy: 'Filter by',
        sort: 'Sort',
        sortBy: 'Sort by',
        filterAndSort: '@:filter.filter and @:filter.sort',
        equal: 'Equals',
        isEqualTo: 'Is equal to',
        contains: 'Contains',
        start: 'Starts with',
        end: 'Ends with',
        name: 'Name',
        extension: 'Extension',
        size: 'Size',
        createdOn: 'Created on',
        lastModified: 'Last modified',
        lastAccessed: 'Last accessed',
        asc: 'A>Z',
        desc: 'Z>A',
        filterValue: 'filter',
    },
    vault: {
        vaultName: '@:vault.vault name',
        vaults: 'vaults',
        vault: 'vault',
        hasNoVaultsFor: 'has no @:vault.vaults for: {search}',
        hasNoVaults: 'has no @:vault.vaults',
        userHasNoVaults: "You don't have any @:vault.vaults yet",
        createNewVault: 'Create new @:vault.vault',
        addUserToVault: 'Add users',
        vaultHasNoNotes: 'Add a description',
        editVault: 'Edit vault',
        noVaultSelected: 'Select a vault to view its details',
        renewAccess: 'Renew access',
        noLimit: 'No limit',
        restarting: 'Your vault is restarting',
        revokeAdmins: 'Revoke the existing admins from vault',
        permissions: {
            fullAccess: 'Read and write access',
            readAccess: 'Readonly access',
            noAccess: 'No access',
        },
        roles: {
            [VaultRole.LIMITED_VAULT_USER]: 'limited @:user.user',
            [VaultRole.BASIC_VAULT_USER]: 'basic @:user.user',
            [VaultRole.ADVANCED_VAULT_USER]: 'advanced @:user.user',
            [VaultRole.VAULT_ADMIN]: 'admin',
        },
    },
    folder: {
        folder: 'folder',
        createNewFolder: 'New @:folder.folder',
        editFolder: 'Edit @:folder.folder',
        editCategory: 'Edit category',
        createNewCategory: 'New category',
        categoryIconAndColor: 'Select an icon and colour for the new category',
        editCategoryIconAndColor: 'Select an icon and colour for the category to edit',
        foldername: '@:folder.folder name',
        all_files: 'All files',
        to_be_classified: 'To be classified',
        archived: 'Archived',
        default_folder_select: 'Select a new folder',
        personal_data: 'Personal data',
        legal_entities: 'Legal entities',
        taxation: 'Taxation',
        estate_planning: 'Estate planning',
        financial_institutions: 'Investments and portfolios',
        real_estate: 'Real estate',
        private_equity: 'Private Equity/Debt',
        credits_debts: 'Credits/debts',
        life_pension_insurance: 'Life- and pension insurance',
        other_investments: 'Other investments',
        miscellaneous: 'Miscellaneous',
        confirmation: 'Delete {name}',
        confirmationMessage: 'Are you sure that you want to delete this folder?',
        categoryConfirmationMessage: 'Are you sure that you want to delete this category?',
        rename: 'Rename Folder',
        notEmpty: 'Folder not empty',
        categoryNotEmpty: 'Category not empty',
        moveToClassified: "Do you want to move these files to the 'To be classified' category?",
        showMoreFolders: 'Show more folders',
        showLessFolders: 'Show less folders',
    },
    invite: {
        invite: 'Invite',
        newInvite: 'Invite a user',
        details: 'Invitation details',
        writeDownSeedPhrase: 'Please write this seed phrase down on the provided paper.',
        controlWords: 'Please fill in the words corresponding to the following numbers.',
        inviteMailNotification: 'Fill in the information of the person you want to invite.',
        inviteDuration: 'This person will receive an invite link that is valid for the following {duration} days.',
        addAdmin: 'Add admin',
        addUser: 'Add user',
        receivedInvite: `You have been invited to join the vault. After agreeing to the Terms Of Service and the Privacy Policy, you will receive a QR code to scan with the { companyName } app on your smartphone. This will complete the registration.`,
        receivedInviteExpiringDate: 'The invite expires on {expireDate}.',
        inviteCouldNotBeFound: 'The invite could not be found or is already claimed, please check if you are using the correct invitation url.',
        inviteHasAlreadyBeen: 'The invite is {status}.',
        sureToAdd: 'Are you sure you want to add :',
        seedPhraseWarning: 'The seed phrase was regenerated since you clicked the "previous" button. Please WRITE DOWN the seed phrase.',
        userInfo: 'User info',
        selectUsername: 'Select username',
        confirmSeedphrase: 'Confirm seedphrase',
        statuses: {
            pending: 'pending',
            accepted: 'accepted',
            expired: 'expired',
            rejected: 'rejected',
            canceled: 'canceled',
        },
        extend: 'Extend',
        add: 'Add user',
        contactInfo: 'If needed, please contact',
        singleVault: {
            enterVault: 'Enter the vault',
            receivedInvite: `You have been invited to join the vault. If you proceed you will have to login via the { companyName } app on your smartphone. This will complete the registration and you gain access to the vault.`,
        },
        grabSeedPhrase: 'You will need your 24-word seed phrase. Make sure you have this ready for the next steps.',
    },
    session: {
        sessionWillExpire: 'Your session will expire soon ...',
        idleBrowser: 'You have left this browser idle for too long.',
        timeLeft: 'In {seconds} seconds you will be redirected to the login page.',
    },
    error: {
        uploadingProgress: 'Status: {status}',
        title: {
            spaceExpiryReminder: 'You have access till { expiryDate }!',
            uploadingProgress: 'Uploading { name }',
        },
        userAlreadyInSpace: 'This user is already in this vault.',
        successfullyRenewedExpiryDate: 'The vault access for { username } is successful extended.',
        FailedToRenewExpiryDate: 'The vault access for { username } could not be extended.',
        spaceExpiryReminder: "After this you won't have access to this vault anymore!",
        internalServerError: 'Something went wrong',
        fileNotFound: 'File not found',
        noFileFoundFor: 'No file found for: {filename}.',
        noFileInFolder: 'There are no files in folder: {folderName}.',
        noFilesInTotal: 'There are no files yet.',
        email_not_valid: 'It seems like your email address is not verified yet.',
        folderDoesNotExist: 'The folder does not exist.',
        page_not_found: 'The following page does not exist.',
        ip_blocked: 'Your IP has been blocked for 24 hours since you have too many failed login attempts.',
        login_failed: 'Failed to login.',
        login_cancelled: 'The user cancelled the login.',
        authentication_failed: 'Failed to log in, please try again.',
        invalid_user: 'Your user could not be found on the platform, request a vault invitation via your vault admin.',
        something_went_wrong: 'Something went wrong',
        failedToCloseVault: 'Failed to close the vault',
        dnsLookupFailed: 'Failed to reach the vault',
        unAuthorized: 'You are not authorized',
        folderAlreadyExist: 'Folder already exists',
        folderAlreadyDeleted: 'Folder is already deleted',
        fileAlreadyDeleted: '{name} has already been deleted.',
        folderIsDeleted: 'Folder is deleted',
        fileDoesNotExist: 'The requested @:file.file does not exist',
        uploadingFailed: 'Failed to upload {name}',
        fileMissing: 'The @:file.file is missing',
        invalidFileFormat: 'Invalid file format',
        fileMissingData: 'The @:file.file data is missing',
        spaceDoesNotExist: 'The requested @:vault.vault does not exist',
        noAccessToSpace: 'You do not have access to this @:vault.vault',
        userDoesNotExist: 'User does not exist',
        invitationNotFound: 'Could not find the invitation',
        invalidEmailAddress: 'Not a valid email address',
        invalidEmailFillIn: 'You have to fill in a valid email address',
        invalidEmailForInvite: 'Your email does not match the invitation email',
        invalidInvitationStatus: 'Invalid invitation type',
        invalidInvitationType: 'Invalid invitation type',
        successfullySendInvite: 'Successfully sent an invite to {user}',
        invitationFailed: 'The invitation request has failed',
        invitationAccept: 'You have {status} the invitation',
        alreadyInSpace: 'You are already in this @:vault.vault',
        alreadyVaultAdmin: 'You are already @:role.vaultAdmin',
        invalidFolderName: 'You have to fill in a valid folder name',
        successFullyCreated: 'Successfully created {name}',
        successFullyDeleted: 'Successfully deleted {name}',
        successFullyRecovered: 'Successfully recovered {name}',
        successFullyRename: 'Successfully renamed file to {name}',
        successFullyRenameFolder: 'Successfully renamed folder to {name}',
        successFullySharedItem: 'Shared successfully',
        successFullyEditedSettings: 'Successfully edited settings',
        successFullyCreatedCategory: 'Successfully created category {name}',
        successFullyEditedCategory: 'Category has been updated successfully.',
        failedToDelete: 'Failed to delete {name}',
        failedToCreate: 'Failed to create {name}',
        failedToChange: 'Failed to save changes',
        failedToRenameFile: 'Failed to rename {name}',
        cannotDeleteYourself: 'You cannot delete yourself from the @:vault.vault .',
        cannotRemoveFromSpace: 'You cannot delete this user from this @:vault.vault .',
        savedChanges: 'Your changes have been saved',
        folderNameTooLong: 'The given folder name is too long',
        successFullyUpdated: 'The vault has been updated successfully',
        phraseAlreadyClaimed: 'This invite is already in use',
        invalidEmail: 'The given email is invalid.',
        invalidSite: 'The given website is invalid.',
        lengthToHigh: 'Exceeds the max amount of characters.',
        lengthToSmall: "The field doesn't satisfy the minimum amount of characters.",
        cantSave: 'The given information is not correct.',
        fieldRequired: 'This field is required.',
        duplicateFileName: 'A file with this name already exists in this folder.',
        userSuccessFullyUpdated: 'The user has been updated successfully',
        failedToEdit: 'Failed to edit user.',
        forbidden: 'Forbidden',
        roleNotSupported: 'The given role is not supported',
        wordInSeedIncorrect: 'The given words do not match',
        invalidSeedPhrase: 'The given seedphrase is not correct',
        userRecoverySent: 'The user has been sent a mail to recover the account',
        filenameTooLong: 'The uploaded filename is too long',
        notEditableSupportedFileExtension: '{ extension } extension is not supported to edit.',
        fileTooBig: 'The uploaded file is too big.',
        failedToCreateUser: 'The combination of the given email, first name and last name is not unique.',
        emailAlreadyTaken: 'The given email address is already taken.',
        usernameAlreadyExists: 'The given username already exists in the authenticator app.',
        usernameMismatch: 'Only letters and numbers are allowed characters',
        notSupportedFileExtension: '{ extension } @:file.files are not supported.',
        fileTypeNotSupported: '{ extension } type @:file.files are not supported.',
        fileTypeUnknown: 'Unknown file types are not supported.',
        copiedToClipboard: 'Text was copied to your clipboard',
        willBeDownloaded: 'File { filename } will be downloaded',
        fileCannotBeOpened: 'The file cannot be opened',
        spaceNameAlreadyExists: 'The given vault name already exists',
        pleasePickCategory: 'Please pick a category',
        cannotUploadFolder: 'You are not allowed to upload folders',
        uploadSuccessfully: '{name} has been uploaded successfully',
        spaceNameIsEmpty: 'Please enter a valid vault name',
        moveSuccessfully: 'The selected item has been successfully moved to {name}',
        cannotDeleteFolder: "You cannot delete '{name}'",
        cannotMoveDeletedFolder: 'You cannot move a deleted folder',
        moveMultipleSuccessfully: 'The selected items are successfully moved to {name}',
        moveFailed: '{sourceName} could not be moved to {destinationName}',
        moveArchivedFailed: 'Cannot move files out of archived',
        moveMultipleFailed: 'Items could not be moved to {destinationName}',
        duplicateFolder: 'There is already a folder with the same name in this folder.',
        duplicateFile: 'There is already a file with the same name in this folder.',
        multipleFolderUpload: 'You can only upload 1 folder on its own.',
        invalidFileType: 'Invalid filetype.',
        fileTooBigError: 'File too big.',
        duplicateFileError: 'Duplicate file.',
        itemNameAlreadyExist: 'The given name is already in use',
        FailEditSettings: 'Something went wrong when editing your settings.',
        acceptTerms: 'Make sure to accept the Terms Of Service and Privacy Policy in order to continue.',
        successfullyInvited: 'User {name} is successfully invited',
        parentIsAlreadyMounted: 'There is already a parent folder mounted.',
        duplicateUserVault: "Invitation couldn't be accepted since there is already a user with this authenticator in the vault.",
        filenameMismatch: 'The given filename contains invalid characters.',
        foldernameMismatch: 'The given foldername contains invalid characters.',
        categoryNameMismatch: 'The given category name contains invalid characters.',
    },
    misc: {
        movingItem: 'Moving item',
        secureDocuments: 'secure documents',
        cancel: 'Cancel',
        previous: 'previous',
        registered: 'Invited at',
        logout: 'logout',
        readOnlyClosed: 'The read only environment has closed. Check your e-mail for a new link.',
        youHaveBeenLoggedOut: 'You have been logged out',
        selection: 'Back to selection',
        goBack: 'Go back',
        history: 'history',
        firstname: 'first name',
        lastname: 'last name',
        notes: 'notes',
        open: 'open',
        ok: 'ok',
        next: 'next',
        accept: 'accept',
        seeDetails: 'see details',
        redirect: 'Redirecting to the vault',
        noFilesFound: 'There are no files found ',
        imagePreview: 'Image preview',
        warning: 'Warning',
        actions: {
            open: 'Open',
            edit: 'Edit',
            print: 'Print',
            download: 'Download',
            delete: 'Delete',
            move: 'Move',
            SHARE: 'Share',
            rename: 'Rename',
            recover: 'Recover',
            save: 'Save',
            share: 'Share',
            viewInvitation: 'View invitation',
            uploadFile: 'Upload files',
            preview: 'Preview',
            info: 'Info',
            createFolder: 'Create folder',
            close: 'Close',
            logout: 'Logout',
            home: 'Go to home',
            switchVault: 'Switch from vault',
            pickLanguage: 'Choose your language',
            invite: 'Invite',
            typeText: 'Type your text here',
            copyToClipboard: 'Copy to clipboard',
            copied: 'Copied',
        },
        neverAccessed: 'Not opened yet',
        neverLoggedIn: 'Not logged in yet',
        neverModified: 'Not Modified yet',
        givePermission: 'I want to give the following people full access to the vault and make them able to see and manage this vault.',
        showQR: 'Show QR-code',
        hideQR: 'Hide QR-code',
        toLogin: 'To login',
        showCategories: 'Show categories',
        hideCategories: 'Hide categories',
        brandAdmin: `{ companyName } Admin`,
        new: 'New',
        readAndAccept: 'I have read and agree to the',
    },
    tables: {
        headers: {
            ip: 'IP address',
            status: 'status',
            date: 'date',
            geolocation: 'location',
            accessedOn: 'Accessed on',
            type: 'Action',
            user: 'User',
            name: 'Name',
            rights: 'Rights',
            lastSignedIn: 'Last accessed',
            lastAccessed: 'Last accessed',
            validUntil: 'Valid until',
            vaults: 'Vaults',
            userRoles: 'Role',
            totalFiles: 'Total files',
            toBeClassified: '@:folder.to_be_classified',
            username: '@:user.username',
            email: '@:user.email',
            neverLoggedIn: '@:misc.neverLoggedIn',
            neverAccessed: '@:misc.neverAccessed',
            deleted: '@:file.deleted',
            add: 'Select',
            actions: 'Actions',
            filePermissions: 'File Permissions',
            timeLimit: 'Time limit',
            canRead: 'Read',
            canWrite: 'Write',
            size: 'Size',
            createdOn: 'Created on',
        },
        loadingState: {
            vaultLoading: 'Retrieving vaults',
            userLoading: 'Retrieving users',
            folderLoading: 'Retrieving folders',
        },
        actions: {
            EDIT: 'Edit',
            OVERWRITE: 'Overwritten',
            READ: 'Opened',
            MOVED: 'Moved',
            RENAME: 'Renamed',
            DETAILS: 'Changed Details',
            DELETED: 'Deleted',
        },
        succeed: 'succeed',
        failed: 'failed',
        more: 'Load more',
        login: {
            success: 'Logged in',
            failed: 'Failed login attempt',
        },
    },
    spinner: {
        logout: 'Logging you out securely',
        restartingDocker: 'Setting up the @:vault.vault',
    },
    settings: {
        globalTitle: 'Global Settings',
        globalSubtitle: 'Change your global settings here',
        brandingTitle: 'Branding Settings',
        brandingSubtitle: 'Change brand related stuff here',
        companyName: 'Company Name',
        DefaultLanguage: 'Default Language',
        DefaultLanguageSubtitle: 'This is the default language across all devices.',
        logoSubtitle: 'This is the standard logo that will be shown throughout the application.',
        faviconSubtitle: 'This is the icon that shows in your browser tab.',
    },
    forms: {
        labels: {
            notes: 'Notes',
            vaultName: 'Vault name',
            companyName: 'Company name',
            email: 'Email',
            firstname: 'First name',
            lastname: 'Last name',
            vaultFolderName: 'Folder',
            role: 'Role',
            seedPhrase: 'SeedPhrase',
            file: 'Files',
            note: 'Note',
            folder: 'Folder',
            language: 'Language',
            username: 'Username',
            newFolderName: 'Name of the new folder',
            newCategoryName: 'Name of the new category',
            editCategoryName: 'Name of the category to edit',
            newFileName: 'Name of the new @:file.file',
            newFolderCategory: 'Category to put the new folder in',
            newFileCategory: 'Category to put new @:file.file in',
            emailRecipient: 'Email recipient',
            filename: 'File name',
            foldername: 'Folder name',
            fileType: 'File type',
            shareUntil: 'Share until',
            passwordName: 'Name',
            websiteUrl: 'Website',
            password: 'Password',
            category: 'Category',
            nameIdentifier: 'Username/Email',
        },
        buttons: {
            edit: 'Edit',
            submit: 'Submit',
            confirm: 'Confirm',
            create: 'Create',
            previous: 'Previous',
            cancel: 'Cancel',
            next: 'Next',
            share: 'Share',
            recover: 'Recover',
            back: 'Back',
            uploadFile: 'Upload',
            save: 'Save',
            rename: 'Rename',
            remove: 'Remove',
            ignore: 'Ignore',
            overwrite: 'Overwrite',
            invite: 'invite',
        },
        messages: {
            accessMessage: 'I want to give this person full access to the platform and be able to see and manage all vaults and users.',
            addFileComments: 'Add file comment',
            duplicateFiles: 'These files already exist in the selected folder, these will be overwritten.',
        },
    },
    tabs: {
        edit: 'Edit',
        recover: 'Recover',
        loginHistory: 'Login history',
        vault: 'Vaults',
    },
    setup: {
        openApp: 'Open App',
        appSetup: 'The last step of the setup process is setting up your app, scan the qr-code with your phone and open the url to connect your authenticator with the vault.',
    },
    calendar: {
        days: {
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            sunday: 'Sunday',
        },
        months: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
        },
    },
    share: {
        temporaryShareTitle: 'Create temporary read-only share',
        temporaryShareMessage: 'Create a temporary Read-Only share for a user',
        readonlyTitle: 'Read-Only Shares',
        expiryDate: 'Expiry Date',
    },
    webdav: {
        mount: 'Mount on WebDAV',
        createdTitle: 'New item mounted',
        createdBody: 'New item mounted to the WebDAV system. You can consult these by using the link below in the explorer of your operating system',
        sessionWarning: 'Be sure to save files frequently!',
        username: 'Username',
        password: 'Password',
    },
    passwordManager: {
        vaultPasswords: 'Passwords',
        personalPasswords: 'Personal Passwords',
        items: 'No items | 1 item | {count} items',
        modals: {
            addPassword: 'Add password',
            editPassword: 'Edit password',
            addCategory: 'Add category',
            removePassword: 'Remove Password',
            removeCategory: 'Remove Category',
            removePasswordMessage: 'Are you sure you want to delete this Password?',
            removeCategoryMessage: 'Are you sure you want to delete this category? All passwords in this category will be lost!',
            removeShare: 'Remove password share',
            removeShareMessage: 'Are you sure you want to remove the password share with user?',
            sharePassword: 'Share password',
            fields: {
                readOnly: 'Read only rights',
                canModify: 'Full rights',
            },
        },
        list: {
            emptyItemMessageAdmin: 'Get started by adding a new password or category.',
            emptyItemMessageUser: 'You do not have access to any password or category in this vault.',
        },
        details: {
            vaultPassword: 'Password',
            personalPassword: 'Personal password',
            addedOn: 'added on',
            accountInfo: 'Account information',
            accountDetails: 'Account details',
            sharedWith: 'Shared with',
            fields: {
                nameIdentifier: 'Username/Email',
                password: 'Password',
                website: 'Website',
                createdOn: 'Created on',
                modifiedOn: 'Modified on',
            },
        },
        buttons: {
            addPassword: 'Add password',
            addCategory: 'Add category',
            share: 'Share',
            edit: 'Edit',
            remove: 'Delete',
        },
    },
    navigation: {
        admin: {
            vaults: 'Vaults',
            users: 'Users',
            settings: 'Settings',
        },
        vault: {
            documents: 'Documents',
            passwords: 'Passwords',
            users: 'Users',
        },
        personal: {
            passwords: 'Personal passwords',
        },
    },
    explanation: {
        [VaultRole.LIMITED_VAULT_USER]: 'This user can only create and upload files in "to be classified". By default this user doesn\'t see any files unless they are given access to a specific file.',
        [VaultRole.BASIC_VAULT_USER]: 'This user can only create and upload files in "To be classified". By default, this user sees all files but cannot edit them unless the user is granted access to a specific file.',
        [VaultRole.ADVANCED_VAULT_USER]: "This user can create and upload files anywhere. By default, this user sees all files, folders and categories and is also allowed to edit them. However, this user cannot make changes to other users, and cannot change other users' vault passwords.",
        [VaultRole.VAULT_ADMIN]: 'This user has access to everything by default. The admin can edit other users and can view and edit all vault passwords.',
    },
    search: {
        names: 'Names',
        content: 'Content',
    },
};
export default en;
