import { __awaiter } from "tslib";
import Modal from '@/components/general/Modal.vue';
import { computed, defineComponent, ref, watch } from 'vue';
import Button from '@/components/general/Button.vue';
import { InputType } from '@/types/input/inputDetails';
import Form from '@/components/general/form/Form.vue';
import { onBeforeMount } from '@vue/runtime-core';
import { useStore } from '@/store';
import { AcceptedFiles } from '@/types/file/acceptedFiles.enum';
import { ViewType } from '@/types/views/views.enum';
import { FileManagerActionTypes } from '@/store/modules/FileManager/fileManager.actions';
import { getFoldersAndSubFolders } from '@/services/admin.service';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
import CustomSelect from '@/components/general/CustomSelect.vue';
import { mapSubFolders } from '@/utils';
import { useRoute, useRouter } from 'vue-router';
import FileTreeView from '@/components/general/FileTreeView.vue';
import ConfirmModal from '@/components/vault/modals/ConfirmModal.vue';
import { getDuplicatesItemsInTree } from '@/utils/file.utils';
export default defineComponent({
    name: 'FileUploadModal',
    components: {
        FileTreeView,
        ConfirmModal,
        Button,
        Modal,
        Form,
        CustomSelect,
    },
    props: {
        view: {
            type: String,
        },
        show: {
            type: Boolean,
            default: false,
        },
        uploadedFiles: Object,
    },
    emits: ['update:show', 'update:uploadedFiles'],
    setup({ view, uploadedFiles }, ctx) {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const processingForm = ref(false);
        const folderName = ref('');
        const folders = ref([]);
        const selectedFolder = ref(null);
        const items = ref();
        const duplicateItems = ref([]);
        const showDuplicateItems = ref(false);
        const uploadForm = computed(() => {
            var _a;
            return {
                name: 'FileUploadForm',
                inputs: [
                    {
                        name: 'fileFolder',
                        type: InputType.SELECT,
                        label: 'folder',
                        canChange: true,
                        objectValue: 'name',
                        selectedValue: selectedFolder.value,
                        items: view === ViewType.DRAGGING || view === ViewType.USER
                            ? []
                            : folders.value.map(folder => {
                                return {
                                    value: folder,
                                };
                            }),
                        hidden: view === ViewType.DRAGGING || view === ViewType.USER,
                        translation: 'folder',
                    },
                    {
                        name: 'file',
                        label: 'file',
                        type: InputType.FILE,
                        validation: {
                            required: true,
                            lazy: true,
                        },
                        multiple: true,
                        accept: Object.values(AcceptedFiles).map(file => '.' + file),
                        selectedFolderId: (_a = selectedFolder.value) === null || _a === void 0 ? void 0 : _a.folderUuid,
                        value: uploadedFiles,
                    },
                    {
                        name: 'note',
                        label: 'note',
                        type: InputType.TEXT_AREA,
                        validation: {
                            maxLength: 250,
                        },
                    },
                ],
                submit: 'uploadFile',
                cancel: 'cancel',
            };
        });
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            if (view == ViewType.ADMIN) {
                const foldersToMap = yield getFoldersAndSubFolders(route.params.spaceId);
                folders.value = mapSubFolders(foldersToMap);
                selectedFolder.value = folders.value.find(folder => folder.folderUuid === store.getters.selectedFolderId);
            }
            processingForm.value = false;
        }));
        watch(() => selectedFolder, (folder) => __awaiter(this, void 0, void 0, function* () {
            duplicateItems.value = yield getDuplicatesItemsInTree(items.value, folder.value.folderUuid);
        }), {
            deep: true,
        });
        const closeModal = () => {
            ctx.emit('update:show', false);
            ctx.emit('update:uploadedFiles');
            processingForm.value = false;
        };
        const closeDuplicateItemModal = () => {
            showDuplicateItems.value = false;
        };
        const addFile = (formData, force) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            if (duplicateItems.value.length > 0 && !force) {
                showDuplicateItems.value = true;
                return;
            }
            showDuplicateItems.value = false;
            processingForm.value = true;
            const notes = (_a = formData.values.note) === null || _a === void 0 ? void 0 : _a.value;
            const items = force ? formData : formData.values.file.value;
            const folderId = (_b = selectedFolder.value) === null || _b === void 0 ? void 0 : _b.folderUuid;
            if (view === ViewType.ADMIN && !folderId) {
                processingForm.value = false;
                return store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: `pleasePickCategory`,
                });
            }
            for (const fileUpload of items) {
                const existingFile = store.getters.workspaceItems.find(file => {
                    if (!file['fileUuid']) {
                        return false;
                    }
                    file = file;
                    const fullName = `${file.filename}.${file.extension}`;
                    if (file.parentUuid === folderId) {
                        return fullName === fileUpload.name;
                    }
                });
                const fileUuid = existingFile === null || existingFile === void 0 ? void 0 : existingFile.fileUuid;
                yield store.dispatch(FileManagerActionTypes.UPLOAD_FILE, {
                    uploadedFile: fileUpload.data,
                    name: fileUpload.name,
                    fileUuid,
                    folderId,
                    notes,
                });
                yield router.push({
                    name: 'Files',
                    params: {
                        spaceId: route.params.spaceId,
                        spaceName: route.params.spaceName,
                        selectedFolderId: folderId,
                    },
                });
            }
            ctx.emit('update:uploadedFiles');
            closeModal();
        });
        const updateInput = (formValues) => __awaiter(this, void 0, void 0, function* () {
            var _c, _d;
            items.value = formValues.file.value;
            if (!((_c = selectedFolder.value) === null || _c === void 0 ? void 0 : _c.folderUuid)) {
                return;
            }
            duplicateItems.value = yield getDuplicatesItemsInTree(items.value, (_d = selectedFolder.value) === null || _d === void 0 ? void 0 : _d.folderUuid);
        });
        return {
            processingForm,
            folderName,
            uploadForm,
            folders,
            selectedFolder,
            duplicateItems,
            showDuplicateItems,
            items,
            closeModal,
            addFile,
            updateInput,
            closeDuplicateItemModal,
        };
    },
});
