import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex flex-col h-full" };
const _hoisted_2 = {
    class: "flex flex-row items-center pl-2",
    style: { "max-width": "12rem" }
};
const _hoisted_3 = ["onClick"];
export function render(_ctx, _cache) {
    const _component_search_bar = _resolveComponent("search-bar");
    const _component_avatar = _resolveComponent("avatar");
    const _component_vault_navigation_icon = _resolveComponent("vault-navigation-icon");
    const _component_empty_table_state = _resolveComponent("empty-table-state");
    const _component_Table = _resolveComponent("Table");
    const _directive_infinite_scroll = _resolveDirective("infinite-scroll");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_search_bar, {
            lazy: "",
            class: "my-2 w-full max-w-md",
            searchValue: _ctx.vaultSearch,
            "onUpdate:searchValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.vaultSearch) = $event))
        }, null, 8, ["searchValue"]),
        _withDirectives((_openBlock(), _createBlock(_component_Table, {
            headers: _ctx.headers,
            data: _ctx.vaults,
            "default-sort": _ctx.sortedField,
            "onUpdate:default-sort": _cache[1] || (_cache[1] = ($event) => ((_ctx.sortedField) = $event)),
            "backend-pagination-sorting": "",
            onSortChanged: _ctx.sortVaults,
            "is-loading": _ctx.isLoading
        }, {
            "data-name": _withCtx((originalData) => [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_avatar, {
                        rounded: "",
                        class: "h-8 w-8",
                        "round-size": "full",
                        src: `https://picsum.photos/seed/${originalData.row.id}/300/300`
                    }, null, 8, ["src"]),
                    _createElementVNode("span", {
                        class: "pl-3 font-bold hover:underline truncate",
                        onClick: ($event) => (_ctx.$emit('clickedVaultName', originalData.row))
                    }, _toDisplayString(originalData.row.name), 9, _hoisted_3)
                ])
            ]),
            tableEmptyState: _withCtx(() => [
                _createVNode(_component_empty_table_state, null, {
                    icon: _withCtx(() => [
                        _createVNode(_component_vault_navigation_icon, { class: "h-8 w-8 mx-auto text-gray-400" })
                    ]),
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('filter.vaultsNotFound')), 1)
                    ]),
                    _: 1
                })
            ]),
            loading: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('tables.loadingState.vaultLoading')) + "... ", 1)
            ]),
            _: 1
        }, 8, ["headers", "data", "default-sort", "onSortChanged", "is-loading"])), [
            [_directive_infinite_scroll, {
                    trigger: _ctx.getMoreVaults,
                    options: { limit: 15 },
                }]
        ])
    ]));
}
