import { toastActions } from './toast.actions';
import { toastGetters } from './toast.getters';
import { toastMutations } from './toast.mutations';
import { toastState } from './toast.state';
export const toastStore = {
    state: toastState,
    getters: toastGetters,
    mutations: toastMutations,
    actions: toastActions,
    //namespaced: true,
};
