import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "w-full h-80 flex flex-col justify-center px-6" };
const _hoisted_2 = ["onClick", "checked"];
export function render(_ctx, _cache) {
    const _component_custom_select = _resolveComponent("custom-select");
    const _component_user_navigation_icon = _resolveComponent("user-navigation-icon");
    const _component_empty_table_state = _resolveComponent("empty-table-state");
    const _component_Table = _resolveComponent("Table");
    const _component_Button = _resolveComponent("Button");
    const _component_modal = _resolveComponent("modal");
    return (_ctx.show)
        ? (_openBlock(), _createBlock(_component_modal, {
            key: 0,
            onClose: _cache[1] || (_cache[1] = ($event) => (_ctx.closeModal()))
        }, {
            header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('passwordManager.modals.sharePassword')), 1)
            ]),
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    (!_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_Table, {
                            key: 0,
                            headers: _ctx.headers,
                            data: _ctx.shareableUsers,
                            "is-loading": _ctx.isLoading
                        }, {
                            "data-checkbox": _withCtx(({ row, index }) => [
                                (_openBlock(), _createElementBlock("input", {
                                    onClick: _withModifiers(($event) => (_ctx.toggleSharedUser(row)), ["prevent"]),
                                    type: "checkbox",
                                    checked: _ctx.shareableUsers[index].selected,
                                    key: `${row.id}_${_ctx.shareableUsers[index].selected}`
                                }, null, 8, _hoisted_2))
                            ]),
                            "data-rights": _withCtx(({ row, index }) => [
                                _createVNode(_component_custom_select, {
                                    translation: "passwordManager.modals.fields",
                                    modelValue: _ctx.shareableUsers[index].rights,
                                    "onUpdate:modelValue": ($event) => ((_ctx.shareableUsers[index].rights) = $event),
                                    inputs: Object.values(_ctx.PasswordRights)
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "inputs"])
                            ]),
                            tableEmptyState: _withCtx(() => [
                                _createVNode(_component_empty_table_state, null, {
                                    icon: _withCtx(() => [
                                        _createVNode(_component_user_navigation_icon, { class: "h-8 w-8 mx-auto text-gray-400" })
                                    ]),
                                    header: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('filter.usersNotFound')), 1)
                                    ]),
                                    _: 1
                                })
                            ]),
                            loading: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('tables.loadingState.userLoading')) + "... ", 1)
                            ]),
                            _: 1
                        }, 8, ["headers", "data", "is-loading"]))
                        : _createCommentVNode("", true)
                ])
            ]),
            actions: _withCtx(() => [
                _createVNode(_component_Button, {
                    colored: "",
                    onClick: _withModifiers(_ctx.sharePassword, ["stop"]),
                    disabled: _ctx.shareableUsers.filter(share => share.selected).length <= 0
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('passwordManager.buttons.share')), 1)
                    ]),
                    _: 1
                }, 8, ["onClick", "disabled"]),
                _createVNode(_component_Button, {
                    class: "capitalize mr-2",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.closeModal()))
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('misc.cancel')), 1)
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }))
        : _createCommentVNode("", true);
}
