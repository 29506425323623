import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "flex items-center justify-center" };
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Button = _resolveComponent("Button");
    const _component_modal = _resolveComponent("modal");
    return (_ctx.show)
        ? (_openBlock(), _createBlock(_component_modal, {
            key: 0,
            onClose: _cache[0] || (_cache[0] = ($event) => (_ctx.closeModal())),
            sticky: ""
        }, {
            header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('misc.imagePreview')), 1)
            ]),
            actions: _withCtx(() => [
                _createVNode(_component_Button, {
                    colored: "",
                    onMousedown: _ctx.saveImage,
                    class: "px-2 mr-0 capitalize bg-primary text-white"
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('forms.buttons.save')), 1)
                    ]),
                    _: 1
                }, 8, ["onMousedown"]),
                _createVNode(_component_Button, {
                    onMousedown: _ctx.closeModal,
                    class: "capitalize mr-2"
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('forms.buttons.cancel')), 1)
                    ]),
                    _: 1
                }, 8, ["onMousedown"])
            ]),
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("img", {
                        class: "h-64",
                        src: _ctx.downscaledImage,
                        alt: "company logo"
                    }, null, 8, _hoisted_2)
                ])
            ]),
            _: 1
        }))
        : _createCommentVNode("", true);
}
