import { __awaiter } from "tslib";
import { isMobile } from '@/filters';
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import SelectionCard from '@/components/vault/SelectionCard.vue';
import { useRouter, useRoute } from 'vue-router';
import { mapGetters } from 'vuex';
import { useStore } from '@/store';
import { UserActionTypes } from '@/store/modules/users/user.actions';
import LanguageSelector from '@/components/general/LanguageSelector/LanguageSelector.vue';
import { GeneralActionTypes } from '@/store/modules/general/general.actions';
import UserDropdown from '@/components/general/UserDropdown.vue';
import { config } from '@/config';
import MobileMenu from '@/components/general/MobileMenu.vue';
import { logoutUser } from '@/services/authentication.service';
import SpinnerModal from '@/components/vault/modals/SpinnerModal.vue';
export default defineComponent({
    name: 'Selection',
    components: {
        SelectionCard,
        LanguageSelector,
        UserDropdown,
        MobileMenu,
        SpinnerModal,
    },
    setup() {
        const store = useStore();
        const showWorkSpaces = ref(false);
        const router = useRouter();
        const route = useRoute();
        const showPopUp = ref(false);
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            yield store.dispatch(UserActionTypes.SET_PERSONAL_SPACES);
            const personalWorkspaces = store.getters.personalWorkspaces;
            if (store.getters.isAdmin) {
                return router.push({ name: 'AdminVaultsOverview' });
            }
            if (personalWorkspaces.length <= 0) {
                return;
            }
            if (personalWorkspaces.length > 1) {
                return;
            }
            yield openPersonalSpace(personalWorkspaces[0]);
        }));
        const hasNoWorkspace = computed(() => { var _a; return ((_a = store.getters.personalWorkspaces) === null || _a === void 0 ? void 0 : _a.length) <= 0; });
        const openAdminSection = () => {
            return router.push({ name: 'AdminVaultsOverview' });
        };
        const openPersonalSpace = (workspace) => __awaiter(this, void 0, void 0, function* () {
            yield store.dispatch(GeneralActionTypes.SET_SELECTED_WORKSPACE, workspace.id);
            yield router.push({
                name: 'Files',
                params: {
                    spaceId: workspace.id,
                    spaceName: workspace.name,
                },
            });
        });
        const doLogout = () => __awaiter(this, void 0, void 0, function* () {
            yield store.dispatch(GeneralActionTypes.SET_STATUS_LOGIN_SPINNER, true);
            yield logoutUser();
            window.location.href = '/logout';
            yield store.dispatch(GeneralActionTypes.SET_STATUS_LOGIN_SPINNER, false);
        });
        const goToSelection = () => {
            showPopUp.value = false;
            return router.push({ name: 'Selection' });
        };
        return {
            hasNoWorkspace,
            showWorkSpaces,
            UserDropdown,
            route,
            config,
            showPopUp,
            isMobile,
            openAdminSection,
            openPersonalSpace,
            doLogout,
            goToSelection,
        };
    },
    computed: Object.assign({}, mapGetters(['personalWorkspaces', 'isAdmin', 'statusLoginSpinner'])),
});
