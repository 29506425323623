import { webdavState } from '@/store/modules/webdav/webdav.state';
import { webdavMutations } from '@/store/modules/webdav/webdav.mutations';
import { webdavActions } from '@/store/modules/webdav/webdav.actions';
import { webdavGetters } from '@/store/modules/webdav/webdav.getters';
export const webdavStore = {
    state: webdavState,
    getters: webdavGetters,
    mutations: webdavMutations,
    actions: webdavActions,
    //namespaced: true,
};
