import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "w-full flex flex-col justify-center" };
const _hoisted_2 = ["for"];
const _hoisted_3 = {
    key: 1,
    class: "fas fa-folder"
};
const _hoisted_4 = ["for"];
export function render(_ctx, _cache) {
    const _component_CustomSelect = _resolveComponent("CustomSelect");
    const _component_document_icon = _resolveComponent("document-icon");
    const _component_power_point_icon = _resolveComponent("power-point-icon");
    const _component_spread_sheet_icon = _resolveComponent("spread-sheet-icon");
    const _component_Form = _resolveComponent("Form");
    const _component_modal = _resolveComponent("modal");
    return (_ctx.show)
        ? (_openBlock(), _createBlock(_component_modal, {
            key: 0,
            onClose: _cache[3] || (_cache[3] = ($event) => (_ctx.closeModal()))
        }, {
            header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('file.createNewFile')), 1)
            ]),
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_Form, {
                        ref: "newFileForm",
                        "form-data": _ctx.form,
                        "onUpdate:form-data": _cache[2] || (_cache[2] = ($event) => ((_ctx.form) = $event)),
                        onSubmit: _ctx.CreateFile,
                        onCancel: _ctx.closeModal,
                        loading: _ctx.creatingFile
                    }, {
                        "input-fileFolder": _withCtx(({ input }) => [
                            _withDirectives(_createElementVNode("label", {
                                class: "text-primary",
                                for: input.name
                            }, _toDisplayString(_ctx.$t(`forms.labels.${input.label}`)) + " " + _toDisplayString(input.required ? '*' : ''), 9, _hoisted_2), [
                                [_vShow, input.label]
                            ]),
                            (_ctx.selectedFolder && input.items)
                                ? (_openBlock(), _createBlock(_component_CustomSelect, {
                                    key: 0,
                                    modelValue: _ctx.selectedFolder,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.selectedFolder) = $event)),
                                    "default-icon": "fas fa-folder",
                                    "object-value": input.objectValue,
                                    translation: input.translation,
                                    inputs: input.items.map(item => item.value)
                                }, {
                                    icon: _withCtx(({ selected }) => {
                                        var _a, _b;
                                        return [
                                            (_openBlock(), _createElementBlock("div", {
                                                key: selected.folderUuid
                                            }, [
                                                (selected.metaData)
                                                    ? (_openBlock(), _createElementBlock("em", {
                                                        key: 0,
                                                        class: _normalizeClass(["fas", `fa-${(_a = selected.metaData) === null || _a === void 0 ? void 0 : _a.icon}`]),
                                                        style: _normalizeStyle(`color: ${(_b = selected.metaData) === null || _b === void 0 ? void 0 : _b.color}`)
                                                    }, null, 6))
                                                    : (_openBlock(), _createElementBlock("em", _hoisted_3))
                                            ]))
                                        ];
                                    }),
                                    _: 2
                                }, 1032, ["modelValue", "object-value", "translation", "inputs"]))
                                : _createCommentVNode("", true)
                        ]),
                        "input-fileType": _withCtx(({ input }) => [
                            _withDirectives(_createElementVNode("label", {
                                class: "text-primary",
                                for: input.name
                            }, _toDisplayString(_ctx.$t(`forms.labels.${input.label}`)) + " " + _toDisplayString(input.required ? '*' : ''), 9, _hoisted_4), [
                                [_vShow, input.label]
                            ]),
                            (_ctx.selectedFileType && input.items)
                                ? (_openBlock(), _createBlock(_component_CustomSelect, {
                                    key: 0,
                                    modelValue: _ctx.selectedFileType,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.selectedFileType) = $event)),
                                    icons: _ctx.fileIcons,
                                    translation: input.translation,
                                    inputs: input.items
                                }, {
                                    icon: _withCtx(({ selected }) => [
                                        (selected === _ctx.OfficeType.DOCX)
                                            ? (_openBlock(), _createBlock(_component_document_icon, { key: 0 }))
                                            : _createCommentVNode("", true),
                                        (selected === _ctx.OfficeType.PPTX)
                                            ? (_openBlock(), _createBlock(_component_power_point_icon, { key: 1 }))
                                            : _createCommentVNode("", true),
                                        (selected === _ctx.OfficeType.XLSX)
                                            ? (_openBlock(), _createBlock(_component_spread_sheet_icon, { key: 2 }))
                                            : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                }, 1032, ["modelValue", "icons", "translation", "inputs"]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    }, 8, ["form-data", "onSubmit", "onCancel", "loading"])
                ])
            ]),
            _: 1
        }))
        : _createCommentVNode("", true);
}
