import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "mobile:px-4 flex flex-col xl:justify-between xl:flex-row xl:items-center" };
const _hoisted_2 = { class: "flex items-center mobile:mb-0 mb-3" };
const _hoisted_3 = { class: "flex items-center" };
const _hoisted_4 = { class: "md:hidden" };
const _hoisted_5 = ["src"];
const _hoisted_6 = { class: "text-3xl max-w-2xl truncate font-bold" };
const _hoisted_7 = { class: "flex" };
const _hoisted_8 = { class: "flex items-center mr-4" };
const _hoisted_9 = {
    key: 0,
    class: "text-gray-500"
};
const _hoisted_10 = {
    key: 1,
    class: "text-gray-500"
};
const _hoisted_11 = { class: "flex items-center" };
const _hoisted_12 = { class: "text-gray-500" };
const _hoisted_13 = {
    key: 0,
    class: "mt-2 xl:mt-0 flex flex-row"
};
const _hoisted_14 = { class: "mobile:mx-4 bg-white border overflow-hidden sm:rounded-lg mt-3" };
const _hoisted_15 = { class: "px-4 py-3 sm:px-6" };
const _hoisted_16 = { class: "text-lg leading-6 font-medium text-gray-900" };
const _hoisted_17 = { class: "mt-1 max-w-2xl text-sm text-gray-500" };
const _hoisted_18 = { class: "border-t border-gray-200" };
const _hoisted_19 = { class: "bg-gray-50 px-4 py-4 flex flex-col md:grid md:grid-cols-2 md:gap-4 md:px-6" };
const _hoisted_20 = { class: "text-sm font-medium text-gray-500" };
const _hoisted_21 = { class: "flex flex-row items-center" };
const _hoisted_22 = { class: "mt-1 flex-grow text-sm truncate text-gray-900 md:mt-0" };
const _hoisted_23 = {
    key: 0,
    class: "justify-self-end"
};
const _hoisted_24 = { class: "bg-white px-4 py-4 flex flex-col md:grid md:grid-cols-2 md:gap-4 md:px-6" };
const _hoisted_25 = { class: "text-sm font-medium text-gray-500" };
const _hoisted_26 = { class: "flex flex-row items-center" };
const _hoisted_27 = { class: "mt-1 flex-grow text-sm whitespace-pre text-gray-900 md:mt-0" };
const _hoisted_28 = {
    key: 0,
    class: "ml-2"
};
const _hoisted_29 = { class: "bg-gray-50 px-4 py-4 flex flex-col md:grid md:grid-cols-2 md:gap-4 md:px-6" };
const _hoisted_30 = { class: "text-sm font-medium text-gray-500" };
const _hoisted_31 = { class: "flex flex-row items-center" };
const _hoisted_32 = { class: "mt-1 flex-grow text-sm truncate text-gray-900 md:mt-0" };
const _hoisted_33 = ["href"];
const _hoisted_34 = { key: 1 };
const _hoisted_35 = {
    key: 0,
    class: "justify-self-end"
};
const _hoisted_36 = { class: "bg-white px-4 py-4 flex flex-col md:grid md:grid-cols-2 md:gap-4 md:px-6" };
const _hoisted_37 = { class: "text-sm font-medium text-gray-500" };
const _hoisted_38 = { class: "flex flex-row items-center" };
const _hoisted_39 = { class: "mt-1 flex-grow text-sm text-gray-900 md:mt-0" };
const _hoisted_40 = { class: "bg-gray-50 px-4 py-4 flex flex-col md:grid md:grid-cols-2 md:gap-4 md:px-6" };
const _hoisted_41 = { class: "text-sm font-medium text-gray-500" };
const _hoisted_42 = { class: "flex flex-row items-center" };
const _hoisted_43 = { class: "mt-1 flex-grow text-sm text-gray-900 md:mt-0" };
const _hoisted_44 = {
    key: 0,
    class: "bg-white h-min border overflow-hidden sm:rounded-lg"
};
const _hoisted_45 = { class: "px-4 py-3 sm:px-6" };
const _hoisted_46 = { class: "text-lg leading-6 font-medium text-gray-900" };
const _hoisted_47 = { class: "border-t border-gray-200" };
const _hoisted_48 = {
    role: "list",
    class: "divide-y divide-gray-200 max-h-96 overflow-y-auto"
};
const _hoisted_49 = { class: "flex items-center justify-self-start overflow-hidden overflow-ellipsis" };
const _hoisted_50 = { class: "ml-3" };
const _hoisted_51 = { class: "text-sm font-medium text-gray-900" };
const _hoisted_52 = { class: "justify-self-left px-4" };
const _hoisted_53 = ["onClick"];
export function render(_ctx, _cache) {
    const _component_arrow_narrow_left_icon = _resolveComponent("arrow-narrow-left-icon");
    const _component_lock_closed_icon = _resolveComponent("lock-closed-icon");
    const _component_calendar_icon = _resolveComponent("calendar-icon");
    const _component_link_icon = _resolveComponent("link-icon");
    const _component_Button = _resolveComponent("Button");
    const _component_pencil_icon = _resolveComponent("pencil-icon");
    const _component_trash_icon = _resolveComponent("trash-icon");
    const _component_document_duplicate_icon = _resolveComponent("document-duplicate-icon");
    const _component_eye_off_icon = _resolveComponent("eye-off-icon");
    const _component_eye_icon = _resolveComponent("eye-icon");
    const _component_login_icon = _resolveComponent("login-icon");
    const _component_avatar = _resolveComponent("avatar");
    const _component_remove_share_modal = _resolveComponent("remove-share-modal");
    const _component_share_password_modal = _resolveComponent("share-password-modal");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_arrow_narrow_left_icon, {
                                onClick: _ctx.goBack,
                                class: "w-7 h-7 cursor-pointer text-black mr-1.5"
                            }, null, 8, ["onClick"])
                        ]),
                        (_openBlock(), _createElementBlock("img", {
                            width: "32",
                            height: "32",
                            class: "rounded-full mr-3",
                            key: _ctx.currentPassword.site,
                            alt: "passwordLogo",
                            src: _ctx.getFavIconOfSite
                        }, null, 8, _hoisted_5)),
                        _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.currentPassword.name), 1)
                    ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_lock_closed_icon, { class: "h-5 w-5 text-gray-400 mr-1" }),
                        (_ctx.personal)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.$t('passwordManager.details.personalPassword')), 1))
                            : (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t('passwordManager.details.vaultPassword')), 1))
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_calendar_icon, { class: "h-5 w-5 text-gray-400 mr-1" }),
                        _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('passwordManager.details.addedOn')) + " " + _toDisplayString(_ctx.formatDate(_ctx.currentPassword.createdAt, false)), 1)
                    ])
                ])
            ]),
            (Boolean(_ctx.currentPassword.canModify) || _ctx.personal)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    (!_ctx.personal && (_ctx.hasAccessToOperation({ accessType: _ctx.AccessType.passwords, operation: _ctx.AccessOperations.edit }) || Boolean(_ctx.currentPassword.canModify)))
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 0,
                            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.showSharePassword = true)),
                            class: "m-0"
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_link_icon, { class: "h-5 w-5 text-gray-400 mr-2" }),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('passwordManager.buttons.share')), 1)
                            ]),
                            _: 1
                        }))
                        : _createCommentVNode("", true),
                    (_ctx.hasAccessToOperation({ accessType: _ctx.AccessType.passwords, operation: _ctx.AccessOperations.edit }) || Boolean(_ctx.currentPassword.canModify))
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 1,
                            onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('show-edit-password', _ctx.currentPassword))),
                            class: "m-0 ml-2"
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_pencil_icon, { class: "h-5 w-5 text-gray-400 mr-2" }),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('passwordManager.buttons.edit')), 1)
                            ]),
                            _: 1
                        }))
                        : _createCommentVNode("", true),
                    (_ctx.personal || _ctx.hasAccessToOperation({ accessType: _ctx.AccessType.passwords, operation: _ctx.AccessOperations.delete }))
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 2,
                            onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('show-remove-item', _ctx.currentPassword))),
                            class: "m-0 ml-2"
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_trash_icon, { class: "h-5 w-5 text-gray-400 mr-2" }),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('passwordManager.buttons.remove')), 1)
                            ]),
                            _: 1
                        }))
                        : _createCommentVNode("", true)
                ]))
                : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
                _createElementVNode("h3", _hoisted_16, _toDisplayString(_ctx.$t('passwordManager.details.accountInfo')), 1),
                _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t('passwordManager.details.accountDetails')), 1)
            ]),
            _createElementVNode("div", _hoisted_18, [
                _createElementVNode("dl", null, [
                    _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("dt", _hoisted_20, _toDisplayString(_ctx.$t('passwordManager.details.fields.nameIdentifier')), 1),
                        _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("dd", _hoisted_22, _toDisplayString(_ctx.currentPassword.nameIdentifier), 1),
                            (!_ctx.isBrowserIsolated())
                                ? (_openBlock(), _createElementBlock("dd", _hoisted_23, [
                                    _createVNode(_component_document_duplicate_icon, {
                                        onClick: _cache[3] || (_cache[3] = _withModifiers(($event) => (_ctx.copyTextToClipboard(_ctx.currentPassword.nameIdentifier)), ["stop"])),
                                        class: "cursor-pointer h-5 w-5 text-black"
                                    })
                                ]))
                                : _createCommentVNode("", true)
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("dt", _hoisted_25, _toDisplayString(_ctx.$t('passwordManager.details.fields.password')), 1),
                        _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("dd", _hoisted_27, _toDisplayString(_ctx.isPasswordVisible ? _ctx.currentPassword.password : '********'), 1),
                            _createElementVNode("dd", null, [
                                (_ctx.isPasswordVisible)
                                    ? (_openBlock(), _createBlock(_component_eye_off_icon, {
                                        key: 0,
                                        onClick: _withModifiers(_ctx.viewPassword, ["stop"]),
                                        class: "cursor-pointer h-5 w-5 text-black"
                                    }, null, 8, ["onClick"]))
                                    : (_openBlock(), _createBlock(_component_eye_icon, {
                                        key: 1,
                                        onClick: _withModifiers(_ctx.viewPassword, ["stop"]),
                                        class: "cursor-pointer h-5 w-5 text-black"
                                    }, null, 8, ["onClick"]))
                            ]),
                            (!_ctx.isBrowserIsolated())
                                ? (_openBlock(), _createElementBlock("dd", _hoisted_28, [
                                    _createVNode(_component_document_duplicate_icon, {
                                        onClick: _withModifiers(_ctx.copyPassword, ["stop"]),
                                        class: "cursor-pointer h-5 w-5 text-black"
                                    }, null, 8, ["onClick"])
                                ]))
                                : _createCommentVNode("", true)
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("dt", _hoisted_30, _toDisplayString(_ctx.$t('passwordManager.details.fields.website')), 1),
                        _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("dd", _hoisted_32, [
                                (!_ctx.isBrowserIsolated())
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 0,
                                        href: _ctx.formatUrl,
                                        target: "_blank"
                                    }, _toDisplayString(_ctx.formatUrl), 9, _hoisted_33))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(_ctx.formatUrl), 1))
                            ]),
                            (!_ctx.isBrowserIsolated())
                                ? (_openBlock(), _createElementBlock("dd", _hoisted_35, [
                                    _createVNode(_component_login_icon, {
                                        onClick: _cache[4] || (_cache[4] = _withModifiers(($event) => (_ctx.openUrl()), ["stop"])),
                                        class: "cursor-pointer h-5 w-5 text-black transform rotate-180"
                                    })
                                ]))
                                : _createCommentVNode("", true)
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_36, [
                        _createElementVNode("dt", _hoisted_37, _toDisplayString(_ctx.$t('passwordManager.details.fields.createdOn')), 1),
                        _createElementVNode("div", _hoisted_38, [
                            _createElementVNode("dd", _hoisted_39, _toDisplayString(_ctx.formatDate(_ctx.currentPassword.createdAt)), 1)
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_40, [
                        _createElementVNode("dt", _hoisted_41, _toDisplayString(_ctx.$t('passwordManager.details.fields.modifiedOn')), 1),
                        _createElementVNode("div", _hoisted_42, [
                            _createElementVNode("dd", _hoisted_43, _toDisplayString(_ctx.formatDate(_ctx.currentPassword.updatedAt)), 1)
                        ])
                    ])
                ])
            ])
        ]),
        _createElementVNode("div", {
            class: _normalizeClass(["mobile:px-4 grid gap-3 my-3", { 'grid-rows-auto lg:grid-rows-none lg:grid-cols-2': !_ctx.personal, 'grid-rows-1 lg:grid-rows-none lg:grid-cols-1': _ctx.personal }])
        }, [
            ((!_ctx.personal || _ctx.hasAccessToOperation({ accessType: _ctx.AccessType.passwords, operation: _ctx.AccessOperations.edit }) || Boolean(_ctx.currentPassword.canModify)) &&
                _ctx.passwordUsers.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                    _createElementVNode("div", _hoisted_45, [
                        _createElementVNode("h3", _hoisted_46, _toDisplayString(_ctx.$t('passwordManager.details.sharedWith')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_47, [
                        _createElementVNode("ul", _hoisted_48, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.passwordUsers, (passwordUser) => {
                                return (_openBlock(), _createElementBlock("li", {
                                    key: passwordUser.id,
                                    class: "py-3 px-4 sm:px-6 grid grid-cols-3 items-center"
                                }, [
                                    _createElementVNode("div", _hoisted_49, [
                                        _createVNode(_component_avatar, {
                                            class: "h-10 w-10",
                                            "round-size": "full",
                                            src: `https://picsum.photos/seed/${passwordUser.id}/300/300`
                                        }, null, 8, ["src"]),
                                        _createElementVNode("div", _hoisted_50, [
                                            _createElementVNode("p", _hoisted_51, _toDisplayString(passwordUser.username), 1)
                                        ])
                                    ]),
                                    _createElementVNode("p", _hoisted_52, _toDisplayString(passwordUser.canModify ? _ctx.$t('passwordManager.modals.fields.canModify') : _ctx.$t('passwordManager.modals.fields.readOnly')), 1),
                                    (passwordUser.id !== _ctx.currentUser.id)
                                        ? (_openBlock(), _createElementBlock("button", {
                                            key: 0,
                                            onClick: ($event) => (_ctx.setPasswordShareUser(passwordUser)),
                                            class: "inline-flex items-center border border-red-500 px-3 py-2 shadow-sm text-sm font-medium cursor-pointer rounded-md w-min justify-self-end"
                                        }, [
                                            _createVNode(_component_trash_icon, { class: "h-4 w-4 text-red-500" })
                                        ], 8, _hoisted_53))
                                        : _createCommentVNode("", true)
                                ]));
                            }), 128))
                        ])
                    ])
                ]))
                : _createCommentVNode("", true)
        ], 2),
        (_ctx.showRemoveShare)
            ? (_openBlock(), _createBlock(_component_remove_share_modal, {
                key: 0,
                show: _ctx.showRemoveShare,
                "onUpdate:show": _cache[5] || (_cache[5] = ($event) => ((_ctx.showRemoveShare) = $event)),
                "selected-password": _ctx.currentPassword,
                "selected-user": _ctx.sharedUserToRemove,
                onDeleteSharedUser: _ctx.deleteSharedUser
            }, null, 8, ["show", "selected-password", "selected-user", "onDeleteSharedUser"]))
            : _createCommentVNode("", true),
        (_ctx.showSharePassword)
            ? (_openBlock(), _createBlock(_component_share_password_modal, {
                key: 1,
                show: _ctx.showSharePassword,
                "onUpdate:show": _cache[6] || (_cache[6] = ($event) => ((_ctx.showSharePassword) = $event)),
                "selected-password": _ctx.currentPassword,
                "onUpdate:selected-password": _cache[7] || (_cache[7] = ($event) => ((_ctx.currentPassword) = $event)),
                onAddSharedUsers: _ctx.addSharedUsers
            }, null, 8, ["show", "selected-password", "onAddSharedUsers"]))
            : _createCommentVNode("", true)
    ], 64));
}
