import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "fixed right-0 mr-5 mt-5 hidden xl:flex flex-row items-center justify-center z-10" };
const _hoisted_2 = { class: "mx-3" };
const _hoisted_3 = { class: "h-screen flex flex-col items-center justify-center" };
const _hoisted_4 = { class: "text-primary font-bold text-xl p-4" };
const _hoisted_5 = { class: "text-sm" };
const _hoisted_6 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LanguageSelector = _resolveComponent("LanguageSelector");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_LanguageSelector)
            ])
        ]),
        _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('error.something_went_wrong')), 1),
            _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t(`error.${$setup.error}`)), 1),
            _createElementVNode("img", {
                class: "pt-4",
                src: $setup.logoUrl,
                alt: "Logo"
            }, null, 8, _hoisted_6),
            _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => ($setup.goBack && $setup.goBack(...args))),
                class: "mt-2"
            }, _toDisplayString(_ctx.$t('misc.goBack')), 1)
        ])
    ], 64));
}
