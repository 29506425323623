import { __awaiter } from "tslib";
import { onBeforeMount } from '@vue/runtime-core';
import { InputEvent, InputType } from '@/types/input/inputDetails';
import { computed, defineComponent, ref, watch } from 'vue';
import { useStore } from '@/store';
import FileTreeView from '@/components/general/FileTreeView.vue';
import { fileListToItemTree, itemListToItemTree } from '@/utils/file.utils';
import Button from '@/components/general/Button.vue';
import { AccessOperations, AccessType } from '@/types/authorization';
export default defineComponent({
    name: 'FormFile',
    components: { FileTreeView, Button },
    props: {
        inputDetails: Object,
        validationError: Object,
    },
    emits: ['updateField'],
    setup({ inputDetails }, ctx) {
        const isLoading = ref(true);
        const store = useStore();
        const fileInput = ref(undefined);
        const fileTree = ref([]);
        const files = store.getters.workspaceItems.filter(file => file.parentUuid);
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (!inputDetails.value) {
                fileTree.value = [];
                isLoading.value = false;
                return;
            }
            fileTree.value = yield itemListToItemTree(Array.from([...inputDetails.value]));
            ctx.emit('updateField', {
                name: inputDetails.name,
                event: ((_a = inputDetails === null || inputDetails === void 0 ? void 0 : inputDetails.validation) === null || _a === void 0 ? void 0 : _a.lazy) ? InputEvent.BLUR : InputEvent.NONE,
                data: {
                    value: fileTree.value,
                },
            });
            isLoading.value = false;
        }));
        watch(fileTree, value => {
            var _a;
            ctx.emit('updateField', {
                name: inputDetails.name,
                event: ((_a = inputDetails === null || inputDetails === void 0 ? void 0 : inputDetails.validation) === null || _a === void 0 ? void 0 : _a.lazy) ? InputEvent.BLUR : InputEvent.NONE,
                data: {
                    value,
                },
            });
        });
        const handleFile = event => {
            appendFiles(event.target.files);
            fileInput.value.value = '';
        };
        const triggerUpload = () => {
            fileInput.value.click();
        };
        const appendFiles = (files) => __awaiter(this, void 0, void 0, function* () {
            var _b;
            const newItemTree = yield fileListToItemTree(files);
            newItemTree.forEach(item => {
                var _a;
                const index = (_a = fileTree.value) === null || _a === void 0 ? void 0 : _a.findIndex(f => f.name === item.name);
                if (index === -1) {
                    fileTree.value.push(item);
                    return;
                }
                fileTree.value[index] = item;
            });
            ctx.emit('updateField', {
                name: inputDetails.name,
                event: ((_b = inputDetails === null || inputDetails === void 0 ? void 0 : inputDetails.validation) === null || _b === void 0 ? void 0 : _b.lazy) ? InputEvent.BLUR : InputEvent.NONE,
                data: {
                    value: fileTree.value,
                },
            });
        });
        const fileAlreadyExists = computed(() => (newFile, selectedFolderId) => {
            var _a;
            if (!store.getters.hasAccessToOperation({ accessType: AccessType.files, operation: AccessOperations.create })) {
                selectedFolderId = (_a = store.getters.getItemsByParentId(null).find(folder => folder['name'] === 'to_be_classified')) === null || _a === void 0 ? void 0 : _a['folderUuid'];
            }
            return !!files.find(file => {
                const extension = newFile.name.substring(newFile.name.lastIndexOf('.') + 1).toLowerCase();
                return selectedFolderId && selectedFolderId === file.parentUuid && `${file['filename']}${extension ? '.' + file['extension'] : ''}` === newFile.name;
            });
        });
        const handleDelete = (file) => {
            var _a;
            const fileIndex = (_a = fileTree.value) === null || _a === void 0 ? void 0 : _a.findIndex(f => f.name === file.name);
            if (fileIndex === -1) {
                return;
            }
            fileTree.value.splice(fileIndex, 1);
            ctx.emit('updateField', {
                name: inputDetails.name,
                data: {
                    value: fileTree.value,
                },
            });
        };
        return {
            InputType,
            fileInput,
            fileTree,
            fileAlreadyExists,
            isLoading,
            handleFile,
            triggerUpload,
            handleDelete,
        };
    },
});
