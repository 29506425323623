const icons = [
    {
        name: 'address-book',
    },
    {
        name: 'address-card',
    },
    {
        name: 'arrow-alt-circle-down',
    },
    {
        name: 'arrow-alt-circle-left',
    },
    {
        name: 'arrow-alt-circle-right',
    },
    {
        name: 'arrow-alt-circle-up',
    },
    {
        name: 'bell',
    },
    {
        name: 'bell-slash',
    },
    {
        name: 'bookmark',
    },
    {
        name: 'building',
    },
    {
        name: 'calendar',
    },
    {
        name: 'calendar-alt',
    },
    {
        name: 'calendar-check',
    },
    {
        name: 'calendar-minus',
    },
    {
        name: 'calendar-plus',
    },
    {
        name: 'calendar-times',
    },
    {
        name: 'caret-square-down',
    },
    {
        name: 'caret-square-left',
    },
    {
        name: 'caret-square-right',
    },
    {
        name: 'caret-square-up',
    },
    {
        name: 'chart-bar',
    },
    {
        name: 'check-circle',
    },
    {
        name: 'check-square',
    },
    {
        name: 'circle',
    },
    {
        name: 'clipboard',
    },
    {
        name: 'clock',
    },
    {
        name: 'clone',
    },
    {
        name: 'closed-captioning',
    },
    {
        name: 'comment',
    },
    {
        name: 'comment-alt',
    },
    {
        name: 'comments',
    },
    {
        name: 'compass',
    },
    {
        name: 'copy',
    },
    {
        name: 'copyright',
    },
    {
        name: 'credit-card',
    },
    {
        name: 'dot-circle',
    },
    {
        name: 'edit',
    },
    {
        name: 'envelope',
    },
    {
        name: 'envelope-open',
    },
    {
        name: 'eye-slash',
    },
    {
        name: 'file',
    },
    {
        name: 'file-alt',
    },
    {
        name: 'file-archive',
    },
    {
        name: 'file-audio',
    },
    {
        name: 'file-code',
    },
    {
        name: 'file-excel',
    },
    {
        name: 'file-image',
    },
    {
        name: 'file-pdf',
    },
    {
        name: 'file-powerpoint',
    },
    {
        name: 'file-video',
    },
    {
        name: 'file-word',
    },
    {
        name: 'flag',
    },
    {
        name: 'folder',
    },
    {
        name: 'folder-open',
    },
    {
        name: 'frown',
    },
    {
        name: 'futbol',
    },
    {
        name: 'gem',
    },
    {
        name: 'hand-lizard',
    },
    {
        name: 'hand-paper',
    },
    {
        name: 'hand-peace',
    },
    {
        name: 'hand-point-down',
    },
    {
        name: 'hand-point-left',
    },
    {
        name: 'hand-point-right',
    },
    {
        name: 'hand-point-up',
    },
    {
        name: 'hand-pointer',
    },
    {
        name: 'hand-rock',
    },
    {
        name: 'hand-scissors',
    },
    {
        name: 'hand-spock',
    },
    {
        name: 'handshake',
    },
    {
        name: 'hdd',
    },
    {
        name: 'heart',
    },
    {
        name: 'hospital',
    },
    {
        name: 'hourglass',
    },
    {
        name: 'id-badge',
    },
    {
        name: 'id-card',
    },
    {
        name: 'image',
    },
    {
        name: 'images',
    },
    {
        name: 'keyboard',
    },
    {
        name: 'lemon',
    },
    {
        name: 'life-ring',
    },
    {
        name: 'lightbulb',
    },
    {
        name: 'list-alt',
    },
    {
        name: 'map',
    },
    {
        name: 'meh',
    },
    {
        name: 'minus-square',
    },
    {
        name: 'money-bill-alt',
    },
    {
        name: 'moon',
    },
    {
        name: 'newspaper',
    },
    {
        name: 'object-group',
    },
    {
        name: 'object-ungroup',
    },
    {
        name: 'paper-plane',
    },
    {
        name: 'pause-circle',
    },
    {
        name: 'play-circle',
    },
    {
        name: 'plus-square',
    },
    {
        name: 'question-circle',
    },
    {
        name: 'registered',
    },
    {
        name: 'save',
    },
    {
        name: 'share-square',
    },
    {
        name: 'smile',
    },
    {
        name: 'snowflake',
    },
    {
        name: 'square',
    },
    {
        name: 'star',
    },
    {
        name: 'star-half',
    },
    {
        name: 'sticky-note',
    },
    {
        name: 'stop-circle',
    },
    {
        name: 'sun',
    },
    {
        name: 'thumbs-down',
    },
    {
        name: 'thumbs-up',
    },
    {
        name: 'times-circle',
    },
    {
        name: 'trash-alt',
    },
    {
        name: 'user',
    },
    {
        name: 'user-circle',
    },
    {
        name: 'window-close',
    },
    {
        name: 'window-maximize',
    },
    {
        name: 'window-minimize',
    },
    {
        name: 'window-restore',
    },
];
export { icons as default };
