export var ToastMutationTypes;
(function (ToastMutationTypes) {
    ToastMutationTypes["ADD_TOAST_TO_QUEUE"] = "ADD_TOAST_TO_QUEUE";
    ToastMutationTypes["REMOVE_TOAST_FROM_QUEUE"] = "REMOVE_TOAST_FROM_QUEUE";
    ToastMutationTypes["UPDATE_TOAST_PROGRESSION"] = "UPDATE_TOAST_PROGRESSION";
})(ToastMutationTypes || (ToastMutationTypes = {}));
export const toastMutations = {
    [ToastMutationTypes.ADD_TOAST_TO_QUEUE](state, toast) {
        // Added toast.data == t.data because this avoided to send multiple toast messages if multiple users are invited, etc.
        // And this was necessary for good user feedback
        if (state.toasts.find(t => t.message === toast.message && toast.data == t.data)) {
            return;
        }
        state.toasts.push(toast);
    },
    [ToastMutationTypes.REMOVE_TOAST_FROM_QUEUE](state, index) {
        state.toasts.splice(index, 1);
    },
    [ToastMutationTypes.UPDATE_TOAST_PROGRESSION](state, toast) {
        const toastIndex = state.toasts.findIndex(t => t.data.name === toast.data.name);
        if (toastIndex === -1) {
            return;
        }
        Object.assign(state.toasts[toastIndex], toast);
    },
};
