import { InputEvent, InputType } from '@/types/input/inputDetails';
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
    name: 'FormText',
    props: { inputDetails: Object, validationError: Object },
    emits: ['updateField'],
    setup({ inputDetails }, ctx) {
        const fieldValue = ref(inputDetails.value || '');
        watch(fieldValue, newValue => {
            var _a;
            ctx.emit('updateField', {
                name: inputDetails.name,
                event: ((_a = inputDetails === null || inputDetails === void 0 ? void 0 : inputDetails.validation) === null || _a === void 0 ? void 0 : _a.lazy) ? InputEvent.BLUR : InputEvent.NONE,
                data: {
                    value: inputDetails.type === InputType.PASSWORD ? newValue : newValue.trim(),
                },
            });
        });
        return {
            fieldValue,
            InputType,
        };
    },
});
