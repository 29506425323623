import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "flex-col flex-wrap" };
const _hoisted_2 = { class: "absolute right-5 bottom-5 z-100" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_toast = _resolveComponent("toast");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toasts, (toast, index) => {
                return (_openBlock(), _createBlock(_component_toast, {
                    key: toast,
                    onRemoveToast: ($event) => (_ctx.removeToastFromQueue(index)),
                    "toast-data": toast
                }, null, 8, ["onRemoveToast", "toast-data"]));
            }), 128))
        ])
    ]));
}
