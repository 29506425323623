<template>
  <svg width="32" height="25" viewBox="0 0 32 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-sideBarIcons stroke-current fill-current">
    <path
      d="M31.3718 0H7.51001C7.16309 0 6.88184 0.28125 6.88184 0.628174C6.88184 0.975098 7.16309 1.25635 7.51001 1.25635H30.7437V21.0859H1.25635V1.25635H2.56299C2.90991 1.25635 3.19116 0.975098 3.19116 0.628174C3.19116 0.28125 2.90991 0 2.56299 0H0.628174C0.28125 0 0 0.28125 0 0.628174V21.7141C0 22.061 0.28125 22.3423 0.628174 22.3423H3.79468C4.08228 23.6345 5.23706 24.6038 6.61475 24.6038H6.89771C8.27539 24.6038 9.43018 23.6345 9.71777 22.3423H22.2632C22.5508 23.6345 23.7056 24.6038 25.0833 24.6038H25.3662C26.7439 24.6038 27.8987 23.6345 28.1863 22.3423H31.3718C31.7187 22.3423 32 22.061 32 21.7141V0.628174C32 0.28125 31.7187 0 31.3718 0V0ZM6.89771 23.3474H6.61475C5.93652 23.3474 5.35352 22.9321 5.10693 22.3423H8.40527C8.15869 22.9321 7.57593 23.3474 6.89771 23.3474ZM25.3662 23.3474H25.0833C24.405 23.3474 23.822 22.9321 23.5754 22.3423H26.874C26.6272 22.9321 26.0444 23.3474 25.3662 23.3474V23.3474Z"
    />
    <path
      d="M3.08069 19.1035C3.08069 19.4507 3.36194 19.7317 3.70886 19.7317H25.64C25.9869 19.7317 26.2682 19.4507 26.2682 19.1035V16.7615C27.2592 16.5408 28.0028 15.655 28.0028 14.5984C28.0028 13.5418 27.2592 12.656 26.2682 12.4353V9.91358C27.2592 9.69287 28.0028 8.80713 28.0028 7.75049C28.0028 6.6936 27.2592 5.80786 26.2682 5.5874V3.28418C26.2682 2.93701 25.9869 2.65601 25.64 2.65601H3.70886C3.36194 2.65601 3.08069 2.93701 3.08069 3.28418V19.1035ZM26.7462 14.5984C26.7462 15.1277 26.3156 15.5584 25.7863 15.5584H25.5289C24.9996 15.5584 24.5687 15.1277 24.5687 14.5984C24.5687 14.0689 24.9994 13.6382 25.5289 13.6382H25.7863C26.3156 13.6382 26.7462 14.0689 26.7462 14.5984ZM26.7462 7.75049C26.7462 8.27979 26.3156 8.71045 25.7863 8.71045H25.5289C24.9996 8.71045 24.5687 8.27979 24.5687 7.75049C24.5687 7.22095 24.9994 6.79028 25.5289 6.79028H25.7863C26.3156 6.79028 26.7462 7.22095 26.7462 7.75049ZM4.33704 3.91235H25.0118V5.59546C24.0382 5.8291 23.3124 6.7063 23.3124 7.75049C23.3124 8.79444 24.0382 9.67163 25.0118 9.90528V12.4434C24.0382 12.677 23.3124 13.5542 23.3124 14.5984C23.3124 15.6423 24.0382 16.5195 25.0118 16.7532V18.4753H4.33704V3.91235Z"
    />
    <path
      d="M18.1476 7.86377C18.1208 7.81763 18.0883 7.77393 18.0488 7.73413C18.0082 7.69385 17.9636 7.66064 17.9162 7.63354C17.0483 6.82764 15.8869 6.33325 14.6118 6.33325C11.9316 6.33325 9.75092 8.51367 9.75092 11.1938C9.75092 13.874 11.9316 16.0544 14.6118 16.0544C15.8994 16.0544 17.071 15.55 17.9416 14.7297C17.988 14.7029 18.0317 14.6704 18.0712 14.6309C18.1118 14.5903 18.1447 14.5457 18.1721 14.4983C18.978 13.6304 19.4723 12.469 19.4723 11.1938C19.4723 9.90601 18.968 8.73438 18.1476 7.86377V7.86377ZM17.8693 11.822H18.1601C18.0681 12.3433 17.8642 12.8259 17.5734 13.2446L17.3823 13.0532C17.1369 12.8081 16.7392 12.8081 16.4938 13.0532C16.2485 13.2986 16.2485 13.6963 16.4938 13.9417L16.6887 14.1367C16.2641 14.4375 15.7722 14.6482 15.2399 14.7422V14.4517C15.2399 14.1047 14.9587 13.8235 14.6118 13.8235C14.2648 13.8235 13.9836 14.1047 13.9836 14.4517V14.7422C13.4514 14.6482 12.9592 14.4375 12.5346 14.1367L12.7524 13.9189C12.9977 13.6738 12.9977 13.2759 12.7524 13.0305C12.507 12.7854 12.1093 12.7854 11.864 13.0305L11.6501 13.2446C11.3593 12.8259 11.1555 12.3433 11.0634 11.822H11.3539C11.7009 11.822 11.9821 11.5408 11.9821 11.1938C11.9821 10.8469 11.7009 10.5657 11.3539 10.5657H11.0634C11.1574 10.0334 11.3681 9.5415 11.6689 9.1167L11.8864 9.33447C12.0092 9.45703 12.1699 9.51831 12.3307 9.51831C12.4914 9.51831 12.6523 9.45703 12.7748 9.33447C13.0202 9.08911 13.0202 8.69141 12.7748 8.44604L12.561 8.23218C12.9794 7.94141 13.4623 7.73755 13.9833 7.64551V7.93604C13.9833 8.28296 14.2646 8.56421 14.6115 8.56421C14.9587 8.56421 15.2399 8.28296 15.2399 7.93604V7.64551C15.7609 7.73755 16.2436 7.94141 16.6623 8.23218L16.4711 8.42334C16.2258 8.6687 16.2258 9.06641 16.4711 9.31177C16.5937 9.43433 16.7546 9.49561 16.9152 9.49561C17.0759 9.49561 17.2368 9.43433 17.3593 9.31177L17.5544 9.1167C17.8552 9.54126 18.0661 10.0334 18.1601 10.5657H17.8693C17.5224 10.5657 17.2411 10.8469 17.2411 11.1938C17.2411 11.5408 17.5224 11.822 17.8693 11.822V11.822Z"
    />
    <path
      d="M14.6119 9.17725C13.4998 9.17725 12.5953 10.082 12.5953 11.1938C12.5953 12.3059 13.4998 13.2105 14.6119 13.2105C15.7237 13.2105 16.6282 12.3057 16.6282 11.1938C16.6282 10.082 15.7237 9.17725 14.6119 9.17725V9.17725ZM14.6119 11.9539C14.1927 11.9539 13.8516 11.613 13.8516 11.1938C13.8516 10.7747 14.1927 10.4338 14.6119 10.4338C15.0308 10.4338 15.3719 10.7747 15.3719 11.1938C15.3719 11.613 15.0308 11.9539 14.6119 11.9539Z"
    />
    <path
      d="M5.64636 8.39111C5.99329 8.39111 6.27454 8.10986 6.27454 7.7627V5.90479H7.61462C7.96155 5.90479 8.2428 5.62378 8.2428 5.27661C8.2428 4.92969 7.96155 4.64844 7.61462 4.64844H5.64636C5.29944 4.64844 5.01819 4.92969 5.01819 5.27661V7.7627C5.01819 8.10986 5.29944 8.39111 5.64636 8.39111Z"
    />
    <path
      d="M8.22943 16.5215H6.37152V14.4695C6.37152 14.1226 6.09027 13.8413 5.74335 13.8413C5.39642 13.8413 5.11517 14.1226 5.11517 14.4695V17.1499C5.11517 17.4968 5.39642 17.7781 5.74335 17.7781H8.22943C8.57636 17.7781 8.85761 17.4968 8.85761 17.1499C8.85761 16.8027 8.57636 16.5215 8.22943 16.5215V16.5215Z"
    />
    <path
      d="M29.4159 3.2248C29.4943 3.2248 29.5738 3.21015 29.6507 3.1789C29.9725 3.04926 30.128 2.68305 29.9984 2.36152L29.9921 2.34565C29.8622 2.02387 29.4962 1.86836 29.1744 1.99824C28.8527 2.12788 28.6971 2.49409 28.8268 2.81587L28.8331 2.83149C28.932 3.07636 29.1674 3.2248 29.4159 3.2248Z"
    />
    <path
      d="M10.3665 5.86682C10.6882 5.73669 10.8435 5.37073 10.7136 5.04895L10.7073 5.03332C10.5771 4.71155 10.2109 4.55627 9.8894 4.6864C9.56762 4.81628 9.41235 5.18249 9.54248 5.50427L9.54883 5.5199C9.6477 5.76453 9.88281 5.91272 10.1313 5.91272C10.2097 5.91272 10.2895 5.89807 10.3665 5.86682Z"
    />
    <path
      d="M4.44225 0.900265C4.54112 1.14465 4.77648 1.29309 5.02477 1.29309C5.10338 1.29309 5.18297 1.27819 5.26012 1.24719C5.58189 1.11706 5.73717 0.750851 5.60704 0.429318L5.60069 0.413693C5.47057 0.0919162 5.1046 -0.063357 4.78282 0.0667697C4.46129 0.196652 4.30602 0.562863 4.4359 0.884396L4.44225 0.900265Z"
    />
    <path
      d="M28.8274 19.9813L28.8337 19.9972C28.9326 20.2416 29.1677 20.39 29.4162 20.39C29.4946 20.39 29.5744 20.3754 29.6516 20.3441C29.9731 20.214 30.1284 19.8478 29.9985 19.5262L29.9922 19.5106C29.862 19.1888 29.4958 19.0336 29.1743 19.1637C28.8528 19.2936 28.6975 19.6598 28.8274 19.9813V19.9813Z"
    />
  </svg>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'VaultNavigationIcon',
  });
</script>

<style scoped></style>
