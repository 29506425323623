import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { key: 1 };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_alert = _resolveComponent("alert");
    return ($props.show)
        ? (_openBlock(), _createBlock(_component_alert, {
            key: 0,
            onClose: _cache[2] || (_cache[2] = ($event) => ($setup.closeModal()))
        }, {
            header: _withCtx(() => [
                ($props.modelValue.isFolder)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('passwordManager.modals.removeCategory')), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('passwordManager.modals.removePassword')), 1))
            ]),
            default: _withCtx(() => [
                ($props.modelValue.isFolder)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('passwordManager.modals.removeCategoryMessage')), 1))
                    : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('passwordManager.modals.removePasswordMessage')), 1))
            ]),
            actions: _withCtx(() => [
                _createElementVNode("button", {
                    type: "button",
                    class: "mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 sm:w-auto sm:text-sm",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
                    //@ts-ignore
                    (...args) => ($setup.closeModal && $setup.closeModal(...args)), ["stop"]))
                }, _toDisplayString(_ctx.$t(`forms.buttons.cancel`)), 1),
                _createElementVNode("button", {
                    type: "button",
                    class: "mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 ml-2 sm:w-auto sm:text-sm",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(
                    //@ts-ignore
                    (...args) => ($setup.handleDelete && $setup.handleDelete(...args)), ["stop"]))
                }, _toDisplayString(_ctx.$t(`forms.buttons.remove`)), 1)
            ]),
            _: 1
        }))
        : _createCommentVNode("", true);
}
