import { store } from '@/store';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
const copyToClipboardFn = function (el) {
    navigator.clipboard.writeText(el.innerHTML).then(() => {
        store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
            type: ToastType.SUCCESS,
            message: `copiedToClipboard`,
        });
    });
};
export default {
    beforeMount: function (el, _) {
        el.addEventListener('click', function () {
            copyToClipboardFn(el);
        });
    },
    unmounted: function (_) {
        document.removeEventListener('click', copyToClipboardFn);
    },
};
