import { __awaiter } from "tslib";
import { defineComponent, onBeforeMount } from 'vue';
import LanguageSelector from '@/components/general/LanguageSelector/LanguageSelector.vue';
import { config } from '@/config';
import CommonVaultSetup from '@/components/setup/CommonVaultSetup.vue';
import SingleVaultSetup from '@/components/setup/SingleVaultSetup.vue';
import { isSetupCompleted } from '@/services/setup.service';
import { useRouter } from 'vue-router';
export default defineComponent({
    name: 'Setup',
    components: {
        CommonVaultSetup,
        SingleVaultSetup,
        LanguageSelector,
    },
    setup() {
        const router = useRouter();
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            const isVaultSetupCompleted = yield isSetupCompleted();
            if (!isVaultSetupCompleted) {
                return;
            }
            return proceedToSelection();
        }));
        const proceedToSelection = () => __awaiter(this, void 0, void 0, function* () {
            return router.push({ name: 'Selection' });
        });
        return {
            isSingleVault: config.value.env.isSingleVault,
        };
    },
});
