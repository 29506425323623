import { __awaiter } from "tslib";
import { config } from '@/config';
import axios from 'axios';
export const getFolderPath = (spaceId, folderId) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    return (_a = (yield axios.get(`${config.value.apiEndPoint}/folders/${spaceId}/${folderId}/path`))) === null || _a === void 0 ? void 0 : _a.data;
});
export const getFolderStructure = (spaceId, folderId) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    return (_b = (yield axios.get(`${config.value.apiEndPoint}/folders/${spaceId}/${folderId}/folderTree`))) === null || _b === void 0 ? void 0 : _b.data;
});
export const editFolder = (spaceId, folderUuid, folderDetails) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    return (_c = (yield axios.put(`${config.value.apiEndPoint}/folders/${spaceId}/${folderUuid}/edit`, folderDetails))) === null || _c === void 0 ? void 0 : _c.data;
});
export const removeFolder = (spaceId, folderUuid, moveFolderDto) => __awaiter(void 0, void 0, void 0, function* () {
    var _d;
    return (_d = (yield axios.delete(`${config.value.apiEndPoint}/folders/${spaceId}/${folderUuid}`, { data: moveFolderDto }))) === null || _d === void 0 ? void 0 : _d.data;
});
export const getFilesInSpaceByFolderId = (spaceId, folderId) => __awaiter(void 0, void 0, void 0, function* () {
    var _e;
    return (_e = (yield axios.get(`${config.value.apiEndPoint}/folders/${spaceId}/folder?folderUuid=${folderId ? folderId : ''}`))) === null || _e === void 0 ? void 0 : _e.data;
});
