export var InputEvent;
(function (InputEvent) {
    InputEvent[InputEvent["NONE"] = 0] = "NONE";
    InputEvent[InputEvent["SUBMIT"] = 1] = "SUBMIT";
    InputEvent[InputEvent["BLUR"] = 2] = "BLUR";
})(InputEvent || (InputEvent = {}));
export var InputType;
(function (InputType) {
    InputType["TEXT"] = "text";
    InputType["EMAIL"] = "email";
    InputType["TEXT_AREA"] = "textarea";
    InputType["SELECT"] = "select";
    InputType["NUMBER"] = "number";
    InputType["FILE"] = "file";
    InputType["PASSWORD"] = "password";
})(InputType || (InputType = {}));
