import { defineComponent } from 'vue';
import InvitationVault from '@/components/invitation/InvitationVault.vue';
import InvitationSingleVault from '@/components/invitation/InvitationSingleVault.vue';
import { config } from '@/config';
export default defineComponent({
    name: 'Invitation',
    components: {
        InvitationSingleVault,
        InvitationVault,
    },
    setup() {
        return {
            isAppPreregistrationEnabled: config.value.env.isAppPreregistrationEnabled,
        };
    },
});
