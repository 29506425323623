import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "w-full h-screen"
};
export function render(_ctx, _cache) {
    const _component_router_view = _resolveComponent("router-view");
    const _component_toast_container = _resolveComponent("toast-container");
    const _component_spinner_modal = _resolveComponent("spinner-modal");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (!_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_router_view)
            ]))
            : _createCommentVNode("", true),
        _createVNode(_component_toast_container, { class: "hidden md:flex" }),
        (_ctx.currentUser && _ctx.currentUser.dockerStatus === _ctx.DockerStatus.RESTARTING)
            ? (_openBlock(), _createBlock(_component_spinner_modal, { key: 1 }, {
                message: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('vault.restarting')), 1)
                ]),
                _: 1
            }))
            : _createCommentVNode("", true)
    ], 64));
}
