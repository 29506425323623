import { computed, defineComponent, ref } from 'vue';
import { onBeforeMount } from '@vue/runtime-core';
import { isUnderMaxSize } from '@/utils/file.utils';
export default defineComponent({
    name: 'FileTreeView',
    props: {
        tree: Array,
    },
    emits: ['update:tree'],
    setup(props, ctx) {
        const isOpen = ref([]);
        onBeforeMount(() => {
            props.tree.forEach(() => {
                isOpen.value.push(false);
            });
        });
        const toggle = (treeItem, index) => {
            if (treeItem.isFolder) {
                isOpen.value[index] = !isOpen.value[index];
            }
        };
        const sortedTree = computed(() => {
            return props.tree.sort((a, b) => {
                if (a.isFile && b.isFile) {
                    return a.name.localeCompare(b.name);
                }
                return Number(b.isFolder) - Number(a.isFolder);
            });
        });
        const removeItemFromTree = (item) => {
            const rootItemIndex = props.tree.findIndex(treeItem => treeItem.name === item.name);
            if (rootItemIndex === -1) {
                return;
            }
            const newTree = [...props.tree];
            newTree.splice(rootItemIndex, 1);
            ctx.emit('update:tree', newTree);
        };
        return {
            sortedTree,
            isOpen,
            removeItemFromTree,
            toggle,
            isUnderMaxSize,
        };
    },
});
