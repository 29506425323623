import Button from '@/components/general/Button.vue';
import { defineComponent, ref } from 'vue';
import { SearchIcon, XIcon } from '@heroicons/vue/solid';
export default defineComponent({
    name: 'SearchBar',
    components: { Button, SearchIcon, XIcon },
    props: { searchValue: String, lazy: Boolean, buttons: Boolean },
    emits: ['update:searchValue', 'submit'],
    setup({ lazy }, ctx) {
        const searchString = ref('');
        const timeout = ref(null);
        const changeSearchValue = () => {
            const searchValue = searchString.value.trim();
            if (lazy) {
                clearTimeout(timeout.value);
                timeout.value = setTimeout(() => {
                    ctx.emit('update:searchValue', searchValue);
                }, 1000);
                return;
            }
            ctx.emit('update:searchValue', searchValue);
        };
        const submitSearch = () => {
            const searchValue = searchString.value.trim();
            ctx.emit('submit', searchValue);
        };
        const clearSearch = event => {
            searchString.value = '';
            ctx.emit('update:searchValue', searchString.value);
        };
        return {
            searchString,
            changeSearchValue,
            submitSearch,
            clearSearch,
        };
    },
});
