export var AcceptedFiles;
(function (AcceptedFiles) {
    AcceptedFiles["DOC"] = "doc";
    AcceptedFiles["DOCX"] = "docx";
    AcceptedFiles["PDF"] = "pdf";
    AcceptedFiles["EID"] = "eid";
    AcceptedFiles["XLS"] = "xls";
    AcceptedFiles["XLSX"] = "xlsx";
    AcceptedFiles["PNG"] = "png";
    AcceptedFiles["JPG"] = "jpg";
    AcceptedFiles["JPEG"] = "jpeg";
    AcceptedFiles["GIF"] = "gif";
    AcceptedFiles["PPT"] = "ppt";
    AcceptedFiles["PPTX"] = "pptx";
    AcceptedFiles["TXT"] = "txt";
})(AcceptedFiles || (AcceptedFiles = {}));
