export const fileManagerState = {
    workspaces: [],
    workspaceItems: [],
    selectedItemDetails: undefined,
    selectedItemsIds: [],
    selectedWorkspaceId: undefined,
    selectedFolderId: undefined,
    searchResults: [],
    currentSearchId: undefined,
};
