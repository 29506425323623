import { __awaiter } from "tslib";
import axios from 'axios';
import { config } from '@/config';
/* Shares */
export const shareItem = (spaceId, share) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    return (_a = (yield axios.post(`${config.value.apiEndPoint}/share/${spaceId}/createShare`, share))) === null || _a === void 0 ? void 0 : _a.data;
});
export const createTemporarySpace = (email, key) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const tempSpaceDetails = {
        email: email,
        key: key,
    };
    return (_b = (yield axios.post(`${config.value.apiEndPoint}/share/createTempSpace`, tempSpaceDetails))) === null || _b === void 0 ? void 0 : _b.data;
});
export const getShares = () => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    return (_c = (yield axios.get(`${config.value.apiEndPoint}/share/getShares`))) === null || _c === void 0 ? void 0 : _c.data;
});
export const getTemporaryUserById = (tempUserId) => __awaiter(void 0, void 0, void 0, function* () {
    var _d;
    return (_d = (yield axios.get(`${config.value.apiEndPoint}/share/getTempUser/${tempUserId}`))) === null || _d === void 0 ? void 0 : _d.data;
});
