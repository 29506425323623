import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Form = _resolveComponent("Form");
    const _component_modal = _resolveComponent("modal");
    return _withDirectives((_openBlock(), _createBlock(_component_modal, {
        onClose: _cache[1] || (_cache[1] = ($event) => (_ctx.closeModal()))
    }, {
        header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('vault.editVault')), 1)
        ]),
        content: _withCtx(() => [
            _createVNode(_component_Form, {
                "form-data": _ctx.form,
                "onUpdate:form-data": _cache[0] || (_cache[0] = ($event) => ((_ctx.form) = $event)),
                onSubmit: _ctx.confirmEdit,
                onCancel: _ctx.closeModal
            }, null, 8, ["form-data", "onSubmit", "onCancel"])
        ]),
        _: 1
    }, 512)), [
        [_vShow, _ctx.show]
    ]);
}
