import { io as socketIo } from 'socket.io-client';
export class Namespace {
    constructor(namespace, options) {
        this._namespaceSocket = socketIo(`${window.location.origin}/${namespace}`, options);
        this.init();
    }
    init() { }
    get socket() {
        return this._namespaceSocket;
    }
}
