import Button from '@/components/general/Button.vue';
import Alert from '@/components/general/Alert.vue';
import Form from '@/components/general/form/Form.vue';
import { useStore } from '@/store';
import { FileManagerActionTypes } from '@/store/modules/FileManager/fileManager.actions';
import { convertToTEntry } from '@/utils';
import { computed } from 'vue';
export default {
    name: 'DeleteFileModal',
    components: {
        Form,
        Alert,
        Button,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:show'],
    setup(_, ctx) {
        const store = useStore();
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        const handleDelete = () => {
            store.dispatch(FileManagerActionTypes.DELETE_ITEMS, { items: items.value });
            closeModal();
        };
        const items = computed(() => {
            var _a;
            if (((_a = store.getters.selectedItemsIds) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                return store.getters.selectedItemsIds.map(itemId => {
                    const item = store.getters.workspaceItems.find(item => (item === null || item === void 0 ? void 0 : item['fileUuid']) === itemId || (item === null || item === void 0 ? void 0 : item['folderUuid']) === itemId);
                    return convertToTEntry(item);
                });
            }
            return [convertToTEntry(store.getters.selectedItemDetails)];
        });
        return {
            items,
            open,
            closeModal,
            handleDelete,
        };
    },
};
