import { defineComponent } from 'vue';
import TabLayout from '@/layout/TabLayout.vue';
export default defineComponent({
    name: 'AdminSettings',
    components: {
        TabLayout,
    },
    setup() {
        return {};
    },
});
