import { computed, defineComponent, ref } from 'vue';
import { onBeforeMount } from '@vue/runtime-core';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { CalendarIcon } from '@heroicons/vue/outline';
import { Days, Months } from '@/components/general/datePicker/datePicker';
import moment from 'moment';
export default defineComponent({
    name: 'DatePicker',
    components: {
        Popover,
        PopoverButton,
        PopoverPanel,
        CalendarIcon,
    },
    props: {
        modelValue: Date,
    },
    setup(props, ctx) {
        const today = ref(new Date());
        const month = ref(NaN);
        const year = ref(NaN);
        const numberOfDays = ref([]);
        const emptyDays = ref([]);
        const days = ref(Object.keys(Days));
        onBeforeMount(() => {
            month.value = today.value.getMonth();
            year.value = today.value.getFullYear();
            setDaysOfMonth();
        });
        const isToday = day => {
            const selectedDate = new Date(year.value, month.value, day);
            return today.value.toDateString() === selectedDate.toDateString();
        };
        const isPast = computed(() => (day) => {
            const selectedDate = new Date(year.value, month.value, day || today.value.getDay());
            return moment(selectedDate) < moment(today.value) && !isToday(day);
        });
        const isSelectedDay = day => {
            const selectedDate = new Date(year.value, month.value, day);
            return ((props.modelValue && selectedDate.toDateString() === props.modelValue.toDateString() && !isToday(day)) ||
                (isToday(day) && (!props.modelValue || props.modelValue.toDateString() === today.value.toDateString())));
        };
        const setDate = (day, close) => {
            if (isPast.value(day)) {
                return;
            }
            const newDate = new Date(year.value, month.value, day);
            ctx.emit('update:modelValue', newDate);
            ctx.emit('select', newDate);
            close();
        };
        const setDaysOfMonth = () => {
            const dayOfWeek = moment(new Date(year.value, month.value, 1)).weekday();
            const daysInMonth = new Date(year.value, month.value + 1, 0).getDate();
            const newEmptyDays = [];
            for (let i = 1; i <= dayOfWeek; i++) {
                newEmptyDays.push(i);
            }
            const daysArray = [];
            for (let i = 1; i <= daysInMonth; i++) {
                daysArray.push(i);
            }
            emptyDays.value = newEmptyDays;
            numberOfDays.value = daysArray;
        };
        const formatDate = () => {
            if (!props.modelValue) {
                return null;
            }
            return moment(props.modelValue).format('DD MMMM YYYY');
        };
        const increaseMonth = () => {
            month.value++;
            if (month.value > 11) {
                month.value = 0;
                year.value++;
            }
            setDaysOfMonth();
        };
        const decreaseMonth = () => {
            month.value--;
            if (month.value < 0) {
                month.value = 11;
                year.value--;
            }
            setDaysOfMonth();
        };
        return {
            days,
            months: Object.values(Months),
            isPast,
            year,
            month,
            emptyDays,
            numberOfDays,
            formatDate,
            isToday,
            isSelectedDay,
            setDate,
            increaseMonth,
            decreaseMonth,
        };
    },
});
