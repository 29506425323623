import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Transition as _Transition, vModelCheckbox as _vModelCheckbox, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-fb127770"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "mt-1 relative"
};
const _hoisted_2 = { class: "block truncate" };
const _hoisted_3 = { class: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" };
const _hoisted_4 = {
    key: 1,
    class: "mt-2"
};
const _hoisted_5 = { class: "flex flex-row mt-2 justify-center items-center" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Tooltip = _resolveComponent("Tooltip");
    const _component_ListboxLabel = _resolveComponent("ListboxLabel");
    const _component_SelectorIcon = _resolveComponent("SelectorIcon");
    const _component_ListboxButton = _resolveComponent("ListboxButton");
    const _component_CheckIcon = _resolveComponent("CheckIcon");
    const _component_ListboxOption = _resolveComponent("ListboxOption");
    const _component_ListboxOptions = _resolveComponent("ListboxOptions");
    const _component_Listbox = _resolveComponent("Listbox");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_Listbox, {
            as: "div",
            class: "mb-4",
            modelValue: _ctx.fieldValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.fieldValue) = $event)),
            name: _ctx.inputDetails.name,
            id: _ctx.inputDetails.name,
            disabled: _ctx.inputDetails.readonly,
            placeholder: _ctx.inputDetails.placeholder
        }, {
            default: _withCtx(() => [
                _withDirectives(_createVNode(_component_ListboxLabel, {
                    class: "flex items-center text-sm font-medium text-gray-700",
                    for: _ctx.inputDetails.name
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(`forms.labels.${_ctx.inputDetails.label}`)) + " " + _toDisplayString(_ctx.inputDetails.required ? '*' : '') + " ", 1),
                        (_ctx.inputDetails.info)
                            ? (_openBlock(), _createBlock(_component_Tooltip, {
                                key: 0,
                                class: "-mb-1 ml-1",
                                text: _ctx.inputDetails.info
                            }, null, 8, ["text"]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["for"]), [
                    [_vShow, _ctx.inputDetails.label]
                ]),
                (_ctx.inputDetails.canChange)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(_component_ListboxButton, { class: "relative w-full bg-white border border-gray-300 rounded-md pl-3 m-0 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-0 focus:ring-button focus:border-button sm:text-sm" }, {
                            default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.optionValue(_ctx.fieldValue)), 1),
                                _createElementVNode("span", _hoisted_3, [
                                    _createVNode(_component_SelectorIcon, {
                                        class: "h-5 w-5 text-gray-400",
                                        "aria-hidden": "true"
                                    })
                                ])
                            ]),
                            _: 1
                        }),
                        _createVNode(_Transition, {
                            "leave-active-class": "transition ease-in duration-100",
                            "leave-from-class": "opacity-100",
                            "leave-to-class": "opacity-0"
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_ListboxOptions, { class: "absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm" }, {
                                    default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inputDetails.items, (item) => {
                                            return (_openBlock(), _createBlock(_component_ListboxOption, {
                                                as: "template",
                                                value: item.value,
                                                key: item.value
                                            }, {
                                                default: _withCtx(({ active, selected }) => [
                                                    _createElementVNode("li", {
                                                        class: _normalizeClass([active ? 'text-white bg-button' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-8 pr-4'])
                                                    }, [
                                                        _createElementVNode("span", {
                                                            class: _normalizeClass([selected ? 'font-semibold' : 'font-normal', 'block truncate'])
                                                        }, _toDisplayString(_ctx.optionValue(item.value)), 3),
                                                        selected
                                                            ? (_openBlock(), _createElementBlock("span", {
                                                                key: 0,
                                                                class: _normalizeClass([active ? 'text-white' : 'text-button', 'absolute inset-y-0 left-0 flex items-center pl-1.5'])
                                                            }, [
                                                                _createVNode(_component_CheckIcon, {
                                                                    class: "h-5 w-5",
                                                                    "aria-hidden": "true"
                                                                })
                                                            ], 2))
                                                            : _createCommentVNode("", true)
                                                    ], 2)
                                                ]),
                                                _: 2
                                            }, 1032, ["value"]));
                                        }), 128))
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        })
                    ]))
                    : _createCommentVNode("", true)
            ]),
            _: 1
        }, 8, ["modelValue", "name", "id", "disabled", "placeholder"]),
        (!_ctx.inputDetails.canChange)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.inputDetails.capitalize ? 'capitalize' : '')
            }, _toDisplayString(_ctx.inputDetails.selectedValue), 3))
            : _createCommentVNode("", true),
        (_ctx.inputDetails.checkboxWarning && _ctx.inputDetails.checkboxWarning.condition === _ctx.fieldValue && _ctx.inputDetails.canChange)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                    _withDirectives(_createElementVNode("input", {
                        class: "w-12",
                        type: "checkbox",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.checkboxValue) = $event))
                    }, null, 512), [
                        [_vModelCheckbox, _ctx.checkboxValue]
                    ]),
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t(_ctx.inputDetails.checkboxWarning.message)), 1)
                ])
            ]))
            : _createCommentVNode("", true)
    ], 64));
}
