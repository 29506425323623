import { __awaiter } from "tslib";
import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue';
import Avatar from '@/components/general/Avatar.vue';
import { formatDate } from '@/filters';
import SearchBar from '@/components/vault/SearchBar.vue';
import { SortType, Table } from '@jimber/shared-components';
import { translate } from '@/filters';
import { getVaultsOfUser } from '@/services/admin.service';
import EmptyTableState from '@/components/general/emptyStates/EmptyTableState.vue';
import VaultNavigationIcon from '@/components/icons/navigation/VaultNavigationIcon.vue';
export default defineComponent({
    name: 'AdminVaultUserOverview',
    components: {
        VaultNavigationIcon,
        Avatar,
        SearchBar,
        Table,
        EmptyTableState,
    },
    props: {
        selectedVaultUser: Object,
    },
    emits: ['clickedVaultName'],
    setup({ selectedVaultUser }) {
        const isLoading = ref(true);
        const userVaults = ref([]);
        const searchedUserVaults = ref([]);
        const vaultSearch = ref('');
        const sortedField = ref({
            prop: 'lastAccessed',
            order: SortType.DESCENDING,
        });
        const headers = computed(() => [
            {
                key: 'name',
                displayName: translate('tables.headers.name'),
                enableSorting: true,
            },
            {
                key: 'lastAccessed',
                displayName: translate('tables.headers.lastSignedIn'),
                enableSorting: true,
                formatter: entry => {
                    if (!entry.lastAccessed) {
                        return translate('tables.headers.neverLoggedIn');
                    }
                    const date = new Date(entry.lastAccessed);
                    return formatDate(date);
                },
            },
        ]);
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            userVaults.value = yield getVaultsOfUser(selectedVaultUser.id, {
                skip: 0,
                take: 15,
                field: (_a = sortedField.value) === null || _a === void 0 ? void 0 : _a.prop,
                order: (_b = sortedField.value) === null || _b === void 0 ? void 0 : _b.order,
            });
            isLoading.value = false;
        }));
        watch(vaultSearch, (value) => __awaiter(this, void 0, void 0, function* () {
            var _c, _d;
            if (value === '') {
                return;
            }
            const newSearchedUsers = yield getVaultsOfUser(selectedVaultUser.id, {
                value,
                skip: 0,
                take: 15,
                field: (_c = sortedField.value) === null || _c === void 0 ? void 0 : _c.prop,
                order: (_d = sortedField.value) === null || _d === void 0 ? void 0 : _d.order,
            });
            if (newSearchedUsers.length <= 0) {
                searchedUserVaults.value = [];
                return;
            }
            searchedUserVaults.value = newSearchedUsers;
        }));
        const sortVaults = () => __awaiter(this, void 0, void 0, function* () {
            var _e, _f;
            const newVaults = yield getVaultsOfUser(selectedVaultUser.id, {
                value: vaultSearch.value !== '' ? vaultSearch.value : undefined,
                skip: 0,
                take: 15,
                field: (_e = sortedField.value) === null || _e === void 0 ? void 0 : _e.prop,
                order: (_f = sortedField.value) === null || _f === void 0 ? void 0 : _f.order,
            });
            if (newVaults.length <= 0) {
                return;
            }
            if (vaultSearch.value !== '') {
                searchedUserVaults.value = newVaults;
                return newVaults;
            }
            userVaults.value = newVaults;
        });
        const getMoreVaults = ({ index }) => __awaiter(this, void 0, void 0, function* () {
            var _g, _h;
            const newVaults = yield getVaultsOfUser(selectedVaultUser.id, {
                value: vaultSearch.value !== '' ? vaultSearch.value : undefined,
                skip: index * 15,
                take: 15,
                field: (_g = sortedField.value) === null || _g === void 0 ? void 0 : _g.prop,
                order: (_h = sortedField.value) === null || _h === void 0 ? void 0 : _h.order,
            });
            if (newVaults.length <= 0) {
                return [];
            }
            if (vaultSearch.value !== '') {
                searchedUserVaults.value = searchedUserVaults.value.concat(newVaults);
                return newVaults;
            }
            userVaults.value = userVaults.value.concat(newVaults);
            return newVaults;
        });
        const vaults = computed(() => (vaultSearch.value !== '' ? searchedUserVaults.value : userVaults.value));
        return {
            isLoading,
            sortedField,
            vaults,
            vaultSearch,
            headers,
            formatDate,
            sortVaults,
            getMoreVaults,
        };
    },
});
