export var WebdavMutationTypes;
(function (WebdavMutationTypes) {
    WebdavMutationTypes["ADD_MOUNT"] = "ADD_MOUNT";
})(WebdavMutationTypes || (WebdavMutationTypes = {}));
export const webdavMutations = {
    [WebdavMutationTypes.ADD_MOUNT](state, resp) {
        state.webdavLoginPassword = resp.password;
        state.webdavLoginUsername = resp.username;
    },
};
