import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Tab',
    props: {
        selectedTab: String,
        tabs: Object,
    },
    emits: ['update:selectedTab'],
    setup({ tabs }, ctx) {
        const changeTab = tab => {
            ctx.emit('update:selectedTab', tab);
        };
        return {
            changeTab,
        };
    },
});
