import { mapGetters } from 'vuex';
import { useRoute } from 'vue-router';
import WorkSpaceDropdown from '@/components/WorkSpaceDropDown.vue';
import UserDropdown from './UserDropdown.vue';
import Avatar from './Avatar.vue';
import LanguageSelector from './LanguageSelector/LanguageSelector.vue';
import Button from '@/components/general/Button.vue';
import MobileLanguageSelector from '@/components/general/LanguageSelector/MobileLanguageSelector.vue';
export default {
    name: 'MobileMenu',
    props: {},
    emits: ['closeMenu', 'doLogout', 'goToSelection'],
    components: {
        WorkSpaceDropdown,
        UserDropdown,
        Avatar,
        LanguageSelector,
        Button,
        MobileLanguageSelector,
    },
    setup() {
        const currentRoute = useRoute();
        return {
            currentRoute,
        };
    },
    computed: Object.assign({}, mapGetters(['currentUser'])),
};
