import { __awaiter } from "tslib";
import axios from 'axios';
import { config } from '@/config';
import { useStore } from '@/store';
import { Permissions } from '@/types/file/permission.enum';
/* Users */
export const getVaultUsers = (options) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    return (_a = (yield axios.get(`${config.value.apiEndPoint}/users`, {
        params: {
            vault: options.vaultId,
            q: options === null || options === void 0 ? void 0 : options.value,
            order: options === null || options === void 0 ? void 0 : options.order,
            field: options === null || options === void 0 ? void 0 : options.field,
            skip: options === null || options === void 0 ? void 0 : options.skip,
            take: options === null || options === void 0 ? void 0 : options.take,
        },
    }))) === null || _a === void 0 ? void 0 : _a.data;
});
export const getVaultsOfUser = (userId, options) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    return (_b = (yield axios.get(`${config.value.apiEndPoint}/users/${userId}/spaces`, {
        params: {
            q: options === null || options === void 0 ? void 0 : options.value,
            order: options === null || options === void 0 ? void 0 : options.order,
            field: options === null || options === void 0 ? void 0 : options.field,
            skip: options === null || options === void 0 ? void 0 : options.skip,
            take: options === null || options === void 0 ? void 0 : options.take,
        },
    }))) === null || _b === void 0 ? void 0 : _b.data;
});
export const getVaultUserHistory = (userId, startIndex, limit) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    return (_c = (yield axios.get(`${config.value.apiEndPoint}/users/${userId}/history?startIndex=${startIndex}&limit=${limit}`))) === null || _c === void 0 ? void 0 : _c.data;
});
export const checkIfUsernameExistsInAuthenticator = (username) => __awaiter(void 0, void 0, void 0, function* () {
    var _d;
    return (_d = (yield axios.post(`${config.value.apiEndPoint}/users/username/exists`, {
        username: username,
    }))) === null || _d === void 0 ? void 0 : _d.data;
});
export const checkIfEmailAlreadyExists = (email) => __awaiter(void 0, void 0, void 0, function* () {
    var _e;
    return (_e = (yield axios.post(`${config.value.apiEndPoint}/users/email/exists`, {
        email: email,
    }))) === null || _e === void 0 ? void 0 : _e.data;
});
/* Spaces */
export const getFoldersAndSubFolders = (spaceId) => __awaiter(void 0, void 0, void 0, function* () {
    var _f;
    return (_f = (yield axios.get(`${config.value.apiEndPoint}/spaces/${spaceId}/allFolders`))) === null || _f === void 0 ? void 0 : _f.data;
});
export const getFileDetailsByFileUuid = (spaceId, fileUuid) => __awaiter(void 0, void 0, void 0, function* () {
    var _g;
    return (_g = (yield axios.get(`${config.value.apiEndPoint}/spaces/${spaceId}/files/${fileUuid}`))) === null || _g === void 0 ? void 0 : _g.data;
});
export const deleteUserFromSpace = (spaceId, userId) => __awaiter(void 0, void 0, void 0, function* () {
    return axios.delete(`${config.value.apiEndPoint}/spaces/${spaceId}/users/${userId}`);
});
export const deleteFileFromSpace = (spaceId, fileUuid) => __awaiter(void 0, void 0, void 0, function* () {
    return (yield axios.delete(`${config.value.apiEndPoint}/spaces/${spaceId}/files/${fileUuid}`)).data;
});
/* Files */
export const changeFolderFiles = (spaceId, folderDetails) => __awaiter(void 0, void 0, void 0, function* () {
    var _h;
    return (_h = (yield axios.put(`${config.value.apiEndPoint}/files/${spaceId}/changefolder`, folderDetails))) === null || _h === void 0 ? void 0 : _h.data;
});
export const updateFileDetails = (spaceId, fileUuid, fileUpdateDTO) => __awaiter(void 0, void 0, void 0, function* () {
    var _j;
    return (_j = (yield axios.put(`${config.value.apiEndPoint}/spaces/${spaceId}/files/${fileUuid}`, fileUpdateDTO))) === null || _j === void 0 ? void 0 : _j.data;
});
/* Invitation */
export const inviteSpaceUser = (spaceId, data) => __awaiter(void 0, void 0, void 0, function* () {
    var _k, _l;
    const store = useStore();
    return (_l = (yield axios.put(`${config.value.vaultSpaceApi}/${(_k = store.getters.currentUser) === null || _k === void 0 ? void 0 : _k.id}/spaces/${spaceId}/invite-user`, data))) === null || _l === void 0 ? void 0 : _l.data;
});
export const recoverVaultUser = (userId, seedPhrase, language) => __awaiter(void 0, void 0, void 0, function* () {
    var _m;
    return (_m = (yield axios.post(`${config.value.apiEndPoint}/users/${userId}/recover`, {
        seedPhrase,
        language,
    }))) === null || _m === void 0 ? void 0 : _m.data;
});
export const updateUser = (user, hasPromoted) => __awaiter(void 0, void 0, void 0, function* () {
    var _o;
    const updatedUser = (_o = (yield axios.put(`${config.value.apiEndPoint}/users`, user))) === null || _o === void 0 ? void 0 : _o.data;
    if (!hasPromoted || !updatedUser) {
        return updatedUser;
    }
    const store = useStore();
    yield axios.put(`${config.value.vaultSpaceApi}/${store.getters.currentUser.id}/spaces/promoteUser`, {
        [user.id]: {
            user: {
                id: user.id,
                username: user.username,
                email: user.email,
            },
            permission: Permissions.ADVANCED_VAULT_USER,
            filePermissions: [],
        },
    });
    return updatedUser;
});
export const editExistingSpaceById = (space) => __awaiter(void 0, void 0, void 0, function* () {
    return (yield axios.put(`${config.value.apiEndPoint}/spaces/${space.id}`, {
        name: space.name,
        notes: space.notes,
    })).data;
});
/* Settings */
export const getSettings = () => __awaiter(void 0, void 0, void 0, function* () {
    var _p;
    return (_p = (yield axios.get(`${config.value.apiEndPoint}/settings`))) === null || _p === void 0 ? void 0 : _p.data;
});
export const setDefaultSettings = (settings) => __awaiter(void 0, void 0, void 0, function* () {
    var _q;
    return (_q = (yield axios.post(`${config.value.apiEndPoint}/settings`, settings))) === null || _q === void 0 ? void 0 : _q.data;
});
export const editSettings = (settings) => __awaiter(void 0, void 0, void 0, function* () {
    var _r;
    return (_r = (yield axios.put(`${config.value.apiEndPoint}/settings`, settings))) === null || _r === void 0 ? void 0 : _r.data;
});
export const getLogos = () => __awaiter(void 0, void 0, void 0, function* () {
    var _s;
    return (_s = (yield axios.get(`${config.value.apiEndPoint}/settings/logo`))) === null || _s === void 0 ? void 0 : _s.data;
});
export const uploadLogo = (logo, type) => __awaiter(void 0, void 0, void 0, function* () {
    var _t;
    const formData = new FormData();
    formData.append('uploadedLogo', logo);
    formData.append('type', type);
    return (_t = (yield axios.put(`${config.value.apiEndPoint}/settings/logo`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }))) === null || _t === void 0 ? void 0 : _t.data;
});
