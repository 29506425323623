import FolderCard from '@/components/general/FolderCard.vue';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';
export default defineComponent({
    name: 'BreadCrumb',
    components: { FolderCard },
    emits: ['openFolder', 'showFolders'],
    props: {
        folders: {
            type: Array,
            default: [],
        },
    },
    setup(_, ctx) {
        const store = useStore();
        const isMenuOpen = ref(false);
        const openFolder = folder => {
            isMenuOpen.value = false;
            ctx.emit('openFolder', folder.folderUuid);
        };
        const rootFolders = computed(() => store.getters.getItemsByParentId(null));
        return {
            isMenuOpen,
            rootFolders,
            openFolder,
        };
    },
});
