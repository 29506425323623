import { __awaiter } from "tslib";
import Modal from '@/components/general/Modal.vue';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import Button from '@/components/general/Button.vue';
import { ToastType } from '@/types/toast/toast.enum';
import { useStore } from '@/store';
import { AdminActionTypes } from '@/store/modules/admin/admin.actions';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { InputType } from '@/types/input/inputDetails';
import Form from '@/components/general/form/Form.vue';
import SpinnerModal from '@/components/vault/modals/SpinnerModal.vue';
import { FileManagerActionTypes } from '@/store/modules/FileManager/fileManager.actions';
export default defineComponent({
    name: 'CreateWorkspaceModal',
    components: {
        Button,
        Modal,
        Form,
        SpinnerModal,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:show'],
    setup(props, ctx) {
        const form = ref({
            name: 'AddWorkSpaceForm',
            inputs: [
                {
                    name: 'vaultName',
                    label: 'vaultName',
                    type: InputType.TEXT,
                    validation: {
                        maxLength: 50,
                        required: true,
                    },
                    placeholder: '',
                },
            ],
            submit: 'create',
            cancel: 'cancel',
        });
        const isLoading = ref(false);
        const store = useStore();
        const router = useRouter();
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        const createWorkspace = (formData) => __awaiter(this, void 0, void 0, function* () {
            if (!formData.isValid) {
                return;
            }
            const doesExists = store.getters.workspaces.find(space => {
                return space.name === formData.values.vaultName.value;
            });
            if (doesExists) {
                return store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: 'spaceNameAlreadyExists',
                    data: {
                        name: formData.values.vaultName.value,
                    },
                });
            }
            // // store.commit(AdminMutationTypes.SET_CREATING_WORKSPACE, true);
            isLoading.value = true;
            const newSpace = yield store.dispatch(AdminActionTypes.ADD_WORKSPACE, formData.values.vaultName.value);
            yield store.dispatch(FileManagerActionTypes.SET_WORKSPACES);
            isLoading.value = false;
            if (newSpace) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.SUCCESS,
                    message: 'successFullyCreated',
                    data: {
                        name: formData.values.vaultName.value,
                    },
                });
                closeModal();
                return router.push({
                    name: 'Files',
                    params: {
                        spaceId: newSpace.id,
                        spaceName: formData.values.vaultName.value,
                    },
                });
            }
        });
        return {
            form,
            closeModal,
            isLoading,
            createWorkspace,
        };
    },
});
