import { __awaiter } from "tslib";
import Button from '@/components/general/Button.vue';
import Alert from '@/components/general/Alert.vue';
import { deleteUserFromShare } from '@/services/password.service';
import { useRoute } from 'vue-router';
export default {
    name: 'RemoveShareModal',
    components: {
        Alert,
        Button,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        selectedPassword: Object,
        selectedUser: Object,
    },
    emits: ['update:show', 'delete-shared-user'],
    setup(props, ctx) {
        var _a;
        const route = useRoute();
        const selectedSpaceId = (_a = route.params) === null || _a === void 0 ? void 0 : _a.spaceId;
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        const handleDelete = () => __awaiter(this, void 0, void 0, function* () {
            const response = yield deleteUserFromShare(selectedSpaceId, props.selectedPassword.id, props.selectedUser.id);
            if (response.status !== 204) {
                return;
            }
            ctx.emit('delete-shared-user');
            closeModal();
        });
        return {
            closeModal,
            handleDelete,
        };
    },
};
