import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "pl-1 md:text-xl lg:text-3xl" };
const _hoisted_2 = { class: "flex h-full" };
const _hoisted_3 = {
    style: { "height": "calc(100vh - 3rem)" },
    class: "overflow-y-auto mobile:p-0 p-4 flex-grow"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_password_list = _resolveComponent("password-list");
    const _component_password_detail = _resolveComponent("password-detail");
    const _component_add_password_modal = _resolveComponent("add-password-modal");
    const _component_add_category_modal = _resolveComponent("add-category-modal");
    const _component_remove_category_modal = _resolveComponent("remove-category-modal");
    const _component_edit_password_modal = _resolveComponent("edit-password-modal");
    const _component_tab_layout = _resolveComponent("tab-layout");
    return (_openBlock(), _createBlock(_component_tab_layout, null, {
        title: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('passwordManager.personalPasswords')), 1)
        ]),
        content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
                (!_ctx.isLoading)
                    ? (_openBlock(), _createElementBlock("aside", {
                        key: 0,
                        class: _normalizeClass(["mobile:w-full w-1/4 h-full overflow-y-auto border-r-2 border-gray-200", { 'mobile:hidden': !!_ctx.currentPassword }])
                    }, [
                        _createVNode(_component_password_list, {
                            items: _ctx.items,
                            "onUpdate:items": _cache[0] || (_cache[0] = ($event) => ((_ctx.items) = $event)),
                            "selected-category": _ctx.currentCategory,
                            "onUpdate:selected-category": _cache[1] || (_cache[1] = ($event) => ((_ctx.currentCategory) = $event)),
                            "selected-item": _ctx.currentPassword,
                            "onUpdate:selected-item": _cache[2] || (_cache[2] = ($event) => ((_ctx.currentPassword) = $event)),
                            onShowRemoveItem: _ctx.setItemToDelete,
                            onShowAddPassword: _cache[3] || (_cache[3] = ($event) => (_ctx.showAddPassword = true)),
                            onShowAddCategory: _cache[4] || (_cache[4] = ($event) => (_ctx.showAddCategory = true)),
                            personal: ""
                        }, null, 8, ["items", "selected-category", "selected-item", "onShowRemoveItem"])
                    ], 2))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, [
                    (_ctx.currentPassword)
                        ? (_openBlock(), _createBlock(_component_password_detail, {
                            key: 0,
                            personal: "",
                            currentPassword: _ctx.currentPassword,
                            "onUpdate:currentPassword": _cache[5] || (_cache[5] = ($event) => ((_ctx.currentPassword) = $event)),
                            onShowRemoveItem: _ctx.setItemToDelete,
                            onShowEditPassword: _cache[6] || (_cache[6] = ($event) => (_ctx.showEditPassword = true))
                        }, null, 8, ["currentPassword", "onShowRemoveItem"]))
                        : _createCommentVNode("", true)
                ])
            ]),
            (_ctx.showAddPassword)
                ? (_openBlock(), _createBlock(_component_add_password_modal, {
                    key: 0,
                    personal: "",
                    show: _ctx.showAddPassword,
                    "onUpdate:show": _cache[7] || (_cache[7] = ($event) => ((_ctx.showAddPassword) = $event)),
                    "selected-category": _ctx.currentCategory,
                    onCreatePassword: _ctx.updateItem
                }, null, 8, ["show", "selected-category", "onCreatePassword"]))
                : _createCommentVNode("", true),
            (_ctx.showAddCategory)
                ? (_openBlock(), _createBlock(_component_add_category_modal, {
                    key: 1,
                    personal: "",
                    show: _ctx.showAddCategory,
                    "onUpdate:show": _cache[8] || (_cache[8] = ($event) => ((_ctx.showAddCategory) = $event)),
                    "selected-category": _ctx.currentCategory,
                    onCreateCategory: _ctx.updateItem
                }, null, 8, ["show", "selected-category", "onCreateCategory"]))
                : _createCommentVNode("", true),
            (_ctx.showRemoveCategory)
                ? (_openBlock(), _createBlock(_component_remove_category_modal, {
                    key: 2,
                    show: _ctx.showRemoveCategory,
                    "onUpdate:show": _cache[9] || (_cache[9] = ($event) => ((_ctx.showRemoveCategory) = $event)),
                    modelValue: _ctx.currentItem,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event) => ((_ctx.currentItem) = $event)),
                    onRemoveItem: _ctx.handleItemDeletion
                }, null, 8, ["show", "modelValue", "onRemoveItem"]))
                : _createCommentVNode("", true),
            (_ctx.showEditPassword)
                ? (_openBlock(), _createBlock(_component_edit_password_modal, {
                    key: 3,
                    personal: "",
                    show: _ctx.showEditPassword,
                    "onUpdate:show": _cache[11] || (_cache[11] = ($event) => ((_ctx.showEditPassword) = $event)),
                    "selected-password": _ctx.currentPassword,
                    "onUpdate:selected-password": _cache[12] || (_cache[12] = ($event) => ((_ctx.currentPassword) = $event)),
                    onUpdateItem: _ctx.updateItem
                }, null, 8, ["show", "selected-password", "onUpdateItem"]))
                : _createCommentVNode("", true)
        ]),
        _: 1
    }));
}
