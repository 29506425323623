import { __awaiter } from "tslib";
import copyToClipboard from './directive/copyToClipboard.directive';
import clickOutside from '@/directive/clickOuside.directive';
import infiniteScroll from '@/directive/infiniteScroll.directive';
import './assets/index.css';
import { messages } from './constants/translation';
import '@/plugins/axios';
import { store } from '@/store';
import { setLanguage } from '@/utils';
import 'moment/locale/en-gb';
import 'moment/locale/nl';
import { createI18n } from 'vue-i18n';
import { config, setConfigVariables } from '@/config';
import { registerNavigationIconComponents } from '@/components/icons/navigation';
import { initRouter } from '@/router';
import { createApp } from 'vue';
import App from '@/App.vue';
let router;
(function main() {
    return __awaiter(this, void 0, void 0, function* () {
        yield setConfigVariables();
        const i18n = createI18n({
            locale: localStorage.getItem('selectedLanguage') || config.value.defaultLanguage,
            fallbackLocale: 'nl',
            silentTranslationWarn: true,
            silentFallbackWarn: true,
            //@ts-ignore
            messages,
        });
        router = yield initRouter();
        const vueInstance = createApp(App)
            .directive('click-outside', clickOutside)
            .directive('infinite-scroll', infiniteScroll)
            .directive('copy-to-clipboard', copyToClipboard)
            .use(router)
            .use(i18n)
            .use(store);
        setLanguage();
        registerNavigationIconComponents(vueInstance);
        vueInstance.mount('#app');
    });
})();
export { router };
