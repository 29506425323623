import { __awaiter } from "tslib";
import { defineComponent, onBeforeMount, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { getInvitationDetails, getPublicUrl } from '@/services/invitaiton.service';
import Avatar from '@/components/general/Avatar.vue';
import Card from '@/components/general/Card.vue';
import LanguageSelector from '@/components/general/LanguageSelector/LanguageSelector.vue';
import { formatDate, isMobile } from '@/filters';
import { InvitationStatus } from '@/types/invitation/invitation.enum';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { useStore } from '@/store';
import QrCode from 'qrcode.vue';
import Button from '@/components/general/Button.vue';
import { config } from '@/config';
import { ToastType } from '@/types/toast/toast.enum';
import { GeneralActionTypes } from '@/store/modules/general/general.actions';
export default defineComponent({
    name: 'InvitationVault',
    components: {
        Button,
        Avatar,
        Card,
        LanguageSelector,
        QrCode,
    },
    setup() {
        const { params } = useRoute();
        const store = useStore();
        const terms = reactive({
            acceptTerms: false,
            acceptPrivacy: false,
        });
        const invitation = ref(undefined);
        const appUrl = ref('');
        watch(terms, (newTerms) => __awaiter(this, void 0, void 0, function* () {
            if (!newTerms.acceptTerms || !newTerms.acceptPrivacy) {
                return;
            }
            yield store.dispatch(GeneralActionTypes.UPDATE_PRIVACY_STATEMENT, invitation.value.user.id);
        }), {
            deep: true,
        });
        onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
            invitation.value = yield getInvitationDetails(params.invitationId);
            const exposedUrl = yield getPublicUrl();
            const urlForApp = `${exposedUrl}/invitation/getPhrase/${params.invitationId}`;
            appUrl.value = `${config.value.app.urlHead}://register?url=${Buffer.from(urlForApp).toString('base64')}`;
        }));
        const openAppUrl = () => __awaiter(this, void 0, void 0, function* () {
            window.open(appUrl.value, '_self');
        });
        const goToLogin = () => __awaiter(this, void 0, void 0, function* () {
            if (config.value.privacy.hasPrivacyPolicy && (!terms.acceptPrivacy || !terms.acceptTerms) && invitation.value.status === InvitationStatus.PENDING) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: 'acceptTerms',
                });
                return;
            }
            window.location.href = '/signin';
        });
        return {
            contactEmail: config.value.app.contactEmail,
            hasPrivacyPolicy: config.value.privacy.hasPrivacyPolicy,
            invitation,
            appUrl,
            terms,
            config,
            goToLogin,
            openAppUrl,
            isMobile,
            formatDate,
            InvitationStatus,
        };
    },
});
