import { defineComponent, ref } from 'vue';
import { Language } from '@/types';
import { useStore } from '@/store';
import { UserMutationTypes } from '@/store/modules/users/user.mutations';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    name: 'MobileLanguageSelector',
    setup() {
        const i18n = useI18n();
        const selectedLanguage = ref(localStorage.getItem('selectedLanguage'));
        const languages = [
            {
                key: Language.NL,
                shorthand: 'nl',
                longhand: 'Nederlands',
            },
            {
                key: Language.EN,
                shorthand: 'en',
                longhand: 'English',
            },
        ];
        const setLang = lang => {
            i18n.locale.value = lang;
            const store = useStore();
            store.commit(UserMutationTypes.SET_CURRENT_LANGUAGE, lang);
        };
        const clickedLang = lang => {
            selectedLanguage.value = lang;
            const store = useStore();
            store.commit(UserMutationTypes.SET_CURRENT_LANGUAGE, lang);
            i18n.locale.value = lang;
        };
        const getLang = () => {
            return languages.find(x => x.shorthand == i18n.locale.value).shorthand;
        };
        return {
            languages,
            selectedLanguage,
            Language,
            setLang,
            clickedLang,
            getLang,
        };
    },
});
