export var AdminMutationTypes;
(function (AdminMutationTypes) {
    AdminMutationTypes["SET_VAULT_USERS"] = "SET_VAULT_USERS";
    AdminMutationTypes["UPDATE_VAULT_USER"] = "UPDATE_VAULT_USER";
    AdminMutationTypes["REMOVE_VAULT_USER"] = "REMOVE_VAULT_USER";
})(AdminMutationTypes || (AdminMutationTypes = {}));
export const mutations = {
    [AdminMutationTypes.SET_VAULT_USERS](state, users) {
        state.vaultUsers = users;
    },
    [AdminMutationTypes.UPDATE_VAULT_USER](state, user) {
        const userIndex = state.vaultUsers.findIndex(vaultUser => vaultUser.id === user.id);
        if (userIndex === -1) {
            state.vaultUsers.push(user);
        }
        state.vaultUsers[userIndex] = user;
    },
    [AdminMutationTypes.REMOVE_VAULT_USER](state, user) {
        const userIndex = state.vaultUsers.findIndex(vaultUser => vaultUser.id === user.id);
        if (userIndex === -1) {
            return;
        }
        state.vaultUsers.splice(userIndex, 1);
    },
};
