<template>
  <section
    class="rounded text-primary m-1 font-bold cursor-pointer bg-white hover:z-10 transition-shadow"
    :class="{
      'border border-grey-200 w-32 h-32 md:w-40 md:h-40 lg:w-80 lg:h-80 shadow-sm hover:shadow-lg': !showGradient,
      'w-32 h-32 md:w-40 md:h-40 lg:w-52 lg:h-52 shadow-md hover:shadow-2xl': showGradient,
    }"
    :style="{
      backgroundImage: background ? (showGradient ? `linear-gradient(rgba(245, 246, 252, 0.40), rgb(19 61 117 / 73%)), url('${background}')` : `url('${background}'))`) : '',
    }"
  >
    <div class="flex flex-col justify-center items-center h-full truncate">
      <img v-if="icon && logoUrl" class="max-h-32" :src="logoUrl" alt="Icon" />
      <span class="capitalize text-center py-5 max-w-full truncate" :class="{ 'text-white': background }">{{ text }}</span>
    </div>
  </section>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'SelectionCard',
    props: {
      text: {
        type: String,
        required: true,
      },
      color: {
        type: String,
        required: true,
      },
      icon: Boolean,
      showGradient: {
        type: Boolean,
        default: true,
      },
      background: String,
      logoUrl: String,
    },
  });
</script>

<style scoped></style>
