import { camelCase } from 'lodash';
const requireComponent = require.context('./', true, /[A-Z]\w+\.(vue)$/);
export const registerNavigationIconComponents = (app) => {
    requireComponent.keys().forEach(fileName => {
        const componentConfig = requireComponent(fileName);
        const componentName = camelCase(fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, ''));
        app.component(componentName, componentConfig.default || componentConfig);
    });
};
