import { __awaiter } from "tslib";
import { store } from '@/store';
import { FileManagerMutationTypes } from './fileManager.mutations';
import { changeFolderFiles, deleteFileFromSpace, getFileDetailsByFileUuid } from '@/services/admin.service';
import { uploadFile } from '@/services/vaultSpace.service';
import { getFilesInSpaceByFolderId, removeFolder } from '@/services/folder.service';
import { updateLastAccessedTime } from '@/services/space.service';
import { getFileAccessHistory, globalSearchFiles, globalSearchFilesContent, renameFile } from '@/services/file.service';
import { ToastMutationTypes } from '@/store/modules/toasts/toast.mutations';
import { ToastType } from '@/types/toast/toast.enum';
import { AcceptedFiles } from '@/types/file/acceptedFiles.enum';
import { convertToTEntry } from '@/utils';
import { formatDate, translate } from '@/filters';
import { findFileTypeExtension } from '@/utils/file.utils';
import { useRouter } from 'vue-router';
import { SearchType } from '@/types/file/searchType.enum';
export var FileManagerActionTypes;
(function (FileManagerActionTypes) {
    FileManagerActionTypes["DELETE_ITEMS"] = "DELETE_ITEMS";
    FileManagerActionTypes["SET_WORKSPACES"] = "SET_WORKSPACES";
    FileManagerActionTypes["SET_SELECTED_WORKSPACE_ID"] = "SET_SELECTED_WORKSPACE_ID";
    FileManagerActionTypes["ADD_WORKSPACE_ITEMS_FROM_FOLDER"] = "ADD_WORKSPACE_ITEMS_FROM_FOLDER";
    FileManagerActionTypes["CLEAR_AND_UPDATE_WORKSPACE_ITEMS"] = "CLEAR_AND_UPDATE_WORKSPACE_ITEMS";
    FileManagerActionTypes["SET_SELECTED_ITEMS_IDS"] = "SET_SELECTED_ITEMS_IDS";
    FileManagerActionTypes["SET_SELECTED_VAULT_USER"] = "SET_SELECTED_VAULT_USER";
    FileManagerActionTypes["CHANGE_FOLDER_OF_FILE"] = "CHANGE_FOLDER_OF_FILE";
    FileManagerActionTypes["UPLOAD_FILE"] = "UPLOAD_FILE";
    FileManagerActionTypes["UPDATE_FOLDER"] = "UPDATE_FOLDER";
    FileManagerActionTypes["UPDATE_ITEM_NAME"] = "UPDATE_ITEM_NAME";
    FileManagerActionTypes["SET_SELECTED_FOLDER_ID"] = "SET_SELECTED_FOLDER_ID";
    FileManagerActionTypes["SEARCH_IN_SELECTED_WORKSPACE"] = "SEARCH_IN_SELECTED_WORKSPACE";
})(FileManagerActionTypes || (FileManagerActionTypes = {}));
export const actions = {
    [FileManagerActionTypes.DELETE_ITEMS]({ getters, commit, dispatch }, { items, destinationFolderId, sourceFolderId }) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const archivedId = (_a = store.getters.getItemsByParentId(null).find(folder => folder['name'] === 'archived')) === null || _a === void 0 ? void 0 : _a['folderUuid'];
            if (items) {
                let updatedItems = [];
                for (const item of items) {
                    if (item.deleted) {
                        store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                            type: ToastType.ERROR,
                            message: `alreadyDeleted`,
                            data: {
                                name: item.name,
                            },
                        });
                        continue;
                    }
                    if (item.isFolder) {
                        const folder = yield removeFolder(getters.selectedWorkspaceId, item.id, { newFolderId: destinationFolderId });
                        // @todo this was a quick fix for #293 parentuuid is not correct from be -> there was a lot of logic to fix this but this is the most simple solution
                        folder.parentUuid = archivedId;
                        updatedItems.push(folder);
                        continue;
                    }
                    const file = yield deleteFileFromSpace(getters.selectedWorkspaceId, item.id);
                    // @todo this was a quick fix for #293 parentuuid is not correct from be -> there was a lot of logic to fix this but this is the most simple solution
                    if (file) {
                        store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                            type: ToastType.SUCCESS,
                            message: `successFullyDeleted`,
                            data: {
                                name: item.name,
                            },
                        });
                    }
                    file.parentUuid = archivedId;
                    updatedItems.push(file);
                }
                commit(FileManagerMutationTypes.SET_SELECTED_ITEM_DETAILS, null);
                commit(FileManagerMutationTypes.SET_SELECTED_ITEMS_IDS, []);
                commit(FileManagerMutationTypes.REMOVE_FOLDER_ID, sourceFolderId);
                commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, updatedItems);
                return;
            }
            // @todo this is not clearly implemented
            // this is a category removal
            const category = yield removeFolder(getters.selectedWorkspaceId, sourceFolderId, { newFolderId: destinationFolderId });
            if (category.folderUuid !== sourceFolderId) {
                commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, [category]);
            }
            commit(FileManagerMutationTypes.REMOVE_FOLDER_ID, category.folderUuid);
            const router = useRouter();
            if (router.currentRoute.value.params.selectedFolderId !== category.folderUuid) {
                return;
            }
            yield router.push({
                name: 'Files',
                params: {
                    spaceId: router.currentRoute.value.params.spaceId,
                    spaceName: router.currentRoute.value.params.spaceName,
                    selectedFolderId: category.folderUuid,
                },
            });
        });
    },
    [FileManagerActionTypes.SET_WORKSPACES]({ commit }) {
        return __awaiter(this, void 0, void 0, function* () {
            //const workspaces = await getVaults();
            //commit(FileManagerMutationTypes.SET_WORKSPACES, workspaces);
        });
    },
    [FileManagerActionTypes.SET_SELECTED_WORKSPACE_ID]({ commit, dispatch, getters }, workspaceId) {
        return __awaiter(this, void 0, void 0, function* () {
            commit(FileManagerMutationTypes.CLEAR_ITEMS, undefined);
            commit(FileManagerMutationTypes.SET_SELECTED_WORKSPACE_ID, workspaceId);
            const spaceAccess = yield updateLastAccessedTime(workspaceId);
            if (!(spaceAccess === null || spaceAccess === void 0 ? void 0 : spaceAccess.expiryDate)) {
                return;
            }
            store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                type: ToastType.WARNING,
                message: 'spaceExpiryReminder',
                canOverflow: true,
                data: {
                    expiryDate: formatDate(spaceAccess.expiryDate, false),
                },
                delay: 10000,
            });
        });
    },
    [FileManagerActionTypes.UPDATE_ITEM_NAME]({ commit, getters }, itemName) {
        return __awaiter(this, void 0, void 0, function* () {
            const originalFile = yield getters.selectedItemDetails;
            if (!originalFile)
                return;
            const file = {
                filename: itemName,
                fileUuid: originalFile.fileuuid,
            };
            const sameNameFile = getters.workspaceItems.find(item => {
                return originalFile.extension === item.extension && originalFile.parentUuid === item.parentUuid && itemName === item.filename;
            });
            if (sameNameFile) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: `itemNameAlreadyExist`,
                });
                return;
            }
            const renamedItem = yield renameFile(yield getters.selectedWorkspaceId, originalFile.fileUuid, file);
            if (!renamedItem) {
                return;
            }
            store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                type: ToastType.SUCCESS,
                message: `successFullyRename`,
                data: {
                    name: file.filename,
                },
            });
            commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, [renamedItem]);
            commit(FileManagerMutationTypes.SET_SELECTED_ITEM_DETAILS, Object.assign(Object.assign({}, getters.selectedItemDetails), renamedItem));
        });
    },
    [FileManagerActionTypes.ADD_WORKSPACE_ITEMS_FROM_FOLDER]({ commit }, parentUuid) {
        return __awaiter(this, void 0, void 0, function* () {
            const newItems = yield getFilesInSpaceByFolderId(store.getters.selectedWorkspace.id, parentUuid);
            commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, newItems);
        });
    },
    [FileManagerActionTypes.CLEAR_AND_UPDATE_WORKSPACE_ITEMS]({ commit }, newItems) {
        return __awaiter(this, void 0, void 0, function* () {
            commit(FileManagerMutationTypes.CLEAR_ITEMS, undefined);
            commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, newItems);
        });
    },
    [FileManagerActionTypes.SET_SELECTED_VAULT_USER]({ commit }, vaultUser) {
        return __awaiter(this, void 0, void 0, function* () {
            commit(FileManagerMutationTypes.SET_SELECTED_VAULT_USER, vaultUser);
        });
    },
    [FileManagerActionTypes.UPDATE_FOLDER]({ commit, getters }, newFolder) {
        return __awaiter(this, void 0, void 0, function* () {
            commit(FileManagerMutationTypes.UPDATE_FOLDER, newFolder);
            commit(FileManagerMutationTypes.SET_SELECTED_ITEM_DETAILS, Object.assign(Object.assign({}, getters.selectedItemDetails), newFolder));
        });
    },
    [FileManagerActionTypes.SET_SELECTED_ITEMS_IDS]({ commit, getters }, items) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!items || items.length <= 0) {
                commit(FileManagerMutationTypes.SET_SELECTED_ITEMS_IDS, []);
                commit(FileManagerMutationTypes.SET_SELECTED_ITEM_DETAILS, null);
                return;
            }
            commit(FileManagerMutationTypes.SET_SELECTED_ITEMS_IDS, items.map(i => i.id));
            //@todo if folder dont get file permissions
            if (items.length === 1) {
                const item = items[0];
                if (item.isFolder) {
                    const workspaceItem = getters.workspaceItems.find(workspaceItem => workspaceItem['folderUuid'] === item.id);
                    commit(FileManagerMutationTypes.SET_SELECTED_ITEM_DETAILS, workspaceItem);
                    return;
                }
                if (!store.getters.isVaultAdmin) {
                    const workspaceItem = getters.workspaceItems.find(workspaceItem => workspaceItem['fileUuid'] === item.id);
                    commit(FileManagerMutationTypes.SET_SELECTED_ITEM_DETAILS, workspaceItem);
                    return;
                }
                // @TODO : (make this 1 call) `getFileDetailsByFileUuid` should return `FullFileDetails` but do not include accessHistory for some reason
                const fullFileDetails = yield getFileDetailsByFileUuid(getters.selectedWorkspace.id, item.id);
                // @TODO : Find a good way to know if it is a folder or not, else there will be a non breaking error
                if (!fullFileDetails) {
                    commit(FileManagerMutationTypes.SET_SELECTED_ITEM_DETAILS, null);
                    return;
                }
                const fileAccessHistory = yield getFileAccessHistory(item.id, 0, 5);
                const selectedFile = Object.assign(Object.assign({}, fullFileDetails), { accessHistory: fileAccessHistory });
                commit(FileManagerMutationTypes.SET_SELECTED_ITEM_DETAILS, selectedFile);
                return;
            }
            commit(FileManagerMutationTypes.SET_SELECTED_ITEM_DETAILS, null);
        });
    },
    [FileManagerActionTypes.UPLOAD_FILE]({ commit, getters }, data) {
        return __awaiter(this, void 0, void 0, function* () {
            if (data.uploadedFile.name.length > 260) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: `filenameTooLong`,
                });
                return;
            }
            const { filename, ext } = yield findFileTypeExtension(data.uploadedFile);
            if (!ext) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: `fileTypeUnknown`,
                });
                return;
            }
            if (!Object.values(AcceptedFiles).includes(ext)) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: `notSupportedFileExtension`,
                    data: { extension: ext },
                });
                return;
            }
            store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                type: ToastType.INFO,
                message: `uploadingProgress`,
                data: {
                    name: filename,
                    progress: 0,
                    status: 'In progress',
                },
            });
            const uploadedData = yield uploadFile(getters.selectedWorkspace.id, data.uploadedFile, data.folderId, data.fileUuid, data.notes);
            if (!uploadedData || uploadedData.length <= 0) {
                store.commit(ToastMutationTypes.UPDATE_TOAST_PROGRESSION, {
                    type: ToastType.ERROR,
                    message: `uploadingProgress`,
                    data: {
                        name: filename,
                        progress: 100,
                        status: 'Failed',
                    },
                });
                return;
            }
            commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, uploadedData);
            // commit(FileManagerMutationTypes.SET_SELECTED_FOLDER_ID, (<BaseFolderDto>uploadedData[1]).folderUuid);
            store.commit(ToastMutationTypes.UPDATE_TOAST_PROGRESSION, {
                type: ToastType.SUCCESS,
                message: `uploadingProgress`,
                data: { name: filename, progress: 100, status: 'Complete' },
            });
        });
    },
    [FileManagerActionTypes.CHANGE_FOLDER_OF_FILE]({ commit, getters }, folderChangeDetails) {
        return __awaiter(this, void 0, void 0, function* () {
            const destinationNameFolder = getters.workspaceItems.find(item => (item.parentUuid === folderChangeDetails[0].newFolderId || (item['folderUuid'] && item['folderUuid'] === folderChangeDetails[0].newFolderId)) &&
                folderChangeDetails[0]['newFolderName'] === item.name);
            if (!destinationNameFolder) {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: `moveFailed`,
                    data: {
                        name: translate(`folder.${destinationNameFolder.name}`, destinationNameFolder.name),
                    },
                });
                return;
            }
            const oldFolder = store.getters.workspaceItems.find(workspace => workspace.folderUuid === folderChangeDetails[0].oldFolderId);
            if (oldFolder.name === 'archived') {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: `moveArchivedFailed`,
                });
                return;
            }
            const itemsInDestination = yield getFilesInSpaceByFolderId(getters.selectedWorkspaceId, folderChangeDetails[0].newFolderId);
            commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, itemsInDestination);
            const duplicateItems = [];
            const itemsToUpdate = [];
            folderChangeDetails.forEach(folderChange => {
                const destinationFolder = getters.getItemsByParentId(folderChange.newFolderId);
                const itemExist = !!destinationFolder.find(item => {
                    const tEntry = convertToTEntry(item);
                    return `${tEntry.name}.${tEntry.extension}` === `${folderChange.item.name}.${folderChange.item.extension}`;
                });
                if (!itemExist) {
                    itemsToUpdate.push({ itemId: folderChange.item.id, oldFolderId: folderChange.oldFolderId, newFolderId: folderChange.newFolderId, isFolder: folderChange.isFolder });
                    return;
                }
                duplicateItems.push(folderChange.item);
            });
            duplicateItems.forEach(duplicate => {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: `duplicate${duplicate.isFolder ? 'Folder' : 'File'}`,
                    data: {
                        destinationName: translate(`folder.${destinationNameFolder.name}`, destinationNameFolder.name),
                        sourceName: translate(`folder.${duplicate.name}`, duplicate.name),
                    },
                });
            });
            if (itemsToUpdate.length <= 0) {
                return;
            }
            const movedItems = yield changeFolderFiles(getters.selectedWorkspaceId, itemsToUpdate);
            if (!movedItems || (movedItems === null || movedItems === void 0 ? void 0 : movedItems.length) < 2) {
                return store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: `moveFailed`,
                    data: {
                        destinationName: translate(`folder.${destinationNameFolder.name}`, destinationNameFolder.name),
                        sourceName: 'Item',
                    },
                });
            }
            const updatedItems = movedItems.filter(f => !!itemsToUpdate.find(i => (f === null || f === void 0 ? void 0 : f['fileUuid']) === i.itemId || (f === null || f === void 0 ? void 0 : f['folderUuid']) === i.itemId || !(f === null || f === void 0 ? void 0 : f.parentUuid)));
            if (updatedItems.length <= 2) {
                return store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.ERROR,
                    message: itemsToUpdate.length > 1 ? `moveMultipleFailed` : `moveFailed`,
                    data: {
                        destinationName: translate(`folder.${destinationNameFolder.name}`, destinationNameFolder.name),
                        sourceName: 'Item',
                    },
                });
            }
            commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, updatedItems);
            if (updatedItems.length > 3) {
                return store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.SUCCESS,
                    message: `moveMultipleSuccessfully`,
                    data: {
                        name: translate(`folder.${destinationNameFolder.name}`, destinationNameFolder.name),
                    },
                });
            }
            if (getters.selectedItemDetails) {
                commit(FileManagerMutationTypes.SET_SELECTED_ITEM_DETAILS, Object.assign(Object.assign({}, getters.selectedItemDetails), updatedItems.find(updatedFile => { var _a; return convertToTEntry(updatedFile).id === ((_a = getters.selectedItemDetails) === null || _a === void 0 ? void 0 : _a.fileUuid); })));
            }
            if (destinationNameFolder.name !== 'archived') {
                store.commit(ToastMutationTypes.ADD_TOAST_TO_QUEUE, {
                    type: ToastType.SUCCESS,
                    message: `moveSuccessfully`,
                    data: {
                        name: translate(`folder.${destinationNameFolder.name}`, destinationNameFolder.name),
                    },
                });
            }
        });
    },
    [FileManagerActionTypes.SET_SELECTED_FOLDER_ID]({ commit, getters }, folderId) {
        return __awaiter(this, void 0, void 0, function* () {
            const newItems = yield getFilesInSpaceByFolderId(getters.selectedWorkspaceId, folderId);
            commit(FileManagerMutationTypes.ADD_ITEMS_TO_ALL_ITEMS, newItems);
            commit(FileManagerMutationTypes.SET_SELECTED_FOLDER_ID, folderId);
        });
    },
    [FileManagerActionTypes.SEARCH_IN_SELECTED_WORKSPACE]({ commit, getters }, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const { searchString, searchId, searchType } = data;
            if (!searchString) {
                return;
            }
            commit(FileManagerMutationTypes.CLEAR_SEARCH_RESULTS, undefined);
            if (!searchType) {
                return;
            }
            if (searchType === SearchType.CONTENT) {
                globalSearchFilesContent(getters.selectedWorkspaceId, searchId, searchString);
                return;
            }
            const searchResults = yield globalSearchFiles(getters.selectedWorkspaceId, searchString);
            commit(FileManagerMutationTypes.SET_SEARCH_RESULTS, searchResults);
        });
    },
};
