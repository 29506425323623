import { defineComponent } from 'vue';
import { formatDate } from '@/filters';
import { isDate } from 'moment';
import Button from '@/components/general/Button.vue';
export default defineComponent({
    name: 'DataTable',
    components: { Button },
    emits: ['showMore'],
    props: {
        name: String,
        headers: Array,
        selectedFields: Array,
        data: Array,
        hasMore: {
            type: Boolean,
            default: false,
        },
        disabledMore: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return {
            formatDate,
            isDate,
        };
    },
});
