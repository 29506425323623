import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "px-6" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Button = _resolveComponent("Button");
    const _component_modal = _resolveComponent("modal");
    return (_ctx.show)
        ? (_openBlock(), _createBlock(_component_modal, {
            key: 0,
            onClose: _cache[1] || (_cache[1] = ($event) => (_ctx.closeModal())),
            sticky: ""
        }, {
            header: _withCtx(() => [
                _renderSlot(_ctx.$slots, "header", {}, () => [
                    _createTextVNode(_toDisplayString(_ctx.$t(`${_ctx.type}.confirmation`, _ctx.data)), 1)
                ])
            ]),
            actions: _withCtx(() => [
                _createVNode(_component_Button, {
                    colored: "",
                    class: "bg-primary text-white capitalize",
                    onClick: _ctx.confirm
                }, {
                    default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "ok", {}, () => [
                            _createTextVNode(_toDisplayString(_ctx.$t('misc.ok')), 1)
                        ])
                    ]),
                    _: 3
                }, 8, ["onClick"]),
                _createVNode(_component_Button, {
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.closeModal())),
                    class: "capitalize mr-2"
                }, {
                    default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "cancel", {}, () => [
                            _createTextVNode(_toDisplayString(_ctx.$t('misc.cancel')), 1)
                        ])
                    ]),
                    _: 3
                })
            ]),
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _renderSlot(_ctx.$slots, "message", {}, () => [
                        _createTextVNode(_toDisplayString(_ctx.$t(`${_ctx.type}.confirmationMessage`, { type: _ctx.type })), 1)
                    ])
                ])
            ]),
            _: 3
        }))
        : _createCommentVNode("", true);
}
