<template>
  <div class="cursor-pointer" :class="{ relative: button }" @click.stop="toggleOpen" v-click-outside="closeDropdown">
    <div
      ref="dropdown"
      :class="{
        'rounded-md border border-gray-300 shadow-sm py-2 text-sm font-medium text-gray-700 bg-gray-50 px-4': button,
      }"
    >
      <div v-if="button" class="inline-flex justify-between items-center w-full">
        <span v-if="!selected" class="capitalize">
          {{ optionValue(options[0]) }}
        </span>
        <span v-else class="capitalize">{{ optionValue(selected) }}</span>
        <em class="fas fa-chevron-down"></em>
      </div>
      <div v-else>
        <em class="fas p-2" :class="`fa-${icon}`"></em>
      </div>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75 transform"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-show="isOpen" class="origin-top-right absolute rounded-md shadow-lg bg-white mt-2" :class="{ 'w-full': button, 'w-25': !button }" style="z-index: 9999">
        <ul class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <li
            v-for="option of options.filter(o => (selected && objectValue ? o[objectValue] !== selected[objectValue] : o !== selected))"
            v-show="option && !option.hidden"
            :key="option"
            @click.stop="changeSelected(option)"
            class="flex whitespace-nowrap px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 capitalize"
          >
            <div v-if="option.icon">
              <em class="fas" :class="`fa-${option['icon']} pl-3 pr-6`"></em>
              <span>{{ optionValue(option) }}</span>
            </div>
            <div v-else>
              {{ optionValue(option) }}
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
  import { defineComponent, ref } from 'vue';
  import { translate } from '@/filters';

  export default defineComponent({
    name: 'Dropdown',
    emits: ['update:selected', 'process-click'],
    props: {
      objectValue: {
        type: String,
        required: false,
      },
      options: Array,
      selected: Object | String,
      returnObject: {
        type: Boolean,
        default: false,
      },
      button: Boolean,
      icon: String,
      translation: String,
    },
    setup({ objectValue, returnObject, translation }, ctx) {
      const dropdown = ref(null);
      const isOpen = ref(false);

      const changeSelected = option => {
        ctx.emit('update:selected', objectValue && !returnObject ? option[objectValue] : option);
        ctx.emit('process-click', objectValue && !returnObject ? option[objectValue] : option);
        isOpen.value = false;
      };

      const toggleOpen = () => {
        if (!isOpen.value) document.getElementById('app').click();
        isOpen.value = !isOpen.value;
      };

      const closeDropdown = e => {
        const target = e.target;
        if (dropdown.value && dropdown.value.contains(target)) {
          return;
        }
        isOpen.value = false;
      };

      const optionValue = option => {
        const selectedOption = objectValue ? option[objectValue] : option;
        if (translation) {
          return translate(`${translation}.${selectedOption}`, selectedOption);
        }
        return selectedOption;
      };

      return {
        dropdown,
        isOpen,
        toggleOpen,
        changeSelected,
        closeDropdown,
        optionValue,
      };
    },
  });
</script>

<style scoped></style>
