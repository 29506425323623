import Button from '@/components/general/Button.vue';
import Modal from '@/components/general/Modal.vue';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ConfirmModal',
    components: { Button, Modal },
    emits: ['update:show', 'confirm', 'cancel'],
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        type: String,
        data: Object,
    },
    setup(_, ctx) {
        const closeModal = () => {
            ctx.emit('update:show', false);
        };
        const confirm = () => {
            closeModal();
            ctx.emit('confirm');
        };
        const cancel = () => {
            closeModal();
            ctx.emit('cancel');
        };
        return {
            confirm,
            cancel,
            closeModal,
        };
    },
});
